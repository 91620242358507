<div style="height: 40.886px;">
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="171.336" height="40.886" viewBox="0 0 171.336 40.886">
    <defs>
      <linearGradient id="linear-gradient" x1="0.128" y1="1.308" x2="0.653" y2="0.421" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#0f2b6f"/>
        <stop offset="1" stop-color="#3366e4"/>
      </linearGradient>
      <linearGradient id="linear-gradient-2" x1="0.259" y1="0.847" x2="0.652" y2="-0.098" gradientUnits="objectBoundingBox">
        <stop offset="0.004" stop-color="#3033be"/>
        <stop offset="0.901" stop-color="#30335c"/>
      </linearGradient>
      <linearGradient id="linear-gradient-3" x1="0.466" y1="0.438" x2="0.54" y2="0.572" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#3032be"/>
        <stop offset="1" stop-color="#3366e4"/>
      </linearGradient>
      <linearGradient id="linear-gradient-4" x1="0.128" y1="1.308" x2="0.654" y2="0.421" xlink:href="#linear-gradient"/>
      <linearGradient id="linear-gradient-199" x1="0.288" y1="0.499" x2="20.792" y2="0.625" gradientUnits="objectBoundingBox">
        <stop offset="0.004" stop-color="#3033be"/>
        <stop offset="0.901" stop-color="#3366e4"/>
      </linearGradient>
      <linearGradient id="linear-gradient-200" x1="-0.582" y1="0.472" x2="4.256" y2="0.598" xlink:href="#linear-gradient-199"/>
      <linearGradient id="linear-gradient-201" x1="-1.741" y1="0.43" x2="2.639" y2="0.556" xlink:href="#linear-gradient-199"/>
      <linearGradient id="linear-gradient-202" x1="-4.097" y1="0.402" x2="1.789" y2="0.528" xlink:href="#linear-gradient-199"/>
      <linearGradient id="linear-gradient-203" x1="-4.491" y1="0.37" x2="0.354" y2="0.496" xlink:href="#linear-gradient-199"/>
    </defs>
    <g id="Group_180" data-name="Group 180" transform="translate(-143.918 -292.428)">
      <g id="Group_177" data-name="Group 177" transform="translate(143.918 292.428)">
        <g id="Group_111" data-name="Group 111">
          <path id="Path_201" data-name="Path 201" d="M199.912,338.17l-.07-.192v-.012A2.069,2.069,0,0,0,199.912,338.17Z" transform="translate(-173.433 -316.462)" fill="#303391"/>
          <path id="Path_202" data-name="Path 202" d="M204.016,308.662c0,3.059,2.061,4.965,4.579,4.218l9.3-2.76a1.749,1.749,0,0,1,2.161.82,3.1,3.1,0,0,1,.383,1.571v3.182l-11.81,3.772a7.586,7.586,0,0,1-4.635.09,7.231,7.231,0,0,1-3.6-2.6,7.769,7.769,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a1.952,1.952,0,0,1-.07-.2,12,12,0,0,1-.571-3.776c0-6.907,4.683-13.657,10.231-14.967l11.845-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.881c-.284.073-.579.146-.878.223l-1.188.387A7.286,7.286,0,0,0,204.016,308.662Z" transform="translate(-172.653 -292.428)" fill="url(#linear-gradient)"/>
          <path id="Path_203" data-name="Path 203" d="M163.891,320.212c0,3.177-2.254,6.46-5.1,7.3l-11.45,3.4a5.112,5.112,0,0,0-3.421,4.7v3.726l14.831-4.736a13.968,13.968,0,0,0,5.162-3.092,18.4,18.4,0,0,0,3.788-4.969,15.8,15.8,0,0,0,.9-2.121l.015-.044.176-.451.179-.559.056-.183.086-.286c.026-.083.052-.166.071-.246a15.327,15.327,0,0,0,.573-4.108c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512v3.683a2.666,2.666,0,0,0,3.378,2.852l9.081-2.351c.336-.087.683-.181,1.033-.272l1.377-.271C161.653,315.206,163.891,317.034,163.891,320.212Z" transform="translate(-143.92 -300.813)" fill="url(#linear-gradient-2)"/>
          <path id="Path_204" data-name="Path 204" d="M191.7,321.516v2.561l-11.81,3.771a7.588,7.588,0,0,1-4.636.09,7.227,7.227,0,0,1-3.6-2.6,7.754,7.754,0,0,1-.883-1.57l-.015-.035-.174-.346-.177-.452-.056-.149-.085-.235a2.121,2.121,0,0,1-.07-.2,11.334,11.334,0,0,1-.508-2.453v-.008c-.581-6.114-5.172-9.911-10.9-8.506l-14.87,3.645V312.1l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.627,0-.016,0-.028,0-.044a11.132,11.132,0,0,0,.5,2.427,2.1,2.1,0,0,0,.07.2l.085.236.056.15.177.453.174.348.015.035a7.858,7.858,0,0,0,.883,1.578,7.315,7.315,0,0,0,3.6,2.628,7.582,7.582,0,0,0,4.636-.047Z" transform="translate(-143.918 -300.812)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_112" data-name="Group 112" transform="translate(0.102 0.036)">
          <path id="Path_205" data-name="Path 205" d="M200.127,338.247l-.07-.192v-.012A1.959,1.959,0,0,0,200.127,338.247Z" transform="translate(-173.648 -316.539)" fill="#303391"/>
          <path id="Path_206" data-name="Path 206" d="M204.231,308.738c0,3.06,2.061,4.965,4.58,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.117,3.117,0,0,1,.383,1.571v3.182l-11.81,3.772a7.583,7.583,0,0,1-4.635.09,7.376,7.376,0,0,1-3.6-2.595,8.191,8.191,0,0,1-.882-1.57l-.015-.035-.173-.346-.177-.451-.056-.149-.085-.235a1.958,1.958,0,0,1-.07-.2,12,12,0,0,1-.571-3.776c0-6.907,4.682-13.657,10.231-14.967l11.844-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.881c-.284.073-.579.146-.878.223l-1.188.387A7.286,7.286,0,0,0,204.231,308.738Z" transform="translate(-172.869 -292.505)" fill="url(#linear-gradient-4)"/>
          <path id="Path_207" data-name="Path 207" d="M164.107,320.289c0,3.177-2.254,6.46-5.1,7.3l-11.45,3.4a4.834,4.834,0,0,0-2.9,2.566,4.884,4.884,0,0,0-.524,2.133v3.726l14.83-4.736a13.97,13.97,0,0,0,5.162-3.092,18.818,18.818,0,0,0,3.787-4.969,16.649,16.649,0,0,0,.9-2.122l.015-.044.176-.451.179-.559.056-.183.086-.286c.026-.083.052-.165.071-.246a15.327,15.327,0,0,0,.573-4.108c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.272l1.378-.271C161.869,315.283,164.107,317.111,164.107,320.289Z" transform="translate(-144.136 -300.89)" fill="url(#linear-gradient-2)"/>
          <path id="Path_208" data-name="Path 208" d="M191.918,321.593v2.56l-11.81,3.772a7.586,7.586,0,0,1-4.635.09,7.378,7.378,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a1.993,1.993,0,0,1-.07-.2,11.306,11.306,0,0,1-.508-2.453v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.871,3.645v-2.933L159,308.662c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.028,0-.044a11.133,11.133,0,0,0,.5,2.427,1.973,1.973,0,0,0,.07.2l.085.236.055.15.178.453.173.348.015.035a8.315,8.315,0,0,0,.883,1.578,7.465,7.465,0,0,0,3.6,2.628,7.58,7.58,0,0,0,4.635-.047Z" transform="translate(-144.133 -300.889)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_113" data-name="Group 113" transform="translate(0.204 0.073)">
          <path id="Path_209" data-name="Path 209" d="M200.342,338.324l-.07-.192v-.012A2.068,2.068,0,0,0,200.342,338.324Z" transform="translate(-173.864 -316.616)" fill="#303391"/>
          <path id="Path_210" data-name="Path 210" d="M204.447,308.815c0,3.06,2.061,4.965,4.579,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.121,3.121,0,0,1,.383,1.571v3.182l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.37,7.37,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.569l-.015-.035-.174-.346-.177-.451-.055-.149-.085-.235c-.026-.067-.051-.135-.07-.2a12.017,12.017,0,0,1-.571-3.776c0-6.907,4.682-13.657,10.231-14.967l11.845-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.88c-.284.074-.579.146-.878.224l-1.188.387A7.286,7.286,0,0,0,204.447,308.815Z" transform="translate(-173.084 -292.582)" fill="url(#linear-gradient-4)"/>
          <path id="Path_211" data-name="Path 211" d="M164.322,320.366c0,3.177-2.254,6.46-5.1,7.3l-11.45,3.4a5.111,5.111,0,0,0-3.421,4.7v3.726l14.831-4.736a13.968,13.968,0,0,0,5.162-3.092,18.809,18.809,0,0,0,3.787-4.969,16.646,16.646,0,0,0,.9-2.122l.015-.044.176-.451.179-.559.056-.183.086-.286c.026-.083.052-.165.071-.246a15.33,15.33,0,0,0,.573-4.108c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.082-2.35c.336-.087.683-.181,1.033-.271l1.377-.271C162.084,315.36,164.322,317.188,164.322,320.366Z" transform="translate(-144.351 -300.967)" fill="url(#linear-gradient-2)"/>
          <path id="Path_212" data-name="Path 212" d="M192.134,321.67v2.56L180.324,328a7.586,7.586,0,0,1-4.635.09,7.38,7.38,0,0,1-3.6-2.6,8.214,8.214,0,0,1-.882-1.57l-.015-.035-.173-.346-.177-.451-.056-.149-.085-.235a1.969,1.969,0,0,1-.07-.2,11.33,11.33,0,0,1-.508-2.452v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.87,3.645v-2.933l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.627,0-.016,0-.028,0-.044a11.16,11.16,0,0,0,.5,2.427,1.967,1.967,0,0,0,.07.2l.085.236.056.15.177.453.173.348.015.035a8.311,8.311,0,0,0,.882,1.578,7.467,7.467,0,0,0,3.6,2.628,7.581,7.581,0,0,0,4.635-.047Z" transform="translate(-144.349 -300.966)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_114" data-name="Group 114" transform="translate(0.305 0.109)">
          <path id="Path_213" data-name="Path 213" d="M200.558,338.4l-.07-.192V338.2A2.073,2.073,0,0,0,200.558,338.4Z" transform="translate(-174.079 -316.693)" fill="#303391"/>
          <path id="Path_214" data-name="Path 214" d="M204.662,308.892c0,3.06,2.061,4.965,4.579,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.121,3.121,0,0,1,.383,1.571v3.182l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.371,7.371,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.569l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a1.957,1.957,0,0,1-.07-.2,12,12,0,0,1-.571-3.776c0-6.907,4.683-13.657,10.231-14.967l11.845-2.8V295.8a3.979,3.979,0,0,1-2.512,3.833l-7.266,1.88c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,204.662,308.892Z" transform="translate(-173.299 -292.659)" fill="url(#linear-gradient-4)"/>
          <path id="Path_215" data-name="Path 215" d="M164.538,320.443c0,3.177-2.254,6.46-5.1,7.3l-11.449,3.4a5.11,5.11,0,0,0-3.42,4.7v3.726l14.83-4.736a13.97,13.97,0,0,0,5.162-3.092,18.819,18.819,0,0,0,3.788-4.969,16.649,16.649,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.085-.286c.026-.083.052-.165.071-.246a15.323,15.323,0,0,0,.573-4.107c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.272l1.377-.271C162.3,315.437,164.538,317.265,164.538,320.443Z" transform="translate(-144.567 -301.044)" fill="url(#linear-gradient-2)"/>
          <path id="Path_216" data-name="Path 216" d="M192.349,321.747v2.56l-11.81,3.772a7.588,7.588,0,0,1-4.636.09,7.378,7.378,0,0,1-3.6-2.6,8.215,8.215,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a2.081,2.081,0,0,1-.07-.2,11.3,11.3,0,0,1-.508-2.452v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.87,3.645v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.044a11.132,11.132,0,0,0,.5,2.427,2.134,2.134,0,0,0,.07.2l.085.236.055.15.178.453.173.348.015.035a8.315,8.315,0,0,0,.883,1.578,7.472,7.472,0,0,0,3.6,2.628,7.582,7.582,0,0,0,4.636-.047Z" transform="translate(-144.564 -301.043)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_115" data-name="Group 115" transform="translate(0.407 0.145)">
          <path id="Path_217" data-name="Path 217" d="M200.773,338.478l-.07-.192v-.012A1.966,1.966,0,0,0,200.773,338.478Z" transform="translate(-174.295 -316.77)" fill="#303391"/>
          <path id="Path_218" data-name="Path 218" d="M204.878,308.969c0,3.06,2.06,4.965,4.579,4.218l9.3-2.759a1.752,1.752,0,0,1,2.161.82,3.115,3.115,0,0,1,.384,1.571V316l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.373,7.373,0,0,1-3.6-2.6,8.21,8.21,0,0,1-.882-1.569l-.015-.035-.173-.346-.177-.451-.056-.149-.085-.235a1.966,1.966,0,0,1-.07-.2,12.006,12.006,0,0,1-.571-3.777c0-6.907,4.682-13.656,10.231-14.967l11.845-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.88c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,204.878,308.969Z" transform="translate(-173.515 -292.736)" fill="url(#linear-gradient-4)"/>
          <path id="Path_219" data-name="Path 219" d="M164.753,320.52c0,3.177-2.254,6.46-5.1,7.3l-11.45,3.4a4.835,4.835,0,0,0-2.9,2.566,4.888,4.888,0,0,0-.524,2.133v3.726l14.83-4.736a13.977,13.977,0,0,0,5.162-3.092,18.827,18.827,0,0,0,3.787-4.969,16.644,16.644,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.086-.286c.026-.083.052-.165.071-.246a15.326,15.326,0,0,0,.573-4.107c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.271l1.378-.271C162.515,315.514,164.753,317.342,164.753,320.52Z" transform="translate(-144.782 -301.121)" fill="url(#linear-gradient-2)"/>
          <path id="Path_220" data-name="Path 220" d="M192.565,321.824v2.56l-11.81,3.772a7.583,7.583,0,0,1-4.635.09,7.376,7.376,0,0,1-3.6-2.595,8.215,8.215,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a1.961,1.961,0,0,1-.07-.2,11.307,11.307,0,0,1-.508-2.452V320.2c-.58-6.114-5.172-9.911-10.9-8.506l-14.87,3.645v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.044a11.138,11.138,0,0,0,.5,2.427,2.01,2.01,0,0,0,.07.2l.085.236.055.15.178.453.173.348.015.035a8.3,8.3,0,0,0,.883,1.577,7.469,7.469,0,0,0,3.6,2.628,7.58,7.58,0,0,0,4.635-.047Z" transform="translate(-144.78 -301.12)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_116" data-name="Group 116" transform="translate(0.509 0.182)">
          <path id="Path_221" data-name="Path 221" d="M200.988,338.555l-.07-.192v-.012A2.081,2.081,0,0,0,200.988,338.555Z" transform="translate(-174.51 -316.847)" fill="#303391"/>
          <path id="Path_222" data-name="Path 222" d="M205.093,309.046c0,3.06,2.061,4.965,4.579,4.218l9.3-2.759a1.752,1.752,0,0,1,2.161.82,3.12,3.12,0,0,1,.383,1.571v3.182l-11.81,3.772a7.584,7.584,0,0,1-4.636.09,7.372,7.372,0,0,1-3.6-2.6,8.212,8.212,0,0,1-.883-1.569l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a2.083,2.083,0,0,1-.07-.2,12.006,12.006,0,0,1-.571-3.777c0-6.907,4.683-13.656,10.231-14.967l11.845-2.8v3.146A3.979,3.979,0,0,1,219,299.792l-7.267,1.88c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,205.093,309.046Z" transform="translate(-173.73 -292.813)" fill="url(#linear-gradient-4)"/>
          <path id="Path_223" data-name="Path 223" d="M164.968,320.6c0,3.177-2.254,6.46-5.1,7.3l-11.45,3.4a4.835,4.835,0,0,0-2.9,2.566A4.887,4.887,0,0,0,145,336v3.726l14.831-4.736a13.974,13.974,0,0,0,5.162-3.092,18.827,18.827,0,0,0,3.788-4.969,16.651,16.651,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.086-.286c.026-.083.052-.165.071-.246a15.325,15.325,0,0,0,.573-4.107c0-6.9-4.828-11.405-10.965-9.956L145,312.483v3.683a2.665,2.665,0,0,0,3.378,2.852l9.082-2.35c.336-.087.683-.181,1.033-.272l1.377-.271C162.73,315.591,164.968,317.419,164.968,320.6Z" transform="translate(-144.997 -301.197)" fill="url(#linear-gradient-2)"/>
          <path id="Path_224" data-name="Path 224" d="M192.78,321.9v2.56l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.374,7.374,0,0,1-3.6-2.6,8.222,8.222,0,0,1-.882-1.569l-.015-.035-.174-.346-.177-.451-.056-.149-.085-.235a2.075,2.075,0,0,1-.07-.2,11.307,11.307,0,0,1-.508-2.453v-.008c-.581-6.114-5.172-9.911-10.9-8.506l-14.87,3.645v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.044a11.166,11.166,0,0,0,.5,2.427,2.123,2.123,0,0,0,.07.2l.085.236.056.15.177.453.174.348.015.035a8.337,8.337,0,0,0,.882,1.577,7.471,7.471,0,0,0,3.6,2.628,7.581,7.581,0,0,0,4.635-.047Z" transform="translate(-144.995 -301.197)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_117" data-name="Group 117" transform="translate(0.61 0.218)">
          <path id="Path_225" data-name="Path 225" d="M201.2,338.632l-.07-.192v-.012A2,2,0,0,0,201.2,338.632Z" transform="translate(-174.725 -316.924)" fill="#303391"/>
          <path id="Path_226" data-name="Path 226" d="M205.308,309.123c0,3.06,2.061,4.965,4.58,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.821,3.114,3.114,0,0,1,.383,1.57v3.183l-11.81,3.772a7.583,7.583,0,0,1-4.635.09,7.377,7.377,0,0,1-3.6-2.6,8.186,8.186,0,0,1-.882-1.569l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a1.958,1.958,0,0,1-.07-.2,12.006,12.006,0,0,1-.571-3.777c0-6.907,4.682-13.656,10.231-14.967l11.844-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.88c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,205.308,309.123Z" transform="translate(-173.946 -292.89)" fill="url(#linear-gradient-4)"/>
          <path id="Path_227" data-name="Path 227" d="M165.184,320.673c0,3.178-2.254,6.46-5.1,7.3l-11.45,3.4a4.834,4.834,0,0,0-2.9,2.566,4.888,4.888,0,0,0-.524,2.133V339.8l14.83-4.736a13.971,13.971,0,0,0,5.162-3.093A18.826,18.826,0,0,0,168.995,327a16.656,16.656,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.085-.286c.026-.083.052-.165.071-.246A15.308,15.308,0,0,0,171.05,319c0-6.9-4.828-11.405-10.965-9.956l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.272l1.378-.271C162.946,315.668,165.184,317.5,165.184,320.673Z" transform="translate(-145.213 -301.274)" fill="url(#linear-gradient-2)"/>
          <path id="Path_228" data-name="Path 228" d="M193,321.978v2.56l-11.81,3.772a7.583,7.583,0,0,1-4.635.09,7.371,7.371,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.569l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a1.962,1.962,0,0,1-.07-.2,11.306,11.306,0,0,1-.508-2.453v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.871,3.645v-2.934l14.871-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.044a11.139,11.139,0,0,0,.5,2.427,2.012,2.012,0,0,0,.07.2l.085.236.055.149.178.453.173.348.015.035a8.309,8.309,0,0,0,.883,1.577,7.469,7.469,0,0,0,3.6,2.628,7.581,7.581,0,0,0,4.635-.048Z" transform="translate(-145.21 -301.274)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_118" data-name="Group 118" transform="translate(0.712 0.255)">
          <path id="Path_229" data-name="Path 229" d="M201.419,338.709l-.07-.192v-.012A2,2,0,0,0,201.419,338.709Z" transform="translate(-174.941 -317.001)" fill="#303391"/>
          <path id="Path_230" data-name="Path 230" d="M205.524,309.2c0,3.06,2.061,4.965,4.579,4.218l9.3-2.76a1.751,1.751,0,0,1,2.161.82,3.117,3.117,0,0,1,.384,1.571v3.183L210.137,320a7.582,7.582,0,0,1-4.635.09,7.377,7.377,0,0,1-3.6-2.6,8.235,8.235,0,0,1-.882-1.569l-.015-.035-.174-.346-.177-.452-.056-.149-.085-.235a2.131,2.131,0,0,1-.07-.2,12.02,12.02,0,0,1-.571-3.777c0-6.907,4.682-13.656,10.231-14.967l11.845-2.8v3.145a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.88c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,205.524,309.2Z" transform="translate(-174.161 -292.967)" fill="url(#linear-gradient-4)"/>
          <path id="Path_231" data-name="Path 231" d="M165.4,320.75c0,3.178-2.254,6.46-5.1,7.3l-11.45,3.4a4.835,4.835,0,0,0-2.9,2.566,4.888,4.888,0,0,0-.524,2.133v3.726l14.83-4.736a13.976,13.976,0,0,0,5.162-3.093,18.805,18.805,0,0,0,3.787-4.969,16.677,16.677,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.086-.286c.026-.083.052-.165.071-.246a15.326,15.326,0,0,0,.573-4.107c0-6.9-4.828-11.405-10.965-9.956l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.082-2.35c.336-.087.683-.181,1.033-.272l1.378-.271C163.161,315.745,165.4,317.573,165.4,320.75Z" transform="translate(-145.428 -301.351)" fill="url(#linear-gradient-2)"/>
          <path id="Path_232" data-name="Path 232" d="M193.211,322.055v2.561l-11.81,3.772a7.583,7.583,0,0,1-4.635.09,7.373,7.373,0,0,1-3.6-2.6,8.21,8.21,0,0,1-.882-1.569l-.015-.035-.173-.346-.177-.451-.056-.149-.085-.235a1.959,1.959,0,0,1-.07-.2,11.308,11.308,0,0,1-.508-2.453v-.008c-.58-6.114-5.172-9.911-10.9-8.507l-14.87,3.646v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.627,0-.016,0-.029,0-.044a11.139,11.139,0,0,0,.5,2.427,2.005,2.005,0,0,0,.07.2l.085.236.056.15.177.453.173.348.015.035a8.308,8.308,0,0,0,.882,1.577,7.471,7.471,0,0,0,3.6,2.628,7.581,7.581,0,0,0,4.635-.048Z" transform="translate(-145.426 -301.351)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_119" data-name="Group 119" transform="translate(0.814 0.29)">
          <path id="Path_233" data-name="Path 233" d="M201.635,338.786l-.07-.192v-.012A2.126,2.126,0,0,0,201.635,338.786Z" transform="translate(-175.156 -317.077)" fill="#303391"/>
          <path id="Path_234" data-name="Path 234" d="M205.739,309.277c0,3.059,2.061,4.965,4.579,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.123,3.123,0,0,1,.383,1.571v3.183l-11.81,3.771a7.579,7.579,0,0,1-4.635.09,7.375,7.375,0,0,1-3.6-2.6,8.214,8.214,0,0,1-.883-1.569l-.015-.035-.173-.346-.178-.452-.055-.149-.085-.235a2,2,0,0,1-.07-.2,12.006,12.006,0,0,1-.571-3.777c0-6.907,4.683-13.656,10.231-14.967l11.845-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.266,1.88c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,205.739,309.277Z" transform="translate(-174.376 -293.043)" fill="url(#linear-gradient-4)"/>
          <path id="Path_235" data-name="Path 235" d="M165.614,320.827c0,3.177-2.254,6.46-5.1,7.3l-11.449,3.4a5.112,5.112,0,0,0-3.421,4.7v3.726l14.831-4.736a13.971,13.971,0,0,0,5.162-3.093,18.817,18.817,0,0,0,3.788-4.969,16.675,16.675,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.085-.286c.026-.083.052-.165.071-.246a15.328,15.328,0,0,0,.573-4.108c0-6.9-4.828-11.4-10.965-9.955l-14.87,3.512V316.4a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.272l1.377-.271C163.376,315.822,165.614,317.65,165.614,320.827Z" transform="translate(-145.643 -301.428)" fill="url(#linear-gradient-2)"/>
          <path id="Path_236" data-name="Path 236" d="M193.426,322.132v2.561l-11.81,3.772a7.584,7.584,0,0,1-4.636.09,7.372,7.372,0,0,1-3.6-2.6,8.209,8.209,0,0,1-.883-1.569l-.015-.035-.174-.346-.177-.451-.055-.149-.085-.235c-.025-.067-.051-.135-.07-.2a11.333,11.333,0,0,1-.508-2.453v-.008c-.581-6.114-5.172-9.911-10.9-8.506l-14.87,3.646v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.044a11.144,11.144,0,0,0,.5,2.427c.019.069.045.137.07.2l.085.236.055.15.177.453.174.348.015.035a8.3,8.3,0,0,0,.883,1.578,7.47,7.47,0,0,0,3.6,2.628,7.582,7.582,0,0,0,4.636-.048Z" transform="translate(-145.641 -301.427)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_120" data-name="Group 120" transform="translate(0.915 0.327)">
          <path id="Path_237" data-name="Path 237" d="M201.85,338.863l-.07-.192v-.012A2,2,0,0,0,201.85,338.863Z" transform="translate(-175.371 -317.154)" fill="#303391"/>
          <path id="Path_238" data-name="Path 238" d="M205.954,309.354c0,3.06,2.061,4.965,4.58,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.118,3.118,0,0,1,.383,1.571v3.183l-11.81,3.771a7.579,7.579,0,0,1-4.635.09,7.377,7.377,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.882-1.57l-.015-.035-.173-.346-.177-.452-.056-.149-.085-.235a2.008,2.008,0,0,1-.07-.2,12.009,12.009,0,0,1-.571-3.777c0-6.907,4.682-13.656,10.231-14.967l11.844-2.8v3.146a3.978,3.978,0,0,1-2.512,3.833l-7.267,1.881c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,205.954,309.354Z" transform="translate(-174.592 -293.12)" fill="url(#linear-gradient-4)"/>
          <path id="Path_239" data-name="Path 239" d="M165.83,320.9c0,3.177-2.254,6.46-5.1,7.3l-11.45,3.4a4.834,4.834,0,0,0-2.9,2.566,4.886,4.886,0,0,0-.524,2.133v3.726l14.83-4.736a13.978,13.978,0,0,0,5.162-3.093,18.816,18.816,0,0,0,3.787-4.969,16.676,16.676,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.086-.286c.026-.083.052-.165.071-.246a15.328,15.328,0,0,0,.573-4.108c0-6.9-4.828-11.4-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.272l1.378-.271C163.592,315.9,165.83,317.727,165.83,320.9Z" transform="translate(-145.859 -301.505)" fill="url(#linear-gradient-2)"/>
          <path id="Path_240" data-name="Path 240" d="M193.642,322.209v2.561l-11.81,3.772a7.583,7.583,0,0,1-4.635.09,7.374,7.374,0,0,1-3.6-2.6,8.212,8.212,0,0,1-.883-1.569l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a2,2,0,0,1-.07-.2,11.306,11.306,0,0,1-.508-2.453v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.871,3.646V312.79l14.871-3.512c5.742-1.356,10.333,2.493,10.9,8.627,0-.016,0-.029,0-.044a11.144,11.144,0,0,0,.5,2.427,2,2,0,0,0,.07.2l.085.236.055.15.178.453.173.348.015.035a8.3,8.3,0,0,0,.883,1.578,7.469,7.469,0,0,0,3.6,2.628,7.581,7.581,0,0,0,4.635-.048Z" transform="translate(-145.856 -301.504)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_121" data-name="Group 121" transform="translate(1.017 0.363)">
          <path id="Path_241" data-name="Path 241" d="M202.065,338.94l-.07-.192v-.012A2.119,2.119,0,0,0,202.065,338.94Z" transform="translate(-175.587 -317.231)" fill="#303391"/>
          <path id="Path_242" data-name="Path 242" d="M206.17,309.431c0,3.06,2.061,4.965,4.579,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.123,3.123,0,0,1,.383,1.571v3.182l-11.81,3.772a7.588,7.588,0,0,1-4.636.09,7.378,7.378,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.452-.055-.149-.085-.235c-.025-.068-.051-.135-.07-.2a12.022,12.022,0,0,1-.571-3.777c0-6.907,4.682-13.657,10.231-14.967l11.845-2.8v3.146a3.978,3.978,0,0,1-2.512,3.833l-7.267,1.881c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,206.17,309.431Z" transform="translate(-174.807 -293.197)" fill="url(#linear-gradient-4)"/>
          <path id="Path_243" data-name="Path 243" d="M166.045,320.981c0,3.177-2.254,6.46-5.1,7.3l-11.449,3.4a4.834,4.834,0,0,0-2.9,2.566,4.885,4.885,0,0,0-.524,2.133v3.726l14.831-4.736a13.967,13.967,0,0,0,5.162-3.092,18.8,18.8,0,0,0,3.787-4.969,16.639,16.639,0,0,0,.9-2.121l.015-.044.176-.451.179-.559.056-.183.086-.286c.026-.083.052-.166.071-.246a15.327,15.327,0,0,0,.573-4.108c0-6.9-4.828-11.4-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.082-2.35c.336-.087.683-.181,1.033-.272l1.377-.271C163.807,315.976,166.045,317.8,166.045,320.981Z" transform="translate(-146.074 -301.582)" fill="url(#linear-gradient-2)"/>
          <path id="Path_244" data-name="Path 244" d="M193.857,322.286v2.561l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.377,7.377,0,0,1-3.6-2.6,8.236,8.236,0,0,1-.882-1.569l-.015-.035-.174-.346-.177-.452-.056-.149-.085-.235a2.008,2.008,0,0,1-.07-.2,11.309,11.309,0,0,1-.508-2.453v-.008c-.581-6.114-5.172-9.911-10.9-8.506l-14.87,3.646v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.045a11.161,11.161,0,0,0,.5,2.427,1.981,1.981,0,0,0,.07.2l.085.236.056.15.177.453.174.348.015.035a8.3,8.3,0,0,0,.882,1.578,7.471,7.471,0,0,0,3.6,2.628,7.581,7.581,0,0,0,4.635-.048Z" transform="translate(-146.072 -301.581)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_122" data-name="Group 122" transform="translate(1.119 0.399)">
          <path id="Path_245" data-name="Path 245" d="M202.281,339.017l-.07-.192v-.012A2,2,0,0,0,202.281,339.017Z" transform="translate(-175.802 -317.308)" fill="#303391"/>
          <path id="Path_246" data-name="Path 246" d="M206.385,309.508c0,3.06,2.061,4.965,4.579,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.121,3.121,0,0,1,.383,1.571v3.182L211,320.311a7.586,7.586,0,0,1-4.635.09,7.378,7.378,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.452-.055-.149-.085-.235a2,2,0,0,1-.07-.2,12.008,12.008,0,0,1-.571-3.777c0-6.907,4.683-13.657,10.231-14.967l11.845-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.266,1.881c-.284.073-.579.146-.878.223l-1.189.388A7.285,7.285,0,0,0,206.385,309.508Z" transform="translate(-175.022 -293.274)" fill="url(#linear-gradient-4)"/>
          <path id="Path_247" data-name="Path 247" d="M166.261,321.058c0,3.177-2.254,6.46-5.1,7.3l-11.45,3.4a4.833,4.833,0,0,0-2.9,2.566,4.884,4.884,0,0,0-.524,2.133v3.726l14.83-4.736a13.964,13.964,0,0,0,5.162-3.092,18.812,18.812,0,0,0,3.788-4.969,16.645,16.645,0,0,0,.9-2.121l.015-.044.176-.451.179-.559.056-.183.085-.286c.026-.083.052-.166.071-.246a15.308,15.308,0,0,0,.573-4.108c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.272l1.378-.271C164.023,316.053,166.261,317.88,166.261,321.058Z" transform="translate(-146.29 -301.659)" fill="url(#linear-gradient-2)"/>
          <path id="Path_248" data-name="Path 248" d="M194.072,322.362v2.561l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.374,7.374,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.452-.055-.149-.085-.235a2.007,2.007,0,0,1-.07-.2,11.308,11.308,0,0,1-.508-2.453v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.87,3.646v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.028,0-.044a11.133,11.133,0,0,0,.5,2.427,1.976,1.976,0,0,0,.07.2l.085.236.055.15.178.453.173.348.015.035a8.3,8.3,0,0,0,.883,1.578,7.469,7.469,0,0,0,3.6,2.628,7.584,7.584,0,0,0,4.635-.048Z" transform="translate(-146.287 -301.658)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_123" data-name="Group 123" transform="translate(1.221 0.436)">
          <path id="Path_249" data-name="Path 249" d="M202.5,339.094l-.07-.192v-.012A1.962,1.962,0,0,0,202.5,339.094Z" transform="translate(-176.018 -317.385)" fill="#303391"/>
          <path id="Path_250" data-name="Path 250" d="M206.6,309.585c0,3.06,2.06,4.965,4.579,4.218l9.3-2.76a1.751,1.751,0,0,1,2.161.82,3.116,3.116,0,0,1,.384,1.571v3.182l-11.81,3.772a7.586,7.586,0,0,1-4.635.09,7.379,7.379,0,0,1-3.6-2.6,8.238,8.238,0,0,1-.882-1.57l-.015-.035-.174-.346-.177-.451-.056-.149-.085-.235a1.972,1.972,0,0,1-.07-.2,12,12,0,0,1-.571-3.776c0-6.907,4.682-13.657,10.231-14.967l11.845-2.8V296.5a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.881c-.284.073-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,206.6,309.585Z" transform="translate(-175.238 -293.351)" fill="url(#linear-gradient-4)"/>
          <path id="Path_251" data-name="Path 251" d="M166.476,321.135c0,3.177-2.254,6.46-5.1,7.3l-11.449,3.4a4.834,4.834,0,0,0-2.9,2.566,4.884,4.884,0,0,0-.524,2.133v3.726l14.83-4.736a13.969,13.969,0,0,0,5.162-3.092,18.8,18.8,0,0,0,3.787-4.969,16.671,16.671,0,0,0,.9-2.121l.015-.044.176-.451.179-.559.056-.183.086-.286c.026-.083.052-.166.071-.246a15.327,15.327,0,0,0,.573-4.108c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512V316.7a2.665,2.665,0,0,0,3.378,2.852l9.082-2.351c.335-.087.682-.18,1.033-.271l1.378-.271C164.238,316.13,166.476,317.957,166.476,321.135Z" transform="translate(-146.505 -301.736)" fill="url(#linear-gradient-2)"/>
          <path id="Path_252" data-name="Path 252" d="M194.288,322.439V325l-11.81,3.771a7.579,7.579,0,0,1-4.635.09,7.377,7.377,0,0,1-3.6-2.6,8.176,8.176,0,0,1-.882-1.57l-.015-.035-.173-.346-.177-.452-.056-.149-.085-.235a2,2,0,0,1-.07-.2,11.306,11.306,0,0,1-.508-2.453v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.87,3.646v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.028,0-.044a11.132,11.132,0,0,0,.5,2.427,1.977,1.977,0,0,0,.07.2l.085.236.056.15.177.453.173.348.015.035a8.273,8.273,0,0,0,.882,1.578,7.471,7.471,0,0,0,3.6,2.628,7.584,7.584,0,0,0,4.635-.048Z" transform="translate(-146.503 -301.735)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_124" data-name="Group 124" transform="translate(1.322 0.472)">
          <path id="Path_253" data-name="Path 253" d="M202.712,339.17l-.07-.192v-.012A2.073,2.073,0,0,0,202.712,339.17Z" transform="translate(-176.233 -317.462)" fill="#303391"/>
          <path id="Path_254" data-name="Path 254" d="M206.816,309.662c0,3.059,2.061,4.965,4.579,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.121,3.121,0,0,1,.383,1.571v3.182l-11.81,3.772a7.586,7.586,0,0,1-4.635.09,7.378,7.378,0,0,1-3.6-2.6,8.214,8.214,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a1.959,1.959,0,0,1-.07-.2,12,12,0,0,1-.571-3.776c0-6.907,4.683-13.657,10.231-14.967l11.845-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.881c-.284.073-.579.146-.878.223l-1.188.387A7.286,7.286,0,0,0,206.816,309.662Z" transform="translate(-175.453 -293.428)" fill="url(#linear-gradient-4)"/>
          <path id="Path_255" data-name="Path 255" d="M166.691,321.212c0,3.177-2.254,6.46-5.1,7.3l-11.449,3.4a5.112,5.112,0,0,0-3.421,4.7v3.726l14.831-4.736a13.967,13.967,0,0,0,5.162-3.092,18.812,18.812,0,0,0,3.788-4.969,16.671,16.671,0,0,0,.9-2.121l.015-.044.176-.451.179-.559.056-.183.086-.286c.026-.083.052-.166.071-.246a15.327,15.327,0,0,0,.573-4.108c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512v3.683a2.666,2.666,0,0,0,3.378,2.852l9.081-2.351c.336-.087.683-.181,1.033-.272l1.377-.271C164.453,316.206,166.691,318.034,166.691,321.212Z" transform="translate(-146.72 -301.813)" fill="url(#linear-gradient-2)"/>
          <path id="Path_256" data-name="Path 256" d="M194.5,322.516v2.561l-11.81,3.771a7.588,7.588,0,0,1-4.636.09,7.376,7.376,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.57l-.015-.035-.174-.346-.177-.452-.056-.149-.085-.235a2.117,2.117,0,0,1-.07-.2,11.335,11.335,0,0,1-.508-2.453v-.008c-.581-6.114-5.172-9.911-10.9-8.506l-14.87,3.645V313.1l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.028,0-.044a11.131,11.131,0,0,0,.5,2.427,2.09,2.09,0,0,0,.07.2l.085.236.056.15.177.453.174.348.015.035a8.3,8.3,0,0,0,.883,1.578,7.464,7.464,0,0,0,3.6,2.628,7.582,7.582,0,0,0,4.636-.047Z" transform="translate(-146.718 -301.812)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_125" data-name="Group 125" transform="translate(1.424 0.509)">
          <path id="Path_257" data-name="Path 257" d="M202.927,339.247l-.07-.192v-.012A1.963,1.963,0,0,0,202.927,339.247Z" transform="translate(-176.448 -317.539)" fill="#303391"/>
          <path id="Path_258" data-name="Path 258" d="M207.031,309.738c0,3.06,2.061,4.965,4.58,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.116,3.116,0,0,1,.383,1.571v3.182l-11.81,3.772a7.583,7.583,0,0,1-4.635.09,7.376,7.376,0,0,1-3.6-2.595,8.19,8.19,0,0,1-.882-1.57l-.015-.035-.173-.346-.177-.451-.056-.149-.085-.235a1.956,1.956,0,0,1-.07-.2,12,12,0,0,1-.571-3.776c0-6.907,4.682-13.657,10.231-14.967l11.844-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.881c-.284.073-.579.146-.878.223l-1.188.387A7.286,7.286,0,0,0,207.031,309.738Z" transform="translate(-175.669 -293.505)" fill="url(#linear-gradient-4)"/>
          <path id="Path_259" data-name="Path 259" d="M166.907,321.289c0,3.177-2.254,6.46-5.1,7.3l-11.449,3.4a4.834,4.834,0,0,0-2.9,2.566,4.884,4.884,0,0,0-.524,2.133v3.726l14.83-4.736a13.97,13.97,0,0,0,5.162-3.092,18.818,18.818,0,0,0,3.787-4.969,16.652,16.652,0,0,0,.9-2.122l.015-.044.176-.451.179-.559.056-.183.086-.286c.026-.083.052-.165.071-.246a15.327,15.327,0,0,0,.573-4.108c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.272l1.378-.271C164.669,316.283,166.907,318.111,166.907,321.289Z" transform="translate(-146.936 -301.89)" fill="url(#linear-gradient-2)"/>
          <path id="Path_260" data-name="Path 260" d="M194.718,322.593v2.56l-11.81,3.772a7.586,7.586,0,0,1-4.635.09,7.377,7.377,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a2,2,0,0,1-.07-.2,11.305,11.305,0,0,1-.508-2.453v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.871,3.645v-2.933l14.871-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.028,0-.044a11.133,11.133,0,0,0,.5,2.427,1.98,1.98,0,0,0,.07.2l.085.236.055.15.178.453.173.348.015.035a8.314,8.314,0,0,0,.883,1.578,7.466,7.466,0,0,0,3.6,2.628,7.58,7.58,0,0,0,4.635-.047Z" transform="translate(-146.933 -301.889)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_126" data-name="Group 126" transform="translate(1.526 0.545)">
          <path id="Path_261" data-name="Path 261" d="M203.142,339.324l-.07-.192v-.012A2.078,2.078,0,0,0,203.142,339.324Z" transform="translate(-176.664 -317.616)" fill="#303391"/>
          <path id="Path_262" data-name="Path 262" d="M207.247,309.815c0,3.06,2.061,4.965,4.579,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.121,3.121,0,0,1,.383,1.571v3.182l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.37,7.37,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.569l-.015-.035-.174-.346-.177-.451-.055-.149-.085-.235c-.026-.067-.051-.135-.07-.2a12.017,12.017,0,0,1-.571-3.776c0-6.907,4.682-13.657,10.231-14.967l11.845-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.88c-.284.074-.579.146-.878.224l-1.188.387A7.286,7.286,0,0,0,207.247,309.815Z" transform="translate(-175.884 -293.582)" fill="url(#linear-gradient-4)"/>
          <path id="Path_263" data-name="Path 263" d="M167.122,321.366c0,3.177-2.254,6.46-5.1,7.3l-11.449,3.4a5.111,5.111,0,0,0-3.421,4.7v3.726l14.831-4.736a13.967,13.967,0,0,0,5.162-3.092,18.808,18.808,0,0,0,3.787-4.969,16.646,16.646,0,0,0,.9-2.122l.015-.044.176-.451.179-.559.056-.183.086-.286c.026-.083.052-.165.071-.246a15.33,15.33,0,0,0,.573-4.108c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.082-2.35c.336-.087.683-.181,1.033-.272l1.377-.271C164.884,316.36,167.122,318.188,167.122,321.366Z" transform="translate(-147.151 -301.967)" fill="url(#linear-gradient-2)"/>
          <path id="Path_264" data-name="Path 264" d="M194.934,322.67v2.56L183.124,329a7.586,7.586,0,0,1-4.635.09,7.379,7.379,0,0,1-3.6-2.6,8.214,8.214,0,0,1-.882-1.57l-.015-.035-.173-.346-.177-.451-.056-.149-.085-.235a1.97,1.97,0,0,1-.07-.2,11.332,11.332,0,0,1-.508-2.452v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.87,3.645v-2.933l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.627,0-.016,0-.028,0-.044a11.161,11.161,0,0,0,.5,2.427,1.97,1.97,0,0,0,.07.2l.085.236.056.15.177.453.173.348.015.035a8.313,8.313,0,0,0,.882,1.578,7.467,7.467,0,0,0,3.6,2.628,7.581,7.581,0,0,0,4.635-.047Z" transform="translate(-147.149 -301.966)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_127" data-name="Group 127" transform="translate(1.627 0.581)">
          <path id="Path_265" data-name="Path 265" d="M203.358,339.4l-.07-.192V339.2A2.084,2.084,0,0,0,203.358,339.4Z" transform="translate(-176.879 -317.693)" fill="#303391"/>
          <path id="Path_266" data-name="Path 266" d="M207.462,309.892c0,3.06,2.061,4.965,4.579,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.121,3.121,0,0,1,.383,1.571v3.182l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.372,7.372,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.569l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a1.955,1.955,0,0,1-.07-.2,12,12,0,0,1-.571-3.776c0-6.907,4.683-13.657,10.231-14.967l11.845-2.8V296.8a3.979,3.979,0,0,1-2.512,3.833l-7.266,1.88c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,207.462,309.892Z" transform="translate(-176.099 -293.659)" fill="url(#linear-gradient-4)"/>
          <path id="Path_267" data-name="Path 267" d="M167.338,321.443c0,3.177-2.254,6.46-5.1,7.3l-11.449,3.4a5.11,5.11,0,0,0-3.42,4.7v3.726l14.83-4.736a13.97,13.97,0,0,0,5.162-3.092,18.82,18.82,0,0,0,3.788-4.969,16.651,16.651,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.085-.286c.026-.083.052-.165.071-.246a15.323,15.323,0,0,0,.573-4.107c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.272l1.377-.271C165.1,316.437,167.338,318.265,167.338,321.443Z" transform="translate(-147.367 -302.044)" fill="url(#linear-gradient-2)"/>
          <path id="Path_268" data-name="Path 268" d="M195.149,322.747v2.56l-11.81,3.772a7.587,7.587,0,0,1-4.636.09,7.378,7.378,0,0,1-3.6-2.6,8.215,8.215,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a2.077,2.077,0,0,1-.07-.2,11.3,11.3,0,0,1-.508-2.452v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.87,3.645v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.044a11.134,11.134,0,0,0,.5,2.427,2.132,2.132,0,0,0,.07.2l.085.236.055.15.178.453.173.348.015.035a8.314,8.314,0,0,0,.883,1.578,7.472,7.472,0,0,0,3.6,2.628,7.582,7.582,0,0,0,4.636-.047Z" transform="translate(-147.364 -302.043)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_128" data-name="Group 128" transform="translate(1.729 0.618)">
          <path id="Path_269" data-name="Path 269" d="M203.573,339.478l-.07-.192v-.012A1.963,1.963,0,0,0,203.573,339.478Z" transform="translate(-177.095 -317.77)" fill="#303391"/>
          <path id="Path_270" data-name="Path 270" d="M207.678,309.969c0,3.06,2.06,4.965,4.579,4.218l9.3-2.759a1.752,1.752,0,0,1,2.161.82,3.115,3.115,0,0,1,.384,1.571V317l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.373,7.373,0,0,1-3.6-2.6,8.208,8.208,0,0,1-.882-1.569l-.015-.035-.173-.346-.177-.451-.056-.149-.085-.235a1.959,1.959,0,0,1-.07-.2,12.006,12.006,0,0,1-.571-3.777c0-6.907,4.682-13.656,10.231-14.967l11.845-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.88c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,207.678,309.969Z" transform="translate(-176.315 -293.736)" fill="url(#linear-gradient-4)"/>
          <path id="Path_271" data-name="Path 271" d="M167.553,321.52c0,3.177-2.254,6.46-5.1,7.3L151,332.221a4.835,4.835,0,0,0-2.9,2.566,4.887,4.887,0,0,0-.524,2.133v3.726l14.83-4.736a13.976,13.976,0,0,0,5.162-3.092,18.825,18.825,0,0,0,3.787-4.969,16.645,16.645,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.086-.286c.026-.083.052-.165.071-.246a15.326,15.326,0,0,0,.573-4.107c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.272l1.378-.271C165.315,316.514,167.553,318.342,167.553,321.52Z" transform="translate(-147.582 -302.121)" fill="url(#linear-gradient-2)"/>
          <path id="Path_272" data-name="Path 272" d="M195.365,322.824v2.56l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.376,7.376,0,0,1-3.6-2.595,8.216,8.216,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a1.959,1.959,0,0,1-.07-.2,11.308,11.308,0,0,1-.508-2.452V321.2c-.58-6.114-5.172-9.911-10.9-8.506l-14.87,3.645v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.044a11.139,11.139,0,0,0,.5,2.427,2,2,0,0,0,.07.2l.085.236.055.15.178.453.173.348.015.035a8.3,8.3,0,0,0,.883,1.577,7.469,7.469,0,0,0,3.6,2.628,7.58,7.58,0,0,0,4.635-.047Z" transform="translate(-147.58 -302.12)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_129" data-name="Group 129" transform="translate(1.831 0.654)">
          <path id="Path_273" data-name="Path 273" d="M203.788,339.555l-.07-.192v-.012A2.089,2.089,0,0,0,203.788,339.555Z" transform="translate(-177.31 -317.847)" fill="#303391"/>
          <path id="Path_274" data-name="Path 274" d="M207.893,310.046c0,3.06,2.061,4.965,4.579,4.218l9.3-2.759a1.752,1.752,0,0,1,2.161.82,3.12,3.12,0,0,1,.383,1.571v3.182l-11.81,3.772a7.584,7.584,0,0,1-4.636.09,7.372,7.372,0,0,1-3.6-2.6,8.213,8.213,0,0,1-.883-1.569l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a2.087,2.087,0,0,1-.07-.2,12.006,12.006,0,0,1-.571-3.777c0-6.907,4.683-13.656,10.231-14.967l11.845-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.88c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,207.893,310.046Z" transform="translate(-176.53 -293.813)" fill="url(#linear-gradient-4)"/>
          <path id="Path_275" data-name="Path 275" d="M167.768,321.6c0,3.177-2.254,6.46-5.1,7.3l-11.449,3.4a4.835,4.835,0,0,0-2.9,2.566A4.888,4.888,0,0,0,147.8,337v3.726l14.831-4.736a13.974,13.974,0,0,0,5.162-3.092,18.827,18.827,0,0,0,3.788-4.969,16.649,16.649,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.086-.286c.026-.083.052-.165.071-.246a15.325,15.325,0,0,0,.573-4.107c0-6.9-4.828-11.405-10.965-9.956l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.082-2.35c.336-.087.683-.181,1.033-.272l1.377-.271C165.53,316.591,167.768,318.419,167.768,321.6Z" transform="translate(-147.797 -302.197)" fill="url(#linear-gradient-2)"/>
          <path id="Path_276" data-name="Path 276" d="M195.58,322.9v2.56l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.374,7.374,0,0,1-3.6-2.6,8.223,8.223,0,0,1-.882-1.569l-.015-.035-.174-.346-.177-.451-.056-.149-.085-.235a2.083,2.083,0,0,1-.07-.2,11.308,11.308,0,0,1-.508-2.453v-.008c-.581-6.114-5.172-9.911-10.9-8.506l-14.87,3.645v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.044a11.168,11.168,0,0,0,.5,2.427,2.14,2.14,0,0,0,.07.2l.085.236.056.15.177.453.174.348.015.035a8.335,8.335,0,0,0,.882,1.577,7.471,7.471,0,0,0,3.6,2.628,7.581,7.581,0,0,0,4.635-.047Z" transform="translate(-147.795 -302.197)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_130" data-name="Group 130" transform="translate(1.932 0.69)">
          <path id="Path_277" data-name="Path 277" d="M204,339.632l-.07-.192v-.012A2,2,0,0,0,204,339.632Z" transform="translate(-177.525 -317.924)" fill="#303391"/>
          <path id="Path_278" data-name="Path 278" d="M208.108,310.123c0,3.06,2.061,4.965,4.58,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.821,3.114,3.114,0,0,1,.383,1.57v3.183l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.377,7.377,0,0,1-3.6-2.6,8.186,8.186,0,0,1-.882-1.569l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a1.958,1.958,0,0,1-.07-.2,12.006,12.006,0,0,1-.571-3.777c0-6.907,4.682-13.656,10.231-14.967l11.844-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.88c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,208.108,310.123Z" transform="translate(-176.746 -293.89)" fill="url(#linear-gradient-4)"/>
          <path id="Path_279" data-name="Path 279" d="M167.984,321.673c0,3.178-2.254,6.46-5.1,7.3l-11.449,3.4a4.834,4.834,0,0,0-2.9,2.566,4.888,4.888,0,0,0-.524,2.133V340.8l14.83-4.736a13.971,13.971,0,0,0,5.162-3.093A18.825,18.825,0,0,0,171.795,328a16.651,16.651,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.085-.286c.026-.083.052-.165.071-.246A15.308,15.308,0,0,0,173.85,320c0-6.9-4.828-11.405-10.965-9.956l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.272l1.378-.271C165.746,316.668,167.984,318.5,167.984,321.673Z" transform="translate(-148.013 -302.274)" fill="url(#linear-gradient-2)"/>
          <path id="Path_280" data-name="Path 280" d="M195.8,322.978v2.56l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.371,7.371,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.569l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a1.967,1.967,0,0,1-.07-.2,11.307,11.307,0,0,1-.508-2.453v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.871,3.645v-2.934l14.871-3.512c5.742-1.356,10.333,2.493,10.9,8.627,0-.016,0-.029,0-.044a11.139,11.139,0,0,0,.5,2.427,2.011,2.011,0,0,0,.07.2l.085.236.055.149.178.453.173.348.015.035a8.308,8.308,0,0,0,.883,1.577,7.469,7.469,0,0,0,3.6,2.628,7.581,7.581,0,0,0,4.635-.048Z" transform="translate(-148.01 -302.274)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_131" data-name="Group 131" transform="translate(2.034 0.727)">
          <path id="Path_281" data-name="Path 281" d="M204.219,339.709l-.07-.192v-.012A2,2,0,0,0,204.219,339.709Z" transform="translate(-177.741 -318.001)" fill="#303391"/>
          <path id="Path_282" data-name="Path 282" d="M208.324,310.2c0,3.06,2.061,4.965,4.579,4.218l9.3-2.76a1.751,1.751,0,0,1,2.161.82,3.117,3.117,0,0,1,.384,1.571v3.183L212.937,321a7.582,7.582,0,0,1-4.635.09,7.377,7.377,0,0,1-3.6-2.6,8.236,8.236,0,0,1-.882-1.569l-.015-.035-.174-.346-.177-.452-.056-.149-.085-.235a2.121,2.121,0,0,1-.07-.2,12.019,12.019,0,0,1-.571-3.777c0-6.907,4.682-13.656,10.231-14.967l11.845-2.8v3.145a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.88c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,208.324,310.2Z" transform="translate(-176.961 -293.967)" fill="url(#linear-gradient-4)"/>
          <path id="Path_283" data-name="Path 283" d="M168.2,321.75c0,3.178-2.254,6.46-5.1,7.3l-11.449,3.4a4.835,4.835,0,0,0-2.9,2.566,4.887,4.887,0,0,0-.524,2.133v3.726l14.83-4.736a13.977,13.977,0,0,0,5.162-3.093,18.805,18.805,0,0,0,3.787-4.969,16.68,16.68,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.086-.286c.026-.083.052-.165.071-.246a15.326,15.326,0,0,0,.573-4.107c0-6.9-4.828-11.405-10.965-9.956l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.082-2.35c.336-.087.683-.181,1.033-.272l1.378-.271C165.961,316.745,168.2,318.573,168.2,321.75Z" transform="translate(-148.228 -302.351)" fill="url(#linear-gradient-2)"/>
          <path id="Path_284" data-name="Path 284" d="M196.011,323.055v2.561l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.373,7.373,0,0,1-3.6-2.6,8.213,8.213,0,0,1-.882-1.569l-.015-.035-.173-.346-.177-.451-.056-.149-.085-.235a1.958,1.958,0,0,1-.07-.2,11.306,11.306,0,0,1-.508-2.453v-.008c-.58-6.114-5.172-9.911-10.9-8.507l-14.87,3.646v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.044a11.136,11.136,0,0,0,.5,2.427,2,2,0,0,0,.07.2l.085.236.056.15.177.453.173.348.015.035a8.311,8.311,0,0,0,.882,1.577,7.471,7.471,0,0,0,3.6,2.628,7.581,7.581,0,0,0,4.635-.048Z" transform="translate(-148.226 -302.351)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_132" data-name="Group 132" transform="translate(2.136 0.763)">
          <path id="Path_285" data-name="Path 285" d="M204.435,339.786l-.07-.192v-.012A2.122,2.122,0,0,0,204.435,339.786Z" transform="translate(-177.956 -318.077)" fill="#303391"/>
          <path id="Path_286" data-name="Path 286" d="M208.539,310.277c0,3.059,2.061,4.965,4.579,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.123,3.123,0,0,1,.383,1.571v3.183l-11.81,3.771a7.579,7.579,0,0,1-4.635.09,7.375,7.375,0,0,1-3.6-2.6,8.213,8.213,0,0,1-.883-1.569l-.015-.035-.173-.346-.178-.452-.055-.149-.085-.235a2,2,0,0,1-.07-.2,12.005,12.005,0,0,1-.571-3.777c0-6.907,4.683-13.656,10.231-14.967l11.845-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.266,1.88c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,208.539,310.277Z" transform="translate(-177.176 -294.043)" fill="url(#linear-gradient-4)"/>
          <path id="Path_287" data-name="Path 287" d="M168.414,321.827c0,3.177-2.254,6.46-5.1,7.3l-11.45,3.4a5.112,5.112,0,0,0-3.421,4.7v3.726l14.831-4.736a13.971,13.971,0,0,0,5.162-3.093,18.817,18.817,0,0,0,3.788-4.969,16.679,16.679,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.085-.286c.026-.083.052-.165.071-.246a15.327,15.327,0,0,0,.573-4.108c0-6.9-4.828-11.4-10.965-9.955l-14.87,3.512V317.4a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.272l1.377-.271C166.176,316.822,168.414,318.65,168.414,321.827Z" transform="translate(-148.443 -302.428)" fill="url(#linear-gradient-2)"/>
          <path id="Path_288" data-name="Path 288" d="M196.226,323.132v2.561l-11.81,3.772a7.584,7.584,0,0,1-4.636.09,7.372,7.372,0,0,1-3.6-2.6,8.211,8.211,0,0,1-.883-1.569l-.015-.035-.174-.346-.177-.451-.055-.149-.085-.235c-.026-.067-.051-.135-.07-.2a11.334,11.334,0,0,1-.508-2.453v-.008c-.581-6.114-5.172-9.911-10.9-8.506l-14.87,3.646v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.044a11.145,11.145,0,0,0,.5,2.427c.019.069.045.137.07.2l.085.236.055.15.177.453.174.348.015.035a8.3,8.3,0,0,0,.883,1.578,7.47,7.47,0,0,0,3.6,2.628,7.582,7.582,0,0,0,4.636-.048Z" transform="translate(-148.441 -302.427)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_133" data-name="Group 133" transform="translate(2.237 0.799)">
          <path id="Path_289" data-name="Path 289" d="M204.65,339.863l-.07-.192v-.012A2,2,0,0,0,204.65,339.863Z" transform="translate(-178.171 -318.154)" fill="#303391"/>
          <path id="Path_290" data-name="Path 290" d="M208.754,310.354c0,3.06,2.061,4.965,4.58,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.118,3.118,0,0,1,.383,1.571v3.183l-11.81,3.771a7.579,7.579,0,0,1-4.635.09,7.377,7.377,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.882-1.57l-.015-.035-.173-.346-.177-.452-.056-.149-.085-.235a2,2,0,0,1-.07-.2,12.008,12.008,0,0,1-.571-3.777c0-6.907,4.682-13.656,10.231-14.967l11.844-2.8v3.146a3.978,3.978,0,0,1-2.512,3.833l-7.267,1.881c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,208.754,310.354Z" transform="translate(-177.392 -294.12)" fill="url(#linear-gradient-4)"/>
          <path id="Path_291" data-name="Path 291" d="M168.63,321.9c0,3.177-2.254,6.46-5.1,7.3l-11.449,3.4a4.834,4.834,0,0,0-2.9,2.566,4.886,4.886,0,0,0-.524,2.133v3.726l14.83-4.736a13.977,13.977,0,0,0,5.162-3.093,18.815,18.815,0,0,0,3.787-4.969,16.68,16.68,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.086-.286c.026-.083.052-.165.071-.246a15.327,15.327,0,0,0,.573-4.108c0-6.9-4.828-11.4-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.272l1.378-.271C166.392,316.9,168.63,318.727,168.63,321.9Z" transform="translate(-148.659 -302.505)" fill="url(#linear-gradient-2)"/>
          <path id="Path_292" data-name="Path 292" d="M196.442,323.209v2.561l-11.81,3.772a7.583,7.583,0,0,1-4.635.09,7.374,7.374,0,0,1-3.6-2.6,8.212,8.212,0,0,1-.883-1.569l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a2,2,0,0,1-.07-.2,11.306,11.306,0,0,1-.508-2.453v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.871,3.646V313.79l14.871-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.044a11.143,11.143,0,0,0,.5,2.427,2.009,2.009,0,0,0,.07.2l.085.236.055.15.178.453.173.348.015.035a8.3,8.3,0,0,0,.883,1.578,7.469,7.469,0,0,0,3.6,2.628,7.581,7.581,0,0,0,4.635-.048Z" transform="translate(-148.656 -302.504)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_134" data-name="Group 134" transform="translate(2.339 0.835)">
          <path id="Path_293" data-name="Path 293" d="M204.865,339.94l-.07-.192v-.012A2.125,2.125,0,0,0,204.865,339.94Z" transform="translate(-178.387 -318.231)" fill="#303391"/>
          <path id="Path_294" data-name="Path 294" d="M208.97,310.431c0,3.06,2.061,4.965,4.579,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.123,3.123,0,0,1,.383,1.571v3.182l-11.81,3.772a7.588,7.588,0,0,1-4.636.09,7.378,7.378,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.452-.055-.149-.085-.235c-.025-.068-.051-.135-.07-.2a12.022,12.022,0,0,1-.571-3.777c0-6.907,4.682-13.657,10.231-14.967l11.845-2.8v3.146a3.978,3.978,0,0,1-2.512,3.833l-7.267,1.881c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,208.97,310.431Z" transform="translate(-177.607 -294.197)" fill="url(#linear-gradient-4)"/>
          <path id="Path_295" data-name="Path 295" d="M168.845,321.981c0,3.177-2.254,6.46-5.1,7.3l-11.45,3.4a4.834,4.834,0,0,0-2.9,2.566,4.885,4.885,0,0,0-.524,2.133v3.726l14.831-4.736a13.968,13.968,0,0,0,5.162-3.092,18.8,18.8,0,0,0,3.787-4.969,16.639,16.639,0,0,0,.9-2.121l.015-.044.176-.451.179-.559.056-.183.086-.286c.026-.083.052-.166.071-.246a15.327,15.327,0,0,0,.573-4.108c0-6.9-4.828-11.4-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.082-2.35c.336-.087.683-.181,1.033-.272l1.377-.271C166.607,316.976,168.845,318.8,168.845,321.981Z" transform="translate(-148.874 -302.582)" fill="url(#linear-gradient-2)"/>
          <path id="Path_296" data-name="Path 296" d="M196.657,323.286v2.561l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.377,7.377,0,0,1-3.6-2.6,8.234,8.234,0,0,1-.882-1.569l-.015-.035-.174-.346-.177-.452-.056-.149-.085-.235a2.009,2.009,0,0,1-.07-.2,11.309,11.309,0,0,1-.508-2.453v-.008c-.581-6.114-5.172-9.911-10.9-8.506l-14.87,3.646v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.045a11.161,11.161,0,0,0,.5,2.427,1.985,1.985,0,0,0,.07.2l.085.236.056.15.177.453.174.348.015.035a8.3,8.3,0,0,0,.882,1.578,7.471,7.471,0,0,0,3.6,2.628,7.581,7.581,0,0,0,4.635-.048Z" transform="translate(-148.872 -302.581)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_135" data-name="Group 135" transform="translate(2.441 0.872)">
          <path id="Path_297" data-name="Path 297" d="M205.081,340.017l-.07-.192v-.012A2,2,0,0,0,205.081,340.017Z" transform="translate(-178.602 -318.308)" fill="#303391"/>
          <path id="Path_298" data-name="Path 298" d="M209.185,310.508c0,3.06,2.061,4.965,4.579,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.121,3.121,0,0,1,.383,1.571v3.182l-11.81,3.772a7.586,7.586,0,0,1-4.635.09,7.377,7.377,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.452-.055-.149-.085-.235a2,2,0,0,1-.07-.2,12.008,12.008,0,0,1-.571-3.777c0-6.907,4.683-13.657,10.231-14.967l11.845-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.266,1.881c-.284.073-.579.146-.878.223l-1.189.388A7.285,7.285,0,0,0,209.185,310.508Z" transform="translate(-177.822 -294.274)" fill="url(#linear-gradient-4)"/>
          <path id="Path_299" data-name="Path 299" d="M169.061,322.058c0,3.177-2.254,6.46-5.1,7.3l-11.45,3.4a4.833,4.833,0,0,0-2.9,2.566,4.884,4.884,0,0,0-.524,2.133v3.726l14.83-4.736a13.964,13.964,0,0,0,5.162-3.092,18.811,18.811,0,0,0,3.788-4.969,16.645,16.645,0,0,0,.9-2.121l.015-.044.176-.451.179-.559.056-.183.085-.286c.026-.083.052-.166.071-.246a15.309,15.309,0,0,0,.573-4.108c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.272l1.378-.271C166.823,317.053,169.061,318.88,169.061,322.058Z" transform="translate(-149.09 -302.659)" fill="url(#linear-gradient-2)"/>
          <path id="Path_300" data-name="Path 300" d="M196.872,323.362v2.561l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.375,7.375,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.452-.055-.149-.085-.235a2.01,2.01,0,0,1-.07-.2,11.309,11.309,0,0,1-.508-2.453v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.87,3.646v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.028,0-.044a11.133,11.133,0,0,0,.5,2.427,1.984,1.984,0,0,0,.07.2l.085.236.055.15.178.453.173.348.015.035a8.3,8.3,0,0,0,.883,1.578,7.469,7.469,0,0,0,3.6,2.628,7.584,7.584,0,0,0,4.635-.048Z" transform="translate(-149.087 -302.658)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_136" data-name="Group 136" transform="translate(2.543 0.908)">
          <path id="Path_301" data-name="Path 301" d="M205.3,340.094l-.07-.192v-.012A1.964,1.964,0,0,0,205.3,340.094Z" transform="translate(-178.818 -318.385)" fill="#303391"/>
          <path id="Path_302" data-name="Path 302" d="M209.4,310.585c0,3.06,2.06,4.965,4.579,4.218l9.3-2.76a1.751,1.751,0,0,1,2.161.82,3.116,3.116,0,0,1,.384,1.571v3.182l-11.81,3.772a7.586,7.586,0,0,1-4.635.09,7.379,7.379,0,0,1-3.6-2.6,8.238,8.238,0,0,1-.882-1.57l-.015-.035-.174-.346-.177-.451-.056-.149-.085-.235a1.971,1.971,0,0,1-.07-.2,12,12,0,0,1-.571-3.776c0-6.907,4.682-13.657,10.231-14.967l11.845-2.8V297.5a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.881c-.284.073-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,209.4,310.585Z" transform="translate(-178.038 -294.351)" fill="url(#linear-gradient-4)"/>
          <path id="Path_303" data-name="Path 303" d="M169.276,322.135c0,3.177-2.254,6.461-5.1,7.3l-11.449,3.4a4.834,4.834,0,0,0-2.9,2.566,4.884,4.884,0,0,0-.524,2.133v3.726l14.83-4.736a13.97,13.97,0,0,0,5.162-3.092,18.8,18.8,0,0,0,3.787-4.969,16.672,16.672,0,0,0,.9-2.121L174,326.3l.176-.451.179-.559.056-.183.086-.286c.026-.083.052-.166.071-.246a15.327,15.327,0,0,0,.573-4.108c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512V317.7a2.665,2.665,0,0,0,3.378,2.852l9.082-2.351c.335-.087.682-.18,1.033-.271l1.378-.271C167.038,317.13,169.276,318.957,169.276,322.135Z" transform="translate(-149.305 -302.736)" fill="url(#linear-gradient-2)"/>
          <path id="Path_304" data-name="Path 304" d="M197.088,323.439V326l-11.81,3.771a7.579,7.579,0,0,1-4.635.09,7.377,7.377,0,0,1-3.6-2.6,8.175,8.175,0,0,1-.882-1.57l-.015-.035-.173-.346-.177-.452-.056-.149-.085-.235a2,2,0,0,1-.07-.2,11.308,11.308,0,0,1-.508-2.453v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.87,3.646v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.627,0-.016,0-.028,0-.044a11.133,11.133,0,0,0,.5,2.427,1.978,1.978,0,0,0,.07.2l.085.236.056.15.177.453.173.348.015.035a8.272,8.272,0,0,0,.882,1.578,7.471,7.471,0,0,0,3.6,2.628,7.584,7.584,0,0,0,4.635-.048Z" transform="translate(-149.303 -302.735)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_137" data-name="Group 137" transform="translate(2.644 0.944)">
          <path id="Path_305" data-name="Path 305" d="M205.512,340.17l-.07-.192v-.012A2.077,2.077,0,0,0,205.512,340.17Z" transform="translate(-179.033 -318.462)" fill="#303391"/>
          <path id="Path_306" data-name="Path 306" d="M209.616,310.662c0,3.059,2.061,4.965,4.579,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.121,3.121,0,0,1,.383,1.571v3.182l-11.81,3.772a7.587,7.587,0,0,1-4.635.09,7.378,7.378,0,0,1-3.6-2.6,8.215,8.215,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a1.962,1.962,0,0,1-.07-.2,12,12,0,0,1-.571-3.776c0-6.907,4.683-13.657,10.231-14.967l11.845-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.881c-.284.073-.579.146-.878.223l-1.188.387A7.286,7.286,0,0,0,209.616,310.662Z" transform="translate(-178.253 -294.428)" fill="url(#linear-gradient-4)"/>
          <path id="Path_307" data-name="Path 307" d="M169.491,322.212c0,3.177-2.254,6.46-5.1,7.3l-11.449,3.4a5.112,5.112,0,0,0-3.421,4.7v3.726l14.831-4.736a13.967,13.967,0,0,0,5.162-3.092,18.812,18.812,0,0,0,3.788-4.969,16.67,16.67,0,0,0,.9-2.121l.015-.044.176-.451.179-.559.056-.183.086-.286c.026-.083.052-.166.071-.246a15.327,15.327,0,0,0,.573-4.108c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.081-2.351c.336-.087.683-.181,1.033-.272l1.377-.271C167.253,317.206,169.491,319.034,169.491,322.212Z" transform="translate(-149.52 -302.813)" fill="url(#linear-gradient-2)"/>
          <path id="Path_308" data-name="Path 308" d="M197.3,323.516v2.561l-11.81,3.771a7.587,7.587,0,0,1-4.636.09,7.376,7.376,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.57l-.015-.035-.174-.346-.177-.452-.056-.149-.085-.235a2.116,2.116,0,0,1-.07-.2,11.335,11.335,0,0,1-.508-2.453v-.008c-.581-6.114-5.172-9.911-10.9-8.506l-14.87,3.645V314.1l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.028,0-.044a11.131,11.131,0,0,0,.5,2.427,2.086,2.086,0,0,0,.07.2l.085.236.056.15.177.453.174.348.015.035a8.3,8.3,0,0,0,.883,1.578,7.464,7.464,0,0,0,3.6,2.628,7.582,7.582,0,0,0,4.636-.047Z" transform="translate(-149.518 -302.812)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_138" data-name="Group 138" transform="translate(2.746 0.981)">
          <path id="Path_309" data-name="Path 309" d="M205.727,340.247l-.07-.192v-.012A1.967,1.967,0,0,0,205.727,340.247Z" transform="translate(-179.248 -318.539)" fill="#303391"/>
          <path id="Path_310" data-name="Path 310" d="M209.831,310.738c0,3.06,2.061,4.965,4.58,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.116,3.116,0,0,1,.383,1.571v3.182l-11.81,3.772a7.583,7.583,0,0,1-4.635.09,7.375,7.375,0,0,1-3.6-2.595,8.187,8.187,0,0,1-.882-1.57l-.015-.035-.173-.346-.177-.451-.056-.149-.085-.235a1.956,1.956,0,0,1-.07-.2,12,12,0,0,1-.571-3.776c0-6.907,4.682-13.657,10.231-14.967l11.844-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.881c-.284.073-.579.146-.878.223l-1.188.387A7.286,7.286,0,0,0,209.831,310.738Z" transform="translate(-178.469 -294.505)" fill="url(#linear-gradient-4)"/>
          <path id="Path_311" data-name="Path 311" d="M169.707,322.289c0,3.177-2.254,6.46-5.1,7.3l-11.45,3.4a4.834,4.834,0,0,0-2.9,2.566,4.884,4.884,0,0,0-.524,2.133v3.726l14.83-4.736a13.97,13.97,0,0,0,5.162-3.092,18.819,18.819,0,0,0,3.787-4.969,16.648,16.648,0,0,0,.9-2.122l.015-.044.176-.451.179-.559.056-.183.086-.286c.026-.083.052-.165.071-.246a15.327,15.327,0,0,0,.573-4.108c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.272l1.378-.271C167.469,317.283,169.707,319.111,169.707,322.289Z" transform="translate(-149.736 -302.89)" fill="url(#linear-gradient-2)"/>
          <path id="Path_312" data-name="Path 312" d="M197.518,323.593v2.56l-11.81,3.772a7.586,7.586,0,0,1-4.635.09,7.377,7.377,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a2,2,0,0,1-.07-.2,11.307,11.307,0,0,1-.508-2.453v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.871,3.645v-2.933l14.871-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.028,0-.044a11.133,11.133,0,0,0,.5,2.427,1.984,1.984,0,0,0,.07.2l.085.236.055.15.178.453.173.348.015.035a8.313,8.313,0,0,0,.883,1.578,7.465,7.465,0,0,0,3.6,2.628,7.581,7.581,0,0,0,4.635-.047Z" transform="translate(-149.733 -302.889)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_139" data-name="Group 139" transform="translate(2.848 1.017)">
          <path id="Path_313" data-name="Path 313" d="M205.942,340.324l-.07-.192v-.012A2.08,2.08,0,0,0,205.942,340.324Z" transform="translate(-179.464 -318.616)" fill="#303391"/>
          <path id="Path_314" data-name="Path 314" d="M210.047,310.815c0,3.06,2.061,4.965,4.579,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.121,3.121,0,0,1,.383,1.571v3.182l-11.81,3.772a7.583,7.583,0,0,1-4.635.09,7.37,7.37,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.569l-.015-.035-.174-.346-.177-.451-.055-.149-.085-.235c-.026-.067-.051-.135-.07-.2a12.016,12.016,0,0,1-.571-3.776c0-6.907,4.682-13.657,10.231-14.967l11.845-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.88c-.284.074-.579.146-.878.224l-1.188.387A7.286,7.286,0,0,0,210.047,310.815Z" transform="translate(-178.684 -294.582)" fill="url(#linear-gradient-4)"/>
          <path id="Path_315" data-name="Path 315" d="M169.922,322.366c0,3.177-2.254,6.46-5.1,7.3l-11.449,3.4a5.111,5.111,0,0,0-3.421,4.7v3.726l14.831-4.736a13.967,13.967,0,0,0,5.162-3.092,18.807,18.807,0,0,0,3.787-4.969,16.651,16.651,0,0,0,.9-2.122l.015-.044.176-.451.179-.559.056-.183.086-.286c.026-.083.052-.165.071-.246a15.33,15.33,0,0,0,.573-4.108c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.082-2.35c.336-.087.683-.181,1.033-.272l1.377-.271C167.684,317.36,169.922,319.188,169.922,322.366Z" transform="translate(-149.951 -302.967)" fill="url(#linear-gradient-2)"/>
          <path id="Path_316" data-name="Path 316" d="M197.734,323.67v2.56L185.924,330a7.586,7.586,0,0,1-4.635.09,7.379,7.379,0,0,1-3.6-2.6,8.213,8.213,0,0,1-.882-1.57l-.015-.035-.173-.346-.177-.451-.056-.149-.085-.235a1.973,1.973,0,0,1-.07-.2,11.33,11.33,0,0,1-.508-2.452v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.87,3.645v-2.933l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.627,0-.016,0-.028,0-.044a11.16,11.16,0,0,0,.5,2.427,1.97,1.97,0,0,0,.07.2l.085.236.056.15.177.453.173.348.015.035a8.312,8.312,0,0,0,.882,1.578,7.467,7.467,0,0,0,3.6,2.628,7.581,7.581,0,0,0,4.635-.047Z" transform="translate(-149.949 -302.966)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_140" data-name="Group 140" transform="translate(2.95 1.054)">
          <path id="Path_317" data-name="Path 317" d="M206.158,340.4l-.07-.192V340.2A2.084,2.084,0,0,0,206.158,340.4Z" transform="translate(-179.679 -318.693)" fill="#303391"/>
          <path id="Path_318" data-name="Path 318" d="M210.262,310.892c0,3.06,2.061,4.965,4.579,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.121,3.121,0,0,1,.383,1.571v3.182l-11.81,3.772a7.583,7.583,0,0,1-4.635.09,7.371,7.371,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.569l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a1.959,1.959,0,0,1-.07-.2,12,12,0,0,1-.571-3.776c0-6.907,4.683-13.657,10.231-14.967l11.845-2.8V297.8a3.979,3.979,0,0,1-2.512,3.833l-7.266,1.88c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,210.262,310.892Z" transform="translate(-178.899 -294.659)" fill="url(#linear-gradient-4)"/>
          <path id="Path_319" data-name="Path 319" d="M170.138,322.443c0,3.177-2.254,6.46-5.1,7.3l-11.449,3.4a5.11,5.11,0,0,0-3.42,4.7v3.726L165,336.833a13.971,13.971,0,0,0,5.162-3.092,18.821,18.821,0,0,0,3.788-4.969,16.651,16.651,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.085-.286c.026-.083.052-.165.071-.246a15.323,15.323,0,0,0,.573-4.107c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.272l1.377-.271C167.9,317.437,170.138,319.265,170.138,322.443Z" transform="translate(-150.167 -303.044)" fill="url(#linear-gradient-2)"/>
          <path id="Path_320" data-name="Path 320" d="M197.949,323.747v2.56l-11.81,3.772a7.587,7.587,0,0,1-4.636.09,7.378,7.378,0,0,1-3.6-2.6,8.215,8.215,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a2.085,2.085,0,0,1-.07-.2,11.3,11.3,0,0,1-.508-2.452v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.87,3.645v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.044a11.133,11.133,0,0,0,.5,2.427,2.144,2.144,0,0,0,.07.2l.085.236.055.15.178.453.173.348.015.035a8.311,8.311,0,0,0,.883,1.578,7.472,7.472,0,0,0,3.6,2.628,7.582,7.582,0,0,0,4.636-.047Z" transform="translate(-150.164 -303.043)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_141" data-name="Group 141" transform="translate(3.052 1.09)">
          <path id="Path_321" data-name="Path 321" d="M206.373,340.478l-.07-.192v-.012A1.963,1.963,0,0,0,206.373,340.478Z" transform="translate(-179.895 -318.77)" fill="#303391"/>
          <path id="Path_322" data-name="Path 322" d="M210.478,310.969c0,3.06,2.06,4.965,4.579,4.218l9.3-2.759a1.752,1.752,0,0,1,2.161.82,3.115,3.115,0,0,1,.384,1.571V318l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.374,7.374,0,0,1-3.6-2.6,8.211,8.211,0,0,1-.882-1.569l-.015-.035-.173-.346-.177-.451-.056-.149-.085-.235a1.967,1.967,0,0,1-.07-.2,12.007,12.007,0,0,1-.571-3.777c0-6.907,4.682-13.656,10.231-14.967l11.845-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.88c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,210.478,310.969Z" transform="translate(-179.115 -294.736)" fill="url(#linear-gradient-4)"/>
          <path id="Path_323" data-name="Path 323" d="M170.353,322.52c0,3.177-2.254,6.46-5.1,7.3l-11.449,3.4a4.835,4.835,0,0,0-2.9,2.566,4.887,4.887,0,0,0-.524,2.133v3.726l14.83-4.736a13.976,13.976,0,0,0,5.162-3.092,18.825,18.825,0,0,0,3.787-4.969,16.643,16.643,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.086-.286c.026-.083.052-.165.071-.246a15.326,15.326,0,0,0,.573-4.107c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.272l1.378-.271C168.115,317.514,170.353,319.342,170.353,322.52Z" transform="translate(-150.382 -303.121)" fill="url(#linear-gradient-2)"/>
          <path id="Path_324" data-name="Path 324" d="M198.165,323.824v2.56l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.376,7.376,0,0,1-3.6-2.595,8.217,8.217,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a1.956,1.956,0,0,1-.07-.2,11.307,11.307,0,0,1-.508-2.452V322.2c-.58-6.114-5.172-9.911-10.9-8.506l-14.87,3.645v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.044a11.137,11.137,0,0,0,.5,2.427,2,2,0,0,0,.07.2l.085.236.055.15.178.453.173.348.015.035a8.3,8.3,0,0,0,.883,1.577,7.469,7.469,0,0,0,3.6,2.628,7.58,7.58,0,0,0,4.635-.047Z" transform="translate(-150.38 -303.12)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_142" data-name="Group 142" transform="translate(3.153 1.126)">
          <path id="Path_325" data-name="Path 325" d="M206.588,340.555l-.07-.192v-.012A2.081,2.081,0,0,0,206.588,340.555Z" transform="translate(-180.11 -318.847)" fill="#303391"/>
          <path id="Path_326" data-name="Path 326" d="M210.693,311.046c0,3.06,2.061,4.965,4.579,4.218l9.3-2.759a1.752,1.752,0,0,1,2.161.82,3.12,3.12,0,0,1,.383,1.571v3.182l-11.81,3.772a7.584,7.584,0,0,1-4.636.09,7.372,7.372,0,0,1-3.6-2.6,8.212,8.212,0,0,1-.883-1.569l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a2.083,2.083,0,0,1-.07-.2,12.006,12.006,0,0,1-.571-3.777c0-6.907,4.683-13.656,10.231-14.967l11.845-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.88c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,210.693,311.046Z" transform="translate(-179.33 -294.813)" fill="url(#linear-gradient-4)"/>
          <path id="Path_327" data-name="Path 327" d="M170.568,322.6c0,3.177-2.254,6.46-5.1,7.3l-11.45,3.4a4.835,4.835,0,0,0-2.9,2.566A4.888,4.888,0,0,0,150.6,338v3.726l14.831-4.736a13.975,13.975,0,0,0,5.162-3.092,18.827,18.827,0,0,0,3.788-4.969,16.652,16.652,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.086-.286c.026-.083.052-.165.071-.246a15.327,15.327,0,0,0,.573-4.107c0-6.9-4.828-11.405-10.965-9.956l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.082-2.35c.336-.087.683-.181,1.033-.272l1.377-.271C168.33,317.591,170.568,319.419,170.568,322.6Z" transform="translate(-150.597 -303.197)" fill="url(#linear-gradient-2)"/>
          <path id="Path_328" data-name="Path 328" d="M198.38,323.9v2.56l-11.81,3.772a7.583,7.583,0,0,1-4.635.09,7.374,7.374,0,0,1-3.6-2.6,8.222,8.222,0,0,1-.882-1.569l-.015-.035-.174-.346-.177-.451-.056-.149-.085-.235a2.081,2.081,0,0,1-.07-.2,11.308,11.308,0,0,1-.508-2.453v-.008c-.581-6.114-5.172-9.911-10.9-8.506l-14.87,3.645v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.044a11.166,11.166,0,0,0,.5,2.427,2.135,2.135,0,0,0,.07.2l.085.236.056.15.177.453.174.348.015.035a8.332,8.332,0,0,0,.882,1.577,7.472,7.472,0,0,0,3.6,2.628,7.581,7.581,0,0,0,4.635-.047Z" transform="translate(-150.595 -303.197)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_143" data-name="Group 143" transform="translate(3.255 1.163)">
          <path id="Path_329" data-name="Path 329" d="M206.8,340.632l-.07-.192v-.012A2.009,2.009,0,0,0,206.8,340.632Z" transform="translate(-180.325 -318.924)" fill="#303391"/>
          <path id="Path_330" data-name="Path 330" d="M210.908,311.123c0,3.06,2.061,4.965,4.58,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.821,3.115,3.115,0,0,1,.383,1.57v3.183l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.377,7.377,0,0,1-3.6-2.6,8.186,8.186,0,0,1-.882-1.569l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a1.967,1.967,0,0,1-.07-.2,12.006,12.006,0,0,1-.571-3.777c0-6.907,4.682-13.656,10.231-14.967l11.844-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.88c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,210.908,311.123Z" transform="translate(-179.546 -294.89)" fill="url(#linear-gradient-4)"/>
          <path id="Path_331" data-name="Path 331" d="M170.784,322.673c0,3.178-2.254,6.46-5.1,7.3l-11.449,3.4a4.834,4.834,0,0,0-2.9,2.566,4.888,4.888,0,0,0-.524,2.133V341.8l14.83-4.736a13.971,13.971,0,0,0,5.162-3.093A18.824,18.824,0,0,0,174.595,329a16.647,16.647,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.085-.286c.026-.083.052-.165.071-.246A15.309,15.309,0,0,0,176.65,321c0-6.9-4.828-11.405-10.965-9.956l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.272l1.378-.271C168.546,317.668,170.784,319.5,170.784,322.673Z" transform="translate(-150.813 -303.274)" fill="url(#linear-gradient-2)"/>
          <path id="Path_332" data-name="Path 332" d="M198.6,323.978v2.56l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.372,7.372,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.569l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a1.961,1.961,0,0,1-.07-.2,11.308,11.308,0,0,1-.508-2.453v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.871,3.645v-2.934l14.871-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.044a11.138,11.138,0,0,0,.5,2.427,2.01,2.01,0,0,0,.07.2l.085.236.055.149.178.453.173.348.015.035a8.312,8.312,0,0,0,.883,1.577,7.469,7.469,0,0,0,3.6,2.628,7.58,7.58,0,0,0,4.635-.048Z" transform="translate(-150.81 -303.274)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_144" data-name="Group 144" transform="translate(3.357 1.199)">
          <path id="Path_333" data-name="Path 333" d="M207.019,340.709l-.07-.192v-.012A2,2,0,0,0,207.019,340.709Z" transform="translate(-180.541 -319.001)" fill="#303391"/>
          <path id="Path_334" data-name="Path 334" d="M211.124,311.2c0,3.06,2.061,4.965,4.579,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.118,3.118,0,0,1,.384,1.571v3.183L215.737,322a7.583,7.583,0,0,1-4.635.09,7.377,7.377,0,0,1-3.6-2.6,8.237,8.237,0,0,1-.882-1.569l-.015-.035-.174-.346-.177-.452-.056-.149-.085-.235a2.125,2.125,0,0,1-.07-.2,12.019,12.019,0,0,1-.571-3.777c0-6.907,4.682-13.656,10.231-14.967l11.845-2.8v3.145a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.88c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,211.124,311.2Z" transform="translate(-179.761 -294.967)" fill="url(#linear-gradient-4)"/>
          <path id="Path_335" data-name="Path 335" d="M171,322.75c0,3.178-2.254,6.46-5.1,7.3l-11.45,3.4a4.835,4.835,0,0,0-2.9,2.566,4.887,4.887,0,0,0-.524,2.133v3.726l14.83-4.736a13.977,13.977,0,0,0,5.162-3.093,18.806,18.806,0,0,0,3.787-4.969,16.674,16.674,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.086-.286c.026-.083.052-.165.071-.246a15.326,15.326,0,0,0,.573-4.107c0-6.9-4.828-11.405-10.965-9.956l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.082-2.35c.336-.087.683-.181,1.033-.272l1.378-.271C168.761,317.745,171,319.573,171,322.75Z" transform="translate(-151.028 -303.351)" fill="url(#linear-gradient-2)"/>
          <path id="Path_336" data-name="Path 336" d="M198.811,324.055v2.561L187,330.387a7.583,7.583,0,0,1-4.635.09,7.373,7.373,0,0,1-3.6-2.6,8.211,8.211,0,0,1-.882-1.569l-.015-.035-.173-.346-.177-.451-.056-.149-.085-.235a1.966,1.966,0,0,1-.07-.2,11.306,11.306,0,0,1-.508-2.453v-.008c-.58-6.114-5.172-9.911-10.9-8.507l-14.87,3.646v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.044a11.139,11.139,0,0,0,.5,2.427,2.012,2.012,0,0,0,.07.2l.085.236.056.15.177.453.173.348.015.035a8.309,8.309,0,0,0,.882,1.577,7.472,7.472,0,0,0,3.6,2.628,7.581,7.581,0,0,0,4.635-.048Z" transform="translate(-151.026 -303.351)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_145" data-name="Group 145" transform="translate(3.458 1.235)">
          <path id="Path_337" data-name="Path 337" d="M207.235,340.786l-.07-.192v-.012A2.116,2.116,0,0,0,207.235,340.786Z" transform="translate(-180.756 -319.077)" fill="#303391"/>
          <path id="Path_338" data-name="Path 338" d="M211.339,311.277c0,3.059,2.061,4.965,4.579,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.123,3.123,0,0,1,.383,1.571v3.183l-11.81,3.771a7.579,7.579,0,0,1-4.635.09,7.374,7.374,0,0,1-3.6-2.6,8.213,8.213,0,0,1-.883-1.569l-.015-.035-.173-.346-.178-.452-.055-.149-.085-.235a1.989,1.989,0,0,1-.07-.2,12.006,12.006,0,0,1-.571-3.777c0-6.907,4.683-13.656,10.231-14.967l11.845-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.266,1.88c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,211.339,311.277Z" transform="translate(-179.976 -295.043)" fill="url(#linear-gradient-4)"/>
          <path id="Path_339" data-name="Path 339" d="M171.214,322.827c0,3.177-2.254,6.46-5.1,7.3l-11.45,3.4a5.112,5.112,0,0,0-3.421,4.7v3.726l14.831-4.736a13.971,13.971,0,0,0,5.162-3.093,18.817,18.817,0,0,0,3.788-4.969,16.673,16.673,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.085-.286c.026-.083.052-.165.071-.246a15.326,15.326,0,0,0,.573-4.108c0-6.9-4.828-11.4-10.965-9.955l-14.87,3.512V318.4a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.272l1.377-.271C168.976,317.822,171.214,319.65,171.214,322.827Z" transform="translate(-151.243 -303.428)" fill="url(#linear-gradient-2)"/>
          <path id="Path_340" data-name="Path 340" d="M199.026,324.132v2.561l-11.81,3.772a7.584,7.584,0,0,1-4.636.09,7.372,7.372,0,0,1-3.6-2.6,8.212,8.212,0,0,1-.883-1.569l-.015-.035-.174-.346-.177-.451-.055-.149-.085-.235c-.025-.067-.051-.135-.07-.2a11.333,11.333,0,0,1-.508-2.453v-.008c-.581-6.114-5.172-9.91-10.9-8.506l-14.87,3.646v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.044a11.144,11.144,0,0,0,.5,2.427c.019.069.045.137.07.2l.085.236.055.15.177.453.174.348.015.035a8.3,8.3,0,0,0,.883,1.578,7.47,7.47,0,0,0,3.6,2.628,7.582,7.582,0,0,0,4.636-.048Z" transform="translate(-151.241 -303.427)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_146" data-name="Group 146" transform="translate(3.56 1.271)">
          <path id="Path_341" data-name="Path 341" d="M207.45,340.863l-.07-.192v-.012A2,2,0,0,0,207.45,340.863Z" transform="translate(-180.971 -319.154)" fill="#303391"/>
          <path id="Path_342" data-name="Path 342" d="M211.554,311.354c0,3.06,2.061,4.965,4.58,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.118,3.118,0,0,1,.383,1.571v3.183l-11.81,3.771a7.579,7.579,0,0,1-4.635.09,7.376,7.376,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.882-1.57l-.015-.035-.173-.346-.177-.452-.056-.149-.085-.235a2,2,0,0,1-.07-.2,12.008,12.008,0,0,1-.571-3.777c0-6.907,4.682-13.656,10.231-14.967l11.844-2.8v3.146a3.978,3.978,0,0,1-2.512,3.833l-7.267,1.881c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,211.554,311.354Z" transform="translate(-180.192 -295.12)" fill="url(#linear-gradient-4)"/>
          <path id="Path_343" data-name="Path 343" d="M171.43,322.9c0,3.177-2.254,6.46-5.1,7.3l-11.45,3.4a4.834,4.834,0,0,0-2.9,2.566,4.886,4.886,0,0,0-.524,2.133v3.726l14.83-4.736a13.978,13.978,0,0,0,5.162-3.093,18.815,18.815,0,0,0,3.787-4.969,16.682,16.682,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.086-.286c.026-.083.052-.165.071-.246a15.327,15.327,0,0,0,.573-4.108c0-6.9-4.828-11.4-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.272l1.378-.271C169.192,317.9,171.43,319.727,171.43,322.9Z" transform="translate(-151.459 -303.505)" fill="url(#linear-gradient-2)"/>
          <path id="Path_344" data-name="Path 344" d="M199.242,324.209v2.561l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.374,7.374,0,0,1-3.6-2.6,8.213,8.213,0,0,1-.883-1.569l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a2,2,0,0,1-.07-.2,11.307,11.307,0,0,1-.508-2.453v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.871,3.646V314.79l14.871-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.044a11.143,11.143,0,0,0,.5,2.427,2,2,0,0,0,.07.2l.085.236.055.15.178.453.173.348.015.035a8.3,8.3,0,0,0,.883,1.578,7.469,7.469,0,0,0,3.6,2.628,7.581,7.581,0,0,0,4.635-.048Z" transform="translate(-151.456 -303.504)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_147" data-name="Group 147" transform="translate(3.662 1.308)">
          <path id="Path_345" data-name="Path 345" d="M207.665,340.94l-.07-.192v-.012A2.117,2.117,0,0,0,207.665,340.94Z" transform="translate(-181.187 -319.231)" fill="#303391"/>
          <path id="Path_346" data-name="Path 346" d="M211.77,311.431c0,3.06,2.061,4.965,4.579,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.122,3.122,0,0,1,.383,1.571v3.182l-11.81,3.772a7.588,7.588,0,0,1-4.636.09,7.378,7.378,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.452-.055-.149-.085-.235c-.026-.068-.051-.135-.07-.2a12.022,12.022,0,0,1-.571-3.777c0-6.907,4.682-13.657,10.231-14.967l11.845-2.8v3.146a3.978,3.978,0,0,1-2.512,3.833l-7.267,1.881c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,211.77,311.431Z" transform="translate(-180.407 -295.197)" fill="url(#linear-gradient-4)"/>
          <path id="Path_347" data-name="Path 347" d="M171.645,322.981c0,3.177-2.254,6.46-5.1,7.3l-11.45,3.4a4.834,4.834,0,0,0-2.9,2.566,4.886,4.886,0,0,0-.524,2.133v3.726l14.831-4.736a13.968,13.968,0,0,0,5.162-3.092,18.8,18.8,0,0,0,3.787-4.969,16.643,16.643,0,0,0,.9-2.121l.015-.044.176-.451.179-.559.056-.183.086-.286c.026-.083.052-.166.071-.246a15.327,15.327,0,0,0,.573-4.108c0-6.9-4.828-11.4-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.082-2.35c.336-.087.683-.181,1.033-.272l1.377-.271C169.407,317.976,171.645,319.8,171.645,322.981Z" transform="translate(-151.674 -303.582)" fill="url(#linear-gradient-2)"/>
          <path id="Path_348" data-name="Path 348" d="M199.457,324.286v2.561l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.377,7.377,0,0,1-3.6-2.6,8.235,8.235,0,0,1-.882-1.569l-.015-.035-.174-.346-.177-.452-.056-.149-.085-.235a2,2,0,0,1-.07-.2,11.305,11.305,0,0,1-.508-2.453v-.008c-.581-6.114-5.172-9.911-10.9-8.506l-14.87,3.646v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.045a11.16,11.16,0,0,0,.5,2.427,1.977,1.977,0,0,0,.07.2l.085.236.056.15.177.453.174.348.015.035a8.3,8.3,0,0,0,.882,1.578,7.472,7.472,0,0,0,3.6,2.628,7.581,7.581,0,0,0,4.635-.048Z" transform="translate(-151.672 -303.581)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_148" data-name="Group 148" transform="translate(3.763 1.344)">
          <path id="Path_349" data-name="Path 349" d="M207.881,341.017l-.07-.192v-.012A2,2,0,0,0,207.881,341.017Z" transform="translate(-181.402 -319.308)" fill="#303391"/>
          <path id="Path_350" data-name="Path 350" d="M211.985,311.508c0,3.06,2.061,4.965,4.579,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.121,3.121,0,0,1,.383,1.571v3.182l-11.81,3.772a7.586,7.586,0,0,1-4.635.09,7.377,7.377,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.452-.055-.149-.085-.235a2,2,0,0,1-.07-.2,12.008,12.008,0,0,1-.571-3.777c0-6.907,4.683-13.657,10.231-14.967l11.845-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.266,1.881c-.284.073-.579.146-.878.223l-1.189.388A7.285,7.285,0,0,0,211.985,311.508Z" transform="translate(-180.622 -295.274)" fill="url(#linear-gradient-4)"/>
          <path id="Path_351" data-name="Path 351" d="M171.861,323.058c0,3.177-2.254,6.46-5.1,7.3l-11.45,3.4a4.833,4.833,0,0,0-2.9,2.566,4.884,4.884,0,0,0-.524,2.133v3.726l14.83-4.736a13.964,13.964,0,0,0,5.162-3.092,18.811,18.811,0,0,0,3.788-4.969,16.643,16.643,0,0,0,.9-2.121l.015-.044.176-.451.179-.559.056-.183.085-.286c.026-.083.052-.166.071-.246a15.309,15.309,0,0,0,.573-4.108c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.272l1.378-.271C169.623,318.053,171.861,319.88,171.861,323.058Z" transform="translate(-151.89 -303.659)" fill="url(#linear-gradient-2)"/>
          <path id="Path_352" data-name="Path 352" d="M199.672,324.362v2.561l-11.81,3.772a7.583,7.583,0,0,1-4.635.09,7.374,7.374,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.452-.055-.149-.085-.235a2,2,0,0,1-.07-.2,11.306,11.306,0,0,1-.508-2.453v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.87,3.646v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.028,0-.044a11.131,11.131,0,0,0,.5,2.427,1.979,1.979,0,0,0,.07.2l.085.236.055.15.178.453.173.348.015.035a8.3,8.3,0,0,0,.883,1.578,7.469,7.469,0,0,0,3.6,2.628,7.584,7.584,0,0,0,4.635-.048Z" transform="translate(-151.887 -303.658)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_149" data-name="Group 149" transform="translate(3.865 1.38)">
          <path id="Path_353" data-name="Path 353" d="M208.1,341.094l-.07-.192v-.012A1.96,1.96,0,0,0,208.1,341.094Z" transform="translate(-181.618 -319.385)" fill="#303391"/>
          <path id="Path_354" data-name="Path 354" d="M212.2,311.585c0,3.06,2.06,4.965,4.579,4.218l9.3-2.76a1.751,1.751,0,0,1,2.161.82,3.117,3.117,0,0,1,.384,1.571v3.182l-11.81,3.772a7.586,7.586,0,0,1-4.635.09,7.379,7.379,0,0,1-3.6-2.6,8.238,8.238,0,0,1-.882-1.57l-.015-.035-.174-.346-.177-.451-.056-.149-.085-.235a1.976,1.976,0,0,1-.07-.2,12,12,0,0,1-.571-3.776c0-6.907,4.682-13.657,10.231-14.967l11.845-2.8V298.5a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.881c-.284.073-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,212.2,311.585Z" transform="translate(-180.838 -295.351)" fill="url(#linear-gradient-4)"/>
          <path id="Path_355" data-name="Path 355" d="M172.076,323.135c0,3.177-2.254,6.46-5.1,7.3l-11.45,3.4a4.834,4.834,0,0,0-2.9,2.566,4.884,4.884,0,0,0-.524,2.133v3.726l14.83-4.736a13.97,13.97,0,0,0,5.162-3.092,18.8,18.8,0,0,0,3.787-4.969,16.67,16.67,0,0,0,.9-2.121l.015-.044.176-.451.179-.559.056-.183.086-.286c.026-.083.052-.166.071-.246a15.327,15.327,0,0,0,.573-4.108c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512V318.7a2.665,2.665,0,0,0,3.378,2.852l9.082-2.351c.335-.087.682-.18,1.033-.271l1.378-.271C169.838,318.13,172.076,319.957,172.076,323.135Z" transform="translate(-152.105 -303.736)" fill="url(#linear-gradient-2)"/>
          <path id="Path_356" data-name="Path 356" d="M199.888,324.439V327l-11.81,3.771a7.579,7.579,0,0,1-4.635.09,7.377,7.377,0,0,1-3.6-2.6,8.175,8.175,0,0,1-.882-1.57l-.015-.035-.173-.346-.177-.452-.056-.149-.085-.235a2,2,0,0,1-.07-.2,11.306,11.306,0,0,1-.508-2.453v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.87,3.646v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.627,0-.016,0-.028,0-.044a11.132,11.132,0,0,0,.5,2.427,1.977,1.977,0,0,0,.07.2l.085.236.056.15.177.453.173.348.015.035a8.273,8.273,0,0,0,.882,1.578,7.471,7.471,0,0,0,3.6,2.628,7.584,7.584,0,0,0,4.635-.048Z" transform="translate(-152.103 -303.735)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_150" data-name="Group 150" transform="translate(3.967 1.417)">
          <path id="Path_357" data-name="Path 357" d="M208.312,341.17l-.07-.192v-.012A2.069,2.069,0,0,0,208.312,341.17Z" transform="translate(-181.833 -319.462)" fill="#303391"/>
          <path id="Path_358" data-name="Path 358" d="M212.416,311.662c0,3.059,2.061,4.965,4.579,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.121,3.121,0,0,1,.383,1.571v3.182l-11.81,3.772a7.586,7.586,0,0,1-4.635.09,7.377,7.377,0,0,1-3.6-2.6,8.216,8.216,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a1.969,1.969,0,0,1-.07-.2,12,12,0,0,1-.571-3.776c0-6.907,4.683-13.657,10.231-14.967l11.845-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.881c-.284.073-.579.146-.878.223l-1.188.387A7.286,7.286,0,0,0,212.416,311.662Z" transform="translate(-181.053 -295.428)" fill="url(#linear-gradient-4)"/>
          <path id="Path_359" data-name="Path 359" d="M172.291,323.212c0,3.177-2.254,6.46-5.1,7.3l-11.449,3.4a5.112,5.112,0,0,0-3.421,4.7v3.726l14.831-4.736a13.967,13.967,0,0,0,5.162-3.092,18.812,18.812,0,0,0,3.788-4.969,16.667,16.667,0,0,0,.9-2.121l.015-.044.176-.451.179-.559.056-.183.086-.286c.026-.083.052-.166.071-.246a15.327,15.327,0,0,0,.573-4.108c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.081-2.351c.336-.087.683-.181,1.033-.272l1.377-.271C170.053,318.206,172.291,320.034,172.291,323.212Z" transform="translate(-152.32 -303.813)" fill="url(#linear-gradient-2)"/>
          <path id="Path_360" data-name="Path 360" d="M200.1,324.516v2.561l-11.81,3.771a7.588,7.588,0,0,1-4.636.09,7.376,7.376,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.57l-.015-.035-.174-.346-.177-.452-.056-.149-.085-.235a2.12,2.12,0,0,1-.07-.2,11.335,11.335,0,0,1-.508-2.453v-.008c-.581-6.114-5.172-9.911-10.9-8.506l-14.87,3.645V315.1l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.627,0-.016,0-.028,0-.044a11.131,11.131,0,0,0,.5,2.427,2.09,2.09,0,0,0,.07.2l.085.236.056.15.177.453.174.348.015.035a8.3,8.3,0,0,0,.883,1.578,7.464,7.464,0,0,0,3.6,2.628,7.582,7.582,0,0,0,4.636-.047Z" transform="translate(-152.318 -303.812)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_151" data-name="Group 151" transform="translate(4.068 1.453)">
          <path id="Path_361" data-name="Path 361" d="M208.527,341.247l-.07-.192v-.012A1.971,1.971,0,0,0,208.527,341.247Z" transform="translate(-182.048 -319.539)" fill="#303391"/>
          <path id="Path_362" data-name="Path 362" d="M212.631,311.738c0,3.06,2.061,4.965,4.58,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.116,3.116,0,0,1,.383,1.571v3.182l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.375,7.375,0,0,1-3.6-2.595,8.188,8.188,0,0,1-.882-1.57l-.015-.035-.173-.346-.177-.451-.056-.149-.085-.235a1.958,1.958,0,0,1-.07-.2,12,12,0,0,1-.571-3.776c0-6.907,4.682-13.657,10.231-14.967l11.844-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.881c-.284.073-.579.146-.878.223l-1.188.387A7.286,7.286,0,0,0,212.631,311.738Z" transform="translate(-181.269 -295.505)" fill="url(#linear-gradient-4)"/>
          <path id="Path_363" data-name="Path 363" d="M172.507,323.289c0,3.177-2.254,6.46-5.1,7.3l-11.45,3.4a4.834,4.834,0,0,0-2.9,2.566,4.884,4.884,0,0,0-.524,2.133v3.726l14.83-4.736a13.97,13.97,0,0,0,5.162-3.092,18.818,18.818,0,0,0,3.787-4.969,16.658,16.658,0,0,0,.9-2.122l.015-.044.176-.451.179-.559.056-.183.086-.286c.026-.083.052-.165.071-.246a15.326,15.326,0,0,0,.573-4.108c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852L165,319.36c.336-.087.683-.181,1.033-.272l1.378-.271C170.269,318.283,172.507,320.111,172.507,323.289Z" transform="translate(-152.536 -303.89)" fill="url(#linear-gradient-2)"/>
          <path id="Path_364" data-name="Path 364" d="M200.318,324.593v2.56l-11.81,3.772a7.586,7.586,0,0,1-4.635.09,7.378,7.378,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a2,2,0,0,1-.07-.2,11.306,11.306,0,0,1-.508-2.453v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.871,3.645v-2.933l14.871-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.028,0-.044a11.133,11.133,0,0,0,.5,2.427,1.982,1.982,0,0,0,.07.2l.085.236.055.15.178.453.173.348.015.035a8.314,8.314,0,0,0,.883,1.578,7.465,7.465,0,0,0,3.6,2.628,7.581,7.581,0,0,0,4.635-.047Z" transform="translate(-152.533 -303.889)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_152" data-name="Group 152" transform="translate(4.17 1.489)">
          <path id="Path_365" data-name="Path 365" d="M208.742,341.324l-.07-.192v-.012A2.079,2.079,0,0,0,208.742,341.324Z" transform="translate(-182.264 -319.616)" fill="#303391"/>
          <path id="Path_366" data-name="Path 366" d="M212.847,311.815c0,3.06,2.061,4.965,4.579,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.121,3.121,0,0,1,.383,1.571v3.182l-11.81,3.772a7.583,7.583,0,0,1-4.635.09,7.37,7.37,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.569l-.015-.035-.174-.346-.177-.451-.055-.149-.085-.235c-.026-.067-.051-.135-.07-.2a12.016,12.016,0,0,1-.571-3.776c0-6.907,4.682-13.657,10.231-14.967l11.845-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.88c-.284.074-.579.146-.878.224l-1.188.387A7.286,7.286,0,0,0,212.847,311.815Z" transform="translate(-181.484 -295.582)" fill="url(#linear-gradient-4)"/>
          <path id="Path_367" data-name="Path 367" d="M172.722,323.366c0,3.177-2.254,6.46-5.1,7.3l-11.45,3.4a5.111,5.111,0,0,0-3.421,4.7v3.726l14.831-4.736a13.967,13.967,0,0,0,5.162-3.092,18.807,18.807,0,0,0,3.787-4.969,16.651,16.651,0,0,0,.9-2.122l.015-.044.176-.451.179-.559.056-.183.086-.286c.026-.083.052-.165.071-.246a15.33,15.33,0,0,0,.573-4.108c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.082-2.35c.336-.087.683-.181,1.033-.272l1.377-.271C170.484,318.36,172.722,320.188,172.722,323.366Z" transform="translate(-152.751 -303.967)" fill="url(#linear-gradient-2)"/>
          <path id="Path_368" data-name="Path 368" d="M200.534,324.67v2.56L188.724,331a7.587,7.587,0,0,1-4.635.09,7.379,7.379,0,0,1-3.6-2.6,8.214,8.214,0,0,1-.882-1.57l-.015-.035-.173-.346-.177-.451-.056-.149-.085-.235a1.968,1.968,0,0,1-.07-.2,11.33,11.33,0,0,1-.508-2.452v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.87,3.645v-2.933l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.028,0-.044a11.16,11.16,0,0,0,.5,2.427,1.968,1.968,0,0,0,.07.2l.085.236.056.15.177.453.173.348.015.035a8.312,8.312,0,0,0,.882,1.578,7.467,7.467,0,0,0,3.6,2.628,7.58,7.58,0,0,0,4.635-.047Z" transform="translate(-152.749 -303.966)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_153" data-name="Group 153" transform="translate(4.272 1.526)">
          <path id="Path_369" data-name="Path 369" d="M208.958,341.4l-.07-.192V341.2A2.086,2.086,0,0,0,208.958,341.4Z" transform="translate(-182.479 -319.693)" fill="#303391"/>
          <path id="Path_370" data-name="Path 370" d="M213.062,311.892c0,3.06,2.061,4.965,4.579,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.121,3.121,0,0,1,.383,1.571v3.182l-11.81,3.772a7.583,7.583,0,0,1-4.635.09,7.372,7.372,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.569l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a1.96,1.96,0,0,1-.07-.2,12.005,12.005,0,0,1-.571-3.776c0-6.907,4.683-13.657,10.231-14.967l11.845-2.8V298.8a3.979,3.979,0,0,1-2.512,3.833l-7.266,1.88c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,213.062,311.892Z" transform="translate(-181.699 -295.659)" fill="url(#linear-gradient-4)"/>
          <path id="Path_371" data-name="Path 371" d="M172.938,323.443c0,3.177-2.254,6.46-5.1,7.3l-11.449,3.4a5.11,5.11,0,0,0-3.42,4.7v3.726l14.83-4.736a13.971,13.971,0,0,0,5.162-3.092,18.82,18.82,0,0,0,3.788-4.969,16.654,16.654,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.085-.286c.026-.083.052-.165.071-.246a15.323,15.323,0,0,0,.573-4.107c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.272l1.377-.271C170.7,318.437,172.938,320.265,172.938,323.443Z" transform="translate(-152.967 -304.044)" fill="url(#linear-gradient-2)"/>
          <path id="Path_372" data-name="Path 372" d="M200.749,324.747v2.56l-11.81,3.772a7.588,7.588,0,0,1-4.636.09,7.379,7.379,0,0,1-3.6-2.6,8.215,8.215,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a2.08,2.08,0,0,1-.07-.2,11.3,11.3,0,0,1-.508-2.452v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.87,3.645v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.044a11.133,11.133,0,0,0,.5,2.427,2.136,2.136,0,0,0,.07.2l.085.236.055.15.178.453.173.348.015.035a8.313,8.313,0,0,0,.883,1.578,7.472,7.472,0,0,0,3.6,2.628,7.582,7.582,0,0,0,4.636-.047Z" transform="translate(-152.964 -304.043)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_154" data-name="Group 154" transform="translate(4.374 1.562)">
          <path id="Path_373" data-name="Path 373" d="M209.173,341.478l-.07-.192v-.012A1.96,1.96,0,0,0,209.173,341.478Z" transform="translate(-182.695 -319.77)" fill="#303391"/>
          <path id="Path_374" data-name="Path 374" d="M213.278,311.969c0,3.06,2.06,4.965,4.579,4.218l9.3-2.759a1.752,1.752,0,0,1,2.161.82,3.115,3.115,0,0,1,.384,1.571V319l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.374,7.374,0,0,1-3.6-2.6,8.209,8.209,0,0,1-.882-1.569l-.015-.035-.173-.346-.177-.451-.056-.149-.085-.235a1.971,1.971,0,0,1-.07-.2,12.006,12.006,0,0,1-.571-3.777c0-6.907,4.682-13.656,10.231-14.967l11.845-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.88c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,213.278,311.969Z" transform="translate(-181.915 -295.736)" fill="url(#linear-gradient-4)"/>
          <path id="Path_375" data-name="Path 375" d="M173.153,323.52c0,3.177-2.254,6.46-5.1,7.3l-11.449,3.4a4.835,4.835,0,0,0-2.9,2.566,4.887,4.887,0,0,0-.524,2.133v3.726l14.83-4.736a13.976,13.976,0,0,0,5.162-3.092,18.825,18.825,0,0,0,3.787-4.969,16.648,16.648,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.086-.286c.026-.083.052-.165.071-.246a15.326,15.326,0,0,0,.573-4.107c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.272l1.378-.271C170.915,318.514,173.153,320.342,173.153,323.52Z" transform="translate(-153.182 -304.121)" fill="url(#linear-gradient-2)"/>
          <path id="Path_376" data-name="Path 376" d="M200.965,324.824v2.56l-11.81,3.772a7.583,7.583,0,0,1-4.635.09,7.375,7.375,0,0,1-3.6-2.595,8.214,8.214,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a1.959,1.959,0,0,1-.07-.2,11.309,11.309,0,0,1-.508-2.452V323.2c-.58-6.114-5.172-9.911-10.9-8.506l-14.87,3.645v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.044a11.138,11.138,0,0,0,.5,2.427,2.006,2.006,0,0,0,.07.2l.085.236.055.15.178.453.173.348.015.035a8.3,8.3,0,0,0,.883,1.577,7.469,7.469,0,0,0,3.6,2.628,7.58,7.58,0,0,0,4.635-.047Z" transform="translate(-153.18 -304.12)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_155" data-name="Group 155" transform="translate(4.475 1.598)">
          <path id="Path_377" data-name="Path 377" d="M209.388,341.555l-.07-.192v-.012A2.077,2.077,0,0,0,209.388,341.555Z" transform="translate(-182.91 -319.847)" fill="#303391"/>
          <path id="Path_378" data-name="Path 378" d="M213.493,312.046c0,3.06,2.061,4.965,4.579,4.218l9.3-2.759a1.752,1.752,0,0,1,2.161.82,3.12,3.12,0,0,1,.383,1.571v3.182l-11.81,3.772a7.584,7.584,0,0,1-4.636.09,7.372,7.372,0,0,1-3.6-2.6,8.213,8.213,0,0,1-.883-1.569l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a2.077,2.077,0,0,1-.07-.2,12.006,12.006,0,0,1-.571-3.777c0-6.907,4.683-13.656,10.231-14.967l11.845-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.88c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,213.493,312.046Z" transform="translate(-182.13 -295.813)" fill="url(#linear-gradient-4)"/>
          <path id="Path_379" data-name="Path 379" d="M173.368,323.6c0,3.177-2.254,6.46-5.1,7.3l-11.449,3.4a4.835,4.835,0,0,0-2.9,2.566A4.887,4.887,0,0,0,153.4,339v3.726l14.831-4.736a13.974,13.974,0,0,0,5.162-3.092,18.828,18.828,0,0,0,3.788-4.969,16.65,16.65,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.086-.286c.026-.083.052-.165.071-.246a15.326,15.326,0,0,0,.573-4.107c0-6.9-4.828-11.405-10.965-9.956l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.082-2.35c.336-.087.683-.181,1.033-.272l1.377-.271C171.13,318.591,173.368,320.419,173.368,323.6Z" transform="translate(-153.397 -304.197)" fill="url(#linear-gradient-2)"/>
          <path id="Path_380" data-name="Path 380" d="M201.18,324.9v2.56l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.374,7.374,0,0,1-3.6-2.6,8.222,8.222,0,0,1-.882-1.569l-.015-.035-.174-.346-.177-.451-.056-.149-.085-.235a2.079,2.079,0,0,1-.07-.2,11.308,11.308,0,0,1-.508-2.453v-.008c-.581-6.114-5.172-9.911-10.9-8.506l-14.87,3.645v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.044a11.167,11.167,0,0,0,.5,2.427,2.135,2.135,0,0,0,.07.2l.085.236.056.15.177.453.174.348.015.035a8.335,8.335,0,0,0,.882,1.577,7.472,7.472,0,0,0,3.6,2.628,7.581,7.581,0,0,0,4.635-.047Z" transform="translate(-153.395 -304.197)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_156" data-name="Group 156" transform="translate(4.577 1.635)">
          <path id="Path_381" data-name="Path 381" d="M209.6,341.632l-.07-.192v-.012A2,2,0,0,0,209.6,341.632Z" transform="translate(-183.125 -319.924)" fill="#303391"/>
          <path id="Path_382" data-name="Path 382" d="M213.708,312.123c0,3.06,2.061,4.965,4.58,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.821,3.114,3.114,0,0,1,.383,1.57v3.183l-11.81,3.772a7.583,7.583,0,0,1-4.635.09,7.377,7.377,0,0,1-3.6-2.6,8.186,8.186,0,0,1-.882-1.569l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a1.967,1.967,0,0,1-.07-.2,12.006,12.006,0,0,1-.571-3.777c0-6.907,4.682-13.656,10.231-14.967l11.844-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.88c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,213.708,312.123Z" transform="translate(-182.346 -295.89)" fill="url(#linear-gradient-4)"/>
          <path id="Path_383" data-name="Path 383" d="M173.584,323.673c0,3.178-2.254,6.46-5.1,7.3l-11.45,3.4a4.834,4.834,0,0,0-2.9,2.566,4.887,4.887,0,0,0-.524,2.133V342.8l14.83-4.736a13.971,13.971,0,0,0,5.162-3.093A18.825,18.825,0,0,0,177.395,330a16.648,16.648,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.085-.286c.026-.083.052-.165.071-.246A15.307,15.307,0,0,0,179.45,322c0-6.9-4.828-11.405-10.965-9.956l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.272l1.378-.271C171.346,318.668,173.584,320.5,173.584,323.673Z" transform="translate(-153.613 -304.274)" fill="url(#linear-gradient-2)"/>
          <path id="Path_384" data-name="Path 384" d="M201.4,324.978v2.56l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.372,7.372,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.569l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a1.965,1.965,0,0,1-.07-.2,11.308,11.308,0,0,1-.508-2.453v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.871,3.645v-2.934l14.871-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.044a11.139,11.139,0,0,0,.5,2.427,2.011,2.011,0,0,0,.07.2l.085.236.055.149.178.453.173.348.015.035a8.311,8.311,0,0,0,.883,1.577,7.47,7.47,0,0,0,3.6,2.628,7.58,7.58,0,0,0,4.635-.048Z" transform="translate(-153.61 -304.274)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_157" data-name="Group 157" transform="translate(4.679 1.671)">
          <path id="Path_385" data-name="Path 385" d="M209.819,341.709l-.07-.192v-.012A2,2,0,0,0,209.819,341.709Z" transform="translate(-183.341 -320.001)" fill="#303391"/>
          <path id="Path_386" data-name="Path 386" d="M213.924,312.2c0,3.06,2.061,4.965,4.579,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.118,3.118,0,0,1,.384,1.571v3.183L218.537,323a7.583,7.583,0,0,1-4.635.09,7.377,7.377,0,0,1-3.6-2.6,8.237,8.237,0,0,1-.882-1.569l-.015-.035-.174-.346-.177-.452-.056-.149-.085-.235a2.117,2.117,0,0,1-.07-.2,12.02,12.02,0,0,1-.571-3.777c0-6.907,4.682-13.656,10.231-14.967l11.845-2.8v3.145a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.88c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,213.924,312.2Z" transform="translate(-182.561 -295.967)" fill="url(#linear-gradient-4)"/>
          <path id="Path_387" data-name="Path 387" d="M173.8,323.75c0,3.178-2.254,6.46-5.1,7.3l-11.449,3.4a4.835,4.835,0,0,0-2.9,2.566,4.887,4.887,0,0,0-.524,2.133v3.726l14.83-4.736a13.977,13.977,0,0,0,5.162-3.093,18.805,18.805,0,0,0,3.787-4.969,16.676,16.676,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.086-.286c.026-.083.052-.165.071-.246a15.327,15.327,0,0,0,.573-4.107c0-6.9-4.828-11.405-10.965-9.956l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.082-2.35c.336-.087.683-.181,1.033-.272l1.378-.271C171.561,318.745,173.8,320.573,173.8,323.75Z" transform="translate(-153.828 -304.351)" fill="url(#linear-gradient-2)"/>
          <path id="Path_388" data-name="Path 388" d="M201.611,325.055v2.561l-11.81,3.772a7.583,7.583,0,0,1-4.635.09,7.374,7.374,0,0,1-3.6-2.6,8.211,8.211,0,0,1-.882-1.569l-.015-.035-.173-.346-.177-.451-.056-.149-.085-.235a1.959,1.959,0,0,1-.07-.2,11.306,11.306,0,0,1-.508-2.453v-.008c-.58-6.114-5.172-9.911-10.9-8.507l-14.87,3.646v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.044a11.137,11.137,0,0,0,.5,2.427,2,2,0,0,0,.07.2l.085.236.056.15.177.453.173.348.015.035a8.311,8.311,0,0,0,.882,1.577,7.472,7.472,0,0,0,3.6,2.628,7.581,7.581,0,0,0,4.635-.048Z" transform="translate(-153.826 -304.351)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_158" data-name="Group 158" transform="translate(4.78 1.707)">
          <path id="Path_389" data-name="Path 389" d="M210.035,341.786l-.07-.192v-.012A2.117,2.117,0,0,0,210.035,341.786Z" transform="translate(-183.556 -320.077)" fill="#303391"/>
          <path id="Path_390" data-name="Path 390" d="M214.139,312.277c0,3.059,2.061,4.965,4.579,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.122,3.122,0,0,1,.383,1.571v3.183l-11.81,3.771a7.579,7.579,0,0,1-4.635.09,7.374,7.374,0,0,1-3.6-2.6,8.209,8.209,0,0,1-.883-1.569l-.015-.035-.173-.346-.178-.452-.055-.149-.085-.235a1.993,1.993,0,0,1-.07-.2,12.006,12.006,0,0,1-.571-3.777c0-6.907,4.683-13.656,10.231-14.967l11.845-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.266,1.88c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,214.139,312.277Z" transform="translate(-182.776 -296.043)" fill="url(#linear-gradient-4)"/>
          <path id="Path_391" data-name="Path 391" d="M174.014,323.827c0,3.177-2.254,6.461-5.1,7.3l-11.449,3.4a5.112,5.112,0,0,0-3.421,4.7v3.726l14.831-4.736a13.971,13.971,0,0,0,5.162-3.093,18.818,18.818,0,0,0,3.788-4.969,16.674,16.674,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.085-.286c.026-.083.052-.165.071-.246a15.327,15.327,0,0,0,.573-4.108c0-6.9-4.828-11.4-10.965-9.955l-14.87,3.512V319.4a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.272l1.377-.271C171.776,318.822,174.014,320.65,174.014,323.827Z" transform="translate(-154.043 -304.428)" fill="url(#linear-gradient-2)"/>
          <path id="Path_392" data-name="Path 392" d="M201.826,325.132v2.561l-11.81,3.772a7.584,7.584,0,0,1-4.636.09,7.372,7.372,0,0,1-3.6-2.6,8.211,8.211,0,0,1-.883-1.569l-.015-.035-.174-.346-.177-.451-.055-.149-.085-.235c-.026-.067-.051-.135-.07-.2a11.335,11.335,0,0,1-.508-2.453v-.008c-.581-6.114-5.172-9.911-10.9-8.506l-14.87,3.646v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.627,0-.016,0-.029,0-.044a11.146,11.146,0,0,0,.5,2.427c.019.069.045.137.07.2l.085.236.055.15.177.453.174.348.015.035a8.3,8.3,0,0,0,.883,1.578,7.47,7.47,0,0,0,3.6,2.628,7.582,7.582,0,0,0,4.636-.048Z" transform="translate(-154.041 -304.427)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_159" data-name="Group 159" transform="translate(4.882 1.743)">
          <path id="Path_393" data-name="Path 393" d="M210.25,341.863l-.07-.192v-.012A2,2,0,0,0,210.25,341.863Z" transform="translate(-183.771 -320.154)" fill="#303391"/>
          <path id="Path_394" data-name="Path 394" d="M214.354,312.354c0,3.06,2.061,4.965,4.58,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.118,3.118,0,0,1,.383,1.571v3.183l-11.81,3.771a7.579,7.579,0,0,1-4.635.09,7.376,7.376,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.882-1.57l-.015-.035-.173-.346-.177-.452-.056-.149-.085-.235a2,2,0,0,1-.07-.2,12.008,12.008,0,0,1-.571-3.777c0-6.907,4.682-13.656,10.231-14.967l11.844-2.8v3.146a3.978,3.978,0,0,1-2.512,3.833L221,304.979c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,214.354,312.354Z" transform="translate(-182.992 -296.12)" fill="url(#linear-gradient-4)"/>
          <path id="Path_395" data-name="Path 395" d="M174.23,323.9c0,3.177-2.254,6.46-5.1,7.3l-11.449,3.4a4.834,4.834,0,0,0-2.9,2.566,4.886,4.886,0,0,0-.524,2.133v3.726l14.83-4.736a13.978,13.978,0,0,0,5.162-3.093,18.816,18.816,0,0,0,3.787-4.969,16.682,16.682,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.086-.286c.026-.083.052-.165.071-.246a15.328,15.328,0,0,0,.573-4.108c0-6.9-4.828-11.4-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.272l1.378-.271C171.992,318.9,174.23,320.727,174.23,323.9Z" transform="translate(-154.259 -304.505)" fill="url(#linear-gradient-2)"/>
          <path id="Path_396" data-name="Path 396" d="M202.042,325.209v2.561l-11.81,3.772a7.583,7.583,0,0,1-4.635.09,7.375,7.375,0,0,1-3.6-2.6,8.212,8.212,0,0,1-.883-1.569l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a2,2,0,0,1-.07-.2,11.306,11.306,0,0,1-.508-2.453v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.871,3.646V315.79l14.871-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.044a11.144,11.144,0,0,0,.5,2.427,2,2,0,0,0,.07.2l.085.236.055.15.178.453.173.348.015.035a8.3,8.3,0,0,0,.883,1.578,7.47,7.47,0,0,0,3.6,2.628,7.581,7.581,0,0,0,4.635-.048Z" transform="translate(-154.256 -304.504)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_160" data-name="Group 160" transform="translate(4.984 1.78)">
          <path id="Path_397" data-name="Path 397" d="M210.465,341.94l-.07-.192v-.012A2.121,2.121,0,0,0,210.465,341.94Z" transform="translate(-183.987 -320.231)" fill="#303391"/>
          <path id="Path_398" data-name="Path 398" d="M214.57,312.431c0,3.06,2.061,4.965,4.579,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.122,3.122,0,0,1,.383,1.571v3.182l-11.81,3.772a7.588,7.588,0,0,1-4.636.09,7.378,7.378,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.452-.055-.149-.085-.235c-.026-.068-.051-.135-.07-.2a12.022,12.022,0,0,1-.571-3.777c0-6.907,4.682-13.657,10.231-14.967l11.845-2.8v3.146a3.978,3.978,0,0,1-2.512,3.833l-7.267,1.881c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,214.57,312.431Z" transform="translate(-183.207 -296.197)" fill="url(#linear-gradient-4)"/>
          <path id="Path_399" data-name="Path 399" d="M174.445,323.981c0,3.177-2.254,6.46-5.1,7.3l-11.449,3.4a4.834,4.834,0,0,0-2.9,2.566,4.886,4.886,0,0,0-.524,2.133v3.726l14.831-4.736a13.968,13.968,0,0,0,5.162-3.092,18.8,18.8,0,0,0,3.787-4.969,16.637,16.637,0,0,0,.9-2.121l.015-.044.176-.451.179-.559.056-.183.086-.286c.026-.083.052-.166.071-.246a15.327,15.327,0,0,0,.573-4.108c0-6.9-4.828-11.4-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.082-2.35c.336-.087.683-.181,1.033-.272l1.377-.271C172.207,318.976,174.445,320.8,174.445,323.981Z" transform="translate(-154.474 -304.582)" fill="url(#linear-gradient-2)"/>
          <path id="Path_400" data-name="Path 400" d="M202.257,325.286v2.561l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.376,7.376,0,0,1-3.6-2.6,8.236,8.236,0,0,1-.882-1.569l-.015-.035-.174-.346-.177-.452-.056-.149-.085-.235a2,2,0,0,1-.07-.2,11.307,11.307,0,0,1-.508-2.453v-.008c-.581-6.114-5.172-9.911-10.9-8.506l-14.87,3.646v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.045a11.161,11.161,0,0,0,.5,2.427,1.981,1.981,0,0,0,.07.2l.085.236.056.15.177.453.174.348.015.035a8.3,8.3,0,0,0,.882,1.578,7.471,7.471,0,0,0,3.6,2.628,7.581,7.581,0,0,0,4.635-.048Z" transform="translate(-154.472 -304.581)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_161" data-name="Group 161" transform="translate(5.085 1.816)">
          <path id="Path_401" data-name="Path 401" d="M210.681,342.017l-.07-.192v-.012A2.006,2.006,0,0,0,210.681,342.017Z" transform="translate(-184.202 -320.308)" fill="#303391"/>
          <path id="Path_402" data-name="Path 402" d="M214.785,312.508c0,3.06,2.061,4.965,4.579,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.121,3.121,0,0,1,.383,1.571v3.182l-11.81,3.772a7.586,7.586,0,0,1-4.635.09,7.377,7.377,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.452-.055-.149-.085-.235a2,2,0,0,1-.07-.2,12.007,12.007,0,0,1-.571-3.777c0-6.907,4.683-13.657,10.231-14.967l11.845-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.266,1.881c-.284.073-.579.146-.878.223l-1.189.388A7.286,7.286,0,0,0,214.785,312.508Z" transform="translate(-183.422 -296.274)" fill="url(#linear-gradient-4)"/>
          <path id="Path_403" data-name="Path 403" d="M174.661,324.058c0,3.177-2.254,6.46-5.1,7.3l-11.45,3.4a4.833,4.833,0,0,0-2.9,2.566,4.884,4.884,0,0,0-.524,2.133v3.726l14.83-4.736a13.964,13.964,0,0,0,5.162-3.092,18.812,18.812,0,0,0,3.788-4.969,16.645,16.645,0,0,0,.9-2.121l.015-.044.176-.451.179-.559.056-.183.085-.286c.026-.083.052-.166.071-.246a15.308,15.308,0,0,0,.573-4.108c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.272l1.378-.271C172.423,319.053,174.661,320.88,174.661,324.058Z" transform="translate(-154.69 -304.659)" fill="url(#linear-gradient-2)"/>
          <path id="Path_404" data-name="Path 404" d="M202.472,325.362v2.561l-11.81,3.772a7.583,7.583,0,0,1-4.635.09,7.374,7.374,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.452-.055-.149-.085-.235a2,2,0,0,1-.07-.2,11.307,11.307,0,0,1-.508-2.453v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.87,3.646v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.028,0-.044a11.133,11.133,0,0,0,.5,2.427,1.979,1.979,0,0,0,.07.2l.085.236.055.15.178.453.173.348.015.035a8.3,8.3,0,0,0,.883,1.578,7.469,7.469,0,0,0,3.6,2.628,7.584,7.584,0,0,0,4.635-.048Z" transform="translate(-154.687 -304.658)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_162" data-name="Group 162" transform="translate(5.187 1.853)">
          <path id="Path_405" data-name="Path 405" d="M210.9,342.094l-.07-.192v-.012A1.963,1.963,0,0,0,210.9,342.094Z" transform="translate(-184.418 -320.385)" fill="#303391"/>
          <path id="Path_406" data-name="Path 406" d="M215,312.585c0,3.06,2.06,4.965,4.579,4.218l9.3-2.76a1.751,1.751,0,0,1,2.161.82,3.116,3.116,0,0,1,.384,1.571v3.182l-11.81,3.772a7.586,7.586,0,0,1-4.635.09,7.379,7.379,0,0,1-3.6-2.6,8.237,8.237,0,0,1-.882-1.57l-.015-.035-.174-.346-.177-.451-.056-.149-.085-.235a1.971,1.971,0,0,1-.07-.2,12,12,0,0,1-.571-3.776c0-6.907,4.682-13.657,10.231-14.967l11.845-2.8V299.5a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.881c-.284.073-.579.146-.878.223l-1.188.388A7.285,7.285,0,0,0,215,312.585Z" transform="translate(-183.638 -296.351)" fill="url(#linear-gradient-4)"/>
          <path id="Path_407" data-name="Path 407" d="M174.876,324.135c0,3.177-2.254,6.461-5.1,7.3l-11.449,3.4a4.834,4.834,0,0,0-2.9,2.566,4.884,4.884,0,0,0-.524,2.133v3.726l14.83-4.736a13.968,13.968,0,0,0,5.162-3.092,18.8,18.8,0,0,0,3.787-4.969,16.667,16.667,0,0,0,.9-2.121l.015-.044.176-.451.179-.559.056-.183.086-.286c.026-.083.052-.166.071-.246a15.327,15.327,0,0,0,.573-4.108c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512V319.7a2.665,2.665,0,0,0,3.378,2.852l9.082-2.351c.335-.087.682-.18,1.033-.271l1.378-.271C172.638,319.13,174.876,320.957,174.876,324.135Z" transform="translate(-154.905 -304.736)" fill="url(#linear-gradient-2)"/>
          <path id="Path_408" data-name="Path 408" d="M202.688,325.439V328l-11.81,3.771a7.579,7.579,0,0,1-4.635.09,7.377,7.377,0,0,1-3.6-2.6,8.174,8.174,0,0,1-.882-1.57l-.015-.035-.173-.346-.177-.452-.056-.149-.085-.235a2,2,0,0,1-.07-.2,11.308,11.308,0,0,1-.508-2.453v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.87,3.646v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.028,0-.044a11.133,11.133,0,0,0,.5,2.427,1.979,1.979,0,0,0,.07.2l.085.236.056.15.177.453.173.348.015.035a8.274,8.274,0,0,0,.882,1.578,7.471,7.471,0,0,0,3.6,2.628,7.584,7.584,0,0,0,4.635-.048Z" transform="translate(-154.903 -304.735)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_163" data-name="Group 163" transform="translate(5.289 1.889)">
          <path id="Path_409" data-name="Path 409" d="M211.112,342.17l-.07-.192v-.012A2.084,2.084,0,0,0,211.112,342.17Z" transform="translate(-184.633 -320.462)" fill="#303391"/>
          <path id="Path_410" data-name="Path 410" d="M215.216,312.662c0,3.059,2.061,4.965,4.579,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.121,3.121,0,0,1,.383,1.571v3.182l-11.81,3.772a7.586,7.586,0,0,1-4.635.09,7.378,7.378,0,0,1-3.6-2.6,8.217,8.217,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a1.975,1.975,0,0,1-.07-.2,12,12,0,0,1-.571-3.776c0-6.907,4.683-13.657,10.231-14.967l11.845-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.881c-.284.073-.579.146-.878.223l-1.188.387A7.286,7.286,0,0,0,215.216,312.662Z" transform="translate(-183.853 -296.428)" fill="url(#linear-gradient-4)"/>
          <path id="Path_411" data-name="Path 411" d="M175.091,324.212c0,3.177-2.254,6.46-5.1,7.3l-11.449,3.4a5.112,5.112,0,0,0-3.421,4.7v3.726l14.831-4.736a13.967,13.967,0,0,0,5.162-3.092,18.811,18.811,0,0,0,3.788-4.969,16.667,16.667,0,0,0,.9-2.121l.015-.044.176-.451.179-.559.056-.183.086-.286c.026-.083.052-.166.071-.246a15.327,15.327,0,0,0,.573-4.108c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512v3.683a2.666,2.666,0,0,0,3.378,2.852l9.081-2.351c.336-.087.683-.181,1.033-.272l1.377-.271C172.853,319.206,175.091,321.034,175.091,324.212Z" transform="translate(-155.12 -304.813)" fill="url(#linear-gradient-2)"/>
          <path id="Path_412" data-name="Path 412" d="M202.9,325.516v2.561l-11.81,3.771a7.588,7.588,0,0,1-4.636.09,7.376,7.376,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.57l-.015-.035-.174-.346-.177-.452-.056-.149-.085-.235a2.126,2.126,0,0,1-.07-.2,11.336,11.336,0,0,1-.508-2.453v-.008c-.581-6.114-5.172-9.911-10.9-8.506l-14.87,3.645V316.1l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.627,0-.016,0-.028,0-.044a11.135,11.135,0,0,0,.5,2.427,2.1,2.1,0,0,0,.07.2l.085.236.056.15.177.453.174.348.015.035a8.3,8.3,0,0,0,.883,1.578,7.464,7.464,0,0,0,3.6,2.628,7.582,7.582,0,0,0,4.636-.047Z" transform="translate(-155.118 -304.812)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_164" data-name="Group 164" transform="translate(5.39 1.925)">
          <path id="Path_413" data-name="Path 413" d="M211.327,342.247l-.07-.192v-.012A1.959,1.959,0,0,0,211.327,342.247Z" transform="translate(-184.848 -320.539)" fill="#303391"/>
          <path id="Path_414" data-name="Path 414" d="M215.431,312.738c0,3.06,2.061,4.965,4.58,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.116,3.116,0,0,1,.383,1.571v3.182l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.375,7.375,0,0,1-3.6-2.595,8.189,8.189,0,0,1-.882-1.57l-.015-.035-.173-.346-.177-.451-.056-.149-.085-.235a1.96,1.96,0,0,1-.07-.2,12,12,0,0,1-.571-3.776c0-6.907,4.682-13.657,10.231-14.967l11.844-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.881c-.284.073-.579.146-.878.223l-1.188.387A7.286,7.286,0,0,0,215.431,312.738Z" transform="translate(-184.069 -296.505)" fill="url(#linear-gradient-4)"/>
          <path id="Path_415" data-name="Path 415" d="M175.307,324.289c0,3.177-2.254,6.46-5.1,7.3l-11.449,3.4a4.834,4.834,0,0,0-2.9,2.566,4.884,4.884,0,0,0-.524,2.133v3.726l14.83-4.736a13.97,13.97,0,0,0,5.162-3.092,18.817,18.817,0,0,0,3.787-4.969,16.652,16.652,0,0,0,.9-2.122l.015-.044.176-.451.179-.559.056-.183.086-.286c.026-.083.052-.165.071-.246a15.327,15.327,0,0,0,.573-4.108c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.272l1.378-.271C173.069,319.283,175.307,321.111,175.307,324.289Z" transform="translate(-155.336 -304.89)" fill="url(#linear-gradient-2)"/>
          <path id="Path_416" data-name="Path 416" d="M203.118,325.593v2.56l-11.81,3.772a7.586,7.586,0,0,1-4.635.09,7.378,7.378,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a2.006,2.006,0,0,1-.07-.2,11.307,11.307,0,0,1-.508-2.453v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.871,3.645v-2.933l14.871-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.028,0-.044a11.134,11.134,0,0,0,.5,2.427,1.986,1.986,0,0,0,.07.2l.085.236.055.15.178.453.173.348.015.035a8.313,8.313,0,0,0,.883,1.578,7.465,7.465,0,0,0,3.6,2.628,7.581,7.581,0,0,0,4.635-.047Z" transform="translate(-155.333 -304.889)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_165" data-name="Group 165" transform="translate(5.492 1.962)">
          <path id="Path_417" data-name="Path 417" d="M211.542,342.324l-.07-.192v-.012A2.079,2.079,0,0,0,211.542,342.324Z" transform="translate(-185.064 -320.616)" fill="#303391"/>
          <path id="Path_418" data-name="Path 418" d="M215.647,312.815c0,3.06,2.061,4.965,4.579,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.121,3.121,0,0,1,.383,1.571v3.182l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.37,7.37,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.569l-.015-.035-.174-.346-.177-.451-.055-.149-.085-.235c-.025-.067-.051-.135-.07-.2a12.016,12.016,0,0,1-.571-3.776c0-6.907,4.682-13.657,10.231-14.967l11.845-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.88c-.284.074-.579.146-.878.224l-1.188.387A7.286,7.286,0,0,0,215.647,312.815Z" transform="translate(-184.284 -296.582)" fill="url(#linear-gradient-4)"/>
          <path id="Path_419" data-name="Path 419" d="M175.522,324.366c0,3.177-2.254,6.46-5.1,7.3l-11.449,3.4a5.111,5.111,0,0,0-3.421,4.7v3.726l14.831-4.736a13.968,13.968,0,0,0,5.162-3.092,18.808,18.808,0,0,0,3.787-4.969,16.649,16.649,0,0,0,.9-2.122l.015-.044.176-.451.179-.559.056-.183.086-.286c.026-.083.052-.165.071-.246a15.33,15.33,0,0,0,.573-4.108c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.082-2.35c.336-.087.683-.181,1.033-.272l1.377-.271C173.284,319.36,175.522,321.188,175.522,324.366Z" transform="translate(-155.551 -304.967)" fill="url(#linear-gradient-2)"/>
          <path id="Path_420" data-name="Path 420" d="M203.334,325.67v2.56L191.524,332a7.586,7.586,0,0,1-4.635.09,7.379,7.379,0,0,1-3.6-2.6,8.215,8.215,0,0,1-.882-1.57l-.015-.035-.173-.346-.177-.451-.056-.149-.085-.235a1.964,1.964,0,0,1-.07-.2,11.33,11.33,0,0,1-.508-2.452v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.87,3.645v-2.933l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.028,0-.044a11.158,11.158,0,0,0,.5,2.427,1.964,1.964,0,0,0,.07.2l.085.236.056.15.177.453.173.348.015.035a8.316,8.316,0,0,0,.882,1.578,7.467,7.467,0,0,0,3.6,2.628,7.581,7.581,0,0,0,4.635-.047Z" transform="translate(-155.549 -304.966)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_166" data-name="Group 166" transform="translate(5.594 1.998)">
          <path id="Path_421" data-name="Path 421" d="M211.758,342.4l-.07-.192V342.2A2.079,2.079,0,0,0,211.758,342.4Z" transform="translate(-185.279 -320.693)" fill="#303391"/>
          <path id="Path_422" data-name="Path 422" d="M215.862,312.892c0,3.06,2.061,4.965,4.579,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.121,3.121,0,0,1,.383,1.571v3.182l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.371,7.371,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.569l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a1.961,1.961,0,0,1-.07-.2,12,12,0,0,1-.571-3.776c0-6.907,4.683-13.657,10.231-14.967l11.845-2.8V299.8a3.979,3.979,0,0,1-2.512,3.833l-7.266,1.88c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,215.862,312.892Z" transform="translate(-184.499 -296.659)" fill="url(#linear-gradient-4)"/>
          <path id="Path_423" data-name="Path 423" d="M175.738,324.443c0,3.177-2.254,6.46-5.1,7.3l-11.45,3.4a5.11,5.11,0,0,0-3.42,4.7v3.726l14.83-4.736a13.971,13.971,0,0,0,5.162-3.092,18.819,18.819,0,0,0,3.788-4.969,16.649,16.649,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.085-.286c.026-.083.052-.165.071-.246a15.324,15.324,0,0,0,.573-4.107c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.271l1.377-.271C173.5,319.437,175.738,321.265,175.738,324.443Z" transform="translate(-155.767 -305.044)" fill="url(#linear-gradient-2)"/>
          <path id="Path_424" data-name="Path 424" d="M203.549,325.747v2.56l-11.81,3.772a7.588,7.588,0,0,1-4.636.09,7.378,7.378,0,0,1-3.6-2.6,8.216,8.216,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a2.082,2.082,0,0,1-.07-.2,11.3,11.3,0,0,1-.508-2.452v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.87,3.645v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.044a11.132,11.132,0,0,0,.5,2.427,2.13,2.13,0,0,0,.07.2l.085.236.055.15.178.453.173.348.015.035a8.313,8.313,0,0,0,.883,1.578,7.472,7.472,0,0,0,3.6,2.628,7.582,7.582,0,0,0,4.636-.047Z" transform="translate(-155.764 -305.043)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_167" data-name="Group 167" transform="translate(5.696 2.034)">
          <path id="Path_425" data-name="Path 425" d="M211.973,342.478l-.07-.192v-.012A1.958,1.958,0,0,0,211.973,342.478Z" transform="translate(-185.495 -320.77)" fill="#303391"/>
          <path id="Path_426" data-name="Path 426" d="M216.078,312.969c0,3.06,2.06,4.965,4.579,4.218l9.3-2.759a1.752,1.752,0,0,1,2.161.82,3.115,3.115,0,0,1,.384,1.571V320l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.374,7.374,0,0,1-3.6-2.6,8.208,8.208,0,0,1-.882-1.569l-.015-.035-.173-.346-.177-.451-.056-.149-.085-.235a1.965,1.965,0,0,1-.07-.2,12.006,12.006,0,0,1-.571-3.777c0-6.907,4.682-13.656,10.231-14.967l11.845-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.88c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,216.078,312.969Z" transform="translate(-184.715 -296.736)" fill="url(#linear-gradient-4)"/>
          <path id="Path_427" data-name="Path 427" d="M175.953,324.52c0,3.177-2.254,6.46-5.1,7.3l-11.449,3.4a4.835,4.835,0,0,0-2.9,2.566,4.887,4.887,0,0,0-.524,2.133v3.726l14.83-4.736a13.975,13.975,0,0,0,5.162-3.092,18.824,18.824,0,0,0,3.787-4.969,16.645,16.645,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.086-.286c.026-.083.052-.165.071-.246a15.326,15.326,0,0,0,.573-4.107c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.272l1.378-.271C173.715,319.514,175.953,321.342,175.953,324.52Z" transform="translate(-155.982 -305.121)" fill="url(#linear-gradient-2)"/>
          <path id="Path_428" data-name="Path 428" d="M203.765,325.824v2.56l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.375,7.375,0,0,1-3.6-2.595,8.215,8.215,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a1.966,1.966,0,0,1-.07-.2,11.309,11.309,0,0,1-.508-2.452V324.2c-.58-6.114-5.172-9.911-10.9-8.506l-14.87,3.645v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.044a11.138,11.138,0,0,0,.5,2.427,2.012,2.012,0,0,0,.07.2l.085.236.055.15.178.453.173.348.015.035a8.3,8.3,0,0,0,.883,1.577,7.469,7.469,0,0,0,3.6,2.628,7.58,7.58,0,0,0,4.635-.047Z" transform="translate(-155.98 -305.12)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_168" data-name="Group 168" transform="translate(5.797 2.071)">
          <path id="Path_429" data-name="Path 429" d="M212.188,342.555l-.07-.192v-.012A2.085,2.085,0,0,0,212.188,342.555Z" transform="translate(-185.71 -320.847)" fill="#303391"/>
          <path id="Path_430" data-name="Path 430" d="M216.293,313.046c0,3.06,2.061,4.965,4.579,4.218l9.3-2.759a1.752,1.752,0,0,1,2.161.82,3.12,3.12,0,0,1,.383,1.571v3.182l-11.81,3.772a7.584,7.584,0,0,1-4.636.09,7.372,7.372,0,0,1-3.6-2.6,8.212,8.212,0,0,1-.883-1.569l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a2.076,2.076,0,0,1-.07-.2,12.006,12.006,0,0,1-.571-3.777c0-6.907,4.683-13.656,10.231-14.967l11.845-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.88c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,216.293,313.046Z" transform="translate(-184.93 -296.813)" fill="url(#linear-gradient-4)"/>
          <path id="Path_431" data-name="Path 431" d="M176.168,324.6c0,3.177-2.254,6.46-5.1,7.3l-11.45,3.4a4.835,4.835,0,0,0-2.9,2.566A4.888,4.888,0,0,0,156.2,340v3.726l14.831-4.736a13.974,13.974,0,0,0,5.162-3.092,18.826,18.826,0,0,0,3.788-4.969,16.652,16.652,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.086-.286c.026-.083.052-.165.071-.246a15.327,15.327,0,0,0,.573-4.107c0-6.9-4.828-11.405-10.965-9.956l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.082-2.35c.336-.087.683-.181,1.033-.272l1.377-.271C173.93,319.591,176.168,321.419,176.168,324.6Z" transform="translate(-156.197 -305.197)" fill="url(#linear-gradient-2)"/>
          <path id="Path_432" data-name="Path 432" d="M203.98,325.9v2.56l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.374,7.374,0,0,1-3.6-2.6,8.222,8.222,0,0,1-.882-1.569l-.015-.035-.174-.346-.177-.451-.056-.149-.085-.235a2.082,2.082,0,0,1-.07-.2,11.308,11.308,0,0,1-.508-2.453v-.008c-.581-6.114-5.172-9.911-10.9-8.506l-14.87,3.645v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.044a11.167,11.167,0,0,0,.5,2.427,2.136,2.136,0,0,0,.07.2l.085.236.056.15.177.453.174.348.015.035a8.334,8.334,0,0,0,.882,1.577,7.472,7.472,0,0,0,3.6,2.628,7.581,7.581,0,0,0,4.635-.047Z" transform="translate(-156.195 -305.197)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_169" data-name="Group 169" transform="translate(5.899 2.107)">
          <path id="Path_433" data-name="Path 433" d="M212.4,342.632l-.07-.192v-.012A2,2,0,0,0,212.4,342.632Z" transform="translate(-185.925 -320.924)" fill="#303391"/>
          <path id="Path_434" data-name="Path 434" d="M216.508,313.123c0,3.06,2.061,4.965,4.58,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.821,3.115,3.115,0,0,1,.383,1.57v3.183l-11.81,3.772a7.583,7.583,0,0,1-4.635.09,7.377,7.377,0,0,1-3.6-2.6,8.184,8.184,0,0,1-.882-1.569l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a1.964,1.964,0,0,1-.07-.2,12.006,12.006,0,0,1-.571-3.777c0-6.907,4.682-13.656,10.231-14.967l11.844-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.88c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,216.508,313.123Z" transform="translate(-185.146 -296.89)" fill="url(#linear-gradient-4)"/>
          <path id="Path_435" data-name="Path 435" d="M176.384,324.673c0,3.178-2.254,6.461-5.1,7.3l-11.449,3.4a4.834,4.834,0,0,0-2.9,2.566,4.887,4.887,0,0,0-.524,2.133V343.8l14.83-4.736a13.97,13.97,0,0,0,5.162-3.093A18.826,18.826,0,0,0,180.195,331a16.646,16.646,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.085-.286c.026-.083.052-.165.071-.246A15.307,15.307,0,0,0,182.25,323c0-6.9-4.828-11.405-10.965-9.956l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.272l1.378-.271C174.146,319.668,176.384,321.5,176.384,324.673Z" transform="translate(-156.413 -305.274)" fill="url(#linear-gradient-2)"/>
          <path id="Path_436" data-name="Path 436" d="M204.2,325.978v2.56l-11.81,3.772a7.583,7.583,0,0,1-4.635.09,7.371,7.371,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.569l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a1.963,1.963,0,0,1-.07-.2,11.307,11.307,0,0,1-.508-2.453v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.871,3.645v-2.934l14.871-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.044a11.138,11.138,0,0,0,.5,2.427,2.011,2.011,0,0,0,.07.2l.085.236.055.149.178.453.173.348.015.035a8.31,8.31,0,0,0,.883,1.577,7.469,7.469,0,0,0,3.6,2.628,7.581,7.581,0,0,0,4.635-.048Z" transform="translate(-156.41 -305.274)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_170" data-name="Group 170" transform="translate(6.001 2.143)">
          <path id="Path_437" data-name="Path 437" d="M212.619,342.709l-.07-.192v-.012A2.007,2.007,0,0,0,212.619,342.709Z" transform="translate(-186.141 -321.001)" fill="#303391"/>
          <path id="Path_438" data-name="Path 438" d="M216.724,313.2c0,3.06,2.061,4.965,4.579,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.118,3.118,0,0,1,.384,1.571v3.183L221.337,324a7.583,7.583,0,0,1-4.635.09,7.376,7.376,0,0,1-3.6-2.6,8.234,8.234,0,0,1-.882-1.569l-.015-.035-.174-.346-.177-.452-.056-.149-.085-.235a2.125,2.125,0,0,1-.07-.2,12.02,12.02,0,0,1-.571-3.777c0-6.907,4.682-13.656,10.231-14.967l11.845-2.8v3.145a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.88c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,216.724,313.2Z" transform="translate(-185.361 -296.967)" fill="url(#linear-gradient-4)"/>
          <path id="Path_439" data-name="Path 439" d="M176.6,324.75c0,3.178-2.254,6.46-5.1,7.3l-11.449,3.4a4.835,4.835,0,0,0-2.9,2.566,4.888,4.888,0,0,0-.524,2.133v3.726l14.83-4.736a13.977,13.977,0,0,0,5.162-3.093,18.8,18.8,0,0,0,3.787-4.969,16.676,16.676,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.086-.286c.026-.083.052-.165.071-.246a15.326,15.326,0,0,0,.573-4.107c0-6.9-4.828-11.405-10.965-9.956l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.082-2.35c.336-.087.683-.181,1.033-.272l1.378-.271C174.361,319.745,176.6,321.573,176.6,324.75Z" transform="translate(-156.628 -305.351)" fill="url(#linear-gradient-2)"/>
          <path id="Path_440" data-name="Path 440" d="M204.411,326.055v2.561l-11.81,3.772a7.583,7.583,0,0,1-4.635.09,7.374,7.374,0,0,1-3.6-2.6,8.21,8.21,0,0,1-.882-1.569l-.015-.035-.173-.346-.177-.451-.056-.149-.085-.235a1.954,1.954,0,0,1-.07-.2,11.306,11.306,0,0,1-.508-2.453v-.008c-.58-6.114-5.172-9.911-10.9-8.507l-14.87,3.646v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.627,0-.016,0-.029,0-.044a11.137,11.137,0,0,0,.5,2.427,2,2,0,0,0,.07.2l.085.236.056.15.177.453.173.348.015.035a8.308,8.308,0,0,0,.882,1.577,7.471,7.471,0,0,0,3.6,2.628,7.581,7.581,0,0,0,4.635-.048Z" transform="translate(-156.626 -305.351)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_171" data-name="Group 171" transform="translate(6.103 2.179)">
          <path id="Path_441" data-name="Path 441" d="M212.835,342.786l-.07-.192v-.012A2.124,2.124,0,0,0,212.835,342.786Z" transform="translate(-186.356 -321.077)" fill="#303391"/>
          <path id="Path_442" data-name="Path 442" d="M216.939,313.277c0,3.06,2.061,4.965,4.579,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.122,3.122,0,0,1,.383,1.571v3.183l-11.81,3.771a7.579,7.579,0,0,1-4.635.09,7.374,7.374,0,0,1-3.6-2.6,8.21,8.21,0,0,1-.883-1.569l-.015-.035-.173-.346-.178-.452-.055-.149-.085-.235a2,2,0,0,1-.07-.2,12.006,12.006,0,0,1-.571-3.777c0-6.907,4.683-13.656,10.231-14.967l11.845-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.266,1.88c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,216.939,313.277Z" transform="translate(-185.576 -297.043)" fill="url(#linear-gradient-4)"/>
          <path id="Path_443" data-name="Path 443" d="M176.814,324.827c0,3.177-2.254,6.46-5.1,7.3l-11.45,3.4a5.112,5.112,0,0,0-3.421,4.7v3.726l14.831-4.736a13.971,13.971,0,0,0,5.162-3.093,18.818,18.818,0,0,0,3.788-4.969,16.68,16.68,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.085-.286c.026-.083.052-.165.071-.246a15.327,15.327,0,0,0,.573-4.108c0-6.9-4.828-11.4-10.965-9.955l-14.87,3.512V320.4a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.272l1.377-.271C174.576,319.822,176.814,321.65,176.814,324.827Z" transform="translate(-156.843 -305.428)" fill="url(#linear-gradient-2)"/>
          <path id="Path_444" data-name="Path 444" d="M204.626,326.132v2.561l-11.81,3.772a7.584,7.584,0,0,1-4.636.09,7.372,7.372,0,0,1-3.6-2.6,8.212,8.212,0,0,1-.883-1.569l-.015-.035-.174-.346-.177-.451-.055-.149-.085-.235c-.026-.067-.051-.135-.07-.2a11.334,11.334,0,0,1-.508-2.453v-.008c-.581-6.114-5.172-9.911-10.9-8.506l-14.87,3.646v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.044a11.143,11.143,0,0,0,.5,2.427c.019.069.045.137.07.2l.085.236.055.15.177.453.174.348.015.035a8.3,8.3,0,0,0,.883,1.578,7.47,7.47,0,0,0,3.6,2.628,7.582,7.582,0,0,0,4.636-.048Z" transform="translate(-156.841 -305.427)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_172" data-name="Group 172" transform="translate(6.204 2.216)">
          <path id="Path_445" data-name="Path 445" d="M213.05,342.863l-.07-.192v-.012A2,2,0,0,0,213.05,342.863Z" transform="translate(-186.571 -321.154)" fill="#303391"/>
          <path id="Path_446" data-name="Path 446" d="M217.154,313.354c0,3.06,2.061,4.965,4.58,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.118,3.118,0,0,1,.383,1.571v3.183l-11.81,3.771a7.579,7.579,0,0,1-4.635.09,7.376,7.376,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.882-1.57l-.015-.035-.173-.346-.177-.452-.056-.149-.085-.235a2.005,2.005,0,0,1-.07-.2,12.008,12.008,0,0,1-.571-3.777c0-6.907,4.682-13.656,10.231-14.967l11.844-2.8v3.146a3.978,3.978,0,0,1-2.512,3.833l-7.267,1.881c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,217.154,313.354Z" transform="translate(-185.792 -297.12)" fill="url(#linear-gradient-4)"/>
          <path id="Path_447" data-name="Path 447" d="M177.03,324.9c0,3.177-2.254,6.46-5.1,7.3l-11.45,3.4a4.834,4.834,0,0,0-2.9,2.566,4.886,4.886,0,0,0-.524,2.133v3.726l14.83-4.736a13.977,13.977,0,0,0,5.162-3.093,18.815,18.815,0,0,0,3.787-4.969,16.677,16.677,0,0,0,.9-2.122l.015-.044.176-.452.179-.559.056-.182.086-.286c.026-.083.052-.165.071-.246a15.328,15.328,0,0,0,.573-4.108c0-6.9-4.828-11.4-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.272l1.378-.271C174.792,319.9,177.03,321.727,177.03,324.9Z" transform="translate(-157.059 -305.505)" fill="url(#linear-gradient-2)"/>
          <path id="Path_448" data-name="Path 448" d="M204.842,326.209v2.561l-11.81,3.772a7.583,7.583,0,0,1-4.635.09,7.375,7.375,0,0,1-3.6-2.6,8.212,8.212,0,0,1-.883-1.569l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a2,2,0,0,1-.07-.2,11.305,11.305,0,0,1-.508-2.453v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.871,3.646V316.79l14.871-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.044a11.143,11.143,0,0,0,.5,2.427,2.007,2.007,0,0,0,.07.2l.085.236.055.15.178.453.173.348.015.035a8.3,8.3,0,0,0,.883,1.578,7.47,7.47,0,0,0,3.6,2.628,7.581,7.581,0,0,0,4.635-.048Z" transform="translate(-157.056 -305.504)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_173" data-name="Group 173" transform="translate(6.306 2.252)">
          <path id="Path_449" data-name="Path 449" d="M213.265,342.94l-.07-.192v-.012A2.136,2.136,0,0,0,213.265,342.94Z" transform="translate(-186.787 -321.231)" fill="#303391"/>
          <path id="Path_450" data-name="Path 450" d="M217.37,313.431c0,3.06,2.061,4.965,4.579,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.122,3.122,0,0,1,.383,1.571v3.182l-11.81,3.772a7.588,7.588,0,0,1-4.636.09,7.378,7.378,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.452-.055-.149-.085-.235c-.026-.068-.051-.135-.07-.2a12.022,12.022,0,0,1-.571-3.777c0-6.907,4.682-13.657,10.231-14.967l11.845-2.8v3.146a3.978,3.978,0,0,1-2.512,3.833l-7.267,1.881c-.284.074-.579.146-.878.223l-1.188.388A7.286,7.286,0,0,0,217.37,313.431Z" transform="translate(-186.007 -297.197)" fill="url(#linear-gradient-4)"/>
          <path id="Path_451" data-name="Path 451" d="M177.245,324.981c0,3.177-2.254,6.46-5.1,7.3l-11.45,3.4a4.834,4.834,0,0,0-2.9,2.566,4.885,4.885,0,0,0-.524,2.133v3.726l14.831-4.736a13.968,13.968,0,0,0,5.162-3.092,18.8,18.8,0,0,0,3.787-4.969,16.642,16.642,0,0,0,.9-2.121l.015-.044.176-.451.179-.559.056-.183.086-.286c.026-.083.052-.166.071-.246a15.327,15.327,0,0,0,.573-4.108c0-6.9-4.828-11.4-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.082-2.35c.336-.087.683-.181,1.033-.272l1.377-.271C175.007,319.976,177.245,321.8,177.245,324.981Z" transform="translate(-157.274 -305.582)" fill="url(#linear-gradient-2)"/>
          <path id="Path_452" data-name="Path 452" d="M205.057,326.286v2.561l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.376,7.376,0,0,1-3.6-2.6,8.234,8.234,0,0,1-.882-1.569l-.015-.035-.174-.346-.177-.452-.056-.149-.085-.235a2,2,0,0,1-.07-.2,11.305,11.305,0,0,1-.508-2.453v-.008c-.581-6.114-5.172-9.911-10.9-8.506l-14.87,3.646v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.029,0-.045a11.16,11.16,0,0,0,.5,2.427,1.981,1.981,0,0,0,.07.2l.085.236.056.15.177.453.174.348.015.035a8.3,8.3,0,0,0,.882,1.578,7.471,7.471,0,0,0,3.6,2.628,7.581,7.581,0,0,0,4.635-.048Z" transform="translate(-157.272 -305.581)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_174" data-name="Group 174" transform="translate(6.408 2.288)">
          <path id="Path_453" data-name="Path 453" d="M213.481,343.017l-.07-.192v-.012A2.006,2.006,0,0,0,213.481,343.017Z" transform="translate(-187.002 -321.308)" fill="#303391"/>
          <path id="Path_454" data-name="Path 454" d="M217.585,313.508c0,3.06,2.061,4.965,4.579,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.121,3.121,0,0,1,.383,1.571v3.182l-11.81,3.772a7.586,7.586,0,0,1-4.635.09,7.377,7.377,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.452-.055-.149-.085-.235a2,2,0,0,1-.07-.2,12.008,12.008,0,0,1-.571-3.777c0-6.907,4.683-13.657,10.231-14.967l11.845-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.266,1.881c-.284.073-.579.146-.878.223l-1.189.388A7.286,7.286,0,0,0,217.585,313.508Z" transform="translate(-186.222 -297.274)" fill="url(#linear-gradient-4)"/>
          <path id="Path_455" data-name="Path 455" d="M177.461,325.058c0,3.177-2.254,6.46-5.1,7.3l-11.45,3.4a4.833,4.833,0,0,0-2.9,2.566,4.884,4.884,0,0,0-.524,2.133v3.726l14.83-4.736a13.964,13.964,0,0,0,5.162-3.092,18.813,18.813,0,0,0,3.788-4.969,16.643,16.643,0,0,0,.9-2.121l.015-.044.176-.451.179-.559.056-.183.085-.286c.026-.083.052-.166.071-.246a15.308,15.308,0,0,0,.573-4.108c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.081-2.35c.336-.087.683-.181,1.033-.272l1.378-.271C175.223,320.053,177.461,321.88,177.461,325.058Z" transform="translate(-157.49 -305.659)" fill="url(#linear-gradient-2)"/>
          <path id="Path_456" data-name="Path 456" d="M205.272,326.362v2.561l-11.81,3.772a7.582,7.582,0,0,1-4.635.09,7.374,7.374,0,0,1-3.6-2.6,8.2,8.2,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.452-.055-.149-.085-.235a2.011,2.011,0,0,1-.07-.2,11.308,11.308,0,0,1-.508-2.453v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.87,3.646v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.028,0-.044a11.132,11.132,0,0,0,.5,2.427,1.98,1.98,0,0,0,.07.2l.085.236.055.15.178.453.173.348.015.035a8.3,8.3,0,0,0,.883,1.578,7.469,7.469,0,0,0,3.6,2.628,7.584,7.584,0,0,0,4.635-.048Z" transform="translate(-157.487 -305.658)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_175" data-name="Group 175" transform="translate(6.51 2.325)">
          <path id="Path_457" data-name="Path 457" d="M213.7,343.094l-.07-.192v-.012A1.962,1.962,0,0,0,213.7,343.094Z" transform="translate(-187.218 -321.385)" fill="#303391"/>
          <path id="Path_458" data-name="Path 458" d="M217.8,313.585c0,3.06,2.06,4.965,4.579,4.218l9.3-2.76a1.752,1.752,0,0,1,2.161.82,3.116,3.116,0,0,1,.384,1.571v3.182l-11.81,3.772a7.586,7.586,0,0,1-4.635.09,7.379,7.379,0,0,1-3.6-2.6,8.239,8.239,0,0,1-.882-1.57l-.015-.035-.174-.346-.177-.451-.056-.149-.085-.235a1.975,1.975,0,0,1-.07-.2,12,12,0,0,1-.571-3.776c0-6.907,4.682-13.657,10.231-14.967l11.845-2.8V300.5a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.881c-.284.073-.579.146-.878.223l-1.188.388A7.285,7.285,0,0,0,217.8,313.585Z" transform="translate(-186.438 -297.351)" fill="url(#linear-gradient-4)"/>
          <path id="Path_459" data-name="Path 459" d="M177.676,325.135c0,3.177-2.254,6.46-5.1,7.3l-11.45,3.4a4.834,4.834,0,0,0-2.9,2.566,4.884,4.884,0,0,0-.524,2.133v3.726l14.83-4.736a13.969,13.969,0,0,0,5.162-3.092,18.8,18.8,0,0,0,3.787-4.969,16.667,16.667,0,0,0,.9-2.121l.015-.044.176-.451.179-.559.056-.183.086-.286c.026-.083.052-.166.071-.246a15.327,15.327,0,0,0,.573-4.108c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512V320.7a2.665,2.665,0,0,0,3.378,2.852l9.082-2.351c.335-.087.682-.18,1.033-.271l1.378-.271C175.438,320.13,177.676,321.957,177.676,325.135Z" transform="translate(-157.705 -305.736)" fill="url(#linear-gradient-2)"/>
          <path id="Path_460" data-name="Path 460" d="M205.488,326.439V329l-11.81,3.771a7.579,7.579,0,0,1-4.635.09,7.377,7.377,0,0,1-3.6-2.6,8.176,8.176,0,0,1-.882-1.57l-.015-.035-.173-.346-.177-.452-.056-.149-.085-.235a2,2,0,0,1-.07-.2,11.306,11.306,0,0,1-.508-2.453v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.87,3.646v-2.934l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.028,0-.044a11.132,11.132,0,0,0,.5,2.427,1.977,1.977,0,0,0,.07.2l.085.236.056.15.177.453.173.348.015.035a8.274,8.274,0,0,0,.882,1.578,7.471,7.471,0,0,0,3.6,2.628,7.584,7.584,0,0,0,4.635-.048Z" transform="translate(-157.703 -305.735)" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group_176" data-name="Group 176" transform="translate(6.611 2.361)">
          <path id="Path_461" data-name="Path 461" d="M213.912,343.17l-.07-.192v-.012A2.082,2.082,0,0,0,213.912,343.17Z" transform="translate(-187.433 -321.462)" fill="#303391"/>
          <path id="Path_462" data-name="Path 462" d="M218.016,313.662c0,3.059,2.061,4.965,4.579,4.218l9.3-2.76a1.749,1.749,0,0,1,2.161.82,3.1,3.1,0,0,1,.383,1.571v3.182l-11.81,3.772a7.586,7.586,0,0,1-4.635.09,7.231,7.231,0,0,1-3.6-2.6,7.768,7.768,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.451-.055-.149-.085-.235a1.965,1.965,0,0,1-.07-.2,12,12,0,0,1-.571-3.776c0-6.907,4.683-13.657,10.231-14.967l11.845-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.881c-.284.073-.579.146-.878.223l-1.188.387A7.286,7.286,0,0,0,218.016,313.662Z" transform="translate(-186.653 -297.428)" fill="url(#linear-gradient)"/>
          <path id="Path_463" data-name="Path 463" d="M177.891,325.212c0,3.177-2.254,6.46-5.1,7.3l-11.45,3.4a5.112,5.112,0,0,0-3.421,4.7v3.726l14.831-4.736a13.967,13.967,0,0,0,5.162-3.092,18.4,18.4,0,0,0,3.788-4.969,15.8,15.8,0,0,0,.9-2.121l.015-.044.176-.451.179-.559.056-.183.086-.286c.026-.083.052-.166.071-.246a15.327,15.327,0,0,0,.573-4.108c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512v3.683a2.666,2.666,0,0,0,3.378,2.852l9.081-2.351c.336-.087.683-.181,1.033-.272l1.377-.271C175.653,320.206,177.891,322.034,177.891,325.212Z" transform="translate(-157.92 -305.813)" fill="url(#linear-gradient-2)"/>
          <path id="Path_464" data-name="Path 464" d="M205.7,326.516v2.561l-11.81,3.771a7.587,7.587,0,0,1-4.636.09,7.227,7.227,0,0,1-3.6-2.6,7.756,7.756,0,0,1-.883-1.57l-.015-.035-.174-.346-.177-.452-.056-.149-.085-.235a2.117,2.117,0,0,1-.07-.2,11.335,11.335,0,0,1-.508-2.453v-.008c-.581-6.114-5.172-9.911-10.9-8.506l-14.87,3.645V317.1l14.87-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.028,0-.044a11.133,11.133,0,0,0,.5,2.427,2.1,2.1,0,0,0,.07.2l.085.236.056.15.177.453.174.348.015.035a7.859,7.859,0,0,0,.883,1.578,7.315,7.315,0,0,0,3.6,2.628,7.582,7.582,0,0,0,4.636-.047Z" transform="translate(-157.918 -305.812)" fill="url(#linear-gradient-3)"/>
        </g>
      </g>
      <g id="Group_178" data-name="Group 178" transform="translate(210.006 303.285)">
        <path id="Path_465" data-name="Path 465" d="M283.868,315.42v21.293h4.472V315.42Z" transform="translate(-283.868 -315.42)" fill="url(#linear-gradient-199)"/>
        <path id="Path_466" data-name="Path 466" d="M324.475,315.42v14.573l-9.7-14.573h-4.807v21.293h4.472V322.144l9.671,14.568h4.807V315.42Z" transform="translate(-297.643 -315.42)" fill="url(#linear-gradient-200)"/>
        <path id="Path_467" data-name="Path 467" d="M380.211,315.42l-5.9,15.239-5.9-15.239h-4.562l8.24,21.293h4.439l8.24-21.293Z" transform="translate(-326.08 -315.42)" fill="url(#linear-gradient-201)"/>
        <path id="Path_468" data-name="Path 468" d="M437.407,319.831V315.42H421.838v21.293h15.569V332.3h-11.1v-4.047h9.581v-4.439h-9.581v-3.986Z" transform="translate(-356.686 -315.42)" fill="url(#linear-gradient-202)"/>
        <path id="Path_469" data-name="Path 469" d="M485.605,315.42h-4.717l-4.741,7.3-4.746-7.3h-4.713l6.871,10.649-6.871,10.644H471.4l4.746-7.3,4.741,7.3h4.717l-6.876-10.644Z" transform="translate(-380.356 -315.42)" fill="url(#linear-gradient-203)"/>
      </g>
      <g id="Group_179" data-name="Group 179" transform="translate(150.531 294.67)" opacity="0.15">
        <path id="Path_470" data-name="Path 470" d="M213.916,342.918l-.07-.192v-.012A1.958,1.958,0,0,0,213.916,342.918Z" transform="translate(-187.437 -321.209)" fill="#fff"/>
        <path id="Path_471" data-name="Path 471" d="M218.02,313.409c0,3.06,2.061,4.965,4.58,4.218l9.3-2.76a1.749,1.749,0,0,1,2.161.82,3.1,3.1,0,0,1,.383,1.571v3.182l-11.81,3.772a7.586,7.586,0,0,1-4.635.09,7.231,7.231,0,0,1-3.6-2.6,7.789,7.789,0,0,1-.882-1.57l-.015-.035-.173-.346-.177-.451-.056-.149-.085-.235a1.97,1.97,0,0,1-.07-.2,12,12,0,0,1-.571-3.776c0-6.907,4.682-13.657,10.231-14.967l11.844-2.8v3.146a3.979,3.979,0,0,1-2.512,3.833l-7.267,1.881c-.284.073-.579.146-.878.223l-1.188.388A7.285,7.285,0,0,0,218.02,313.409Z" transform="translate(-186.658 -297.175)" fill="#fff"/>
        <path id="Path_472" data-name="Path 472" d="M177.9,324.959c0,3.177-2.254,6.46-5.1,7.3l-11.45,3.4a5.109,5.109,0,0,0-3.421,4.7v3.726l14.83-4.736a13.97,13.97,0,0,0,5.162-3.092,18.4,18.4,0,0,0,3.787-4.969,15.8,15.8,0,0,0,.9-2.121l.015-.044.176-.451.179-.559.056-.183.086-.286c.026-.083.052-.166.071-.246a15.328,15.328,0,0,0,.573-4.108c0-6.9-4.828-11.405-10.965-9.955l-14.87,3.512v3.683a2.665,2.665,0,0,0,3.378,2.852l9.081-2.351c.336-.087.683-.18,1.033-.271l1.378-.271C175.658,319.954,177.9,321.781,177.9,324.959Z" transform="translate(-157.925 -305.56)" fill="#fff"/>
        <path id="Path_473" data-name="Path 473" d="M205.707,326.263v2.561L193.9,332.6a7.579,7.579,0,0,1-4.635.09,7.226,7.226,0,0,1-3.6-2.6,7.756,7.756,0,0,1-.883-1.57l-.015-.035-.173-.346-.178-.452-.055-.149-.085-.235a2,2,0,0,1-.07-.2,11.307,11.307,0,0,1-.508-2.453v-.008c-.58-6.114-5.172-9.911-10.9-8.506l-14.871,3.646v-2.934l14.871-3.512c5.742-1.356,10.333,2.493,10.9,8.628,0-.016,0-.028,0-.044a11.132,11.132,0,0,0,.5,2.427,1.974,1.974,0,0,0,.07.2l.085.236.055.15.178.453.173.348.015.035a7.86,7.86,0,0,0,.883,1.578,7.321,7.321,0,0,0,3.6,2.628,7.584,7.584,0,0,0,4.635-.048Z" transform="translate(-157.922 -305.559)" fill="#fff"/>
      </g>
    </g>
  </svg>

</div>

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PopupComponent } from 'src/app/shared/components/popup/popup.component';
import { Industry } from 'src/app/models/project/Industry.model';
import { IndustryService } from 'src/app/services/support/industry.service';

@Component({
  selector: 'app-industries',
  templateUrl: './industries.component.html',
  styleUrls: ['./industries.component.css']
})
export class IndustriesComponent implements OnInit {
  
  industries:Industry[] = [];

  constructor(
    public industryService:IndustryService,
  ) { }

  ngOnInit(): void {
    this.RefreshIndustries();
  }

  RefreshIndustries(): void {
    this.industryService.GetIndustriesList((response) => {
      this.industries = response.data.list;
    });
  }

  @ViewChild('deletePopup') deletePopup:PopupComponent;
  OnRemoveClicked(idx:number):void{
    this.industryService.SelectIndustry(idx, () => { this.deletePopup.Open(); });
  }

  @ViewChild('updatePopup') updatePopup:PopupComponent;
  OnEditClicked(idx:number):void{
    this.industryService.SelectIndustry(idx, () => { this.updatePopup.Open(); });
  }

  OnPopupRemoveClicked():void{
    this.industryService.DeleteSelectedIndustry(() => { this.RefreshIndustries(); });
  }

  @ViewChild('updateInput',{static:true}) input:ElementRef;
  OnUpdateSaveClicked():void{
    this.industryService.UpdateSelectedIndustry((this.input.nativeElement as HTMLInputElement).value, () => { this.RefreshIndustries(); });
  }

  isMobileResolution(): boolean {
    if(window.innerWidth<=768){
      return true;
    } else {
      return false;
    }
  }
}

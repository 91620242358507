import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PopupComponent } from 'src/app/shared/components/popup/popup.component';
import { EmailTemplate } from 'src/app/models/utility/EmailTemplate.model';
import { EmailService } from 'src/app/services/support/email.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-email-template-popup',
  templateUrl: './email-template-popup.component.html',
  styleUrls: ['./email-template-popup.component.css']
})
export class EmailTemplatePopupComponent implements OnInit {

  text:string = '';
  @Input() isNew:boolean = false;
  @Output() agree:EventEmitter<EmailTemplate> = new EventEmitter<EmailTemplate>();

  emailTemplate:EmailTemplate;
  @ViewChild('updatePopup',{static:true}) popup:PopupComponent;
  constructor(
    public emailService:EmailService,
    private translateService: TranslateService,
  ) {
    this.text = this.translateService.instant('general.email.edit');
  }

  ngOnInit(): void {

  }

  OnSave():void{
    if(this.emailTemplate.title!='' && this.emailTemplate.text!=''){
      this.agree.emit(this.emailTemplate);
      this.popup.Close()
    }
  }
  Open():void{
    this.emailTemplate = {subject:'', title:'', text:''};
    if(this.isNew){
      this.text = this.translateService.instant('general.email.new');
    }else{
      let tmpEmial:EmailTemplate = this.emailService.GetSelectedEmail();
      this.emailTemplate.title = tmpEmial.title;
      this.emailTemplate.text = tmpEmial.text;
      this.emailTemplate.subject = tmpEmial.subject;
    }
    this.popup.Open();
  }
  Close():void{
    this.popup.Close();
  }
}

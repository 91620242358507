import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { Utils } from 'src/app/common/utils';
import { PopupComponent } from 'src/app/shared/components/popup/popup.component';
import { Task, TaskTypeDisplay } from 'src/app/models/Task.model';
import { User } from 'src/app/models/project/User.model';
import { TeamService } from 'src/app/services/project/team/team.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-task-card',
  templateUrl: './task-card.component.html',
  styleUrls: ['./task-card.component.scss']
})
export class TaskCardComponent implements OnInit {

  @Input() task: Task = new Task;
  @Input() isStartup: boolean = false;
  @Input() isInternal: boolean = true;

  TaskTypeDisplay = TaskTypeDisplay;
  Utils = Utils;

  @Output() tickChange:EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() flagChange:EventEmitter<number> = new EventEmitter<number>();
  @Output() editTask: EventEmitter<Task> = new EventEmitter<Task>();
  @Output() removeTask: EventEmitter<number> = new EventEmitter<number>();
  @Input() tick: boolean = false;
  flagColors: string[] = ['var(--light)', '#f7d733', 'rgb(255,121,121)'];

  projectId:string;
  projectStatus:string;

  constructor(
    private teamService: TeamService,
    private router: Router,
  ) {
    this.projectId = this.router.url.split('/')[2];
    this.projectStatus = this.router.url.split('/')[1] == "portfolio" ? "1" : "0";
  }

  ngOnInit(): void {

  }

  emitEditTask() {
    this.editTask.emit(this.task);
  }

  emitRemoveTask() {
    this.removeTask.emit(this.task.id);
  }

  onFlagClicked(): void {
    if(this.task.priority < this.flagColors.length-1) {
      this.task.priority++;
    } else {
      this.task.priority = 0;
    }
    this.flagChange.emit(this.task.priority);
  }

  onDonerClicked(): void {
    this.tickChange.emit(this.tick);
  }

  getDate(date:Date): string {
    return Utils.stringifyDate(new Date(date));
  }
}

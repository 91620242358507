import { Component, OnInit, HostListener, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { StartUp } from 'src/app/models/project/Startup.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PermissionService } from 'src/app/services/auth/permission.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  hide: boolean;
  selected: string = 'dealflow';
  vh: number;
  @Output() show:EventEmitter<number> = new EventEmitter<number>();
  projects: StartUp[] = [];

  constructor(
    private router:Router,
    public authService:AuthService,
    permissionService:PermissionService,
  ) {
    permissionService.getMenuProjectList((list) => {
      this.projects = list;
    });
    this.hide = this.isMobileResolution();
    this.vh = window.innerHeight;
  }

  ngOnInit(): void {
  }

  isMobileResolution(): boolean {
    if(window.innerWidth<=768){
      return true;
    } else {
      return false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.vh = event.target.innerHeight;
  }

  OnNavigate(path:string): void{
    this.router.navigate([path]);
    this.selected = path;
  }

  hideChanged(event:boolean): void{
    this.hide = event;
    if(this.hide) {
      this.show.emit(49);
    } else {
      this.show.emit(190);
    }
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { PopupComponent } from 'src/app/shared/components/popup/popup.component';
import { Utils } from 'src/app/common/utils';
import { Dashboard } from 'src/app/models/dashboard/Dashboard.model';
import { DashboardService } from 'src/app/services/support/dashboard.service';

@Component({
  selector: 'app-dashboard-editor',
  templateUrl: './dashboard-editor.component.html',
  styleUrls: ['./dashboard-editor.component.css']
})
export class DashboardEditorComponent implements OnInit {
  
  dashboards:Dashboard[]=[];
  constructor(
    public dashboardService:DashboardService,
  ) { }

  ngOnInit(): void {
    this.RefreshDashboards();
  }

  RefreshDashboards(): void {
    this.dashboardService.RefreshDashboardList(() => {
      this.dashboards = this.dashboardService.dashboards;
    });
  }

  GetDateString(date:Date):string{
    return Utils.stringifyDate(date);
  }

  @ViewChild('deletePopup') deletePopup:PopupComponent;
  OnRemoveClicked(dashboard: Dashboard):void{
    this.dashboardService.SelectDashboard(dashboard.id, () => { this.deletePopup.Open(); });
  }

  @ViewChild('editPopup') editPopup:PopupComponent;
  OnEditClicked(dashboard: Dashboard):void{
    this.dashboardService.SelectDashboard(dashboard.id, () => { this.editPopup.Open(); });
  }

  OnPopupRemoveClicked():void{
    this.dashboardService.DeleteSelectedDashboard(() => {
      this.deletePopup.Close()
      this.RefreshDashboards();
    });
  }

  OnPopupEditClicked():void{
    this.dashboardService.UpdateSelectedDashboard(() => {
      this.editPopup.Close()
      this.RefreshDashboards();
    });
  }
  
  isMobileResolution(): boolean {
    if(window.innerWidth<=768){
      return true;
    } else {
      return false;
    }
  }
}

<div style="height: 18.676px;">
  <svg xmlns="http://www.w3.org/2000/svg" width="22.411" height="18.676" viewBox="0 0 22.411 18.676">
    <g id="inbox" transform="translate(0 -4)">
      <path id="Path_130" data-name="Path 130" d="M19.61,12h-2.8a1.868,1.868,0,0,0-1.868,1.868,1.868,1.868,0,0,1-1.868,1.868H9.338A1.868,1.868,0,0,1,7.47,13.868,1.868,1.868,0,0,0,5.6,12H2.8A2.8,2.8,0,0,0,0,14.8v3.735a4.675,4.675,0,0,0,4.669,4.669H17.742a4.675,4.675,0,0,0,4.669-4.669V14.8A2.8,2.8,0,0,0,19.61,12Zm.934,6.537a2.8,2.8,0,0,1-2.8,2.8H4.669a2.8,2.8,0,0,1-2.8-2.8V14.8a.934.934,0,0,1,.934-.934H5.6A3.735,3.735,0,0,0,9.338,17.6h3.735a3.735,3.735,0,0,0,3.735-3.735h2.8a.934.934,0,0,1,.934.934Z"
       transform="translate(0 -0.53)" stroke="none"/>
      <path id="Path_131" data-name="Path 131" d="M2.934,9.868H19.742a.934.934,0,0,0,0-1.868H2.934a.934.934,0,1,0,0,1.868Z" transform="translate(-0.132 -0.265)" stroke="none"/>
      <path id="Path_132" data-name="Path 132" d="M2.934,5.868H19.742a.934.934,0,0,0,0-1.868H2.934a.934.934,0,1,0,0,1.868Z" transform="translate(-0.132)" stroke="none"/>
    </g>
  </svg>
</div>

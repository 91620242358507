import { ElementRef, EventEmitter, ViewChild, Component, OnInit, HostListener } from '@angular/core';
import { NgForm } from '@angular/forms';
import { PopupComponent } from 'src/app/shared/components/popup/popup.component';
import { JWTProfileChange } from 'src/app/models/utility/JWTProfileChange.model';
import { User } from 'src/app/models/project/User.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RecaptchaErrorParameters } from "ng-recaptcha";

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit {
  
  maxHeight: number = -1;

  show = false;
  captcha: string = null;
  public event: EventEmitter<any> = new EventEmitter();
  @ViewChild('assignmentsPopup',{static:true}) assignmentsPopup:PopupComponent;
  @ViewChild('nameInput') nameInput:ElementRef<HTMLInputElement>;
  @ViewChild('reset') reset:ElementRef<HTMLInputElement>;
  user:User;
  authorityId:number;
  newName:string = "";
  newAuthority:number = -1;
  newActive:number = -1;
  resetSent:boolean = false;
  //TODO: Nincs API az assignmentek lekérésére
  assignments:{name:string, email:string, status:number}[] = [{name:"CMO 24", email:"string@alma.hu", status:1}, {name:"Alma", email:"string@alma.hu", status:1}, {name:"Alma", email:"string@alma.hu", status:1}, {name:"Alma", email:"string@alma.hu", status:0}, {name:"Alma", email:"string@alma.hu", status:0}, {name:"Alma", email:"string@alma.hu", status:0}, {name:"CMO 24", email:"string@alma.hu", status:0}];
  filteredAssignments:{name:string, email:string, status:number}[] = [];

  constructor(
    private authService:AuthService,
  ) { }

  ngOnInit(): void {
    this.filteredAssignments = [...this.assignments];
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if(this.isMobileResolution()) {
      this.maxHeight = window.innerHeight-180;
    }
  }

  SetFixed(): void {
    if(document.body.scrollHeight > document.body.clientHeight) {
      document.body.style.top = `-${window.scrollY}px`;
      (document.body.children[0].children[0] as HTMLElement).style.top = '0px';
      (document.body.children[0].children[1] as HTMLElement).style.top = '0px';
      document.body.style.position = 'fixed';
      document.body.style.overflowY = 'scroll';
    }
  }

  UnFix(): void {
    const scrollY = document.body.style.top;
    document.body.style.top = '';
    (document.body.children[0].children[0] as HTMLElement).style.top = '';
    (document.body.children[0].children[1] as HTMLElement).style.top = '';
    document.body.style.position = '';
    document.body.style.overflowY = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  }

  Close():void{
    this.show = false;
    this.UnFix();
  }

  OnInput(inputFiled:HTMLInputElement):void{
    this.filteredAssignments = this.assignments.filter(x=>x.name.toLowerCase().match(inputFiled.value.toLowerCase())!=null);
  }

  editUser(user:User, authority:number){
    this.user = user;
    this.authorityId = authority;
    this.resetSent = false;
    this.show = true;
    this.SetFixed();
  }

  OnAuthoritySelected(authority:string):void {
    //TODO: Még nem tudni, hogy lesznek az authority-k
  }

  OnActiveSelected(active:string):void {
    this.newActive = active=='Yes'?1:0;
  }

  OnResetClicked():void {
    this.authService.lostPassword({email:this.user.email}, () => {
      this.reset.nativeElement.setAttribute('disabled', 'true');
      this.resetSent = true;
    });
  }

  OnSaveClicked():void {
    this.newName = this.nameInput.nativeElement.value!=""?this.nameInput.nativeElement.value:this.user.name;
    let newData:JWTProfileChange = {
      id: this.user.id,
      name: this.user.name,
      address: this.user.address,
      phone: this.user.phone,
      url: this.user.url,
      status: this.user.status,
      removed: this.user.removed,
      regdate: this.user.regdate,
      lastlogin: this.user.lastlogin,
      recaptcha: this.captcha
    };
    if(this.newName!=this.user.name) {
      newData.name = this.newName;
    }
    if(this.newActive!=this.user.status) {
      newData.status = this.newActive;
    }
    //Authority kezelése
    if(this.newName!=this.user.name || this.newActive!=this.user.status) {
      this.authService.updateProfile(newData, (response) => {
        if(response.success == 1) {
          this.Close();
          this.event.emit();
        }
      });
    }
  }

  OnActiveClicked(id:number):void {
    this.assignments[id].status = this.assignments[id].status==1?0:1;
  }

  OnAssignmentSaveClicked():void {
    //TODO: send new statuses to server
  }

  isMobileResolution(): boolean {
    if(window.innerWidth<=768){
      return true;
    } else {
      return false;
    }
  }
  
  public resolved(captchaResponse: string): void {
    this.captcha = captchaResponse;
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  }
}
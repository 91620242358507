import { Injectable } from '@angular/core';
import { DataMatrix, DateScheduling, DropDownMenu, FileUploader, FormValue, InputField, LongTextLine, RadioButton, Repeater, Selector, ShortTextLine, TextEditor } from '../../../models/dashboard/InputField.model';
import { ProcessForm, ProcessStep } from '../../../models/utility/InvestmentProcess.model';
import { BaseService } from '../../utility/base.service';
import { JWTToken } from 'src/app/models/utility/JWTToken.model';
import { ApiResponse } from 'src/app/models/utility/ApiResponse.model';
import { Subject } from 'rxjs';
import { Language } from '../../../models/Language.model';
import { Step } from 'src/app/models/project/Startup.model';

@Injectable({
  providedIn: 'root'
})
export class StepsMakerService {

  steps: ProcessStep[] = [];
  subject = new Subject();
  rearrangeSubject = new Subject();
  rearrangeSectionSubject = new Subject();
  listToRearrange: { id: string, direction: number }[] = [];
  listToRearrangeSections: { id: string, direction: number }[] = [];

  constructor(
    protected baseService: BaseService,
  ) {
    this.rearrangeSubject.subscribe(() => {
      if (this.listToRearrange.length > 0) {
        let obj = this.listToRearrange.shift();
        this.baseService.packageQuery<JWTToken, object>('form', (obj.direction == -1) ? 'Form_editor/to_lower' : 'Form_editor/to_upper', { form_id: obj.id }, () => {
          this.rearrangeSubject.next();
        });
      }
    });
    this.rearrangeSectionSubject.subscribe(() => {
      if (this.listToRearrangeSections.length > 0) {
        let obj = this.listToRearrangeSections.shift();
        this.baseService.packageQuery<JWTToken, object>('form', (obj.direction == -1) ? 'Form_editor_groups/to_group_lower' : 'Form_editor_groups/to_group_upper', { group_id: obj.id }, () => {
          this.rearrangeSectionSubject.next();
        });
      }
    });
  }

  GetList(callBack: (value: ApiResponse<JWTToken>) => void) {
    return this.baseService.packageQuery<JWTToken, object>('form', 'Form_editor/list', {}, callBack);
  }

  GetGroupsInForm(id: number, callBack: (value: ApiResponse<JWTToken>) => void) {
    return this.baseService.packageQuery<JWTToken, object>('form', 'Form_editor_groups/get_groups_in_form', { form_id: id }, callBack);
  }

  GetRowsInGroup(id: number, callBack: (value: ApiResponse<JWTToken>) => void) {
    return this.baseService.packageQuery<JWTToken, object>('form', 'Form_editor_rows/rows_list_by_group', { group_id: id.toString() }, callBack);
  }

  private createInputFiled(row): InputField {
    let name: Language = new Language(row.name_hu, row.name_en);
    let small: Language = new Language(row.small_hu, row.small_en);
    let options0: FormValue[] = [];
    let options1: FormValue[] = [];
    for (let value of row.form_values) {
      if (value.type == 0) {
        options0.push(new FormValue(value.id, new Language(value.value_hu, value.value_en)));
      } else {
        options1.push(new FormValue(value.id, new Language(value.value_hu, value.value_en)));
      }
    }
    switch (row.typeComponent) {
      case 'ShortTextLine': return new ShortTextLine(row.id, row.order, name, small, row.required == 1);
      case 'LongTextLine': return new LongTextLine(row.id, row.order, name, small, row.required == 1);
      case 'DropDownMenu': return new DropDownMenu(row.id, row.order, name, small, row.required == 1, options0);
      case 'RadioButton': return new RadioButton(row.id, row.order, name, small, row.required == 1, options0);
      case 'Selector': return new Selector(row.id, row.order, name, small, row.required == 1, options0);
      case 'FileUploader': return new FileUploader(row.id, row.order, name, small, row.required == 1);
      case 'DataMatrix': return new DataMatrix(row.id, row.order, name, small, row.required == 1, options0, options1);
      case 'Repeater': return new Repeater(row.id, row.order, name, small, row.required == 1);
      case 'TextEditor': return new TextEditor(row.id, row.order, name, small, row.required == 1);
      case 'DateScheduling': return new DateScheduling(row.id, row.order, name, small, row.required == 1);
    }
  }

  GetSteps(): Promise<Step[]> {
    return new Promise((resolve, reject) => {
      this.GetList((response) => {
        let steps: Step[] = [];
        response.data.forms.forEach(form => {
          steps.push(new Step(form.id, new Language(form.name_hu, form.name_en)));
        });
        resolve(steps);
      });
    });
  }

  RefreshList() {
    console.log('RefreshList');
    
    this.GetList((response) => {
      console.log('Callback');
      
      this.steps = [];
      //TODO: jó az irány csak eltört funkció main branchen :'()
      // GetGroupsInForms(formIds )....
      //this.GetGroupsInForm(response.data.forms.map(f=> f.id), (res) => console.log(res));
      
       response.data.forms.forEach(form => {
         this.GetGroupsInForm(form.id, (res) => {
           let forms: ProcessForm[] = [];
           res.data.groups.forEach(group => {
             let rows: InputField[] = [];
             this.GetRowsInGroup(group.id, (res) => {
               res.data.rows.forEach(row => {
                 rows.push(this.createInputFiled(row));
               });
             });
             forms.push(new ProcessForm(group.id, group.order, new Language(group.name_hu, group.name_en), new Language(group.desc_hu.replace(/^\s+|\s+$/g, ''), group.desc_en), rows));
             forms.sort((a, b) => a.order - b.order);
           });
           this.steps.push(new ProcessStep(form.id, form.order, new Language(form.name_hu, form.name_en), new Language(form.text_hu.replace(/^\s+|\s+$/g, ''), form.text_en.replace(/^\s+|\s+$/g, '')), forms));
           this.steps.sort((a, b) => a.order - b.order);
           this.subject.next(this.steps);
         });
       });
    });
  }

  RearrangeList(prev: number, curr: number, id: number) {
    for (let i = 0; i < Math.abs(prev - curr); i++) {
      this.listToRearrange.push({ id: id.toString(), direction: (prev < curr) ? -1 : 1 });
    }
  }

  FixRearrangement() {
    this.rearrangeSubject.next();
  }

  AddForm(callBack: () => void) {
    this.baseService.packageQuery<JWTToken, object>('form', 'Form_editor/new_form', { name: 'Név...', default_add: "1", user_editable: "0", flow_in: "1" }, (response) => {
      this.baseService.packageQuery<JWTToken, object>('form', 'Form_editor/to_upper', { form_id: response.data.form_id }, () => { });
      this.steps.unshift(new ProcessStep(response.data.form_id, response.data.order, new Language(response.data.form.name_hu, response.data.form.name_en), new Language(response.data.form.text_hu.replace(/^\s+|\s+$/g, ''), response.data.form.text_en.replace(/^\s+|\s+$/g, '')), []));
      callBack();
    });
  }

  UpdateFormName(id: number, lang: string, name: Language, callBack: () => void) {
    this.baseService.packageQuery<JWTToken, object>('form', 'Form_editor/update_row', { id: id.toString(), name_hu: name.hu, name_en: name.en }, () => {
      callBack();
    });
  }

  DeleteForm(id: number) {
    this.baseService.packageQuery<JWTToken, object>('form', 'Form_editor/delete_row', { id: id.toString() }, () => {
      this.listToRearrange = this.listToRearrange.filter(obj => parseInt(obj.id) != id);
    });
  }

  RearrangeSections(prev: number, curr: number, id: number) {
    for (let i = 0; i < Math.abs(prev - curr); i++) {
      this.listToRearrangeSections.push({ id: id.toString(), direction: (prev < curr) ? -1 : 1 });
    }
  }

  FixSectionRearrangement() {
    this.rearrangeSectionSubject.next();
  }

  AddSection(id: number, callBack: () => void) {
    this.baseService.packageQuery<JWTToken, object>('form', 'Form_editor_groups/create_group', { form_id: id.toString(), name_hu: 'Új szekció' }, (response) => {
      this.steps.find(form => form.id == id).forms.push(new ProcessForm(response.data.group.id, response.data.group.order, new Language(response.data.group.name_hu, response.data.group.name_en), new Language(response.data.group.desc_hu.replace(/^\s+|\s+$/g, ''), response.data.group.desc_en.replace(/^\s+|\s+$/g, '')), []));
      callBack();
    });
  }

  DeleteSection(id: number, callBack: () => void) {
    this.baseService.packageQuery<JWTToken, object>('form', 'Form_editor_groups/delete_group', { group_id: id.toString() }, callBack);
  }

  UpdateFormContents(step: ProcessStep, callBack: () => void) {
    this.baseService.packageQuery<JWTToken, object>('form', 'Form_editor/update_row', { id: step.id.toString(), text_hu: step.descriptionParagraphs.hu, text_en: step.descriptionParagraphs.en }, () => {
      callBack();
      for (let form of step.forms) {
        this.baseService.packageQuery<JWTToken, object>('form', 'Form_editor_groups/update_group', { group_id: form.id.toString(), name_hu: form.formTitle.hu, name_en: form.formTitle.en, desc_hu: form.description.hu, desc_en: form.description.en }, () => { });
      }
    });
  }

  AddRow(id: number, input: InputField, callBack: (backInput: InputField) => void) {
    this.baseService.packageQuery<JWTToken, object>('form', 'Form_editor_rows/create_row', { group_id: id.toString(), name_hu: input.name.hu, name_en: input.name.en, small_hu: input.small.hu, small_en: input.small.en, type: input.type, order: input.order + 1 }, (response) => {
      input.id = response.data.row_id;
      callBack(input);
    });
  }

  DeleteRow(id: number) {
    this.baseService.packageQuery<JWTToken, object>('form', 'Form_editor_rows/delete_rows', { rows_id: id.toString() }, () => { });
  }

  UpdateRowName(id: number, input: InputField) {
    this.baseService.packageQuery<JWTToken, object>('form', 'Form_editor_rows/update_row', { rows_id: input.id.toString(), group_id: id.toString(), name_hu: input.name.hu, name_en: input.name.en, order: parseInt(input.order.toString()) + 1 }, () => { });
  }

  UpdateRowDescription(id: number, input: InputField) {
    this.baseService.packageQuery<JWTToken, object>('form', 'Form_editor_rows/update_row', { rows_id: input.id.toString(), group_id: id.toString(), small_hu: input.small.hu, small_en: input.small.en, order: parseInt(input.order.toString()) + 1 }, () => { });
  }

  UpdateRequired(id: number, input: InputField) {
    this.baseService.packageQuery<JWTToken, object>('form', 'Form_editor_rows/update_row', { rows_id: input.id.toString(), group_id: id.toString(), required: input.required, order: parseInt(input.order.toString()) + 1 }, () => { });
  }

  UpdateRowOrder(id: number, input: InputField) {
    this.baseService.packageQuery<JWTToken, object>('form', 'Form_editor_rows/update_row', { rows_id: input.id.toString(), group_id: id.toString(), order: parseInt(input.order.toString()) + 1 }, () => { });
  }

  UpdateGroupOfRow(selectedId: number, id: number, input: InputField, callBack: (backInput: InputField) => void) {
    this.baseService.packageQuery<JWTToken, object>('form', 'Form_editor_rows/update_row', { rows_id: input.id.toString(), group_id: this.steps.find(form => form.id == selectedId).forms[id].id.toString(), order: parseInt(input.order.toString()) + 1 }, () => {
      callBack(input);
    });
  }

  AddFormValue(id: number, value: FormValue, type: number, callBack: (backValue: FormValue) => void) {
    this.baseService.packageQuery<JWTToken, object>('form', 'Form_editor_values/new_vals', { rows_id: id.toString(), value_hu: value.value.hu, value_en: value.value.en, type: type.toString() }, (response) => {
      value.id = response.data.form_values_id;
      callBack(value);
    });
  }

  DeleteFormValue(id: number) {
    this.baseService.packageQuery<JWTToken, object>('form', 'Form_editor_values/delete_vals', { id: id.toString() }, () => { });
  }

  UpdateFormValue(value: FormValue) {
    this.baseService.packageQuery<JWTToken, object>('form', 'Form_editor_values/update_vals', { id: value.id.toString(), value_hu: value.value.hu, value_en: value.value.en }, () => { });
  }
}
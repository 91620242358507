<div style="height: 25px;">
  <svg xmlns="http://www.w3.org/2000/svg" width="63" height="25" viewBox="0 0 63 25">
    <g id="external" transform="translate(-632 -283)">
      <rect id="Rectangle_89" data-name="Rectangle 89" width="63" height="25" rx="8" transform="translate(632 283)" fill="#ce375c"/>
      <g id="Icon_feather-eye" data-name="Icon feather-eye" transform="translate(641.974 290)">
        <path id="Path_116" data-name="Path 116" d="M1.5,11.369S4.184,6,8.882,6s7.382,5.369,7.382,5.369-2.684,5.369-7.382,5.369S1.5,11.369,1.5,11.369Z" transform="translate(-1.5 -6)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
        <path id="Path_117" data-name="Path 117" d="M17.526,15.513A2.013,2.013,0,1,1,15.513,13.5,2.013,2.013,0,0,1,17.526,15.513Z" transform="translate(-8.132 -10.145)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      </g>
      <path id="Icon_awesome-edit" data-name="Icon awesome-edit" d="M11.7,2.421l2.62,2.62a.284.284,0,0,1,0,.4L7.972,11.788l-2.7.3a.565.565,0,0,1-.625-.625l.3-2.7L11.3,2.421A.284.284,0,0,1,11.7,2.421ZM16.4,1.756,14.985.338a1.136,1.136,0,0,0-1.6,0L12.353,1.367a.284.284,0,0,0,0,.4l2.62,2.62a.284.284,0,0,0,.4,0L16.4,3.36A1.136,1.136,0,0,0,16.4,1.756Zm-5.247,8.306v2.957h-9.3v-9.3H8.535a.357.357,0,0,0,.247-.1L9.944,2.459a.349.349,0,0,0-.247-.6h-8.3A1.4,1.4,0,0,0,0,3.258V13.484a1.4,1.4,0,0,0,1.394,1.394H11.621a1.4,1.4,0,0,0,1.394-1.394V8.9a.349.349,0,0,0-.6-.247L11.257,9.815A.357.357,0,0,0,11.156,10.062Z" transform="translate(668.293 287.993)" fill="#fff"/>
    </g>
  </svg>
</div>

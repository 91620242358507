import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-tick',
  templateUrl: './svg-tick.component.html',
  styleUrls: ['./svg-tick.component.scss']
})
export class SvgTickComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import {Injectable} from '@angular/core';
import {ApiResponse} from '../../models/utility/ApiResponse.model';
import {ResultList} from '../../models/utility/ResultList.model';
import {ApiResponseItem} from '../../models/utility/ApiResponseItem.model';
import {KnowledgeBaseItem} from '../../models/knowledgebase/KnowledgeBaseItem.model';
import {BaseService} from '../utility/base.service';

@Injectable({
    providedIn: 'root'
})
export class KnowledgeBaseService {

    constructor(
        protected baseService: BaseService,
    ) { }

    getItemList(callBack: (value: ApiResponse<ResultList<KnowledgeBaseItem>>) => void) {
        return this.baseService.packageQuery<ResultList<KnowledgeBaseItem>, object>('sup','knowledgebase/getItemList', {}, callBack);
    }

    getItem(id, callBack: (value: ApiResponse<ApiResponseItem<KnowledgeBaseItem>>) => void) {
        return this.baseService.packageQuery<ApiResponseItem<KnowledgeBaseItem>, object>('sup','knowledgebase/getItem', {kb_id: id}, callBack);
    }

    deleteItem(id: Number, callBack: (value: ApiResponse<ApiResponseItem<any>>) => void) {
        return this.baseService.packageQuery<ApiResponseItem<any>, object>('sup','knowledgebase/deleteItem', {kb_id: id}, callBack);
    }

    addItem(data, callBack: (value: ApiResponse<ApiResponseItem<any>>) => void) {
        return this.baseService.packageQuery<ApiResponseItem<any>, object>('sup','knowledgebase/addItem', data, callBack);
    }

    updateItem(data, callBack: (value: ApiResponse<ApiResponseItem<any>>) => void) {
        return this.baseService.packageQuery<ApiResponseItem<any>, object>('sup','knowledgebase/updateItem', data, callBack);
    }


    getCategoryList(callBack: (value: ApiResponse<ResultList<KnowledgeBaseItem>>) => void) {
        return this.baseService.packageQuery<ResultList<KnowledgeBaseItem>, object>('sup','knowledgebase/getCategoryList', {}, callBack);
    }

    getCategory(id, callBack: (value: ApiResponse<ApiResponseItem<KnowledgeBaseItem>>) => void) {
        return this.baseService.packageQuery<ApiResponseItem<KnowledgeBaseItem>, object>('sup','knowledgebase/getCategory', {kbc_id: id}, callBack);
    }

    deleteCategory(id: Number, callBack: (value: ApiResponse<ApiResponseItem<any>>) => void) {
        return this.baseService.packageQuery<ApiResponseItem<any>, object>('sup','knowledgebase/deleteCategory', {kbc_id: id}, callBack);
    }

    addCategory(data, callBack: (value: ApiResponse<ApiResponseItem<any>>) => void) {
        return this.baseService.packageQuery<ApiResponseItem<any>, object>('sup','knowledgebase/addCategory', data, callBack);
    }

    updateCategory(data, callBack: (value: ApiResponse<ApiResponseItem<any>>) => void) {
        return this.baseService.packageQuery<ApiResponseItem<any>, object>('sup','knowledgebase/updateCategory', data, callBack);
    }
}

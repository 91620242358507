<button
    [disabled]="disabled" 
    (click)="onClick.emit($event)"
    class="btn"
    [ngClass]="
    {
        'primary': type == ButtonType.Primary,
        'primary-red': type == ButtonType.PrimaryRed,
        'secondary': type == ButtonType.Secondary,
        'secondary-red': type == ButtonType.SecondaryRed,
        'secondary-light': type == ButtonType.SecondaryLight,
        'secondary-gray': type == ButtonType.SecondaryGray
    }">
    {{text}}
</button>
<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 25.4.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Réteg_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#CE375C;}
</style>
<path id="Path_951" class="st0" d="M19,12c-0.6,0-1,0.4-1,1v4c0,0.6-0.4,1-1,1H3c-0.6,0-1-0.4-1-1v-4c0-0.6-0.4-1-1-1s-1,0.4-1,1v4
	c0,1.7,1.3,3,3,3h14c1.7,0,3-1.3,3-3v-4C20,12.4,19.6,12,19,12z M10.7,0.3c-0.1-0.1-0.2-0.2-0.3-0.2C10.1,0,9.9,0,9.6,0.1
	c-0.1,0-0.2,0.1-0.3,0.2l-4,4c-0.4,0.4-0.4,1,0,1.4s1,0.4,1.4,0L9,3.4V13c0,0.6,0.4,1,1,1s1-0.4,1-1V3.4l2.3,2.3
	c0.4,0.4,1,0.4,1.4,0s0.4-1,0-1.4L10.7,0.3z"/>
</svg>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Tag } from 'src/app/models/portfolio-management/TimelineAction.model';

@Component({
  selector: 'app-hover-tag',
  templateUrl: './hover-tag.component.html',
  styleUrls: ['./hover-tag.component.css']
})
export class HoverTagComponent implements OnInit {


  constructor() { }

  @Input() tag:Tag;
  @Input() overlayType:'grid'|'text' = 'grid';

  isHover:boolean = false;

  ngOnInit(): void {
  }

  OnMouseEnter():void{
    this.isHover = true;
  }

  OnMouseLeave():void{
    this.isHover = false;
  }
}

<div class="header">
  <input *ngIf="isLabelEdit" class="label-input" type="text" [placeholder]="labelCopy" [(ngModel)]="textLine.name[currentLang]" (keydown.enter)="OnLabelEnter()">
  <div class="label" *ngIf="!isLabelEdit && textLine!=undefined" (dblclick)="OnLabelDoubleClick();">{{textLine.GetName(currentLang)}}<span>{{textLine.required?'*':''}}</span></div>
  <div class="important-container" *ngIf="editmode">
    <input type="checkbox" id="important" name="important" [(ngModel)]="textLine.required" (change)="OnRequiredClicked()">
    <label class="importantLabel disableSelect">Important</label>
  </div>
</div>
<div class="description">
  <input *ngIf="isDescriptionEdit" class="label-input" type="text" [placeholder]="descriptionCopy" [(ngModel)]="textLine.small[currentLang]" (keydown.enter)="OnDescriptionEnter()">
  <div class="desc" *ngIf="!isDescriptionEdit && textLine!=undefined" (dblclick)="OnDescriptionDoubleClick();">{{editmode?(textLine.GetDescription(currentLang)==""?('steps.input.doubleClick' | translate):textLine.GetDescription(currentLang)):textLine.GetDescription(currentLang)}}</div>
</div>
<div style="display: flex; justify-content: space-between; align-items: center;">
  <input class="text-input" type="text" [(ngModel)]="textLine.value" [disabled]="readonly">
</div>
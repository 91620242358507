<div class="nav-content">
  <div class="background" [ngStyle]="{'height.px': vh}" [ngClass]="{show:!hide}" *ngIf="isMobileResolution()"></div>
  <div *ngIf="authService.currentUserValue.main_permission == 1" class="nav-links" [ngStyle]="{'height.px': isMobileResolution()?vh-71:'100%'}" [ngClass]="{show:!hide}">
    <app-nav-separator [text]="'sideNav.modules' | translate" [hide]="hide"></app-nav-separator>

    <app-nav-item [selectedSlug]="'statistics'" [text]="'sideNav.statistics' | translate" [hide]="hide" (clicked)="OnNavigate('statistics')" (hideChanged)="hideChanged($event)" [iconString]="'statistics'">
    </app-nav-item>
    <app-nav-item [selectedSlug]="'database'" [text]="'sideNav.database' | translate" [hide]="hide" (clicked)="OnNavigate('database')" (hideChanged)="hideChanged($event)" [iconString]="'database'">
    </app-nav-item>
    <app-nav-item [selectedSlug]="'dealflow'" [text]="'sideNav.dealFlow' | translate" [hide]="hide" (clicked)="OnNavigate('dealflow')" (hideChanged)="hideChanged($event)" [iconString]="'dealflow'">
    </app-nav-item>
    <app-nav-item [selectedSlug]="'portfolio'" [text]="'sideNav.portMan' | translate" [hide]="hide" (clicked)="OnNavigate('portfolio')" (hideChanged)="hideChanged($event)" [iconString]="'pfmgmt'">
    </app-nav-item>

    <app-nav-separator [text]="'sideNav.assets' | translate" [hide]="hide"></app-nav-separator>

    <app-nav-item [selectedSlug]="'network'" [text]="'sideNav.network' | translate" [hide]="hide" [iconString]="'network'" (hideChanged)="hideChanged($event)"></app-nav-item>

    <app-nav-separator [text]="'sideNav.sets' | translate" [hide]="hide" ></app-nav-separator>

    <app-nav-item [selectedSlug]="'general/industries'" [text]="'sideNav.general' | translate" [hide]="hide" [iconString]="'general'" (clicked)="OnNavigate('general/industries')" (hideChanged)="hideChanged($event)"></app-nav-item>
    <app-nav-item [selectedSlug]="'templates'" [text]="'sideNav.templates' | translate" [hide]="hide" [iconString]="'templates'" (clicked)="OnNavigate('templates')" (hideChanged)="hideChanged($event)"></app-nav-item>
    <app-nav-item [selectedSlug]="'stepsMaker'" [text]="'sideNav.steps' | translate" [hide]="hide" [iconString]="'steps'" (clicked)="OnNavigate('stepsMaker')" (hideChanged)="hideChanged($event)"></app-nav-item>
    <app-nav-item [selectedSlug]="'users'" [text]="'sideNav.users' | translate" [hide]="hide" (clicked)="OnNavigate('users')" (hideChanged)="hideChanged($event)" [iconString]="'users'"></app-nav-item>
    <app-nav-item [selectedSlug]="'history'" class="last" [text]="'sideNav.history' | translate" [hide]="hide" (clicked)="OnNavigate('history')" (hideChanged)="hideChanged($event)" [iconString]="'history'"></app-nav-item>
  </div>

  <div *ngIf="authService.currentUserValue.main_permission == 2" class="nav-links" [ngStyle]="{'height.px': isMobileResolution()?vh-71:'100%'}" [ngClass]="{show:!hide}">
    <app-nav-separator [text]="'sideNav.projects' | translate" [hide]="hide"></app-nav-separator>

    <app-nav-item *ngFor="let project of projects.slice(0, 5)" [text]="project.project_name" [hide]="hide" (clicked)="OnNavigate((project.project_status == 1 ? 'portfolio/' : 'startup/') + project.project_id +'/notes')" (hideChanged)="hideChanged($event)" [iconString]="'dealflow'">
    </app-nav-item>
    <app-nav-item *ngIf="projects.length > 5" [text]="'sideNav.projects' | translate" [hide]="hide" (clicked)="OnNavigate('projects')" (hideChanged)="hideChanged($event)" [iconString]="'dealflow'"></app-nav-item>

    <app-nav-separator [text]="'sideNav.sets' | translate" [hide]="hide" ></app-nav-separator>

    <app-nav-item [text]="'sideNav.notifications' | translate" [hide]="hide" (clicked)="OnNavigate('notifications')" (hideChanged)="hideChanged($event)" [iconString]="'notifications'"></app-nav-item>
    <app-nav-item class="last" [text]="'sideNav.history' | translate" [hide]="hide" (clicked)="OnNavigate('history')" (hideChanged)="hideChanged($event)" [iconString]="'history'"></app-nav-item>
  </div>
</div>

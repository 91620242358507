<div class="header">
  <input *ngIf="isLabelEdit" class="label-input" type="text" [placeholder]="labelCopy" [(ngModel)]="textEditor.name[currentLang]" (keydown.enter)="OnLabelEnter()">
  <div class="label" *ngIf="!isLabelEdit && textEditor!=undefined" (dblclick)="OnLabelDoubleClick();">{{textEditor.GetName(currentLang)}}<span>{{textEditor.required?'*':''}}</span></div>
  <div class="important-container" *ngIf="editmode">
    <input type="checkbox" id="important" name="important" [(ngModel)]="textEditor.required" (change)="OnRequiredClicked()">
    <label class="importantLabel disableSelect">Important</label>
  </div>
</div>
<div class="description">
  <input *ngIf="isDescriptionEdit" class="label-input" type="text" [placeholder]="descriptionCopy" [(ngModel)]="textEditor.small[currentLang]" (keydown.enter)="OnDescriptionEnter()">
  <div class="desc" *ngIf="!isDescriptionEdit && textEditor!=undefined" (dblclick)="OnDescriptionDoubleClick();">{{editmode?(textEditor.GetDescription(currentLang)==""?('steps.input.doubleClick' | translate):textEditor.GetDescription(currentLang)):textEditor.GetDescription(currentLang)}}</div>
</div>
<div class="editor-wrapper">
  <ngx-editor-menu [editor]="editor"></ngx-editor-menu>
  <ngx-editor [editor]="editor" [(ngModel)]="textEditor.value" [disabled]="readonly" [placeholder]="'Type here...'"></ngx-editor>
</div>

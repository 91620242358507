
<div style="display: inline-block; position: relative;" (mouseenter)="MouseEnter()" (mouseleave)="MouseLeave()">
    <svg-icon class="edit" src="/assets/startupDataboard/edit.svg" [ngClass]="{ishov: isHover}"
    [svgStyle]="{'width.%':100, 'height.%':100}" (click)="OnEditClicked()"></svg-icon>
    <svg-icon class="delete" src="/assets/startupDataboard/delete.svg" [ngClass]="{ishov: isHover}"
      [svgStyle]="{'width.%':100, 'height.%':100}" (click)="OnDeleteClicked()"></svg-icon>

    <div class="file-card disableSelect"
    style="display: grid; grid-template-columns: 40px auto auto;" (click)="OnFileClicked()">
      <app-svg-file *ngIf="!file.loading" [ngStyle]="{stroke: fileColors[fileColorsIndex%fileColors.length]}"
      (click)="OnIconClicked()"
      style="grid-row: 1 / 3; grid-column: 1;"></app-svg-file>
      <div *ngIf="file.loading" class="loader-wrapper">
        <div class="loader" id="loader" name="loader"></div>
      </div>
      <div style="font-weight: bold; font-size: 12px; grid-row:1; grid-column: 2; align-self: center;">{{file.fileName}}</div>
      <div style="font-size: 10px; grid-row:2; grid-column: 2;">{{file.versions[0].fileName}}</div>
      <div style="font-size: 10px; grid-row:2; grid-column: 3; justify-self: flex-end;">{{file.versions[0].version}}</div>
    </div>
</div>

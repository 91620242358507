<div style="height: 26px;">
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
    <g id="todo" transform="translate(-1681 -577)">
      <circle id="Ellipse_172" data-name="Ellipse 172" cx="13" cy="13" r="13" transform="translate(1681 577)" fill="#fdba21"/>
      <g id="checklist" transform="translate(1686.986 584.899)">
        <path id="Path_860" data-name="Path 860" d="M4.3,57.722H.621a.613.613,0,0,0-.613.613v3.678a.613.613,0,0,0,.613.613H4.3a.613.613,0,0,0,.613-.613V58.335A.613.613,0,0,0,4.3,57.722ZM3.686,61.4H1.234V58.948H3.686Z" transform="translate(-0.008 -57.722)" fill="#fff"/>
        <path id="Path_861" data-name="Path 861" d="M237.622,126.983h-6.129a.613.613,0,0,0,0,1.226h6.129a.613.613,0,0,0,0-1.226Z" transform="translate(-224.751 -125.144)" fill="#fff"/>
        <path id="Path_862" data-name="Path 862" d="M237.622,357.855h-6.129a.613.613,0,0,0,0,1.226h6.129a.613.613,0,0,0,0-1.226Z" transform="translate(-224.751 -349.887)" fill="#fff"/>
        <path id="Path_863" data-name="Path 863" d="M3.861,310.8l-2.04,2.016-.767-.8a.613.613,0,1,0-.881.853l1.2,1.245a.613.613,0,0,0,.433.184h.007a.613.613,0,0,0,.429-.177l2.48-2.452a.613.613,0,1,0-.858-.872Z" transform="translate(0 -303.883)" fill="#fff"/>
      </g>
    </g>
  </svg>
</div>

<div class="task-container disableSelect" (mouseenter)="ShowTick()" (mouseleave)="HideTick()" [@openClose]="tick ? 'closed' : 'open'">
  <app-ticker id="tick" [(tick)]="tick" (tickChange)="OnDonerClicked()" [ngClass]="{'move-right-tick':show}"></app-ticker>

  <div class="card-wrapper" (click)="OpenTask($event)" (touchstart)="TouchStart($event)" (touchend)="TouchEnd()">
    <div class="header">
      <div *ngIf="startup" class="left-side">
        <img class="startup-img" [src]="startup.project_logo ?? '/assets/test-brand.png'" alt="startup logo">
        <span class="startup-name">{{startup.project_name}}</span>
      </div>
      <div class="right-side">
        <div class="flag">
          <svg-icon src="/assets/startupDataboard/tasks/flag.svg" (click)="OnFlagClicked($event)" [ngStyle]="{stroke: flagColors[task.priority]}" [svgStyle]="{'width.%':100, 'height.%':100}"></svg-icon>
        </div>
        <div class="date">{{dateString}}</div>
      </div>
    </div>
    <div class="tasktext">
      <span>{{task.label.substring(0, 100)}}</span>
    </div>
  </div>
</div>
<div class="shadow-div"></div>
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterLinkActive } from '@angular/router';
import { EmailTemplate } from 'src/app/models/utility/EmailTemplate.model';
import { DashboardService } from 'src/app/services/support/dashboard.service';
import { EmailService } from 'src/app/services/support/email.service';
import { IndustryService } from 'src/app/services/support/industry.service';
import { TagService } from 'src/app/services/project/tag.service';
import { PopupComponent } from '../../../shared/components/popup/popup.component';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.css', 'common-general-styles.css'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class GeneralComponent implements OnInit {
  
  emailGroups:string[] = [];
  
  constructor(
    public industryService:IndustryService,
    private dashboardService:DashboardService,
    private emailService:EmailService,
    private tagService:TagService,
    private router:Router,
  ) { }

  @ViewChild('rld') routerLinkDashBoard:RouterLinkActive;
  @ViewChild('rli') routerLinkIndustries:RouterLinkActive;
  @ViewChild('rldeg') routerLinkEmailGroups:RouterLinkActive;
  @ViewChild('rladt') routerLinkDealFlowTags:RouterLinkActive;
  @ViewChild('rlp') routerLinkPortfolioTags:RouterLinkActive;
  @ViewChild('rlc') routerLinkPortfolioTCategoriesTags:RouterLinkActive;

  @ViewChild('updatePopup',{static:true}) updatePopup:PopupComponent;
  @ViewChild('emailPopup',{static:true}) emailPopup:PopupComponent;
  @ViewChild('tagAddPopup',{static:true}) tagAddpopup:PopupComponent;
  ngOnInit(): void {
    this.emailGroups = [];
    this.emailService.GetEmailGroupList((response) => {
      response.data.list.forEach(group => {
        this.emailGroups.push(group.email_group_name);
      });
    });
  }

  RefreshEmails(id:number): void {
    this.emailService.RefreshGroup(id);
  }

  OnAddButtonClicked():void{
    if(this.routerLinkDealFlowTags.isActive ||
      this.routerLinkPortfolioTags.isActive ||
      this.routerLinkPortfolioTCategoriesTags.isActive){
      this.tagAddpopup.Open();
    } else if(this.routerLinkDashBoard.isActive ||
      this.routerLinkIndustries.isActive ||
      this.routerLinkEmailGroups.isActive){
      this.updatePopup.Open();
    } else {
      this.emailPopup.Open();
    }
  }

  OnEmailSaveClicked(emailTemplate:EmailTemplate):void{
    this.emailService.AddEmail(emailTemplate);
  }

  @ViewChild('updateInput',{static:true}) input:ElementRef;
  OnUpdateSaveClicked():void{
    let inputValue = (this.input.nativeElement as HTMLInputElement).value;
    if(inputValue!==''){
      if(this.routerLinkIndustries.isActive){
        this.industryService.AddIndustry((this.input.nativeElement as HTMLInputElement).value);
      }else if(this.routerLinkDashBoard.isActive){
        this.dashboardService.AddDashboard((this.input.nativeElement as HTMLInputElement).value);
      }else if(this.routerLinkEmailGroups.isActive){
        this.emailService.AddEmailGroup((this.input.nativeElement as HTMLInputElement).value);
      }else{

      }
    }
  }

  OnTagAddClicked(text:string, color:string):void{
    if(text!=''){
      if(this.router.url.includes('/portfolioManagement')){
        this.tagService.AddTagPortfolio({text:text, color:color});
      }else if(this.router.url.includes('/dealflowTags')){
        this.tagService.AddTagDealFlow({text:text, color:color});
      }else{
        this.tagService.AddTimelineTag({text:text, color:color});
      }
      this.tagAddpopup.Close()
    }
  }

}

<div style="height: 6.25px; margin: 0 auto;" [ngStyle]="{'width.px':width}">
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="9.932" viewBox="0 0 19.389 9.932"
    style="vertical-align:top" >
      <path id="fi-rr-angle-small-down"
      d="M23.983,8.32a1.385,1.385,0,0,0-1.958,0l-6.351,6.351a1.414,1.414,0,0,1-1.958,0L7.364,8.32a1.385,1.385,0,1,0-1.958,1.958l6.35,6.351a4.155,4.155,0,0,0,5.876,0l6.351-6.351a1.385,1.385,0,0,0,0-1.958Z"
      transform="translate(-5 -7.914)"
      />
    </svg>
</div>



import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-menu',
  templateUrl: './svg-menu.component.html'
})
export class SvgMenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
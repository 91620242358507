<div class="wrapper disableSelect">
  <div *ngIf="isDefaultBase" class="input-field" [ngStyle]="{color: (color!=='white' && color!=='#ffffff' && color!=='#FFFFFF')?color:'var(--dark)'}" (click)="isOpen=!isOpen">{{text}}</div>

  <ng-content *ngIf="!isDefaultBase" ></ng-content>

  <div class="popup" [ngClass]="{'popup-open': isOpen}" [ngStyle]="{'left.px': left, 'top.px':top}">
    <app-color-slider [selectedwidth]="selectedwidth"  (color)="OnColorChange($event)"></app-color-slider>
    <div style="display: grid; grid-template-columns: auto 40px; width: 200px; align-items: center;">
      <div>{{color}}</div>
      <div style="width: 40px; height: 20px; border-radius: 100px;" [ngStyle]="{'background-color': color}"></div>
    </div>
    <div class="invisible" (click)="OnInvClicked()"></div>
  </div>
</div>

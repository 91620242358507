import { Injectable } from '@angular/core';
import { Tag, TagResponse } from '../../models/portfolio-management/TimelineAction.model';
import { Router } from '@angular/router';
import { BaseService } from '../utility/base.service';
import { JWTToken } from '../../models/utility/JWTToken.model';
import { ApiResponse } from '../../models/utility/ApiResponse.model';
import { ApiResponseItem } from '../../models/utility/ApiResponseItem.model';

@Injectable({
  providedIn: 'root'
})
export class TagService {
  LEGAL: Tag = { text: 'LEGAL', color: '#29487E' }
  ECONOMY: Tag = { text: 'ECONOMY', color: '#7AAF10' }
  TECH: Tag = { text: 'TECH', color: '#ce375c' }

  //TODO: API szükséges a tag-ek lekérésére
  dealFlowTags:Tag[] = [];

  portfolioManagementTags: Tag[] = [
    { text: 'VALAMI', color: '#00FF11' },
    { text: 'MÁSVALAMI', color: '#34EFA2' },
    { text: 'HARMADIK VALAMI', color: '#A54D67' },
    { text: 'NEGYEDIK IZÉ', color: '#FFDA21' },
    { text: 'ÖTÖDIK', color: '#05AA21' },
    { text: 'HAT CUCMÓK', color: '#65434D' },
    { text: 'HETES', color: '#FE456A' },
    { text: 'ALJAS NYOLCAS', color: '#54AD62' },
  ];

  timelineCategories: Tag[] = [];
  timelineCategoryIds: number[] = [];
  constructor(
    protected baseService: BaseService,
    private router: Router,
  ) {
    this.GetTimelineCategories(() => { })
    this.getProjectTagArray()
  }

  GetTimelineCategories(callBack: (value: ApiResponse<JWTToken>) => void) {
    return this.baseService.packageQuery<JWTToken, object>('project', 'Actions_categories/getCategoryList', {}, (response) => {
      this.timelineCategories = [];
      this.timelineCategoryIds = [];
      response.data.list.forEach(tag => {
        this.timelineCategories.push({ text: tag.category, color: tag.category_color });
        this.timelineCategoryIds.push(tag.category_id);
      });
      callBack(response);
    });
  }

  IncludesTag(list: Tag[], tag: Tag): boolean {
    let result: number = list.findIndex(x => x.text === tag.text);
    return result !== -1;
  }

  RemoveFromDfTags(idx: number): void {
    this.dealFlowTags.splice(idx, 1);
  }

  RemoveFromTimelineTags(idx: number, callBack: () => void): void {
    this.baseService.packageQuery<JWTToken, object>('project', 'Actions_categories/deleteCategory', { category_id: this.timelineCategoryIds[idx] }, () => {
      callBack();
    });
  }

  RemoveFromPmTags(idx: number): void {
    this.portfolioManagementTags.splice(idx, 1);
  }

  AddTagDealFlow(tag: Tag): void {
    this.dealFlowTags.push(tag);
  }

  AddTagPortfolio(tag: Tag): void {
    this.portfolioManagementTags.push(tag);
  }

  AddTimelineTag(tag: Tag) {
    return this.baseService.packageQuery<JWTToken, object>('project', 'Actions_categories/addCategory', { category_id: this.timelineCategories.length, category: tag.text, category_color: tag.color }, () => {
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });
    });
  }

  getTagList(callBack: (value: ApiResponse<ApiResponseItem<TagResponse[]>>) => void) {
    return this.baseService.packageQuery<ApiResponseItem<TagResponse[]>, object>('project', 'tags/getTagList', {}, callBack);
  }

  getProjectTagList(project_id: number, callBack: (value: ApiResponse<ApiResponseItem<TagResponse[]>>) => void) {
    return this.baseService.packageQuery<ApiResponseItem<TagResponse[]>, object>('project', 'tags/getTagsFromProject', { tag_join_project: project_id }, callBack);
  }

  getProjectTagArray() {
    var tags: Array<Tag> = [];
    this.getTagList(res => {
      res.data.item.forEach(element => {
        var actual = new Tag(element.tag_id, element.tag_name, element.tag_color);
        if (element.tag_status == false) {
          this.dealFlowTags.push(actual);
        }
        else {
          this.portfolioManagementTags.push(actual)
        }
      })
    })
    return tags;
  }

  addTagToProject(project_id: number, tag_id: number) {
    return this.baseService.packageQuery<ApiResponseItem<TagResponse[]>, object>('project', 'tags/joinTagToProject', {
      tag_join_project: project_id,
      tag_join_tag: tag_id
    }, () => {});
  }

  deleteTagFromProject(project_id: number, tag_id: number) {
    return this.baseService.packageQuery<ApiResponseItem<TagResponse[]>, object>('project', 'tags/unJoinTagFromProject', {
      tag_join_project: project_id,
      tag_join_tag: tag_id
    }, () => {});
  }

  updateTagColor(id: number, color: string) {
    return this.baseService.packageQuery<ApiResponseItem<TagResponse[]>, object>('project', 'tags/updateTag', {
      tag_id: id,
      tag_color: color
    }, () => {});
  }

  updateTagTextColor(id: number, color: string) {
    return this.baseService.packageQuery<ApiResponseItem<TagResponse[]>, object>('project', 'tags/updateTag', {
      tag_id: id,
      tag_textcolor: color
    }, () => {});
  }
}

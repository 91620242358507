<div style="height: 23.311px">
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.0557 0H3.05566C2.26028 0.000868196 1.49772 0.317218 0.935302 0.879639C0.372881 1.44206 0.0565323 2.20462 0.0556641 3V17C0.0565323 17.7954 0.372881 18.5579 0.935302 19.1204C1.49772 19.6828 2.26028 19.9991 3.05566 20H17.0557C17.851 19.9991 18.6136 19.6828 19.176 19.1204C19.7384 18.5579 20.0548 17.7954 20.0557 17V3C20.0548 2.20462 19.7384 1.44206 19.176 0.879639C18.6136 0.317218 17.851 0.000868196 17.0557 0ZM3.05566 2H17.0557C17.3208 2.00026 17.575 2.10571 17.7625 2.29319C17.95 2.48066 18.0554 2.73486 18.0557 3V11H15.5908C15.2615 10.9997 14.9373 11.0809 14.6469 11.2363C14.3566 11.3917 14.1092 11.6165 13.9268 11.8906L12.5205 14H7.59082L6.18457 11.8906C6.00214 11.6165 5.75474 11.3917 5.46441 11.2363C5.17407 11.0809 4.84982 10.9997 4.52051 11H2.05566V3C2.05593 2.73486 2.16137 2.48066 2.34885 2.29319C2.53633 2.10571 2.79053 2.00026 3.05566 2ZM17.0557 18H3.05566C2.79053 17.9997 2.53633 17.8943 2.34885 17.7068C2.16137 17.5193 2.05593 17.2651 2.05566 17V13H4.52051L5.92676 15.1094C6.1092 15.3835 6.35659 15.6083 6.64693 15.7637C6.93726 15.9191 7.26152 16.0003 7.59082 16H12.5205C12.8498 16.0003 13.1741 15.9191 13.4644 15.7637C13.7547 15.6083 14.0021 15.3835 14.1846 15.1094L15.5908 13H18.0557V17C18.0554 17.2651 17.95 17.5193 17.7625 17.7068C17.575 17.8943 17.3208 17.9997 17.0557 18Z"
    />
  </svg>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-ticker',
  templateUrl: './ticker.component.html',
  styleUrls: ['./ticker.component.css']
})
export class TickerComponent implements OnInit {

  @Input() tick: boolean = false;
  @Input() zIndex: number = 1;
  @Output() tickChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit(): void {
  }

  OnDonerClicked(): void {
    this.tick = !this.tick;
    this.tickChange.emit(this.tick);
  }
}
import { Component, Input, OnInit, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { LongTextLine } from 'src/app/models/dashboard/InputField.model';

@Component({
  selector: 'app-long-text-line',
  templateUrl: './long-text-line.component.html',
  styleUrls: ['./long-text-line.component.css']
})
export class LongTextLineComponent implements OnInit {

  constructor() { }

  @Input() textLine:LongTextLine;
  @Input() currentLang:string;
  @Input() editmode:boolean = false;
  @Input() readonly:boolean = false;
  @Output() nameChanged:EventEmitter<string> = new EventEmitter<string>();
  @Output() descriptionChanged:EventEmitter<string> = new EventEmitter<string>();
  @Output() requiredChanged:EventEmitter<boolean> = new EventEmitter<boolean>();
  isLabelEdit:boolean = false;
  isDescriptionEdit:boolean = false;
  
  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.editmode && changes.editmode.currentValue == false) {
      this.isLabelEdit = false;
      this.isDescriptionEdit = false;
    }
  }

  labelCopy: string;
  OnLabelDoubleClick():void{
    if(this.editmode) {
      this.isLabelEdit = true;
      this.labelCopy = this.textLine.name[this.currentLang];
    }
  }

  OnLabelEnter():void{
    if(this.textLine.name[this.currentLang] != "") {
      this.nameChanged.emit();
    } else {
      this.textLine.name[this.currentLang] = this.labelCopy;
    }
    this.isLabelEdit = false;
  }

  descriptionCopy: string;
  OnDescriptionDoubleClick():void{
    if(this.editmode) {
      this.isDescriptionEdit = true;
      this.descriptionCopy = this.textLine.small[this.currentLang];
    }
  }

  OnDescriptionEnter():void{
    this.descriptionChanged.emit();
    this.isDescriptionEdit = false;
  }

  OnRequiredClicked():void{
    this.requiredChanged.emit();
  }
}

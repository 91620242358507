import { Component, Input, OnInit, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { MatDatetimePickerInputEvent } from '@angular-material-components/datetime-picker';
import { DateScheduling } from 'src/app/models/dashboard/InputField.model';

@Component({
  selector: 'app-date-scheduling',
  templateUrl: './date-scheduling.component.html',
  styleUrls: ['./date-scheduling.component.css']
})
export class DateSchedulingComponent implements OnInit {

  constructor() { }

  @Input() dateScheduler:DateScheduling;
  @Input() currentLang:string;
  @Input() editmode:boolean = false;
  @Input() readonly:boolean = false;
  @Output() nameChanged:EventEmitter<string> = new EventEmitter<string>();
  @Output() descriptionChanged:EventEmitter<string> = new EventEmitter<string>();
  @Output() requiredChanged:EventEmitter<boolean> = new EventEmitter<boolean>();
  isLabelEdit:boolean = false;
  isDescriptionEdit:boolean = false;
  dateInvalid:boolean = false;
  now:Date = new Date();

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.editmode && changes.editmode.currentValue == false) {
      this.isLabelEdit = false;
      this.isDescriptionEdit = false;
    }
  }

  OnStartDateChanged(event:MatDatetimePickerInputEvent<Date>){
    this.dateScheduler.valueStart = event.value;
  }

  OnEndDateChanged(event:MatDatetimePickerInputEvent<Date>):void{
    this.dateScheduler.valueEnd = event.value;
  }

  labelCopy: string;
  OnLabelDoubleClick():void{
    if(this.editmode) {
      this.isLabelEdit = true;
      this.labelCopy = this.dateScheduler.name[this.currentLang];
    }
  }

  OnLabelEnter():void{
    if(this.dateScheduler.name[this.currentLang] != "") {
      this.nameChanged.emit();
    } else {
      this.dateScheduler.name[this.currentLang] = this.labelCopy;
    }
    this.isLabelEdit = false;
  }

  descriptionCopy: string;
  OnDescriptionDoubleClick():void{
    if(this.editmode) {
      this.isDescriptionEdit = true;
      this.descriptionCopy = this.dateScheduler.small[this.currentLang];
    }
  }

  OnDescriptionEnter():void{
    this.descriptionChanged.emit();
    this.isDescriptionEdit = false;
  }

  OnRequiredClicked():void{
    this.requiredChanged.emit();
  }
}

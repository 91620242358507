import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { SelectOption } from 'src/app/models/project/SelectOption.model';

@Component({
  selector: 'app-select',
  templateUrl: './app-select.component.html',
  styleUrls: ['./app-select.component.scss']
})
export class AppSelectComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
    @Input() placeholder: string = "";
    @Input() value: any;
    @Input() label: string | null = null;
    @Input() invalid: boolean = false;
    @Input() disabled: boolean = false;
    @Input() options: SelectOption[] = [];
    @Input() allowMultiple: boolean = false;
    @Output() valueChange = new EventEmitter<any>();

    filteredOptions: ReplaySubject<SelectOption[]> = new ReplaySubject<SelectOption[]>(1);
    public filterCtrl: FormControl = new FormControl('');
    protected _onDestroy = new Subject<void>();
    @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
    public selectCtrl: FormControl = new FormControl(null);

    constructor() {}

    ngOnInit(): void {
        // set initial selection
        if(this.value) {
          if(this.allowMultiple) {
            this.selectCtrl.setValue(this.options.filter(x => this.value.some(x.value)));
          }
          else {
            this.selectCtrl.setValue(this.options.find(x => x.value == this.value));
          }
        }

        // load the initial options list
        this.filteredOptions.next(this.options.slice());

        // listen for search field value changes
        this.filterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
            this.filterOptions();
        });
    }

    ngAfterViewInit() {
        this.setInitialValue();
    }

    ngOnChanges(changes: SimpleChanges) {
      if(changes['options']) {
        this.options = changes.options.currentValue;
        this.filterCtrl.setValue("");
      }
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    onSelectedChanged(e) {
      this.value = e.value;
      this.valueChange.emit(e.value);
    }

    protected setInitialValue() {
        this.filteredOptions
          .pipe(take(1), takeUntil(this._onDestroy))
          .subscribe(() => {
            // setting the compareWith property to a comparison function
            // triggers initializing the selection according to the initial value of
            // the form control (i.e. _initializeSelection())
            // this needs to be done after the filteredOptionss are loaded initially
            // and after the mat-option elements are available
            this.singleSelect.compareWith = (a: SelectOption, b: SelectOption) => a && b && a == b;
          });
      }

    protected filterOptions() {
        if (!this.options) {
          return;
        }
        // get the search keyword
        let search = this.filterCtrl.value;
        if (!search) {
          this.filteredOptions.next(this.options.slice());
          return;
        } else {
          search = search.toLowerCase();
        }
        
        this.filteredOptions.next(
          this.options.filter(o => o.text.toLowerCase().indexOf(search) > -1)
        );
      }
}

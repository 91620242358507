import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Project } from 'src/app/models/dashboard/Dashboard.model';
import { StartupService } from 'src/app/services/project/startup.service';
import { StartUp } from 'src/app/models/project/Startup.model';

@Component({
  selector: 'app-projects-form',
  templateUrl: './projects-form.component.html',
  styleUrls: ['./projects-form.component.css']
})
export class ProjectsFormComponent implements OnInit {

  @Input() projects:Project[] = [];
  @ViewChild("inputField") inputField: ElementRef;
  projectsCopy:Project[] = [];
  filteredProjects: Project[] = [];
  isDown: boolean = false;

  constructor(
    private startupService: StartupService,
  ) { }

  ngOnInit(): void {
    this.projectsCopy = [...this.projects];
  }

  OnBtnClick(project: Project): void{
    let id: number = this.projects.indexOf(project);
    if(id != -1) {
      this.projects.splice(id, 1);
    } else {
      this.projects.push(project);
    }
  }

  OnSelect(project: Project): void {
    this.projects.push(project);
    this.projectsCopy.push(project);
    this.isDown = false;
    this.inputField.nativeElement.value = "";
  }

  convertProjects(projects: StartUp[]): Project[] {
    let list: Project[] = [];
    for(let project of projects) {
      list.push({id: parseInt(project.project_id), startup: project.project_name, internalLeader: {id: project.project_contact, name: project.details.project_manager_details.name, email: project.details.project_manager_details.email, image_hash: project.details.project_manager_details.image}});
    }
    return list;
  }

  filterProjects(): void {
    if(this.inputField.nativeElement.value!="") {
      this.isDown = true;
      this.startupService.SearchStartups(this.inputField.nativeElement.value, (startups) => {
        this.filteredProjects = this.convertProjects(startups);
      });
    } else {
      this.isDown = false;
    }
  }

  getHeight(): number {
    if(this.filteredProjects.length >= 4) {
      return 140.8;
    } else if(this.filteredProjects.length == 3) {
      return 105.6;
    } else if(this.filteredProjects.length == 2) {
      return 70.4;
    } else if(this.filteredProjects.length == 1) {
      return 35.2;
    } else {
      return 0;
    }
  }
}

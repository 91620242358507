import { Component, ElementRef, EventEmitter, OnInit, Input, Output, ViewChild } from '@angular/core';
import { User } from 'src/app/models/project/User.model';

@Component({
  selector: 'app-new-email-message',
  templateUrl: './new-email-message.component.html',
  styleUrls: ['./new-email-message.component.css']
})
export class NewEmailMessageComponent implements OnInit {

  @Output() cancel:EventEmitter<void> = new EventEmitter<void>();
  @Output() emailEvent:EventEmitter<{to:User[], subject:string, message:string}> = new EventEmitter<{to:User[], subject:string, message:string}>();

  emailToSend:{to:User[], subject:string, message:string} = {to:[], subject:"", message:""}
  invalidObj:{checkboxInvalid:boolean, subjectInvalid:boolean, messageInvalid:boolean} = {checkboxInvalid:false, subjectInvalid:false, messageInvalid:false}
  @ViewChild('internal') internal:ElementRef;
  @ViewChild('external') external:ElementRef;
  @ViewChild('subject') subject:ElementRef;
  @ViewChild('message') message:ElementRef;
  constructor() { }

  ngOnInit(): void {
  }

  OnCancelClicked():void{
    this.cancel.emit();
    this.resetForm();
  }

  OnSendClicked():void{
    if(this.isFormValid()){
      //TODO: Majd ha lesz API email küldéshez
      this.emailEvent.emit(this.emailToSend);
      this.resetForm();
    }
  }

  private isFormValid():boolean{
    let isValid:boolean = true;
    if(!this.internal.nativeElement.checked && !this.external.nativeElement.checked) {
      this.invalidObj.checkboxInvalid = true;
      isValid = false;
    } else {
      if(this.internal.nativeElement.checked) {
        // this.emailToSend.to.push internal members
      }
      if(this.external.nativeElement.checked) {
        // this.emailToSend.to.push external members
      }
    }
    if(this.subject.nativeElement.value == "") {
      this.invalidObj.subjectInvalid = true;
      isValid = false;
    } else {
      this.emailToSend.subject = this.subject.nativeElement.value;
    }
    if(this.message.nativeElement.value == "") {
      this.invalidObj.messageInvalid = true;
      isValid = false;
    } else {
      this.emailToSend.message = this.message.nativeElement.value;
    }
    return isValid;
  }

  resetForm():void{
    this.emailToSend = {to:[], subject:"", message:""};
    this.internal.nativeElement.checked = false;
    this.external.nativeElement.checked = false;
    this.subject.nativeElement.value = "";
    this.message.nativeElement.value = "";
    this.invalidObj = {checkboxInvalid:false, subjectInvalid:false, messageInvalid:false};
  }

}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { Utils } from 'src/app/common/utils';
import { Note } from 'src/app/models/Note.model';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-note-input',
  templateUrl: './note-input.component.html',
  styleUrls: ['./note-input.component.scss']
})
export class NoteInputComponent implements OnInit {

  @Input() btnColor: String='blue';
  @Input() btnColorHover: String='blue';
  @Input() set note(val: Note | undefined) {
    this._note = val;
    if(this) {
        this.titleText = val.title;
        this.payloadText = val.payload;
        this.dueDate = val.dueDate;
    }
  };

  get note() {
   return this._note;
  }

  private _note;

  titleText: string;
  payloadText: string;
  dateString: string;
  date: Date;
  dueDate: Date | undefined;
  @Output()submitNote:EventEmitter<Note> = new EventEmitter<Note>();
  btnhover: boolean = false;
  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.date = new Date();
    this.dateString = Utils.stringifyDate(this.date);
  }

  onDueDateChanged(event) {
    this.dueDate = event;
  }

  OnSubmit():void {
    if(this.titleText && this.payloadText && this.dueDate) {
      let newNote: Note = {
        id:'-1', title:this.titleText, payload: this.payloadText, author: this.authService.currentUserValue, date:this.date, dueDate: this.dueDate
      }

      if(this.note) {
       newNote.id = this.note.id; 
      }

      this.submitNote.emit(newNote);
      this.note = undefined;
    }
  }
}

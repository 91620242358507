<div class="wrapper" [ngStyle]="{'width': widthString, 'height':heightString}">
  <div class="dropdown" [ngStyle]="{'width': widthString, 'z-index': zIndex}" #toggleBarW >
    <div #toggleBar class="dropdowninput " (click)="OnClick()">
      <div>{{text.toUpperCase()}}</div>
      <app-svg-arrow [ngClass]="{down:isDown}" style="fill: var(--dark);" [width]="13"
      ></app-svg-arrow>
    </div>
    <div class="dropdown-content disableScrollBar " #dropDownContent [ngStyle]="{'height.px': isDown?contentHeight:0, 'width': widthString}">
      <ng-content></ng-content>
    </div>
  </div>
</div>

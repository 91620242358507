import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Utils } from 'src/app/common/utils';
import { trigger, transition, animate, style, state } from '@angular/animations';
import { Notification } from 'src/app/models/Notification.model';

@Component({
  selector: 'app-top-nav-notification',
  animations: [
    trigger('openClose', [
      state('open', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      state('closed', style({
        transform: 'translateX(200%)',
        opacity: 0,
        height: 0
      })),
      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ]),
  ],
  templateUrl: './top-nav-notification.component.html',
  styleUrls: ['./top-nav-notification.component.scss']
})
export class TopNavNotificationComponent implements OnInit {

  @Input() notification: Notification;
  @Input() show: boolean;
  @Input() ex: boolean = false;
  @Output() gotoEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() XChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  OnDonerClicked(): void {
    this.ex = !this.ex;
    setTimeout(() => {
      this.XChange.emit(this.ex);
    }, 500);
  }

  ShowX(): void {
    this.show = true;
  }

  HideX(): void {
    this.show = false;
  }

  OpenNotif(): void {
    if(!(window.innerWidth<=768)) {
      this.gotoEvent.emit(true);
    }
  }

  longPress:boolean;
  TouchStart(): void {
    this.longPress = true;
    setTimeout(() => {
      if(this.longPress) {
        this.show = true;
        setTimeout(() => {
          this.show = false;
        }, 1000);
      } else {
        this.show = false;
        this.gotoEvent.emit(true);
      }
    }, 200);
  }

  TouchEnd(): void {
    this.longPress = false;
  }

  getDate(date:Date): string {
    return Utils.stringifyDate(new Date(date));
  }
}

import { Injectable } from '@angular/core';
import { throwError, Subject } from 'rxjs';
import { EmailTemplate } from '../../models/utility/EmailTemplate.model';
import { Router } from '@angular/router';
import { BaseService } from '../utility/base.service';
import { JWTToken } from '../../models/utility/JWTToken.model';
import {ApiResponse} from '../../models/utility/ApiResponse.model';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  emailGroups:string[] = [];
  emailGroupIds:number[] = [];
  selectedGroup:string;
  selectedGroupId: number = -1;

  emails:EmailTemplate[] = [];
  emailIds:number[] = [];
  selectedEmail:EmailTemplate = null;
  selectedEmailId:number = -1;

  emailGroupId:number = -1;
  subject = new Subject();

  constructor(
    protected baseService: BaseService,
    private router:Router,
  ) {
    this.GetEmailGroupList((response) => {
      const emailGroupRoutes:string[] = [];
      response.data.list.forEach(group => {
        this.emailGroups.push(group.email_group_name);
        emailGroupRoutes.push(group.email_group_name.replaceAll(' ', '').replaceAll('-', ''));
        this.emailGroupIds.push(group.email_group_id);
      });
      if(this.router.url.split('/')[2] == "emailGroups" && this.router.url.split('/')[3]) {
        this.RefreshGroup(1+emailGroupRoutes.findIndex(group => group == this.router.url.split('/')[3]));
      }
    });
  }

  GetEmailGroupList(callBack: (value: ApiResponse<JWTToken>) => void) {
    return this.baseService.packageQuery<JWTToken, object>('sup', 'setting_emails/getEmailGroupList', {}, callBack);
  }

  GetEmailListinGroup(id:number, callBack: (value: ApiResponse<JWTToken>) => void) {
    return this.baseService.packageQuery<JWTToken, object>('sup', 'setting_emails/getEmailListInGroup', {email_group_id: id}, callBack);
  }

  RefreshGroupList(callBack: () => void) : void {
    this.GetEmailGroupList((response) => {
      this.emailGroups = [];
      this.emailGroupIds = [];
      response.data.list.forEach(group => {
        this.emailGroups.push(group.email_group_name);
        this.emailGroupIds.push(group.email_group_id);
      });
      callBack();
    });
  }

  RefreshGroup(id:number): void {
    this.GetEmailListinGroup(id, (response) => {
      this.emails = [];
      this.emailIds = [];
      response.data.list.forEach(email => {
        this.emails.push({title: email.email_title, text: email.email_content, subject: email.email_subject});
        this.emailIds.push(email.email_id);
      });
      this.subject.next(this.emails);
      this.emailGroupId = id;
    });
  }

  AddEmailGroup(name:string) {
    return this.baseService.packageQuery<JWTToken, object>('sup', 'setting_emails/addEmailGroup', {email_group_name: name}, () => {
      this.RefreshGroupList(() => {
        let currentUrl = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([currentUrl]);
        });
      });
    });
  }

  AddEmail(email:EmailTemplate) {
    return this.baseService.packageQuery<JWTToken, object>('sup', 'setting_emails/addEmail', {email_group_id: this.emailGroupId, email_title: email.title, email_subject: email.subject, email_content: email.text}, () => {
      this.RefreshGroup(this.emailGroupId);
    });
  }

  SelectGroup(idx:number, callBack: () => void):void{
    this.selectedGroup = this.emailGroups[idx];
    this.selectedGroupId = this.emailGroupIds[idx];
    callBack();
  }

  SelectEmail(idx:number, callBack: () => void):void{
    this.selectedEmail = this.emails[idx];
    this.selectedEmailId = this.emailIds[idx];
    callBack();
  }

  DeleteSelectedGroup():void{
    if(this.selectedGroupId!=-1) {
      this.baseService.packageQuery<JWTToken, object>('sup', 'setting_emails/deleteEmailGroup', {email_group_id: this.selectedGroupId}, () => {
        this.RefreshGroupList(() => {
          this.selectedGroupId = -1;
          this.selectedGroup = null;
          let currentUrl = this.router.url;
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentUrl]);
          });
        });
      });
    } else throwError("No group selected!!");
  }

  UpdateSelectedGroup(name:string) {
    return this.baseService.packageQuery<JWTToken, object>('sup', 'setting_emails/updateEmailGroup', {email_group_id: this.selectedGroupId, email_group_name: name}, () => {
      this.RefreshGroupList(() => {
        this.selectedGroupId = -1;
        this.selectedGroup = null;
        let currentUrl = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([currentUrl]);
        });
      });
    });
  }

  DeleteSelectedEmail():void{
    if(this.selectedEmailId!=-1) {
      this.baseService.packageQuery<JWTToken, object>('sup', 'setting_emails/deleteEmail', {email_id: this.selectedEmailId}, () => {
        this.selectedEmailId = -1;
        this.selectedEmail = null;
        this.RefreshGroup(this.emailGroupId);
      });
    } else throwError("No email selected!!");
  }

  UpdateSelectedEmail(emailTemplate:EmailTemplate) {
    return this.baseService.packageQuery<JWTToken, object>('sup', 'setting_emails/updateEmail', {email_id: this.selectedEmailId, email_group_id: this.emailGroupId, email_title: emailTemplate.title, email_subject: emailTemplate.subject, email_content: emailTemplate.text}, () => {
      this.selectedEmailId = -1;
      this.selectedEmail = null;
      this.RefreshGroup(this.emailGroupId);
    });
  }

  GetSelectedEmail(): EmailTemplate {
    return this.selectedEmail;
  }
}
import { Component, Input, OnInit, ViewChild, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormValue, RadioButton } from 'src/app/models/dashboard/InputField.model';
import { Language } from 'src/app/models/Language.model';
import { StepsMakerService } from 'src/app/services/project/form/steps-maker.service';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.css']
})
export class RadioButtonComponent implements OnInit {

  constructor(
    private stepsService: StepsMakerService,
  ) { }

  isOther:boolean = false;
  @Input() radioButton:RadioButton;
  @Input() currentLang:string;
  @Input() editmode:boolean = false;
  @Input() readonly:boolean = false;
  @Input() radioId:string = '';
  @ViewChild('textinput',{static:true}) input:HTMLInputElement;
  @Output() nameChanged:EventEmitter<string> = new EventEmitter<string>();
  @Output() descriptionChanged:EventEmitter<string> = new EventEmitter<string>();
  @Output() requiredChanged:EventEmitter<boolean> = new EventEmitter<boolean>();
  isLabelEdit:boolean = false;
  isDescriptionEdit:boolean = false;

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.editmode && changes.editmode.currentValue == false) {
      this.isLabelEdit = false;
      this.isDescriptionEdit = false;
      this.isOptionEdit = -1;
    }
  }

  OnAddClicked():void{
    let value = new FormValue(0, new Language("Érték" + (+this.radioButton.options.length+1), "Value" + (+this.radioButton.options.length+1)));
    this.stepsService.AddFormValue(this.radioButton.id, value, 0, backValue => {
      this.radioButton.options.push(backValue);
    });
  }

  OnOtherCheckChanged():void{
    this.isOther = true;
    this.radioButton.selectedValue = this.radioButton.otherValue;
  }

  OnCheckClicked(idx:number):void{
    this.isOther = false;
    this.radioButton.selectedValue = this.radioButton.options[idx];
  }

  labelCopy: string;
  OnLabelDoubleClick():void{
    if(this.editmode) {
      this.isLabelEdit = true;
      this.labelCopy = this.radioButton.name[this.currentLang];
    }
  }

  OnLabelEnter():void{
    if(this.radioButton.name[this.currentLang] != "") {
      this.nameChanged.emit();
    } else {
      this.radioButton.name[this.currentLang] = this.labelCopy;
    }
    this.isLabelEdit = false;
  }

  descriptionCopy: string;
  OnDescriptionDoubleClick():void{
    if(this.editmode) {
      this.isDescriptionEdit = true;
      this.descriptionCopy = this.radioButton.small[this.currentLang];
    }
  }

  OnDescriptionEnter():void{
    this.descriptionChanged.emit();
    this.isDescriptionEdit = false;
  }

  OnRequiredClicked():void{
    this.requiredChanged.emit();
  }

  OnRadioRemoveClicked(idx:number):void{
    this.stepsService.DeleteFormValue(this.radioButton.options[idx].id);
    this.radioButton.options.splice(idx,1);
  }

  isOptionEdit:number = -1;
  OnOptionDoubleClick(id:number):void{
    if(this.editmode)
      this.isOptionEdit = id;
  }

  OnOptionEnter(event:Event, id:number):void{
    if((event.target as HTMLInputElement).value != "") {
      this.radioButton.options[id].value[this.currentLang] = (event.target as HTMLInputElement).value;
      this.stepsService.UpdateFormValue(this.radioButton.options[id]);
    }
    this.isOptionEdit = -1;
  }
}

<div style="margin: 10px 0; position: relative; z-index: 5;" [ngStyle]="{'width.px':width, 'height.px':width*0.257}">
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%" viewBox="0 0 169.001 43.416">
		<defs>
			<linearGradient id="linear-gradient" x1="0.785" y1="0.447" x2="0.139" y2="0.685" gradientUnits="objectBoundingBox">
				<stop offset="0" stop-color="#0da5f8"/>
				<stop offset="1" stop-color="#414a9e"/>
			</linearGradient>
			<linearGradient id="linear-gradient-2" x1="0.622" y1="0.394" x2="0.13" y2="0.611" xlink:href="#linear-gradient"/>
		</defs>
		<g id="Group_737" data-name="Group 737" transform="translate(-166.235 -387.719)">
			<g id="Group_445" data-name="Group 445" transform="translate(225.664 398.21)">
				<path id="Path_763" data-name="Path 763" d="M129.779,579.065V601.5h-4.711V579.065Z" transform="translate(-125.068 -579.065)" fill="#333"/>
				<path id="Path_764" data-name="Path 764" d="M157.15,601.5V579.065h5.064l10.224,15.352V579.065h4.679V601.5h-5.064l-10.191-15.351V601.5Z" transform="translate(-144.491 -579.065)" fill="#333"/>
				<path id="Path_765" data-name="Path 765" d="M229.043,579.065l6.217,16.057,6.218-16.057h4.808L237.6,601.5h-4.679l-8.686-22.435Z" transform="translate(-185.105 -579.065)" fill="#333"/>
				<path id="Path_766" data-name="Path 766" d="M312.929,579.065v4.648h-11.7v4.2h10.1v4.679h-10.1v4.263h11.7V601.5H296.52V579.065Z" transform="translate(-228.867 -579.065)" fill="#333"/>
				<path id="Path_767" data-name="Path 767" d="M357.2,579.065l5,7.692,5-7.692h4.967l-7.243,11.218,7.243,11.217H367.2l-5-7.692-5,7.692h-4.968l7.243-11.217-7.243-11.218Z" transform="translate(-262.598 -579.065)" fill="#333"/>
			</g>
			<path id="Path_768" data-name="Path 768" d="M201.176,403.871a1.912,1.912,0,0,1,.294,2.982c-4.5,4.323-9.3,7.882-11.6,7.882-4.994,0-21.707-16.716-21.707-21.707s16.713-21.709,21.707-21.709h.02a1.9,1.9,0,0,1,1.716,2.6,24.83,24.83,0,0,0,9.574,29.947Z" transform="translate(-1.925 16.398)" fill="url(#linear-gradient)"/>
			<path id="Path_769" data-name="Path 769" d="M288.324,396.582c0,2.157-3.124,6.508-7.075,10.751a2.262,2.262,0,0,1-2.838.4,24.834,24.834,0,0,1-10.337-29.514,2.261,2.261,0,0,1,3.405-1.1C278.32,381.838,288.324,392.718,288.324,396.582Z" transform="translate(-77.022 12.284)" fill="url(#linear-gradient-2)"/>
		</g>
	</svg>
</div>
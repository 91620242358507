import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { AuthGuard } from './auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GeneralComponent } from './components/pages/general/general.component';
import { IndustriesComponent } from './components/pages/general/industries/industries.component';
import { DashboardEditorComponent } from './components/pages/general/dashboard-editor/dashboard-editor.component';
import { EmailGroupsComponent } from './components/pages/general/email-groups/email-groups.component';
import { EmailSettingsComponent } from './components/pages/general/email-settings/email-settings.component';
import { TagSettingsComponent } from './components/pages/general/tag-settings/tag-settings.component';
import { StepsMakerComponent } from './components/steps-maker/steps-maker.component';
import { HistoryComponent } from './components/pages/history/history.component';
import { UsersComponent } from './components/pages/users/users.component';
import { UserAdderComponent } from './components/pages/users/user-adder/user-adder.component';
import { NotificationsComponent } from './components/pages/notifications/notifications.component';
import { ProjectsComponent } from './components/pages/projects/projects.component';

const routes: Routes = [

  /*
  sample:

  permissionKey:
  {path: 'statistics', component: StatisticsViewComponent, canActivate: [AuthGuard],data: {permission:56320}},

  url:
  {path: 'statistics', component: StatisticsViewComponent, canActivate: [AuthGuard]},

  child url: ('/portfolio/0/files')

  {path: 'portfolio', component: PortfolioComponent, canActivate: [AuthGuard]},
  {
    path: 'portfolio/:id', component: PortfolioManagementViewComponent, canActivateChild: [AuthGuard],
    children: [
      {path: 'notes', component: NotesComponent},
      {path: 'team', component: TeamComponent},
      {path: 'files', component: FilesComponent},

      ...

   */

  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  {
    path: 'statistics',
    loadChildren: () => import('./pages/statistics/statistics.module').then(m => m.StatisticsModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./pages/deal-flow/deal-flow.module').then(m => m.DealFlowModule)
  },
  {
    path: 'portfolio',
    loadChildren: () => import('./pages/portfolio-management/portfolio-management.module').then(m => m.PortfolioManagementModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboards',
    loadChildren: () => import('./pages/dashboards/dashboards.module').then(m => m.DashboardsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'general', component: GeneralComponent, canActivateChild: [AuthGuard],
    children: [
      { path: 'industries', component: IndustriesComponent },
      { path: 'dashboard', component: DashboardEditorComponent },
      { path: 'emailGroups', component: EmailGroupsComponent },
      { path: 'emailGroups/:group', component: EmailSettingsComponent },
      { path: 'portfolioManagement', component: TagSettingsComponent },
      { path: 'categories', component: TagSettingsComponent },
      { path: 'dealflowTags', component: TagSettingsComponent },
    ]
  },
  { path: 'projects', component: ProjectsComponent, canActivate: [AuthGuard] },
  { path: 'stepsMaker', component: StepsMakerComponent, canActivate: [AuthGuard] },
  { path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
  // Ez a sor szerintem nem szükséges
  // { path: 'userAdd', component: UserAdderComponent },
  { path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuard] },
  { path: 'history', component: HistoryComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: '/dealflow', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
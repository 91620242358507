<div style="height: 26px;">
  <svg id="report" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
    <circle id="Ellipse_72" data-name="Ellipse 72" cx="13" cy="13" r="13" fill="#618abb"/>
    <path id="Path_512" data-name="Path 512" d="M11.2,12.618H3.314A1.316,1.316,0,0,1,2,11.3V1.314A1.316,1.316,0,0,1,3.314,0H11.2a1.316,1.316,0,0,1,1.314,1.314V11.3A1.316,1.316,0,0,1,11.2,12.618ZM3.314.526a.79.79,0,0,0-.789.789V11.3a.79.79,0,0,0,.789.789H11.2a.79.79,0,0,0,.789-.789V1.314A.79.79,0,0,0,11.2.526Z" transform="translate(5.742 6.377)" fill="#fff" stroke="#fff" stroke-width="0.2"/>
    <path id="Path_513" data-name="Path 513" d="M17.314,11.526H16.263a.263.263,0,1,1,0-.526h1.051a.263.263,0,0,1,0,.526Z" transform="translate(-0.896 1.16)" fill="#fff" stroke="#fff" stroke-width="0.2"/>
    <path id="Path_514" data-name="Path 514" d="M17.314,8.526H16.263a.263.263,0,1,1,0-.526h1.051a.263.263,0,0,1,0,.526Z" transform="translate(-0.896 2.583)" fill="#fff" stroke="#fff" stroke-width="0.2"/>
    <path id="Path_515" data-name="Path 515" d="M17.314,14.526H16.263a.263.263,0,1,1,0-.526h1.051a.263.263,0,0,1,0,.526Z" transform="translate(-0.896 -0.263)" fill="#fff" stroke="#fff" stroke-width="0.2"/>
    <path id="Path_516" data-name="Path 516" d="M17.314,17.526H16.263a.263.263,0,1,1,0-.526h1.051a.263.263,0,0,1,0,.526Z" transform="translate(-0.896 -1.686)" fill="#fff" stroke="#fff" stroke-width="0.2"/>
    <path id="Path_517" data-name="Path 517" d="M12.1,20.526H5.263a.263.263,0,1,1,0-.526H12.1a.263.263,0,1,1,0,.526Z" transform="translate(4.32 -3.108)" fill="#fff" stroke="#fff" stroke-width="0.2"/>
    <path id="Path_518" data-name="Path 518" d="M7.629,13.257a2.629,2.629,0,1,1,2.629-2.629A2.632,2.632,0,0,1,7.629,13.257Zm0-4.732a2.1,2.1,0,1,0,2.1,2.1A2.105,2.105,0,0,0,7.629,8.526Z" transform="translate(4.32 2.583)" fill="#fff" stroke="#fff" stroke-width="0.2"/>
    <path id="Path_519" data-name="Path 519" d="M6.581,12.565a.263.263,0,0,1-.186-.448l1.6-1.6V8.263a.263.263,0,1,1,.526,0v2.366a.265.265,0,0,1-.077.186L6.767,12.488A.264.264,0,0,1,6.581,12.565Z" transform="translate(3.695 2.583)" fill="#fff" stroke="#fff" stroke-width="0.2"/>
  </svg>
</div>

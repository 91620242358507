import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-new-external-member',
  templateUrl: './new-external-member.component.html',
  styleUrls: ['./new-external-member.component.css']
})
export class NewExternalMemberComponent implements OnInit {

  @Output() cancel:EventEmitter<void> = new EventEmitter<void>();
  @Output() inviteEvent:EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('emailInput', {static:true}) emailInput:ElementRef;

  errorMsg:string = '';
  constructor(
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
  }

  OnCancelClicked():void{
    this.cancel.emit();
  }

  OnInviteClicked():void{
    const re:RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(re.test(String(this.emailInput.nativeElement.value).toLowerCase())){
      this.inviteEvent.emit(this.emailInput.nativeElement.value);
      this.errorMsg = '';
      this.emailInput.nativeElement.value = '';
    }else{
      this.errorMsg = this.translateService.instant('startup.team.invalid');
    }
  }
}

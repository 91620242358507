<div class="wrapper" [ngClass]="{'timeline-style':designType==='timeline', 'step-style':designType==='step', 'users-style':designType==='users'}">
  <div class="dropdown" #toggleBarW [ngClass]="{down: isDown, 'invalid':invalid}"
   [ngStyle]="{'border-radius.px': borderRadius, border: '1px solid '+borderColor}">
    <div #toggleBar style="display: flex; align-items: center; justify-content: space-between;"
    class="dropdowninput disableSelect" [ngStyle]="{'padding.px': padding}"
    (click)="OnClick()">
      <div>{{noneValue!='NONE'?noneValue:placeholder}}</div>
      <app-svg-arrow [ngClass]="{down:isDown}" style="fill: var(--dark);" [width]="13"
      ></app-svg-arrow>
    </div>
    <div class="dropdown-content disableSelect" #dropDownContent [ngClass]="{'hover-effect': isHoverEffect}"
     [ngStyle]="{'height.px': isDown?datas.length*itemHeight:0, 'border-radius': '0 0 '+borderRadius+'px '+borderRadius+'px'}">
      <div *ngFor="let data of datas; let i = index; " style="display: grid; grid-template-columns: auto;
      align-items: center;" [ngStyle]="{'border-radius.px': borderRadius}"
      (click)="OnSelect(data, i)">
        <div>{{data}}</div>
      </div>
    </div>
  </div>
</div>


import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-arrow',
  templateUrl: './svg-arrow.component.html'
})
export class SvgArrowComponent implements OnInit {

  @Input() width:number = 10.54;
  constructor() { }

  ngOnInit(): void {
  }

}

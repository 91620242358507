<div class="header">
  <input *ngIf="isLabelEdit" class="label-input" type="text" [placeholder]="labelCopy" [(ngModel)]="dateScheduler.name[currentLang]" (keydown.enter)="OnLabelEnter()">
  <div class="label" *ngIf="!isLabelEdit && dateScheduler!=undefined" (dblclick)="OnLabelDoubleClick();">{{dateScheduler.GetName(currentLang)}}<span>{{dateScheduler.required?'*':''}}</span></div>
  <div class="important-container" *ngIf="editmode">
    <input type="checkbox" id="important" name="important" [(ngModel)]="dateScheduler.required" (change)="OnRequiredClicked()">
    <label class="importantLabel disableSelect">Important</label>
  </div>
</div>
<div class="description">
  <input *ngIf="isDescriptionEdit" class="label-input" type="text" [placeholder]="descriptionCopy" [(ngModel)]="dateScheduler.small[currentLang]" (keydown.enter)="OnDescriptionEnter()">
  <div class="desc" *ngIf="!isDescriptionEdit && dateScheduler!=undefined" (dblclick)="OnDescriptionDoubleClick();">{{editmode?(dateScheduler.GetDescription(currentLang)==""?('steps.input.doubleClick' | translate):dateScheduler.GetDescription(currentLang)):dateScheduler.GetDescription(currentLang)}}</div>
</div>
<div class="date-wrapper">
  <input class="date-input" matInput [ngxMatDatetimePicker]="pickerStart" (dateChange)="OnStartDateChanged($event)"
    placeholder="{{'steps.input.dateSchedule.startDate' | translate}}" (click)="readonly?'':pickerStart.open()"
    [min]="now" readonly>

  <input class="date-input" matInput [ngxMatDatetimePicker]="pickerEnd" (dateChange)="OnEndDateChanged($event)"
    placeholder="{{'steps.input.dateSchedule.endDate' | translate}}" (click)="readonly?'':pickerEnd.open()"
    [min]="dateScheduler.valueStart" readonly>
</div>

<ngx-mat-datetime-picker #pickerStart [showSpinners]="true"
[showSeconds]="false" [stepMinute]="5">
</ngx-mat-datetime-picker>

<ngx-mat-datetime-picker #pickerEnd [showSpinners]="true" [showSeconds]="false" [stepMinute]="5">
</ngx-mat-datetime-picker>
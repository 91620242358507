import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { emptyTask, Task } from 'src/app/models/Task.model';

@Component({
  selector: 'add-task',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.scss']
})
export class AddTaskComponent implements OnInit, OnChanges {

  @Input() task: Task;
  @Output() taskChange = new EventEmitter<Task>();

  constructor() {
    
  }

  ngOnInit(): void {
    if(!this.task) {
      this.task = {...emptyTask};
      this.taskChange.emit(this.task);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.taskChange.emit(changes.task.currentValue)
  }
}
<div style="height: 21px;">
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
    <g id="add-internal" transform="translate(-374.122 -260.122)">
      <circle id="Ellipse_13" data-name="Ellipse 13" cx="10.5" cy="10.5" r="10.5" transform="translate(374.122 260.122)" fill="var(--internalColor)"/>
      <g id="Group_11" data-name="Group 11" transform="translate(380.41 266.216)">
        <path id="Path_75" data-name="Path 75" d="M6141,263.646v8.812" transform="translate(-6136.594 -263.646)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
        <path id="Path_76" data-name="Path 76" d="M0,0V8.812" transform="translate(8.812 4.406) rotate(90)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
      </g>
    </g>
  </svg>
</div>

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import Swal from 'sweetalert2';
import {InquiryService} from '../../services/support/inquiry.service';
import {InquiryCategory} from '../../models/inquiry/InquiryCategory.model';

@Component({
  selector: 'app-inquiry-category',
  templateUrl: './inquiry-category.component.html',
  styleUrls: ['./inquiry-category.component.css']
})
export class InquiryCategoryComponent implements OnInit {

  constructor(
    private inquiryService: InquiryService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  selectedCategory: InquiryCategory;

  ngOnInit(): void {
    let id = +this.route.snapshot.paramMap.get('id');
    if (id > 0) {
      this.getCategory(id);
    }
  }

  getCategory(id) {
    this.inquiryService.getCategory(id, res => {
      this.selectedCategory = res.data.item;
    });
  }

  saveForm(form: NgForm) {
    if (form.value.inc_id) {
      this.inquiryService.updateCategory(form.value, res => {
        this.router.navigate(['inquiry/category/list']);
      });
    } else {
      this.inquiryService.addCategory(form.value, res => {
        this.router.navigate(['inquiry/category/list']);
      });
    }
  }

  deleteItem() {
    Swal.fire({
      title: 'Törlésre készül !',
      text: 'Biztosan törli a kategóriát ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Igen, Törlöm'
    }).then((result) => {
      if (result.isConfirmed) {
        this.inquiryService.deleteCategory(this.selectedCategory?.inc_id, res => {
          this.router.navigate(['inquiry/category/list']);
        });
      }
    });
  }

}

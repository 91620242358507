import {Component, OnInit} from '@angular/core';
import {KnowledgeBaseService} from '../../services/support/knowledge-base.service';
import {ActivatedRoute, Route, Router} from '@angular/router';
import {KnowledgeBaseItem} from '../../models/knowledgebase/KnowledgeBaseItem.model';
import {NgForm} from '@angular/forms';
import {KnowledgeBaseCategory} from '../../models/knowledgebase/KnowledgeBaseCategory.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-knowledgebase-detail',
  templateUrl: './knowledgebase-detail.component.html',
  styleUrls: ['./knowledgebase-detail.component.css']
})
export class KnowledgebaseDetailComponent implements OnInit {

  constructor(
    private knowledgeBaseService: KnowledgeBaseService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  snConfig: any;
  sn_shortConfig: any;
  selectedItem: KnowledgeBaseItem;
  categories: Array<KnowledgeBaseCategory>;

  ngOnInit(): void {
    this.sn_shortConfig = {height: '200px'};
    this.snConfig = {height: '400px'};
    let id = +this.route.snapshot.paramMap.get('id');
    if (id > 0) {
      this.getItem(id);
    }
    this.knowledgeBaseService.getCategoryList(res => this.categories = res.data.list);
  }

  getItem(id) {
    this.knowledgeBaseService.getItem(id, res => {
      this.selectedItem = res.data.item;
    });
  }

  saveForm(form: NgForm): void {
    if (form.value.kb_id) {
      this.knowledgeBaseService.updateItem(form.value, res => {
        this.router.navigate(['knowledgebase/list']);
      });
    } else {
      this.knowledgeBaseService.addItem(form.value, res => {
        this.router.navigate(['knowledgebase/list']);
      });
    }
  }

  deleteItem() {
    Swal.fire({
      title: 'Törlésre készül !',
      text: 'Törli a cikket ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Igen, Törlöm'
    }).then((result) => {
      if (result.isConfirmed) {
        this.knowledgeBaseService.deleteItem(this.selectedItem.kb_id, res => {
          this.router.navigate(['knowledgebase/list']);
        });
      }
    });
  }
}

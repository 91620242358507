import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'svg-dealflow-onepager',
  templateUrl: './svg-dealflow-onepager.component.html',
  styles: [
  ]
})
export class SvgDealfowOnepagerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

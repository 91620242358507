import { Tag } from "../portfolio-management/TimelineAction.model";
import { User } from "./User.model";
import { Language } from "../Language.model";
import { Industry } from "./Industry.model";

export class StartupFilter{
  filter_industry:number[];
  filter_tag:number[];
  filter_manager:number[];
  filter_search:string;

  constructor() {
    this.filter_industry = [];
    this.filter_manager = [];
    this.filter_tag = [];
    this.filter_search = "";
  }
}

export class Step{
  id: number;
  name: Language;
  startups: StartUp[];
  sumOfFundSizes: number;
  pmList: ManagerDetails[];
  currentPage: number;
  arrievedLastPage: boolean = false;
  loading: boolean;

  constructor(id: number, name: Language, startups: StartUp[] = []) {
    this.id = id;
    this.name = name;
    this.startups = startups;
    this.sumOfFundSizes = 0;
    this.pmList = [];
    this.currentPage = 0;
    this.loading = true;
  }

  GetName(lang: string): string {
    return this.name.GetLanguage(lang);
  }
}

export interface StartUp{
  project_id: string;
  project_name: string;
  project_logo: string;
  project_contact: number;
  project_manager: number;
  project_industry: number;
  project_language: string;
  project_country: string;
  project_city: string;
  project_fund: number;
  project_cost: number;
  project_income: number;
  project_reg_date: Date;
  project_period_start: Date;
  project_period_end: Date;
  project_step: number;
  project_step_due_date: Date;
  project_page: string;
  project_linkedin: string;
  project_status: boolean;
  project_removed: boolean;
  project_about?: string;
  details?: StartupDetails;
  badge: boolean;
}

export interface StartupDetails {
  last_event_on_timeline: string;
  industry: Industry;
  tags: Tag[];
  project_contact_details: User;
  project_manager_details: User;
}

export interface ManagerDetails {
  project_manager: User;
  project_count: number;
}
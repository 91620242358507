<div *ngIf="editmode" class="top-card">
  <app-button (onClick)="saveEditStep()" text="{{'common.save' | translate}}" [type]="ButtonType.Primary"></app-button>
</div>
<div class="container">
  <div class="side-list disableScrollBar" cdkDropList (cdkDropListDropped)="dropSideList($event)">
    <div *ngFor="let step of steps; let i = index" cdkDrag [cdkDragDisabled]="!editmode" class="step-item disableSelect" [ngStyle]="{'margin-top.px': i==0?0:15}">
      <!-- <app-svg-step-remove [width]="24" *ngIf="editmode" (click)="OnStepRemoveClicked(step.id, i)"></app-svg-step-remove> -->
      <div [ngStyle]="{'font-size.px': editmode ? 12 : 13}" class="step-card" (click)="OnStepClicked(i)" [ngClass]="{'selected-step-card': selectedId == i}">
        <span class="int-ext-text" [ngClass]="{'internal-text': step.isInternal, 'external-text': !step.isInternal, 'selected': selectedId == i}">{{step.isInternal ? ('steps.dashboard.intTextShort' | translate) : ('steps.dashboard.extTextShort' | translate)}}</span>
        <span>{{step.GetName(currentLang)}}</span>
      </div>
    </div>
    <div class="step-item">
      <app-button class="black-text-button" (onClick)="OnAddClicked()" text="{{'steps.dashboard.addStep' | translate}}" [type]="ButtonType.SecondaryLight"></app-button>
    </div>
  </div>

  <!-- <div class="side-options disableScrollBar" *ngIf="editmode"
  cdkDropList id="fields-list"
  [cdkDropListConnectedTo]="stepFormIds"
  cdkDropListSortingDisabled
  [cdkDropListData]="inputFieldTypes">
    <div *ngFor="let inputFieldType of inputFieldTypes; let i = index" class="step-item disableSelect"
    cdkDrag
    [cdkDragData]="inputFieldType">
      <div style="flex-grow: 1;" class="step-card">
        {{inputFieldType.GetType()}}
      </div>
    </div>
  </div> -->

  <div class="content">
    <div class="content-header" *ngIf="!editmode">
      <!-- <div class="icons-on-header">
        <app-svg-internal class="icon" style="fill: #2c487c;"></app-svg-internal>
        <app-svg-step-external class="hidden icon" *ngIf="editmode&&!isAdd"></app-svg-step-external>
        <app-svg-step-internal-external class="hidden icon" *ngIf="editmode&&!isAdd"></app-svg-step-internal-external>
        <button *ngIf="isLanguage('en')" (click)="useLanguage('hu')" class="languageButton hu" [ngClass]="{disabled:stepEdit}"></button>
        <button *ngIf="isLanguage('hu')" (click)="useLanguage('en')" class="languageButton en" [ngClass]="{disabled:stepEdit}"></button>
      </div> -->

      <div class="title-wrapper">
        <span class="title">
          {{steps[selectedId].GetName(currentLang)}}
        </span>
        <span class="int-ext-text" [ngClass]="{'internal-text': steps[selectedId].isInternal, 'external-text': !steps[selectedId].isInternal}">{{steps[selectedId].isInternal ? ('steps.dashboard.intText' | translate) : ('steps.dashboard.extText' | translate)}}</span>
      </div>
      <div class="button-wrapper">
        <app-button type="{{ButtonType.SecondaryGray}}" (onClick)="resetForm()" text="{{'steps.dashboard.reset' | translate}}"></app-button>
        <app-button type="{{ButtonType.SecondaryRed}}" (onClick)="rejectStep()" text="{{'steps.dashboard.decline' | translate}}"></app-button>
        <app-button type="{{ButtonType.Primary}}" (onClick)="sendStep()" text="{{'steps.dashboard.accept' | translate}}"></app-button>
      </div>
      <!-- <div class="cont">
        <div *ngIf="usermode" id="go">GO</div>
        <div *ngIf="usermode" id="nogo">NO GO</div>
        <div id="dotdot" (click)="isDotDotClicked = !isDotDotClicked" *ngIf="editmode&&!isAdd">
          <span>...</span>
          <div class="dotdot-dropdown" [ngClass]="{closed:!isDotDotClicked}">
            <div style="border-bottom: 1px solid var(--light);" (click)="stepEdit=true" translate>steps.dashboard.editStep</div>
            <div translate>steps.dashboard.removeAll</div>
          </div>
        </div>
      </div> -->
    </div>

    <div class="edit" *ngIf="editmode">
      <app-button (onClick)="openStepRemoveDialog(steps[selectedId].id, selectedId)" [type]="ButtonType.SecondaryRed" text="{{'steps.dashboard.removeStep' | translate}}"></app-button>
      <div class="content-header">
        <input-field class="title-input" label="{{'steps.dashboard.stepTitle' | translate}}" *ngIf="editmode" [(value)]="steps[selectedId].name[currentLang]"></input-field>
        <app-select style="margin: 0 10px;" allowMultiple="true" label="{{'steps.dashboard.visibleFor' | translate}}"></app-select>
        <app-select allowMultiple="true" label="{{'steps.dashboard.editableFor' | translate}}"></app-select>
      </div>
    </div>

    <!-- <div [ngStyle]="{'height.px': stepEdit?(isMobileResolution()?90:45):0}" class="edit-btns">
      <div style="display: grid; grid-template-columns: auto auto; gap: 20px; margin-top: 20px;">
        <button class="button" id="new-sec-btn" (click)="NewSectionClicked()" translate>steps.dashboard.newSection</button>
        <button class="button" id="new-text-btn" translate>steps.dashboard.nextText</button>
      </div>
      <button class="button" id="done-btn" (click)="OnDoneClicked()" translate>common.done</button>
    </div> -->

    <!-- <div [ngStyle]="{'height.px': usermode&&!formSent?(isMobileResolution()?90:45):0}" class="save-btns">
      <div style="display: grid; grid-template-columns: auto auto; gap: 20px; margin-top: 20px;">
        <button class="button" id="new-sec-btn" (click)="SaveStep()" translate>common.save</button>
        <button class="button" id="new-text-btn" (click)="sendStep()" translate>common.send</button>
      </div>
    </div> -->

    <div class="description">
      <div *ngIf="!editmode && steps[selectedId] != undefined"><p *ngFor="let p of steps[selectedId].GetDescription(currentLang).split('\n')">{{p}}</p></div>
      <!-- <div *ngIf="steps[selectedId]!=undefined && !stepEdit && (steps[selectedId].GetDescription(currentLang)==undefined || steps[selectedId].GetDescription(currentLang)=='')" class="emptyform">
        <p translate>steps.dashboard.emptyForm</p>
        <p translate>steps.dashboard.add</p>
      </div> -->
      <!-- <textarea #arrayTextArea *ngIf="editmode" class="disableScrollBar" id="desc" style="width: 100%; height: 150px; white-space: pre-wrap;" [(ngModel)]="steps[selectedId].descriptionParagraphs[currentLang]" (keydown.enter)="OnEnter($event)">
      </textarea> -->
      <input-field minHeight="250" style="min-height: 250px;" *ngIf="editmode" label="{{'common.description' | translate}}" [invalid]="steps[selectedId].descriptionParagraphs[currentLang] == undefined || steps[selectedId].descriptionParagraphs[currentLang] == '' " [textarea]="true" [(value)]="steps[selectedId].descriptionParagraphs[currentLang]"></input-field>
    </div>

    <div class="form-area" *ngIf="steps[selectedId] != undefined" cdkDropList (cdkDropListDropped)="dropFormArea($event)">
      <div cdkDrag [cdkDragDisabled]="!editmode" *ngFor="let form of steps[selectedId].forms; let i = index">
        <app-step-form-area [form]="form" [listId]="i" [selectedId]="steps[selectedId].id" [stepFormIds]="stepFormIds.concat(['fields-list'])" [isStepEdit]="editmode" (delete)="RemoveSectionClicked(form.id, i)" (deleteRow)="RemoveRowClicked($event)" [currentLang]="currentLang" [readonly]="formSent"></app-step-form-area>
      </div>
    </div>
    <app-button style="margin: 0 0 0 8px;" class="black-text-button" (onClick)="newSectionClicked()" text="{{'steps.dashboard.addSection' | translate}}" [type]="ButtonType.SecondaryLight"></app-button>
  </div>
</div>
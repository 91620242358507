<div class="container disableSelect" *ngIf="show"> 
    <div class="background" (click)="Close()"></div>

    <div class="card-wrapper">
        <h1 translate>users.addUser.title</h1>

        <form #regForm="ngForm">
            <div class="labels">
                <label class="name" for="name" translate>users.addUser.name</label>
            </div>
            <div class="input-row">
                <input type="text" class="input-field name" id="name" name="name" [ngClass]="{invalid: invalidObj.nameInvalid}" (input)="invalidObj.nameInvalid = false" [(ngModel)]="newUser.name" required/>
            </div>
            <div class="labels">
                <label class="label-left" for="email" translate>common.user.emailAddress</label>
                <label class="label-right" for="password" translate>common.user.password</label>
            </div>
            <div class="input-row">
                <input class="input-field" type="email" id="email" name="email" [ngClass]="{invalid: invalidObj.emailInvalid}" (input)="invalidObj.emailInvalid = false" [(ngModel)]="newUser.email" required/>
                <div class="input-field password-box">
                    <input type="text" class="input-field password" id="password" name="password" [placeholder]="newUser.password" disabled/>
                    <button class="copy" (click)="copy()"></button>
                </div>
            </div>
            <div class="labels">
                <label class="label-left" for="authority" translate>common.authority</label>
                <label class="label-right" for="active" translate>users.addUser.activeUser</label>
            </div>
            <div class="input-row dropdown">
                <app-drop-down-input-popup [datas]="['Investment Manager', 'TO-DO']" [defaultSelect]="true" [defaultId]="0" [designType]="'users'" #dropDown (selected)="OnAuthoritySelected($event)"></app-drop-down-input-popup>
                <app-drop-down-input-popup [datas]="['common.no' | translate, 'common.yes' | translate]" [defaultSelect]="true" [defaultId]="0" [designType]="'users'" #dropDown (selected)="OnActiveSelected($event)"></app-drop-down-input-popup>
            </div>
            <div class="buttons">
                <app-agree-disagree-buttons [justifyContent]="'center'" [innerMargin]="30" [agreeColor]="'var(--accentDark)'" [agreeText]="'common.save' | translate" [cancelText]="'common.cancel' | translate" (cancel)="Close()" (agree)="OnSaveClicked()"></app-agree-disagree-buttons>
            </div>
        </form>
    </div>
</div>
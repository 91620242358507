<div class="panels">
  <div *ngIf="authService.currentUserValue.main_permission == 1" class="internal panel">
      <div class="title">
          <h3 translate>startup.internal</h3>
          <app-button class="add-note-btn" type="{{ButtonType.SecondaryLight}}" (onClick)="ActivatePlusInternal()" text="{{'startup.notes.internalAdd' | translate}}"></app-button>
      </div>
      <div *ngIf="!loaded">
        <div class="loader"></div>
      </div>
      <div class="note-list" #internalNoteList *ngIf="loaded && (addNoteInternalActive || filteredInternalNotes.length > 0)">
          <div class="note" *ngIf="addNoteInternalActive">
            <app-note-input #internalInput (submitNote)="AddNoteInternal($event)" [btnColor]="'var(--internalColor)'"
            [btnColorHover]="'var(--internalDarkColor)'"></app-note-input></div>
          <ng-container *ngIf="filteredInternalNotes.length > 0">
            <div *ngFor="let note of filteredInternalNotes" class="note" >
              <app-note (editNoteEvent)="editInternalNote($event)" (delteNoteEvent)="removeNote($event)" [note]="note" [tagColor]="'var(--internalColor)'"></app-note>
            </div>
          </ng-container>
      </div>
      <div class="note-list" *ngIf="loaded && !addNoteInternalActive && filteredInternalNotes.length === 0" translate>startup.notes.internalEmpty</div>
  </div>
  <div *ngIf="authService.currentUserValue.main_permission == 1" class="line"></div>
  <div [ngClass]="{single: authService.currentUserValue.main_permission == 2}" class="external panel">
      <div [ngClass]="{single: authService.currentUserValue.main_permission == 2}" class="title">
          <h3 *ngIf="authService.currentUserValue.main_permission == 1" translate>startup.external</h3>
          <app-button class="add-note-btn" type="{{ButtonType.SecondaryLight}}" (onClick)="ActivatePlusExternal()" text="{{'startup.notes.externalAdd' | translate}}"></app-button>
      </div>
      <div *ngIf="!loaded">
        <div class="loader"></div>
      </div>
      <div class="note-list" #externalNoteList *ngIf="loaded && (addNoteExternalActive || filteredExternalNotes.length > 0)">
        <div class="note" *ngIf="addNoteExternalActive">
          <app-note-input #externalInput (submitNote)="AddNoteExternal($event)" [btnColor]="'var(--externalColor)'"
          [btnColorHover]="'var(--externalDarkColor)'"></app-note-input></div>
        
        <ng-container *ngIf="filteredExternalNotes.length > 0">
          <div *ngFor="let note of filteredExternalNotes" class="note">
            <app-note (editNoteEvent)="editExternalNote($event)" (delteNoteEvent)="removeNote($event)" [note]="note" [tagColor]="'var(--externalColor)'"></app-note>
          </div>
        </ng-container>
      </div>
      <div class="note-list" *ngIf="loaded && !addNoteExternalActive && filteredExternalNotes.length === 0" translate>startup.notes.externalEmpty</div>
  </div>
</div>
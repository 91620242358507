export class Language {
  hu: string;
  en: string;

  constructor(hu = "", en = ""){
    this.hu = hu;
    this.en = en;
  }

  GetLanguage(lang: string): string {
    if(Object.keys(this).includes(lang) && this[lang] != undefined && this[lang] != "") {
      return this[lang];
    } else {
      for(let key in Object.keys(this)) {
        if(this[key] != undefined && this[key] != "") {
          return this[key];
        }
      }
    }
    return "";
  }

  isEmpty(): boolean {
    let r: boolean = true;
    for(let key in Object.keys(this)) {
      r = r && (this[key] != undefined && key != "")
    }
    return r;
  }
}

<div class="container disableSelect" *ngIf="show"> 
    <div class="background" (click)="Close()"></div>

    <div class="card-wrapper">
        <h1 translate>users.editUser.title</h1>

        <form #regForm="ngForm">
            <div class="labels">
                <label class="name" for="name" translate>users.editUser.title</label>
            </div>
            <div class="input-row">
                <input #nameInput type="text" class="input-field name" [placeholder]="user.name" id="name" name="name" [ngModel]="user.name" required/>
            </div>
            <div class="labels">
                <label class="label-left" for="email" translate>common.user.emailAddress</label>
                <label class="label-right" for="password" translate>common.user.password</label>
            </div>
            <div class="input-row">
                <input class="input-field" type="email" [placeholder]="user.email" id="email" name="email" [ngModel]="user.email" disabled/>
                <div class="password">
                    <input class="input-field" type="password" id="password" name="password" value="12345678" disabled/>
                    <button #reset class="reset" (click)="OnResetClicked()" [disabled]="resetSent || captcha==null" [ngClass]="{'disabled': resetSent || captcha==null}" translate>users.editUser.reset</button>
                </div>
            </div>
            <div class="labels">
                <label class="label-left" for="authority" translate>common.authority</label>
                <label class="label-right" for="active" translate>users.editUser.activeUser</label>
            </div>
            <div class="input-row dropdown">
                <app-drop-down-input-popup [datas]="['Investment Manager', 'TO-DO']" [defaultSelect]="true" [defaultId]="authorityId" [designType]="'users'" #dropDown (selected)="OnAuthoritySelected($event)"></app-drop-down-input-popup>
                <app-drop-down-input-popup [datas]="['common.no' | translate, 'common.yes' | translate]" [defaultSelect]="true" [defaultId]="user.status" [designType]="'users'" #dropDown (selected)="OnActiveSelected($event)"></app-drop-down-input-popup>
            </div>
            <div class="assignment-button">
                <button class="button" (click)="assignmentsPopup.Open()" translate>users.editUser.assignment</button>
            </div>
            <div style="display: flex; justify-content: center; align-items: center; margin: 15px 0;">
              <re-captcha (resolved)="resolved($event)" (error)="onError($event)" errorMode="handled" required></re-captcha>
            </div>
            <div class="buttons">
                <app-agree-disagree-buttons [agreeDisabled]="captcha==null" [justifyContent]="'center'" [innerMargin]="30" [agreeColor]="'var(--okColor)'" [agreeText]="'common.save' | translate" [agreeColor]="'var(--okColor)'" (cancel)="Close()" (agree)="OnSaveClicked()"></app-agree-disagree-buttons>
            </div>
        </form>
    </div>
</div>

<app-popup #assignmentsPopup [width]="500">
  <div class="popup-title" translate>users.editUser.editAssignment</div>
  <input class="search-input" type="text" placeholder="{{'common.search' | translate}}" #filterField (input)="OnInput($event.target)">
  <div class="for-div" [ngClass]="{scroll:(assignments.length>8 || (maxHeight!=-1 && maxHeight<(assignments.length)*48.6-20))}" [ngStyle]="{'height.px': assignments.length>8 ? 8*48.6-(isMobileResolution()?20:15) : (assignments.length)*48.6-(isMobileResolution()?20:15), 'max-height.px': maxHeight!=-1 ? maxHeight : 'auto'}">
      <div class="data-row" *ngFor="let assignment of filteredAssignments; let i = index">
        <div class="side-container">{{assignment.name.toUpperCase()}}</div>
        <div>{{assignment.email}}</div>
        <div class="side-container">
            <button *ngIf="assignment.status==1" class="active-button blue" (click)="OnActiveClicked(i)" translate>users.editUser.active</button>
            <button *ngIf="assignment.status!=1" class="active-button gray" (click)="OnActiveClicked(i)" translate>users.editUser.inactive</button>
        </div>
    </div>
  </div>
  <div class="popup-buttons">
    <app-agree-disagree-buttons [justifyContent]="'center'" [innerMargin]="30" [agreeColor]="'var(--accentDark)'" [agreeText]="'common.save' | translate" [cancelText]="'common.cancel' | translate" (cancel)="assignmentsPopup.Close()" (agree)="OnAssignmentSaveClicked(); assignmentsPopup.Close()"></app-agree-disagree-buttons>
  </div>
</app-popup>
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/models/project/User.model';

@Component({
  selector: 'app-new-internal-member',
  templateUrl: './new-internal-member.component.html',
  styleUrls: ['./new-internal-member.component.css']
})
export class NewInternalMemberComponent implements OnInit {

  @Input() personList: User[] = [];
  filteredPersonList: User[] = [];

  @Input() title:string = this.translateService.instant('startup.team.newInMem');
  @Input() agreeText:string = this.translateService.instant('startup.team.addUser');
  @Output() cancel:EventEmitter<void> = new EventEmitter<void>();
  @Output() add:EventEmitter<User> = new EventEmitter<User>();

  errorText:string='';

  constructor(
    private translateService: TranslateService,
  ) { }

  getHeight():number{
    return Math.ceil(this.personList.length/2) * 95;
  }
  ngOnChanges(): void {
    this.filteredPersonList = this.personList;
  }
  ngOnInit(): void {
    this.filteredPersonList = this.personList;
  }
  selectedPersonIndex:number=-1;
  OnPersonSearchInput(input:HTMLInputElement):void{
    this.selectedPersonIndex = -1;
    this.filteredPersonList = this.personList.filter(x=>{
      return (x.name.toLowerCase().match(input.value.toLowerCase())!=null)
      ||(x.jobTitle.toLowerCase().match(input.value.toLowerCase())!=null);
    });
  }

  OnPersonClicked(index:number):void{
    if(this.selectedPersonIndex!==index)
      this.selectedPersonIndex = index;
      else
      this.selectedPersonIndex = -1;
  }

  OnAddUserClicked():void{
    if(this.selectedPersonIndex!==-1){
      this.add.emit(this.filteredPersonList[this.selectedPersonIndex]);
      this.errorText='';
      this.selectedPersonIndex=-1;
    }else{
      this.errorText=this.translateService.instant('startup.team.error');
    }
  }

}

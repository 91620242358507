
<div class="header">
  <h1 translate>history.name</h1>
  <app-drop-down-input class="dropdown" #dropDown [text]="'common.units' | translate" [widthString]='"100px"' [contentHeight]="unitOptions.length*24">
    <div *ngFor="let num of unitOptions" class="dropdown-item">
      <div (click)="perPageChange(num); dropDown.Close()">{{num}}</div>
    </div>
  </app-drop-down-input>
  <input type="text" placeholder="{{'common.search' | translate}}" class="search-input" #filterField (input)="OnInputFiled()">
</div>
<div class="wrapper">
  <div class="header-row">
    <div *ngIf="!isMobileResolution()"></div>
    <div translate>history.header.user</div>
    <div translate>history.header.date</div>
    <div translate>history.header.event</div>
    <div *ngIf="!isMobileResolution()" translate>history.header.ip</div>
  </div>
  <div class="data-row" *ngFor="let log of logList; let i = index">
    <div *ngIf="!isMobileResolution()"><img class="profile-pic" [src]="log.image_hash" [alt]=log.userid></div> <div>{{log.username}}</div> <div>{{getDate(log.date)}}</div> <div>{{log.text}}</div> <div class="ip"><b *ngIf="isMobileResolution()">IP: </b>{{log.ip}}</div>
  </div>
</div>
<div *ngIf="pages.length > 1" class="buttons">
  <app-svg-left-arrow *ngIf="pageId>1" (click)="DecrementPage()"></app-svg-left-arrow>
  <div class="num">{{pageId}}. {{'common.page' | translate}}</div>
  <app-svg-right-arrow *ngIf="pageId<pages.length" (click)="IncrementPage()"></app-svg-right-arrow>
</div>

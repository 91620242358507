import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {
  
  @Input() isOpen:boolean = false;
  @Input() width:number = -1;
  @Output() close:EventEmitter<void> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  Open():void{
    this.isOpen=true;
    this.SetFixed();
  }
  Close():void{
    this.isOpen=false;
    this.UnFix();
    this.close.emit()
  }

  SetFixed(): void {
    if(document.body.scrollHeight > document.body.clientHeight) {
      document.body.style.top = `-${window.scrollY}px`;
      (document.body.children[0].children[0] as HTMLElement).style.top = '0px';
      (document.body.children[0].children[1] as HTMLElement).style.top = '0px';
      document.body.style.position = 'fixed';
      document.body.style.overflowY = 'scroll';
    }
  }
  UnFix(): void {
    const scrollY = document.body.style.top;
    document.body.style.top = '';
    (document.body.children[0].children[0] as HTMLElement).style.top = '';
    (document.body.children[0].children[1] as HTMLElement).style.top = '';
    document.body.style.position = '';
    document.body.style.overflowY = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  }
}

import { Injectable } from '@angular/core';
import { ApiResponse } from "src/app/models/utility/ApiResponse.model";
import { ApiResponseItem } from "src/app/models/utility/ApiResponseItem.model";
import { Note } from "src/app/models/Note.model";
import { NoteResponse } from "src/app/models/NoteResponse.model";
import { ResultList } from "src/app/models/utility/ResultList.model";
import { BaseService } from "../../utility/base.service";
import { AuthService } from 'src/app/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class NotesService{
  internalNotes:Note[] = [];
  externalNotes:Note[] = [];

  constructor(
    private base_svc: BaseService,
    private authService: AuthService,
  ){ }

  FilterNotes(input:string, isInternal:boolean):Note[]{
    let result:Note[] = [];
    if(isInternal){
      result = this.internalNotes.filter(x=>x.title.toLowerCase().match(input.toLowerCase())!=null);
    }else{
      result = this.externalNotes.filter(x=>x.title.toLowerCase().match(input.toLowerCase())!=null);
    }
    return result;
  }

  getNotes(project_id: string, status: string, callBack: (value: boolean) => void) {
    return this.base_svc.packageQuery<ResultList<NoteResponse>, object>('project','notes/getNoteList', {
      note_status: status,
      note_project_id: project_id
    }, (res) => {
      this.internalNotes = [];
      this.externalNotes = [];

      var len = res.data.list.length-2;
      var num = 0;
      if(res.data.list.length == 0) {
        callBack(true);
      } else {
        res.data.list.forEach(element => {
          var actual = new Note();
          actual.id = String(element.note_id);
          actual.date = element.note_date;
          actual.title = element.note_title;
          actual.payload = element.note_description;
          actual.author = element.author_user;

          /*if(len == ++num) {
            callBack(true);
          }*/

          if (element.note_internal == true) {
            this.internalNotes.push(actual);
          } else {
            this.externalNotes.push(actual);
          }
        });
        this.internalNotes.sort(this.sortFunction);
        this.externalNotes.sort(this.sortFunction);
        
        callBack(true);
      }
    });
  }

  sortFunction(a: Note,b: Note){ 
    var dateA = new Date(a.date.toString().replace(/-/g, "/")).getTime();
    var dateB = new Date(b.date.toString().replace(/-/g, "/")).getTime();
    return dateA > dateB ? -1 : 1;  
  };

  addNote(note: Note, project_id: string, status: string, internal: string, callBack: () => void) {
    return this.base_svc.packageQuery<ApiResponseItem<number>, object>('project','notes/addNote', {
      note_author_id: note.author.id,
      note_title: note.title,
      note_description: note.payload,
      note_project_id: project_id,
      note_status: status,
      note_internal: internal
    }, (res) => {
      note.id = res.data.item.toString();
      if(internal === "1") {
        this.internalNotes.unshift(note);
      } else {
        this.externalNotes.unshift(note);
      }
      callBack();
    });
  }

  deleteNote(note_id: number, callBack: (value: ApiResponse<ApiResponseItem<string>>) => void){
    return this.base_svc.packageQuery<ApiResponseItem<string>, object>('project','notes/deleteNote', {
      note_id: note_id
    }, callBack);
  }

  updateNote(note: Note, project_id: string, status: string, internal: string, callBack: (value: ApiResponse<ResultList<NoteResponse>>) => void) {
    return this.base_svc.packageQuery<ResultList<NoteResponse>, object>('project','notes/updateNote', {
      note_id: note.author.id,
      note_author_id: note.author,
      note_title: note.title,
      note_description: note.payload,
      note_project_id: project_id,
      note_status: status,
      note_internal: internal
    }, callBack);
  }
}

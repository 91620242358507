import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-tasks',
  templateUrl: './svg-tasks.component.html'
})
export class SvgTasksComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

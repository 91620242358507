import { BaseService } from './services/utility/base.service';
import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { AuthService } from './services/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'invex';
  currentUser;
  sideNavWidth: number = 49;
  storageToken = '';

  constructor(
    public authService: AuthService,
    public baseService: BaseService,
    private router: Router,
  ) { }
  nullToken = "";

  ngOnInit(): void {
    this.storageToken = this.baseService.storage_token;
    this.currentUser = this.authService.getCurrentUser;
  }

  hideNav(){
    return this.authService.token != "";
  }

  isMobileResolution(): boolean {
    return window.innerWidth <= 768;
  }

  correctSize(event:number): void {
    this.sideNavWidth = event;
  }

  direct() {
    let permission: number = this.authService.currentUserValue.main_permission;
    if(permission == 1) {
      this.router.navigate(['dealflow']);
    } else if(permission == 2) {
      this.router.navigate(['projects']);
    }
  }
}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-svg-invex-logo',
  templateUrl: './svg-invex-logo.component.html',
  styleUrls: ['./svg-invex-logo.component.css']
})
export class SvgInvexLogoComponent implements OnInit {

  @Input() width:number = 169.001;
  constructor() { }

  ngOnInit(): void {
  }

}

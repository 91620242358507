import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-web',
  templateUrl: './svg-web.component.html'
})
export class SvgWebComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-step-internal-external',
  templateUrl: './svg-step-internal-external.component.html',
  styles: [
  ]
})
export class SvgStepInternalExternalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="panels">
  <div *ngIf="authService.currentUserValue.main_permission == 1" class="internal panel">
      <div class="title">
          <h3 translate>startup.internal</h3>
          <app-svg-editcircle style="fill: var(--internalColor);" class="plus" (click)="OnEditPressedInternal()"></app-svg-editcircle>
      </div>
      <div class="pcard-list" #internalPcardList *ngIf="isEditModeInternal || filteredInternalTeam.length > 0"  >
          <app-person-card *ngFor="let p of filteredInternalTeam; let i = index" (delete)="RemovePersonInternal(i)"
          [person]="p" [tagColor]="'var(--dark)'" (tagChange)="OnTagChangeInternal(i)"
           [selectColor]="'var(--internalColor)'" [isEdit]="isEditModeInternal">
          </app-person-card>
          <app-person-card *ngIf="isEditModeInternal" [tagColor]="'rgb(191,191,191)'"
         [isPlus]="true" (click)="OnPlusInternalClicked()"></app-person-card>
      </div>
      <div class="pcard-list empty" *ngIf="!isEditModeInternal && filteredInternalTeam.length === 0" translate>startup.team.internalEmpty</div>
  </div>
  <div *ngIf="authService.currentUserValue.main_permission == 1" class="line"></div>
  <div [ngClass]="{single: authService.currentUserValue.main_permission == 2}" class="external panel">
      <div [ngClass]="{single: authService.currentUserValue.main_permission == 2}" class="title">
          <h3 *ngIf="authService.currentUserValue.main_permission == 1" translate>startup.external</h3>
          <app-svg-editcircle style="fill: var(--externalColor);" class="plus" (click)="OnEditPressedExternal()"></app-svg-editcircle>
      </div>
      <div class="pcard-list" #externalPcardList  *ngIf="isEditModeExternal || filteredExternalTeam.length > 0"  >
          <app-person-card *ngFor="let p of filteredExternalTeam; let i = index"  (delete)="RemovePersonExternal(i)"
          [person]="p" [tagColor]="'var(--dark)'" (tagChange)="OnTagChangeExternal(i)"
           [selectColor]="'var(--externalColor)'" [isEdit]="isEditModeExternal"
          ></app-person-card>
          <app-person-card *ngIf="isEditModeExternal" [tagColor]="'rgb(191,191,191)'"
         [isPlus]="true" (click)="OnPlusExternalClicked()"></app-person-card>
      </div>
      <div class="pcard-list empty" *ngIf="!isEditModeExternal && filteredExternalTeam.length === 0" translate>startup.team.externalEmpty</div>
  </div>
</div>

<app-popup #addInternalPopup [width]="560">
  <app-new-internal-member #newInternalMember (cancel)="addInternalPopup.Close()"
  (add)="AddPersonInternal($event);addInternalPopup.Close()" [title]="'startup.team.newInMem' | translate"
  [personList]="personOptionsList"></app-new-internal-member>
</app-popup>

<app-popup #addExternalPopup>
  <app-new-external-member
  (cancel)="addExternalPopup.Close()"
  (inviteEvent)="AddPersonExternal($event)"></app-new-external-member>
</app-popup>

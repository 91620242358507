<div style="height: 21px;">
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="-0.5 0 22.5 20">
    <g id="externaledit" transform="translate(-912 -263)">
      <g id="Group_12" data-name="Group 12" transform="translate(537.878 2.878)">
        <circle id="Ellipse_13" data-name="Ellipse 13" cx="10.5" cy="10.5" r="10.5" transform="translate(374.122 260.122)"/>
      </g>
      <path id="Icon_feather-edit-2" data-name="Icon feather-edit-2" d="M10.288,3.66A1.374,1.374,0,1,1,12.232,5.6L5.672,12.163,3,12.892l.729-2.672Z" transform="translate(914.683 265.425)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
    </g>
  </svg>
</div>


import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { Utils } from 'src/app/common/utils';
import { InvexFile } from 'src/app/models/utility/File.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FilesService } from 'src/app/services/project/files/files.service';

@Component({
  selector: 'app-edit-file-form',
  templateUrl: './edit-file-form.component.html',
  styleUrls: ['./edit-file-form.component.css']
})
export class EditFileFormComponent implements OnInit,OnChanges {


  @Input() file:InvexFile;
  @Output() fileChanged:EventEmitter<InvexFile> = new EventEmitter<InvexFile>();
  @Input() isEditAllowed:boolean = false;

  @Output() cancel:EventEmitter<void> = new EventEmitter<void>();
  @Output() save:EventEmitter<InvexFile> = new EventEmitter<InvexFile>();

  isEditFileName:boolean = false;
  isEditFileDescription:boolean = false;
  filePath:string = "";

  constructor(
    public authService:AuthService,
    private fileService:FilesService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges():void{
    this.isEditFileName = false;
    this.isEditFileDescription = false;
  }

  OnFileNameDoubleClick():void{
    this.isEditFileName = true;
  }

  OnFileDecriptionDoubleClick():void{
    this.isEditFileDescription = true;
  }
  OnEnter(event:Event):void{
    let type:string = (event.target as HTMLInputElement).type;
    if(type=="textarea"){
      this.isEditFileDescription = false;
      this.file.description = (event.target as HTMLInputElement).value;
    }else if (type=="text"){
      this.isEditFileName = false;
      this.file.fileName = (event.target as HTMLInputElement).value;
    }
  }
  // OnFileInputchanged(fileInput:HTMLInputElement):void{
  //   this.filePath = fileInput.value.split(/(\\|\/)/g).pop();
  // }
  OnFileInputchanged(value:string):void{
    this.filePath = value;
  }
   OnFileSelectInput(event:Event, nativeFileInput:HTMLInputElement):void{
     event.preventDefault();
     nativeFileInput.click();
   }

   @ViewChild('fileNameInput') fNameInput:ElementRef;
   @ViewChild('fileTextInput') fTextInput:ElementRef;
  OnFileSaveClicked():void{
    if(this.fNameInput!=undefined)
    this.file.fileName = this.fNameInput.nativeElement.value;
    if(this.fTextInput!=undefined)
    this.file.description = this.fTextInput.nativeElement.value;
    if(this.filePath!=''){
      let ver:number = this.file.versions.length+1;
      this.file.versions.unshift({
        fileName:this.filePath,
        version:'v'+ver,
        uploadDate:new Date(),
        path:''
      });
    }
    this.save.emit(this.file);
  }

  stringDate(date:Date):string{
    return Utils.stringifyDate(new Date(date));
  }

  OnFileIconClicked():void{
    this.fileService.DownloadFileFromAssets('assets/mockfiles/file1.txt');
  }

}

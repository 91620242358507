import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-fundsize',
  templateUrl: './svg-fundsize.component.html'
})
export class SvgFundsizeComponent implements OnInit {

  constructor() { }

  @Input() width:number=24;
  ngOnInit(): void {
  }

}

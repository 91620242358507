import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { SelectOption } from "src/app/models/project/SelectOption.model";

@Component({
    selector: 'app-video-input',
    templateUrl: './video-input.component.html',
    styleUrls: ['./video-input.component.scss']
  })
  export class VideoInputComponent implements OnInit, AfterViewInit {
    timeout;
    recorder;

    selectedAudioDevice: string;
    audioDevices: SelectOption[] = [];
    selectedVideoDevice: string;
    videoDevices: SelectOption[] = [];
    savedStream: MediaStream | undefined;

    ngAfterViewInit(): void {
        // @ts-ignore
        document.getElementById('your-video-id').muted = true;
        navigator.mediaDevices.enumerateDevices().then((d) => {
            this.gotDevices(d);
        }).then(() => {
            // @ts-ignore
            navigator.permissions.query({name:'microphone'}).then(result => {
                if (result.state == 'granted') {
                  console.log("granted");
                } else if (result.state == 'prompt') {
                    console.log("prompt");
                    var constraints: MediaStreamConstraints = {
                        audio: false,
                        video: false,
                    };
                    
                    if(this.audioDevices.length != 0) {
                        constraints = {...constraints, audio: {deviceId: this.selectedAudioDevice ? {exact: this.selectedAudioDevice} : undefined}}
                    }
                    if(this.videoDevices.length != 0) {
                        constraints = {...constraints, video: {deviceId: this.selectedVideoDevice ? {exact: this.selectedVideoDevice} : undefined}}
                    }
                    
                    navigator.mediaDevices.getUserMedia(constraints).then(res => {
                        console.log("prompted")
                        //We dont want to use them right away just promt it
                        res.getTracks().forEach(function(track) {
                            track.stop();
                        });
                        //Check devices again to populate device selectors
                        navigator.mediaDevices.enumerateDevices().then((d) => {
                            this.gotDevices(d);
                        })
                    })
                    .catch(er => console.log(er))
                } else {
                    console.log("denied");
                }
            });
            
        }).catch(e => {
            console.log("err:" + e);
            this.handleError(e);
        });
    }

    ngOnInit(): void {
      
    }
    
    record_start() {
        console.log("starting...")
        navigator.mediaDevices.getUserMedia({
            video: {deviceId: {exact: this.selectedVideoDevice}},
            audio: {deviceId: {exact: this.selectedAudioDevice}} 
        }).then((stream) => {
            this.savedStream = stream;
            // preview camera during recording
            document.getElementById('your-video-id2').style.display = "none";
            // @ts-ignore
            document.getElementById('your-video-id').muted = true;
            // @ts-ignore
            document.getElementById('your-video-id').srcObject = stream;
            document.getElementById('your-video-id').style.display = "block";
            // @ts-ignore
            document.getElementById('your-video-id').currentTime = "0.0";
            // recording configuration/hints/parameters
            var recordingHints = {
                type: 'video',
                mimeType: 'video/mp4'
                //recorderType: RecordRTC.MediaStreamRecorder
            };

            // initiating the recorder
            // @ts-ignore
            this.recorder = RecordRTC(stream, recordingHints);
            
            // @ts-ignore
            this.recorder.startRecording();
            console.log("started")
            clearTimeout(this.timeout);

            // auto stop recording after 5 minutes
            var milliSeconds = 5 * 60 * 1000;
            this.timeout = setTimeout(function() {
                this.record_stop()
            }, milliSeconds);
        }).catch(err => console.log("Error: " + err.message));
        
        
    }

    record_save() {
        if(this.recorder) {
            // get recorded blob
            var blob = this.recorder.getBlob();

            // generating a random file name
            var fileName = this.getFileName('webm');

            // we need to upload "File" --- not "Blob"
            var fileObject = new File([blob], fileName, {
                type: 'video/webm'
            });

            this.uploadToPHPServer(fileObject, function(response, fileDownloadURL) {
                if(response !== 'ended') {
                    document.getElementById('header').innerHTML = response; // upload progress
                    return;
                }

                document.getElementById('header').innerHTML = '<a href="' + fileDownloadURL + '" target="_blank">' + fileDownloadURL + '</a>';

                alert('Successfully uploaded recorded blob.');

                // preview uploaded file
                // @ts-ignore
                document.getElementById('your-video-id').srcObject = null;
                // @ts-ignore
                document.getElementById('your-video-id').src = fileDownloadURL;

                // open uploaded file in a new tab
                window.open(fileDownloadURL);
            });
        }
    }

    uploadToPHPServer(blob, callback) {
        // create FormData
        var formData = new FormData();
        formData.append('video-filename', blob.name);
        formData.append('video-blob', blob);
        callback('Uploading recorded-file to server.');

        var upload_url = '/save.php';
        
        var upload_directory = '/uploads/';
        
        
        this.makeXMLHttpRequest(upload_url, formData, function(progress) {
            if (progress !== 'upload-ended') {
                callback(progress);
                return;
            }
            var initialURL = upload_directory + blob.name;
            callback('ended', initialURL);
        });
    }

    record_stop() {
        console.log("stopping...")
        // stop recording
        this.recorder.stopRecording(() => {
            
            // release stream
            // @ts-ignore
            document.getElementById('your-video-id').srcObject = document.getElementById('your-video-id').src = null;
            this.savedStream.getTracks().forEach(function(track) {
                track.stop();
            });
            console.log("Stopped")
            document.getElementById('your-video-id').style.display = "none";
            var blob = this.recorder.getBlob();

            // generating a random file name
            var fileName = this.getFileName('mp4');

            // we need to upload "File" --- not "Blob"
            var fileObject = new File([blob], fileName, {
                type: 'video/mp4'
            });
            console.log(blob);
            console.log(fileObject);
            // @ts-ignore
            document.getElementById('your-video-id2').muted = true;
            // @ts-ignore
            document.getElementById('your-video-id2').src = window.URL.createObjectURL(blob);
            document.getElementById('your-video-id2').style.display = "block";
        });

    }

    makeXMLHttpRequest(url, data, callback) {
        var request = new XMLHttpRequest();
        request.onreadystatechange = function() {
            if (request.readyState == 4 && request.status == 200) {
                if (request.responseText === 'success') {
                    callback('upload-ended');
                    return;
                }
                alert(request.responseText);
                return;
            }
        };
        request.upload.onloadstart = function() {
            callback('PHP upload started...');
        };
        request.upload.onprogress = function(event) {
            callback('PHP upload Progress ' + Math.round(event.loaded / event.total * 100) + "%");
        };
        request.upload.onload = function() {
            callback('progress-about-to-end');
        };
        request.upload.onload = function() {
            callback('PHP upload ended. Getting file URL.');
        };
        request.upload.onerror = function(error) {
            callback('PHP upload failed.');
        };
        request.upload.onabort = function(error) {
            callback('PHP upload aborted.');
        };
        request.open('POST', url);
        request.send(data);
    }

    // this function is used to generate random file name
    getFileName(fileExtension) {
        var d = new Date();
        var year = d.getUTCFullYear();
        var month = d.getUTCMonth();
        var date = d.getUTCDate();
        return 'RecordRTC-' + year + month + date + '-' + this.getRandomString() + '.' + fileExtension;
    }

    getRandomString() {
        if (window.crypto && window.crypto.getRandomValues && navigator.userAgent.indexOf('Safari') === -1) {
            var a = window.crypto.getRandomValues(new Uint32Array(3)),
                token = '';
            for (var i = 0, l = a.length; i < l; i++) {
                token += a[i].toString(36);
            }
            return token;
        } else {
            return (Math.random() * new Date().getTime()).toString(36).replace(/\./g, '');
        }
    }

    gotDevices(deviceInfos: MediaDeviceInfo[]) {
        // Handles being called several times to update labels. Preserve values.
        //const values = this.selectors.map(select => select.nativeElement.value);
        
        // while (this.audioInputSelect.nativeElement.firstChild) {
        //     this.audioInputSelect.nativeElement.removeChild(this.audioInputSelect.nativeElement.firstChild);
        // }
        // while (this.videoSelect.nativeElement.firstChild) {
        //     this.videoSelect.nativeElement.removeChild(this.videoSelect.nativeElement.firstChild);
        // }
        var audioDevicesTemp = [];
        var videoDevicesTemp = [];
        
        for (let i = 0; i !== deviceInfos.length; ++i) {
            const deviceInfo = deviceInfos[i];
            // const option = document.createElement('option');
            // option.value = deviceInfo.deviceId;
            if (deviceInfo.kind === 'audioinput') {
                // option.text = deviceInfo.label || `microphone ${this.audioInputSelect.nativeElement.length + 1}`;
                // this.audioInputSelect.nativeElement.appendChild(option);
                audioDevicesTemp.push({value: deviceInfo.deviceId ? deviceInfo.deviceId : "-1", text: deviceInfo.label || `microphone ${this.audioDevices.length + 1}`})
            } else if (deviceInfo.kind === 'videoinput') {
                // option.text = deviceInfo.label || `camera ${this.videoSelect.nativeElement.length + 1}`;
                // this.videoSelect.nativeElement.appendChild(option);
                videoDevicesTemp.push({value: deviceInfo.deviceId, text: deviceInfo.label || `camera ${this.videoDevices.length + 1}`})
            } else {
                //console.log('Some other kind of source/device: ', deviceInfo);
            }
        }
        this.audioDevices = audioDevicesTemp;
        this.videoDevices = videoDevicesTemp;
        
        // if (Array.prototype.slice.call(this.audioInputSelect.nativeElement.childNodes).some(n => n.value === this.audioInputSelect.nativeElement.value)) {
        //     this.audioInputSelect.nativeElement.value = this.audioInputSelect.nativeElement.value;
        // }
        // if (Array.prototype.slice.call(this.videoSelect.nativeElement.childNodes).some(n => n.value === this.videoSelect.nativeElement.value)) {
        //     this.videoSelect.nativeElement.value = this.videoSelect.nativeElement.value;
        // }
    }

    // Attach audio output device to video element using device/sink ID.
    attachSinkId(element, sinkId) {
    if (typeof element.sinkId !== 'undefined') {
        element.setSinkId(sinkId)
            .then(() => {
            console.log(`Success, audio output device attached: ${sinkId}`);
            })
            .catch(error => {
            let errorMessage = error;
            if (error.name === 'SecurityError') {
                errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
            }
            console.error(errorMessage);


            });
    } else {
        console.warn('Browser does not support output device selection.');
    }
    }



    gotStream(stream) {
        // @ts-ignore
        window.stream = stream; // make stream available to console
        var videoElement = document.getElementById('your-video-id');
        // @ts-ignore
        videoElement.srcObject = stream;
        // Refresh button list in case labels have become available
        return navigator.mediaDevices.enumerateDevices();
        }

        handleError(error) {
        console.log('navigator.MediaDevices.getUserMedia stream error: ', error.message, error.name);
    }


    start() {
        // @ts-ignore
        if (window.stream) {
            // @ts-ignore
            window.stream.getTracks().forEach(track => {
                track.stop();
            });
        }
        // // @ts-ignore
        // const audioSource = this.audioInputSelect.nativeElement.value;
        // // @ts-ignore
        // const videoSource = this.videoSelect.nativeElement.value;
        var constraints: MediaStreamConstraints = {
            audio: false,
            video: false
        };
        
        if(this.audioDevices.length != 0) {
            constraints = {...constraints, audio: {deviceId: this.selectedAudioDevice ? {exact: this.selectedAudioDevice} : undefined}}
        }
        if(this.videoDevices.length != 0) {
            constraints = {...constraints, video: {deviceId: this.selectedVideoDevice ? {exact: this.selectedVideoDevice} : undefined}}
        }
        
        navigator.mediaDevices.getUserMedia(constraints).then((m) => {this.gotStream(m).then((d) => {this.gotDevices(d)});}).catch( e => {this.handleError(e)});
    }

    constraintChange() {
        // @ts-ignore
    const track = window.stream.getVideoTracks()[0];
    let constraints;
    // @ts-ignore
    if (aspectLock.checked) {
        constraints = {
            // @ts-ignore
        width: {exact: max_width},
        aspectRatio: {
            // @ts-ignore
            exact: video.videoWidth / video.videoHeight
        }
        };
    } else {
        // @ts-ignore
        constraints = {width: {exact: max_width}};
    }
    // @ts-ignore
    clearErrorMessage();
    console.log('applying ' + JSON.stringify(constraints));
    track.applyConstraints(constraints)
        .then(() => {
            console.log('applyConstraint success');
            // @ts-ignore
            displayVideoDimensions('applyConstraints');
        })
        .catch(err => {
            // @ts-ignore
            errorMessage('applyConstraints', err.name);
        });
    }
    
}
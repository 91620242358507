<div class="header">
  <input *ngIf="isLabelEdit" class="label-input" type="text" [placeholder]="labelCopy" [(ngModel)]="dropDown.name[currentLang]" (keydown.enter)="OnLabelEnter()">
  <div class="label" *ngIf="!isLabelEdit && dropDown!=undefined" (dblclick)="OnLabelDoubleClick();">{{dropDown.GetName(currentLang)}}<span>{{dropDown.required?'*':''}}</span></div>
  <div class="important-container" *ngIf="editmode">
    <input type="checkbox" id="important" name="important" [(ngModel)]="dropDown.required" (change)="OnRequiredClicked()">
    <label class="importantLabel disableSelect">Important</label>
  </div>
</div>
<div class="description">
  <input *ngIf="isDescriptionEdit" class="label-input" type="text" [placeholder]="descriptionCopy" [(ngModel)]="dropDown.small[currentLang]" (keydown.enter)="OnDescriptionEnter()">
  <div class="desc" *ngIf="!isDescriptionEdit && dropDown!=undefined" (dblclick)="OnDescriptionDoubleClick();">{{editmode?(dropDown.GetDescription(currentLang)==""?('steps.input.doubleClick' | translate):dropDown.GetDescription(currentLang)):dropDown.GetDescription(currentLang)}}</div>
</div>
<div class="wrapper" (click)="DropDownToggle()">
  <div class="dropdown" #toggleBarW [ngClass]="{down: isDown}" [ngStyle]="{'z-index': zIndex?'3':'2'}">
    <div #toggleBar class="dropdowninput " [ngClass]="{readonly:readonly}" (click)="OnClick()">
      <div>{{!dropDown.selectedValue.isNone()?dropDown.selectedValue.value[currentLang]:placeholder}}</div>
      <app-svg-arrow [ngClass]="{down:isDown}" style="fill: var(--dark);" [width]="13"></app-svg-arrow>
    </div>
    <div class="dropdown-content " #dropDownContent [ngStyle]="{'height.px': isDown?(editmode?(datas.length+1)*40:datas.length*40):0}">
      <div class="for-div" *ngFor="let data of datas; let i = index;" (click)="OnSelect(data, i)">
        <app-svg-step-remove *ngIf="!data.isNone() && editmode" [width]="18" (click)="OnRadioRemoveClicked(i, $event)"></app-svg-step-remove>
        <input class="text-input" type="text" [placeholder]="data.GetValue(currentLang)" *ngIf="isOptionEdit==i" (keydown.enter)="OnOptionEnter($event, i)">
        <div *ngIf="isOptionEdit!=i" (dblclick)="OnOptionDoubleClick(i);">{{data.GetValue(currentLang)}}</div>
      </div>
      <div class="for-div add" *ngIf="editmode" (click)="OnAddClicked()">
        <app-svg-add-action [width]="18"></app-svg-add-action>
        <div translate>steps.input.dropDown.addValue</div>
      </div>
    </div>
  </div>
</div>
import { InputFieldType } from "../models/dashboard/InputField.model";
import { TimelineActionType } from "../models/portfolio-management/TimelineAction.model";
import { SelectOption } from "../models/project/SelectOption.model";
import { TranslateService } from '@ngx-translate/core';

export class Utils {
  static getRandomInt(min:number, max:number):number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
  }

  static stringifyDate(date:Date):string{
    return date.toLocaleDateString();
  }

  static stringifyDateEurope(date: Date): string {
    var day = date.getDate();
    //Hónap 0-tól van
    return date.getFullYear() + ". " + date.getMonth() + 1 + ". " + (day < 10 ? ("0" + day) : day) + ".";
  }

  static inputFieldTypeSelectList(translateService: TranslateService): SelectOption[] {
    const values1 = Object.values(InputFieldType);
    var options: SelectOption[] = [];
  
    values1.forEach((value, index) => {
      options.push({text: translateService.instant('steps.input.' + value + 'Title'), value: value})
    });
  
    return options;
  }

  static stringifyDateTime(date:Date):string{
    let newDate:Date = new Date(date);
    return newDate.toLocaleString('hu-HU').substring(0, newDate.toLocaleString('hu-HU').lastIndexOf(":"));
  }

  static getMonthDateString(date:Date):string{
    let newDate:Date = new Date(date);
    let result:string = (newDate.getMonth()+1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
    result+=".";
    result+=newDate.getDate().toLocaleString('en-US', {minimumIntegerDigits:2});
    return result;
  }

  static isValidDate(d:Date):boolean {
    let isInstance:boolean = d instanceof Date;
    let nan:boolean = !isNaN(d.getMilliseconds());
    return isInstance && nan;
  }

  static stringifyActionType(actionType:TimelineActionType):string{
    switch (actionType) {
      case TimelineActionType.TRACTION: return "TRACTION";
      case TimelineActionType.EVENT: return "EVENT";
      case TimelineActionType.MILESTONE: return "MILESTONE";
      case TimelineActionType.REPORT: return "REPORT";
      default: return "INVALID";
    }
  }

  static isEmptyString(str:string) {
    let a:boolean = /\S+/.test(str);
    return (!str || str.length === 0 || !a );
}
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss']
})
export class NavItemComponent implements OnInit {
  
  @Input() hide: boolean = false;
  @Input() selectedSlug: string = "";
  @Input() text:string;
  @Input() iconString:'statistics'|'database'|'dealflow'|'pfmgmt'|'docsgen'|'network'|'ppartners'|'inbox'|'general'|'templates'|'steps'|'users'|'notifications'|'history';

  @Output() clicked:EventEmitter<void> = new EventEmitter();
  @Output() hideChanged:EventEmitter<boolean> = new EventEmitter();

  public isSelected = false;
  constructor(private router: Router) { 
    router.events.subscribe((val) => {
      if(val instanceof NavigationStart) {
        this.isSelected = val.url.includes(this.selectedSlug);
      }
  });
  }

  ngOnInit(): void {
  }

  OnClicked(): void{
    this.clicked.emit();
  }

  longPress:boolean;
  TouchStart(event): void {
    this.longPress = true;
    setTimeout(() => {
      if(this.longPress) {
        this.hideChanged.emit(false);
      }
    }, 200);
  }
  
  TouchEnd(): void {
    this.longPress = false;
    setTimeout(() => {
      this.hideChanged.emit(true);
    }, 2000);
  }
}

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-file-picker',
  templateUrl: './file-picker.component.html',
  styleUrls: ['./file-picker.component.css']
})
export class FilePickerComponent implements OnInit {

  constructor() { }

  @ViewChild('fileInput',{static:true}) fileInput:ElementRef;
  ngOnInit(): void {
    (this.fileInput.nativeElement as HTMLInputElement).value = '';
  }

  @Input() fileName:string = "";
  @Input() filePath:string = "";
  fileType:string = "";
  @Input() placeholder:string = '';
  @Output() fileChanged:EventEmitter<{path:string, name:string, type:string, hash:string}> = new EventEmitter<{path:string, name:string, type:string, hash:string}>();

  OnFileInputchanged(fileInput:HTMLInputElement):void{
    const file = fileInput.files[0];
    this.fileName = fileInput.value.split(/(\\|\/)/g).pop();
    this.filePath = fileInput.value;
    this.fileType = file.type;
    const reader = new FileReader();
    reader.onload = () => {
      this.fileChanged.emit({name:this.fileName, path:this.filePath, type:this.fileType, hash:reader.result as string});
    }
    reader.readAsDataURL(file)
  }

  OnFileSelectInput(event:Event, nativeFileInput:HTMLInputElement):void{
    event.preventDefault();
    nativeFileInput.click();
  }
}

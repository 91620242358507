import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { KnowledgeBaseCategory } from '../../models/knowledgebase/KnowledgeBaseCategory.model';
import { KnowledgeBaseService } from '../../services/support/knowledge-base.service';
import { KnowledgeBaseItem } from '../../models/knowledgebase/KnowledgeBaseItem.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-knowledgebase-reader',
  templateUrl: './knowledgebase-reader.component.html',
  styleUrls: ['./knowledgebase-reader.component.scss']
})
export class KnowledgebaseReaderComponent implements OnInit {

  constructor(
    private knowledgeBaseService: KnowledgeBaseService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
  ) { }

  @ViewChild('searcField') shField: ElementRef;
  catMenu = true;
  categoryList: Array<KnowledgeBaseCategory>;
  knowIteimList: Array<KnowledgeBaseItem>;

  selectedCategory = 0;
  searchStr = '';
  selectedItem: KnowledgeBaseItem;


  ngOnInit(): void {
    this.getCategoryList();
    this.getKnowItemList();

    let id = +this.route.snapshot.paramMap.get('id');
    if (id > 0) {
      this.read(id);
    }
  }

  read(id) {
    this.location.replaceState('/knowledge/' + id);
    this.knowledgeBaseService.getItem(id, res => {
      this.selectedItem = res.data.item;
      this.selectedCategory = this.selectedItem.kb_cat_id;
    });
  }

  toggleCatMenu() {
    this.catMenu = !this.catMenu;
  }


  getCategoryList() {
    this.knowledgeBaseService.getCategoryList(res => {
      this.categoryList = res.data.list;
    });
  }

  search(str) {

  }

  selectCategory(id) {
    this.selectedCategory = id;
    this.selectedItem = null;
    this.searchStr = '';
    this.shField.nativeElement.value = '';
    this.location.replaceState('/knowledge/list');
    this.getKnowItemList();
  }

  getKnowItemList() {
    this.knowledgeBaseService.getItemList(res => {
      this.knowIteimList = res.data.list;
    });
  }
}

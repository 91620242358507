import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BaseService } from '../utility/base.service';
import { User } from '../../models/project/User.model';
import { ApiResponseItem } from '../../models/utility/ApiResponseItem.model';
import { JWTPasswordChange } from '../../models/utility/JWTPasswordChange.model';
import { JWTEmailChange } from '../../models/utility/JWTEmailChange.model';
import { JWTImageChange } from '../../models/utility/JWTImageChange.model';
import { JWTToken } from '../../models/utility/JWTToken.model';
import { ApiResponse } from '../../models/utility/ApiResponse.model';
import { JWTProfileChange } from '../../models/utility/JWTProfileChange.model';
import { Project } from '../../models/project/Project.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserAdderComponent } from 'src/app/components/pages/users/user-adder/user-adder.component';

@Injectable({
  providedIn: 'root'
})
export class AuthService { 
  isAdmin: boolean = true;
  private currentUserSubject: BehaviorSubject<User> = new BehaviorSubject({} as User);

  constructor(
    protected http: HttpClient,
    protected baseService: BaseService,
    protected router: Router
  ) {
    if(localStorage.getItem('currentUser')) {      
      this.currentUserSubject = new BehaviorSubject<User>( JSON.parse(localStorage.getItem('currentUser'))); 
    }
  }

  setCurrentUser(user: User) {
    this.currentUserSubject.next(user);

    if(!user) {
      localStorage.removeItem('currentUser')
    }
  }


  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  get getCurrentUser(): Observable<User> {
    return this.currentUserSubject.asObservable();
  }

  login(userdata: User, captcha: string, callBack: (value: boolean) => void) {
    this.token = null;
    this.baseService.packageQuery<JWTToken, object>('auth', '/auth/login', {
      email: userdata.email,
      password: userdata.password,
      recaptcha: captcha
    }, (response) => {
      if (response.data.logintoken == 'non_authorized') {
        this.setCurrentUser(null);
        callBack(false);
        return;
      }
      sessionStorage.setItem('logintoken', response.data.logintoken);
      callBack(true);
    });
  }

  confirmLogin(authCode, callBack: (value: boolean) => void) {
    this.token = null;
    let logtoken = sessionStorage.getItem('logintoken');
    this.baseService.packageQuery<JWTToken, object>('auth', '/auth/confirmLogin', {
      logintoken: logtoken,
      authcode: authCode
    }, (response ) => {
      sessionStorage.removeItem('logintoken');
      let access_token = response.data.usertoken;
      if (access_token && access_token !== 'non_authorized') {
        this.token = access_token;
        console.log(response.data);
        
        localStorage.setItem('currentUser', JSON.stringify(response.data.profile));
        this.currentUserSubject.next(response.data.profile)
        callBack(true);
      } else {
        callBack(false);
      }
    }
    );
  }

  logout() {
    this.token = null;
    localStorage.removeItem('currentUser');
    this.router?.navigateByUrl('/auth/login');

  }

  // public storage_token;

  get token() {
    this.baseService.storage_token = localStorage.getItem('AUTH_TOKEN');
    if (this.baseService.storage_token) {
      return this.baseService.storage_token;
    }
    return '';
  }

  set token(value) {
    if (value) {
      localStorage.setItem('AUTH_TOKEN', value);
    } else if (value == "") {
      localStorage.setItem('AUTH_TOKEN', "");
    } else {
      localStorage.removeItem('AUTH_TOKEN');
    }

    this.baseService.storage_token = value;
  }

  getUserDetails(callBack: (value: ApiResponse<User>) => void) {
    return this.baseService.packageQuery<User, object>('auth', 'user/getLoginedUser', {}, callBack);
  }

  changePassword(chgpw: JWTPasswordChange, callBack: (value: ApiResponse<JWTToken>) => void) {
    return this.baseService.packageQuery<JWTToken, JWTPasswordChange>('auth', '/user/changePassword', chgpw, callBack);
  }

  changeEmail(chgml: JWTEmailChange, callBack: (value: ApiResponse<JWTToken>) => void) {
    return this.baseService.packageQuery<JWTToken, JWTEmailChange>('auth', '/user/changeEmail', chgml, callBack);
  }

  changeImage(chgimg: JWTImageChange, callBack: (value: ApiResponse<JWTToken>) => void) {
    return this.baseService.packageQuery<JWTToken, JWTImageChange>('auth', '/user/setImageHash', chgimg, callBack);
  }

  updateProfile(chgprfl: JWTProfileChange, callBack: (value: ApiResponse<JWTToken>) => void) {
    return this.baseService.packageQuery<JWTToken, JWTProfileChange>('auth', '/user/updateUser', chgprfl, callBack);
  }

  addUser(profile: object, callBack: (value: ApiResponse<JWTToken>) => void) {
    return this.baseService.packageQuery<ApiResponseItem<User>, object>('auth', 'user/addUser', profile, callBack);
  }

  getUser(userId: number, callBack: (value: ApiResponse<ApiResponseItem<User>>) => void) {
    return this.baseService.packageQuery<ApiResponseItem<User>, object>('auth', 'user/getUser', { id: userId }, callBack);
  }

  getUserList(perPage: number, page: number, callBack: (value: ApiResponse<JWTToken>) => void) {
    return this.baseService.packageQuery<JWTToken, object>('auth', 'user/getUserList', { perpage: perPage, page: page }, callBack);
  }

  searchUserList(perPage: number, page: number, key: string, callBack: (value: ApiResponse<JWTToken>) => void) {
    return this.baseService.packageQuery<JWTToken, object>('auth', 'user/getUserList', { perpage: perPage, page: page, filter_name: key }, callBack);
  }

  lostPassword(data, callBack: (value: ApiResponse<ApiResponseItem<any>>) => void) {
    return this.baseService.packageQuery<ApiResponseItem<any>, object>('auth', '/auth/lost_password', { email: data }, callBack);
  }

  resetPassword(token: string, password: string, captcha: string, callBack: () => void) {
    return this.baseService.packageQuery<JWTToken, object>('auth', 'auth/change_password_with_token', { token: token, password: password, recaptcha: captcha }, callBack);
  }

  checkToken(token: string, callBack: (value: ApiResponse<JWTToken>) => void) {
    return this.baseService.packageQuery<JWTToken, object>('auth', 'auth/change_password_token_check', { token: token }, callBack);
  }

  register(projectdata: Project, captcha: string, callBack: (value: boolean) => void) {
    this.token = null
    this.baseService.packageQuery<JWTToken, object>('auth', '/registration', {
      name: projectdata.name,
      contact_name: projectdata.contact_name,
      email: projectdata.email1,
      email2: projectdata.email2,
      phone: projectdata.phone,
      industry: projectdata.industry.toString(),
      language: projectdata.language,
      accept_gdpr: projectdata.accept_gdpr,
      accept_secret: projectdata.accept_secret,
      recaptcha: captcha
    }, (response) => {
      if (response.data.success == false) {
        callBack(false);
        return;
      }
      callBack(true);
    });
  }

  removeUser(id: number, captcha: string, callBack: (value: ApiResponse<ApiResponseItem<any>>) => void) {
    return this.baseService.packageQuery<ApiResponseItem<any>, object>('auth', 'user/deleteUser', { id: id, recaptcha: captcha }, callBack);
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'src/app/models/project/User.model';

@Component({
  selector: 'app-person-card',
  templateUrl: './person-card.component.html',
  styleUrls: ['./person-card.component.css']
})
export class PersonCardComponent implements OnInit {

  @Input()person:User;
  @Input()tagColor:string = 'gray';
  @Input()selectColor:string = 'green';

  @Input()isEdit:boolean = false;
  @Input()isPlus:boolean = false;

  @Output() delete:EventEmitter<void> = new EventEmitter();
  @Output() tagChange:EventEmitter<void> = new EventEmitter();

  activetagColor:string;

  constructor() { }

  ngOnInit(): void {
    this.activetagColor = this.tagColor;
  }

  OnMinusClicked() {
    this.delete.emit();
  }

  OnTagClicked():void {
    if(this.isEdit)
      this.tagChange.emit();
  }

  EmailClicked():void {

  }

  LinkedinClicked():void {
    window.open(this.person.url, "_blank");
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-step-external',
  templateUrl: './svg-step-external.component.html',
  styles: [
  ]
})
export class SvgStepExternalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { Color } from 'ngx-color';
import { Tag } from 'src/app/models/portfolio-management/TimelineAction.model';
import { DashboardService } from 'src/app/services/support/dashboard.service';

@Component({
  selector: 'app-statuses-form',
  templateUrl: './statuses-form.component.html',
  styleUrls: ['./statuses-form.component.css']
})
export class StatusesFormComponent implements OnInit {

  @Input() statuses:Tag[]=[];
  @Input() dashboardId = 0;
  constructor(
    private dashboardService:DashboardService,
  ) { }

  color:Color;

  ngOnInit(): void {
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.statuses, event.previousIndex, event.currentIndex);
  }

  OnAdd(inputFiled:HTMLInputElement):void{
    if(inputFiled.value==='') return;

    this.statuses.unshift({text:inputFiled.value, color:'var(--light)', textColor:'var(--dark)'});
    inputFiled.value = '';
  }

  OnColorChanged(idx:number, color:string):void{
    this.dashboardService.ChangeColor(idx, color);
  }

  OnTextColorChanged(idx:number, color:string):void{
    this.dashboardService.ChangeTextColor(idx, color);
  }

  OnDelete(idx:number):void{
    this.statuses.splice(idx, 1);
  }
}

import { User } from "./project/User.model";

export class Note {
  id: string;
  author: User;
  title: string;
  payload: string;
  date: Date;
  dueDate: Date;
}

<div style="height: 27px;">
  <svg xmlns="http://www.w3.org/2000/svg" [ngStyle]="{'width': width}" height="27" viewBox="0 0 27 27">
    <g id="remove" transform="translate(-912 -262.852)">
      <g id="Group_12" data-name="Group 12" transform="translate(912 263)">
        <circle id="Ellipse_13" data-name="Ellipse 13" cx="13.5" cy="13.5" r="13.5" transform="translate(0 -0.148)" fill="#ff7979"/>
      </g>
      <path id="Path_78" data-name="Path 78" d="M918.14,273.5h14.24" transform="translate(0.166 2.926)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
    </g>
  </svg>
</div>


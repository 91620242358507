<div class="header">
  <img [src]="startup.project_logo ?? '/assets/test-brand.png'" alt="image">
  <h1>{{startup.project_name}}</h1>
</div>

<div class="startup-header">
  <app-startup-header [startup]="startup"></app-startup-header>
  <div class="startup-links">
    <span [ngStyle]="{'display': notesPermission?'':'none'}" [routerLink]="['notes']"
    routerLinkActive #rln="routerLinkActive" [ngClass]="{'notes-active':rln.isActive}" translate>startup.notes.title</span>
    <div class="devider"></div>
    <span [ngStyle]="{'display': teamPermission?'':'none'}" [routerLink]="['team']"
    routerLinkActive #rlt="routerLinkActive" [ngClass]="{'team-active':rlt.isActive}" translate>startup.team.title</span>
    <div class="devider"></div>
    <span [ngStyle]="{'display': filesPermission?'':'none'}" [routerLink]="['files']"
    routerLinkActive #rlf="routerLinkActive" [ngClass]="{'files-active':rlf.isActive}" translate>startup.files.title</span>
    <div class="devider"></div>
    <span [ngStyle]="{'display': tasksPermission?'':'none'}" [routerLink]="['tasks']"
    routerLinkActive #rlta="routerLinkActive" [ngClass]="{'tasks-active':rlta.isActive}" translate>startup.tasks.title</span>
    <div class="devider"></div>
    <span [ngStyle]="{'display': stepsPermission?'':'none'}" [routerLink]="['steps']"
    routerLinkActive #rlts="routerLinkActive" [ngClass]="{'steps-active':rlts.isActive}" translate>startup.steps.title</span>
  </div>
  <div class="search-div">
    <input-field style="min-width: 300px;" isSearchInput="true" [ngClass]="{team: rlt.isActive}" placeholder="{{'common.search' | translate}}" *ngIf="!rlts.isActive" (valueChange)="OnSearchChangedInternal($event)"></input-field>
    <app-svg-email-message [height]="25" *ngIf="rlt.isActive" (click)="newEmailPopup.Open()"></app-svg-email-message>
    <div class="settings-wrap disableSelect">
      <svg-icon class="settings" src="/assets/startupDataboard/settings.svg" [svgStyle]="{'width.%':100, 'height.%':100}"
      (click)="OnSettingsClicked()" [ngClass]="{rotate: isSettingsClicked}" style="width: 25px; height: 25px; stroke: none;"></svg-icon>
      <div class="settings-dropdown" [ngClass]="{closed:!isSettingsClicked}">
        <div style="border-bottom: 1px solid var(--light);" translate>startup.history</div>
        <div style="cursor: pointer;" (click)="closeCompanyPopup.Open()" translate>startup.closeCompany.title</div>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>

<app-popup #closeCompanyPopup [isOpen]="false">
  <div class="close-popup">
    <div class="close-title" translate>
      startup.closeCompany.closeSure "{{startup.project_name}}" startup.closeCompany.create
    </div>
    <div class="close-description" translate>startup.closeCompany.undoWarning</div>
    <app-agree-disagree-buttons [agreeText]="'startup.closeCompany.close' | translate" [justifyContent]="'space-around'" [innerMargin]="'30'"
    (cancel)="closeCompanyPopup.Close()" (agree)="closeCompanyPopup.Close(); portfolioManagerPopup.Open()"></app-agree-disagree-buttons>
  </div>
</app-popup>

<app-popup #portfolioManagerPopup [isOpen]="false" [width]="499">
  <app-new-internal-member [title]="'PORTFOLIO MANAGER'" [agreeText]="'Choose'" [personList]="internalPersonService.portfolioManagers"
  (cancel)="portfolioManagerPopup.Close()" (add)="OnPortfolioManagerSelected()"></app-new-internal-member>
</app-popup>

<app-popup #newEmailPopup [width]="350">
  <app-new-email-message (cancel)="newEmailPopup.Close()" (emailEvent)="NewGroupMessage($event)"></app-new-email-message>
</app-popup>
import { InputField } from "../dashboard/InputField.model";
import { Language } from "../Language.model";

export class ProcessForm{
  id?:number;
  order?:number;
  formTitle:Language;
  description:Language;
  inputFileds:InputField[];

  constructor(id: number, order: number, formTitle:Language, description:Language, inputFileds:InputField[]){
    this.id = id;
    this.order = order;
    this.formTitle = formTitle;
    this.description = description;
    this.inputFileds = inputFileds;
  }

  GetName(lang: string): string {
    return this.formTitle.GetLanguage(lang);
  }

  GetDescription(lang: string): string {
    return this.description.GetLanguage(lang);
  }
}

export class ProcessStep {
  id?: number;
  order?: number;
  name: Language = new Language();
  isInternal: boolean = true; //TODO: értékadást kivenni backend implementáció után
  
  descriptionParagraphs: Language = new Language();
  forms: ProcessForm[] = []; //TODO: kitalálni inputfieldek hogy legyenek.

  constructor(id: number, order: number, name:Language, descriptionParagraphs:Language, forms:ProcessForm[]){
    this.id = id;
    this.order = order;
    this.name = name;
    this.descriptionParagraphs = descriptionParagraphs;
    this.forms = forms;
  }

  GetName(lang: string): string {
    return this.name.GetLanguage(lang);
  }

  GetDescription(lang: string): string {
    return this.descriptionParagraphs.GetLanguage(lang);
  }
}
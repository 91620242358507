<div class="container" *ngIf="show"> 
    <div class="background" (click)="Close()"></div>

    <div class="card-wrapper">
        <h1>{{'users.removeUser.deleteQues' | translate}} {{user.name}}?</h1>
        <a translate>common.deleteAssure</a>
        <div style="display: flex; justify-content: center; align-items: center; margin: 15px 0;">
          <re-captcha (resolved)="resolved($event)" (error)="onError($event)" errorMode="handled" required></re-captcha>
        </div>
        <div class="buttons">
            <app-agree-disagree-buttons [agreeDisabled]="captcha==null" [justifyContent]="'center'" [innerMargin]="30" [agreeColor]="'var(--dangerColor)'" [agreeText]="'common.save' | translate" (cancel)="Close()" (agree)="removeClick()"></app-agree-disagree-buttons>
        </div>
    </div>
</div>
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Log } from 'src/app/models/Log.model';
import { HistoryService } from 'src/app/services/support/history.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {

  sub:Subscription;

  constructor(
    private hist_svc: HistoryService,
  ) { }

  logList: Array<Log>;
  searchList:Log[] = [];
  maxNumber: number;
  pages: Array<number> = []
  perPage = 10;
  unitOptions:number[] = [10, 20, 30, 40, 50]
  pageId:number = 1;
  isSearchOn:boolean = false;

  @ViewChild('filterField') filterInput: ElementRef;
  ngOnInit(): void {
    this.hist_svc.getLogs(10, 1, "date desc", res => {
      this.logList = res.data.list;
      this.maxNumber = res.data.resultRows;
      this.pageCount(10);
    });
    document.body.style.overflowY = 'scroll';
  }

  pageCount(perPage: number){
    this.pages.length = 0
    var pageNumber = Math.ceil(this.maxNumber / perPage)

    for(let i = 0; i < pageNumber; i++){
      this.pages.push(i+1)
    } 
  }

  perPageChange(num:number){
    this.perPage = num;
    this.pageId = 1;
    this.isSearchOn = false;
    this.filterInput.nativeElement.value = null;
    this.hist_svc.getLogs(this.perPage, 1, "date desc", res => {
      this.logList = res.data.list;
      this.maxNumber = res.data.resultRows;
      this.pageCount(this.perPage);
    });
  }

  pageChange(page: number, callBack: () => void){
    this.hist_svc.getLogs(this.perPage, page, "date desc", res => {
      this.logList = res.data.list;
      callBack();
    });
  }

  IncrementPage(): void {
    if(this.pageId < this.pages.length) {
      this.pageId++;
      if(!this.isSearchOn) {
        this.pageChange(this.pageId, () => {
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        });
      } else {
        this.hist_svc.FilterHistory(this.perPage, this.pageId, this.filterInput.nativeElement.value, (res) => {
          this.logList = res.data.list;
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        });
      }
    }
  }

  DecrementPage(): void {
    if(this.pageId > 1) {
      this.pageId--;
      if(!this.isSearchOn) {
        this.pageChange(this.pageId, () => {
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        });
      } else {
        this.hist_svc.FilterHistory(this.perPage, this.pageId, this.filterInput.nativeElement.value, (res) => {
          this.logList = res.data.list;
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        });
      }
    }
  }

  OnInputFiled():void{
    if(this.filterInput.nativeElement.value != '') {
      this.pageId = 1;
      this.isSearchOn = true;
      this.hist_svc.FilterHistory(this.perPage, this.pageId, this.filterInput.nativeElement.value, (res) => {
        this.logList = res.data.list;
        this.maxNumber = res.data.resultRows;
        this.pageCount(this.perPage);
      });
    } else {
      this.perPageChange(this.perPage);
    }
  }

  getDate(date:Date): string {
    return date.toString().replace(/-/g, "/");
  }

  isMobileResolution(): boolean {
    if(window.innerWidth<=768){
      return true;
    } else {
      return false;
    }
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DataMatrix, DateScheduling, DropDownMenu, FileUploader, InputField, InputFieldType, LongTextLine, RadioButton, Repeater, Selector, ShortTextLine, TextEditor, VideoInput } from 'src/app/models/dashboard/InputField.model';
import { ProcessStep } from 'src/app/models/utility/InvestmentProcess.model';
import { StepsMakerService } from 'src/app/services/project/form/steps-maker.service';
import { CookieService } from 'ngx-cookie-service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ButtonType } from 'src/app/models/project/ButtonType.model';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDeleteDialogComponent, DeleteStepData, DeleteStepResult } from './confirm-delete-dialog/confirm-delete-dialog.component';

@Component({
  selector: 'app-steps-dashboard',
  templateUrl: './steps-dashboard.component.html',
  styleUrls: ['./steps-dashboard.component.scss']
})
export class StepsDashboardComponent implements OnInit, OnDestroy {

  @Input() editmode:boolean = false;
  @Input() usermode:boolean = false;
  steps: ProcessStep[] = [];
  selectedId: number = 0;
  isDotDotClicked: boolean = false;
  formSent: boolean = false;
  currentLang: string = "hu";
  subscriptions: Subscription[] = [];

  ButtonType = ButtonType;

  private idCount: number = 0;
  stepFormIds: string[] = [];

  // inputFieldTypes:InputField[] = [
  //   new ShortTextLine(0, 0),
  //   new LongTextLine(0, 0),
  //   new DropDownMenu(0, 0),
  //   new Selector(0, 0),
  //   new RadioButton(0, 0),
  //   new FileUploader(0, 0),
  //   new DataMatrix(0, 0),
  //   new Repeater(0, 0),
  //   new TextEditor(0, 0),
  //   new DateScheduling(0, 0),
  //   new VideoInput(0, 0)
  // ]

  constructor(
    private stepsService: StepsMakerService,
    private cookieService: CookieService,
    private translateService: TranslateService,
    private dialog: MatDialog
  ) {
    this.currentLang = this.cookieService.get("language") || "hu";
    this.stepsService.subject.subscribe((steps: ProcessStep[]) => {
      this.steps = steps;
      this.CollectIds();
    });
    this.stepsService.RefreshList();
    this.subscriptions.push(this.translateService.onLangChange.subscribe((params: LangChangeEvent) => {
      this.currentLang = params.lang;
    }));
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    })
  }

  resetForm() {

  }

  saveEditStep() {

  }

  CollectIds(): void {
    this.idCount = 0;
    this.stepFormIds = [];
    for (let i = 0; i < this.steps[this.selectedId].forms.length; i++) {
      this.stepFormIds.push('form-list-'+this.idCount);
      this.stepFormIds.push('h-'+this.idCount);
      this.idCount++;
    }
  }

  dropSideList(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.steps, event.previousIndex, event.currentIndex);
    if(this.selectedId == event.previousIndex) {
      this.selectedId = event.currentIndex;
    }
    this.stepsService.RearrangeList(event.previousIndex, event.currentIndex, this.steps[event.currentIndex].id);
  }

  dropFormArea(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.steps[this.selectedId].forms, event.previousIndex, event.currentIndex);
    this.stepsService.RearrangeSections(event.previousIndex, event.currentIndex, this.steps[this.selectedId].forms[event.currentIndex].id);
  }

  OnStepClicked(id: number) {
    this.selectedId = id;
    this.CollectIds();
    //this.isAdd = false;
    //this.stepEdit = false;
  }

  OnAddClicked(): void {
    this.stepsService.AddForm(() => {
      this.selectedId = 0;
      this.CollectIds();
      window.scrollTo(0, 0);
      //this.isAdd = true;
    });
  }

  nameCopy: string;
  OnNameDoubleClick(): void {
    if(this.editmode) {
      //this.isNameEdit = true;
      this.nameCopy = this.steps[this.selectedId].name[this.currentLang];
    }
  }
  
  OnNameEnter(): void {
    if(this.steps[this.selectedId].name[this.currentLang] != "") {
      this.stepsService.UpdateFormName(this.steps[this.selectedId].id, this.currentLang, this.steps[this.selectedId].name, () => {
        //this.isNameEdit = false;
        //this.isAdd = false;
      });
    } else {
      this.steps[this.selectedId].name[this.currentLang] = this.nameCopy;
      //this.isNameEdit = false;
      //this.isAdd = false;
    }
  }

  // OnEnter(event:KeyboardEvent): void {
  //   event.stopPropagation();
  // }

  openStepRemoveDialog(id: number, i: number) {
    var d: DeleteStepData = {
      id: id,
      i: i
    }
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      data: d,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((r: DeleteStepResult) => {
      if(r.result) {
        this.onStepRemoveClicked(r.data.id, r.data.i);
      }
    });
  }

  onStepRemoveClicked(id: number, i: number): void {
    this.steps.splice(i, 1);
    if(this.selectedId == i) {
      this.selectedId = 0;
    }
    this.stepsService.DeleteForm(id);
  }

  OnDoneClicked(): void {
    this.stepsService.FixSectionRearrangement();
    this.stepsService.UpdateFormContents(this.steps[this.selectedId], () => {
      //this.stepEdit = false;
    });
  }

  newSectionClicked(): void {
    this.stepsService.AddSection(this.steps[this.selectedId].id, () => {
      this.CollectIds();
    });
  }

  RemoveSectionClicked(id: number, i: number): void {
    this.stepsService.DeleteSection(id, () => {
      this.steps[this.selectedId].forms.splice(i, 1);
      this.CollectIds();
    });
  }

  RemoveRowClicked(id: number): void {
    this.stepsService.DeleteRow(id);
  }

  ClearAddmode(): void {
    //if(this.stepEdit) {
      this.OnDoneClicked();
    //}
    //this.isAdd = false;
    //this.isNameEdit = false;
  }

  SaveStep(): void {
    //TODO: store datas
  }

  sendStep(): void {
    if(this.CheckValidity()) {
      //TODO: send datas
      this.formSent = true;
    }
  }

  rejectStep() {
    //TODO: mit csinál
  }

  CheckValidity(): boolean {
    let valid: boolean = true;
    for(let form of this.steps[this.selectedId].forms) {
      for(let field of form.inputFileds) {
        if(field.required) {
          valid = valid && this.CheckValue(field);
        }
      }
    }
    return valid;
  }

  //Ezt ellenőrizni
  CheckValue(field:InputField): boolean {
    switch (field.type) {
      case InputFieldType.ShortTextLine: return (field as ShortTextLine).value != "";
      case InputFieldType.LongTextLine: return (field as LongTextLine).value != "";
      case InputFieldType.DropDownMenu: return !(field as DropDownMenu).selectedValue.isNone();
      case InputFieldType.RadioButton: return (field as RadioButton).selectedValue.isEmpty();
      case InputFieldType.Selector: return (field as Selector).selectedValues.size != 0;
      case InputFieldType.FileUploader: return (field as FileUploader).file.versions[0].fileName != "";
      case InputFieldType.DataMatrix: {
        let valid: boolean = false;
        (field as DataMatrix).values.forEach(value => {
          if(value.includes(true)) {
            valid = true;
          }
        })
        return valid;
      };
      case InputFieldType.Repeater: return (field as Repeater).values.length != 0;
      case InputFieldType.TextEditor: return (field as TextEditor).value != "";
      case InputFieldType.DateScheduling: return (field as DateScheduling).valueStart < (field as DateScheduling).valueEnd;
    }
  }

  isMobileResolution(): boolean {
    if(window.innerWidth<=768){
      return true;
    } else {
      return false;
    }
  }

  useLanguage(language: string): void {
    //if(!this.stepEdit) {
      this.currentLang = language;
    //}
  }

  isLanguage(language: string): boolean {
    return this.currentLang == language;
  }
}

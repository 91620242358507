import { Injectable } from '@angular/core';
import { BaseService } from '../utility/base.service';
import { Notification } from '../../models/Notification.model';
import { AuthService } from '../auth/auth.service';
import { ResultList } from 'src/app/models/utility/ResultList.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  seen: Date = new Date(localStorage.getItem('notifSeen') ?? '2000-01-01');

  constructor(
    protected baseService: BaseService,
    protected authService: AuthService,
  ) { }

  updateSeen() {
    this.seen = new Date();
    localStorage.setItem('notifSeen', this.seen.toString());
  }

  getNotifyList(perPage: number, page: number, orderBy: string, callBack: (value: Notification[]) => void) {
    return this.baseService.packageQuery<ResultList<Notification>, object>('auth','notify/getNotifyList', {
      perPage: perPage,
      page: page,
      orderBy: orderBy,
      userid: this.authService.currentUserValue.id
    }, (response) => {
      callBack(response.data.list);
    });
  }

  deleteNotify(id: number, perPage: number, page: number, orderBy: string, callBack: (value: Notification[]) => void) {
    return this.baseService.packageQuery<ResultList<Notification>, object>('auth','notify/deleteNotifyAndGetList', {
      notify_id: id,
      perPage: perPage,
      page: page,
      orderBy: orderBy,
      userid: this.authService.currentUserValue.id
    }, (response) => {
      callBack(response.data.list);
    });
  }
}

import { Inject, Injectable, Optional } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Configuration } from '../../configuration';
import { HttpClient, HttpErrorResponse} from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiResponse } from '../../models/utility/ApiResponse.model';
import Swal from 'sweetalert2';
import { API_KEY } from '../../variable';
import { Service } from '../../models/Service.model';
import { CommunicationService } from './communication.service';
import { Subscription, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  protected apiKey = '_not_initialized_yet_';
  public configuration = new Configuration();
  private errors: string[] = [];
  private sub: Subscription;
  private subject = new Subject();
  private errorsShow: boolean = false;

  public storage_token = "";

  constructor(
    protected router: Router,
    protected http: HttpClient,  @Inject(API_KEY) apiKey: string, @Optional() configuration: Configuration, 
    private commService: CommunicationService,
    private translateService: TranslateService,
  ) {
    if (apiKey) {
      this.apiKey = apiKey;
    }

    if (configuration) {
      this.configuration = configuration;
      this.apiKey = apiKey || this.apiKey;
    }
    
    this.sub = this.subject.subscribe(()=>{
      if(!this.errorsShow) {
        this.errorsShow = true;
        setTimeout(() => {
          this.ShowErrors();
          this.errorsShow = false;
        }, 1000);
      }
    });
  }

  getApiKey(): string {
    return this.apiKey;
  }

  async getService(service: string) {
    return await this.http
      .post<ApiResponse<Service>>('https://invexapi.exagent.group/auth-service/service/getService', {
        service_name: service,
        apikey: this.apiKey,
        usertoken: this.privUserToken
      }).toPromise();
  }

  async packageQuery<T, R>(service: string, url: string, params: R, callBack: (value: ApiResponse<T>) => void) {
    let params2: any = params;
    if(localStorage.getItem(service) === null || JSON.parse(localStorage.getItem(service)).expiry < Date.now()) {
      localStorage.removeItem(service);
      let now = new Date();
      now.setTime(now.getTime() + (30*60*1000));
      await this.getService(service).then(res => localStorage.setItem(service, JSON.stringify({ value: res.data['item'].service_base_url, expiry: now })));
    }
    params2.apikey = this.apiKey;
    params2.usertoken = this.privUserToken;

    let request = this.http.post<ApiResponse<T>>(JSON.parse(localStorage.getItem(service)).value + '/' + url, params2).pipe(
      catchError(this.handleError.bind(this))
    );

    request.subscribe(
      data => {
        // if (data.profile) {
          
        //  this.authService.setCurrentUser(data.profile)
        // } else {
        //   this.authService.setCurrentUser(null)
        // }

        if (data.error.length > 0) {
          data.error.forEach(element => {
            this.errors.push(element.toString());
          });
          this.subject.next();
          return;
        }

        if (data.messages.length > 0 && service != "auth") {
          data.messages.forEach(element => {
            Swal.fire(
              this.translateService.instant('message'),
              element.toString(),
              'info'
            );
          });
        }
        callBack(data);
      }
    );
  }

  async ShowErrors() {
    const errors = this.errors;
    const steps = [];
    for (let i = 1; i <= errors.length; i++) {
        steps.push(i);
    }

    if(errors.length == 1) {
      Swal.fire(
        this.translateService.instant('error.title'),
        errors[0],
        'error'
      );
    } else {
      const swalQueueStep = Swal.mixin({
        title: this.translateService.instant('error.title'),
        text: this.translateService.instant('error.text'),
        icon: 'error',
        progressSteps: steps,
        customClass: {
          popup: 'swal-queue-step-padding'
        }
      })
      
      let currentStep;
      for (currentStep = 0; currentStep < errors.length;) {
        const result = await swalQueueStep.fire({
          text: errors[currentStep],
          currentProgressStep: currentStep
        })
        
        if (result.value) {
          currentStep++;
        } else {
          break;
        }
      }
    }
    this.errors = [];
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      if (error.status === 403) {
        let errorURL = String(error.url).match(/login/) || 0;
        if(errorURL != 0){
          this.commService.callinvalidPopUp();
        }
        console.log('NAVIGATE TO LOGIN');
        this.storage_token = "";
        localStorage.removeItem('currentUser');
        this.router.navigate(["/auth/login"]);
      }
    }

    return throwError(
      'Something bad happened; please try again later.');
  };


  get privUserToken() {
    if(this.storage_token != "") {
      return this.storage_token;
    }
    
    const storage_token_local = localStorage.getItem('AUTH_TOKEN');
    if (storage_token_local) {
      return storage_token_local;
    }
    return '';
  }
}

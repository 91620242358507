import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Dashboard } from 'src/app/models/dashboard/Dashboard.model';

@Component({
  selector: 'app-edit-forms',
  templateUrl: './edit-forms.component.html',
  styleUrls: ['./edit-forms.component.css']
})
export class EditFormsComponent implements OnInit {
  
  @Input() dashboard:Dashboard;
  @Output() cancel:EventEmitter<void> = new EventEmitter<void>();
  @Output() agree:EventEmitter<void> = new EventEmitter<void>();
  selectedForm:number = 0;
  constructor() { }

  ngOnInit(): void {
  }

  OnSetColumns():void{
    this.selectedForm = 0;
  }

  OnSetStatuses():void{
    this.selectedForm = 1;
  }

  OnSetProjects():void{
    this.selectedForm = 2;
  }
}

<div style="height: 12.088px;">
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12.029" height="12.088" viewBox="0 0 12.029 12.088">
    <defs>
      <linearGradient id="linear-gradient" x1="2.088" y1="1" x2="0" y2="-1.387" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="red"/>
        <stop offset="0.202" stop-color="#ff00eb"/>
        <stop offset="0.478" stop-color="#0076ff"/>
        <stop offset="0.675" stop-color="#00ff76"/>
        <stop offset="0.833" stop-color="#ffe200"/>
        <stop offset="1" stop-color="red"/>
      </linearGradient>
      <linearGradient id="linear-gradient-2" x1="1.048" y1="1" x2="-0.162" y2="-0.188" xlink:href="#linear-gradient"/>
      <linearGradient id="linear-gradient-3" x1="1.048" y1="1" x2="0.5" y2="-0.445" xlink:href="#linear-gradient"/>
    </defs>
    <g id="tag-hover-color" transform="translate(-77 -58)">
      <g id="bucket" transform="translate(77 57.999)">
        <path id="Path_930" data-name="Path 930" d="M12.757,20H2.566a.566.566,0,0,0,0,1.132H12.757a.566.566,0,0,0,0-1.132Z" transform="translate(-1.293 -9.046)" fill="url(#linear-gradient)"/>
        <path id="Path_931" data-name="Path 931" d="M5.523,11.039A1.66,1.66,0,0,0,6.7,10.552L10.973,6.28a.566.566,0,0,0,0-.8L6.312.818a.566.566,0,0,0-.8,0L1.238,5.09a1.667,1.667,0,0,0,0,2.354l3.108,3.108a1.66,1.66,0,0,0,1.178.487Zm.388-9.021L9.772,5.879l-.066.066H2a.553.553,0,0,1,.036-.054Z" transform="translate(-0.752 -0.652)" fill="url(#linear-gradient-2)"/>
        <path id="Path_932" data-name="Path 932" d="M17,14.51a1.416,1.416,0,1,0,2.831,0A6.379,6.379,0,0,0,18.77,12.2a.44.44,0,0,0-.709,0A6.379,6.379,0,0,0,17,14.51Z" transform="translate(-7.801 -5.584)" fill="url(#linear-gradient-3)"/>
      </g>
    </g>
  </svg>
</div>

import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { SharedRoutingModule } from './shared-routing.module';

import { SideNavComponent } from './components/side-nav/side-nav.component';
import { NavSeparatorComponent } from './components/side-nav/nav-separator/nav-separator.component';
import { NavItemComponent } from './components/side-nav/nav-item/nav-item.component';
import { TopNavComponent } from './components/top-nav/top-nav.component';
import { TopNavNotificationComponent } from './components/top-nav/top-nav-notification/top-nav-notification.component';
import { TopNavTaskComponent } from './components/top-nav/top-nav-task/top-nav-task.component';
import { PopupComponent } from './components/popup/popup.component';

import { NgApexchartsModule } from 'ng-apexcharts';
import { DragScrollModule } from 'ngx-drag-scroll';
import { NgxEditorModule } from 'ngx-editor';
import { ImageCropperModule } from 'ngx-image-cropper';

import { NgErrorHandler } from '../common/ng-error-handler';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TickerComponent } from './components/ticker/ticker.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SvgArrowComponent } from './components/svgs/svg-arrow/svg-arrow.component';
import { SvgNotifyComponent } from './components/svgs/svg-notify/svg-notify.component';
import { SvgUserComponent } from './components/svgs/svg-user/svg-user.component';
import { SvgTasksComponent } from './components/svgs/svg-tasks/svg-tasks.component';
import { SvgLocationComponent } from './components/svgs/startupData/svg-location/svg-location.component';
import { SvgCategoryComponent } from './components/svgs/startupData/svg-category/svg-category.component';
import { SvgFundsizeComponent } from './components/svgs/startupData/svg-fundsize/svg-fundsize.component';
import { SvgStepComponent } from './components/svgs/startupData/svg-step/svg-step.component';
import { SvgCalendarComponent } from './components/svgs/startupData/svg-calendar/svg-calendar.component';
import { SvgWebComponent } from './components/svgs/startupData/svg-web/svg-web.component';
import { SvgLinkedinComponent } from './components/svgs/svg-linkedin/svg-linkedin.component';
import { SvgOnepagerComponent } from './components/svgs/startupData/svg-onepager/svg-onepager.component';
import { SvgBigplusComponent } from './components/svgs/startupData/team/svg-bigplus/svg-bigplus.component';
import { SvgPhoneComponent } from './components/svgs/svg-phone/svg-phone.component';
import { SvgMailComponent } from './components/svgs/startupData/team/svg-mail/svg-mail.component';
import { SvgAddinternalComponent } from './components/svgs/startupData/svg-addinternal/svg-addinternal.component';
import { SvgAddexternalComponent } from './components/svgs/startupData/svg-addexternal/svg-addexternal.component';
import { SvgEditcircleComponent } from './components/svgs/startupData/svg-editcircle/svg-editcircle.component';
import { SvgExternalComponent } from './components/svgs/startupData/steps/svg-external/svg-external.component';
import { SvgExtintComponent } from './components/svgs/startupData/steps/svg-extint/svg-extint.component';
import { SvgMainComponent } from './components/svgs/svg-main/svg-main.component';
import { SvgStatisticsComponent } from './components/svgs/navicons/svg-statistics/svg-statistics.component';
import { SvgDealflowComponent } from './components/svgs/navicons/svg-dealflow/svg-dealflow.component';
import { SvgPfmgmtComponent } from './components/svgs/navicons/svg-pfmgmt/svg-pfmgmt.component';
import { SvgNetworkComponent } from './components/svgs/navicons/svg-network/svg-network.component';
import { SvgInboxComponent } from './components/svgs/navicons/svg-inbox/svg-inbox.component';
import { SvgGeneralComponent } from './components/svgs/navicons/svg-general/svg-general.component';
import { SvgStepsComponent } from './components/svgs/navicons/svg-steps/svg-steps.component';
import { SvgUsersComponent } from './components/svgs/navicons/svg-users/svg-users.component';
import { SvgHistoryComponent } from './components/svgs/navicons/svg-history/svg-history.component';
import { SvgFileComponent } from './components/svgs/startupData/svg-file/svg-file.component';
import { SvgDonerComponent } from './components/svgs/svg-doner/svg-doner.component';
import { SvgTickComponent } from './components/svgs/svg-tick/svg-tick.component';
import { SvgMilestoneComponent } from './components/svgs/portfolio/svg-milestone/svg-milestone.component';
import { SvgTractionComponent } from './components/svgs/portfolio/svg-traction/svg-traction.component';
import { SvgEventComponent } from './components/svgs/portfolio/svg-event/svg-event.component';
import { SvgReportComponent } from './components/svgs/portfolio/svg-report/svg-report.component';
import { SvgAddActionComponent } from './components/svgs/portfolio/svg-add-action/svg-add-action.component';
import { SvgTodoComponent } from './components/svgs/portfolio/svg-todo/svg-todo.component';
import { SvgSubtaskTickComponent } from './components/svgs/portfolio/svg-subtask-tick/svg-subtask-tick.component';
import { SvgRemoveGrComponent } from './components/svgs/general/svg-remove-gr/svg-remove-gr.component';
import { SvgTagBadgeComponent } from './components/svgs/svg-tag-badge/svg-tag-badge.component';
import { SvgTagHoverColorComponent } from './components/svgs/svg-tag-hover-color/svg-tag-hover-color.component';
import { SvgTagHoverRemoveComponent } from './components/svgs/svg-tag-hover-remove/svg-tag-hover-remove.component';
import { SvgEditComponent } from './components/svgs/svg-edit/svg-edit.component';
import { SvgInternalComponent } from './components/svgs/steps/svg-internal/svg-internal.component';
import { SvgStepRemoveComponent } from './components/svgs/steps/svg-step-remove/svg-step-remove.component';
import { SvgStepAddComponent } from './components/svgs/steps/svg-step-add/svg-step-add.component';
import { SvgStepExternalComponent } from './components/svgs/steps/svg-step-external/svg-step-external.component';
import { SvgStepInternalExternalComponent } from './components/svgs/steps/svg-step-internal-external/svg-step-internal-external.component';
import { SvgTasksActiveComponent } from './components/svgs/svg-tasks-active/svg-tasks-active.component';
import { SvgUserActiveComponent } from './components/svgs/svg-user-active/svg-user-active.component';
import { SvgNotifyActiveComponent } from './components/svgs/svg-notify-active/svg-notify-active.component';
import { SvgEmailComponent } from './components/svgs/svg-email/svg-email.component';
import { SvgLogoutComponent } from './components/svgs/svg-logout/svg-logout.component';
import { SvgDeleteComponent } from './components/svgs/svg-delete/svg-delete.component';
import { SvgLeftArrowComponent } from './components/svgs/svg-left-arrow/svg-left-arrow.component';
import { SvgRightArrowComponent } from './components/svgs/svg-right-arrow/svg-right-arrow.component';
import { SvgEmailMessageComponent } from './components/svgs/svg-email-message/svg-email-message.component';
import { SvgEditPencilComponent } from './components/svgs/svg-edit-pencil/svg-edit-pencil.component';
import { SvgInvexLogoComponent } from './components/svgs/svg-invex-logo/svg-invex-logo.component';
import { SvgDownloadFileComponent } from './components/svgs/portfolio/svg-download-file/svg-download-file.component';
import { SvgRequestedFileComponent } from './components/svgs/portfolio/svg-requested-file/svg-requested-file.component';
import { SvgAmountComponent } from './components/svgs/statistics/svg-amount/svg-amount.component';
import { SvgPortfolioComponent } from './components/svgs/statistics/svg-portfolio/svg-portfolio.component';
import { SvgStatDealflowComponent } from './components/svgs/statistics/svg-stat-dealflow/svg-stat-dealflow.component';
import { SvgArrowBlueDownComponent } from './components/svgs/steps/svg-arrow-blue-down/svg-arrow-blue-down.component';
import { SvgXComponent } from './components/svgs/steps/svg-x/svg-x.component';
import { ArrangerComponent } from './components/svgs/arranger/arranger.component';
import { DropDownInputComponent } from './components/drop-down-input/drop-down-input.component';
import { SwiperModule } from 'swiper/angular';
import { TimelineTagComponent } from './components/timeline-tag/timeline-tag.component';
import { AgreeDisagreeButtonsComponent } from './components/agree-disagree-buttons/agree-disagree-buttons.component';
import { StartupHeaderComponent } from './components/startup-header/startup-header.component';
import { FilePickerComponent } from './components/file-picker/file-picker.component';
import { DropDownInputPopupComponent } from './components/drop-down-input-popup/drop-down-input-popup.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { HoverTagComponent } from './components/hover-tag/hover-tag.component';
import { NoteComponent } from '../components/pages/startup/notes/note/note.component';
import { NoteInputComponent } from '../components/pages/startup/notes/note-input/note-input.component';
import { NotesComponent } from '../components/pages/startup/notes/notes.component';
import { TeamComponent } from '../components/pages/startup/team/team.component';
import { FilesComponent } from '../components/pages/startup/files/files.component';
import { TasksComponent } from '../components/pages/startup/tasks/tasks.component';
import { PersonCardComponent } from '../components/pages/startup/team/person-card/person-card.component';
import { FileCardComponent } from '../components/pages/startup/files/file-card/file-card.component';
import { TaskCardComponent } from '../components/pages/startup/tasks/task-card/task-card.component';
import { StepsComponent } from '../components/pages/startup/steps/steps.component';
import { FadeScrollViewComponent } from '../components/pages/startup/tasks/task-card/fade-scroll-view/fade-scroll-view.component';
import { EditFileFormComponent } from '../components/pages/startup/files/edit-file-form/edit-file-form.component';
import { NewExternalMemberComponent } from '../components/pages/startup/team/new-external-member/new-external-member.component';
import { NewInternalMemberComponent } from '../components/pages/startup/team/new-internal-member/new-internal-member.component';
import { NewEmailMessageComponent } from '../components/pages/startup/team/new-email-message/new-email-message.component';
import { StepsDashboardComponent } from '../components/steps-maker/steps-dashboard/steps-dashboard.component';
import { StepFormAreaComponent } from '../components/steps-maker/steps-dashboard/step-form-area/step-form-area.component';
import { ShortTextLineComponent } from '../components/steps-maker/steps-dashboard/step-form-area/input-fields/short-text-line/short-text-line.component';
import { LongTextLineComponent } from '../components/steps-maker/steps-dashboard/step-form-area/input-fields/long-text-line/long-text-line.component';
import { DropDownMenuComponent } from '../components/steps-maker/steps-dashboard/step-form-area/input-fields/drop-down-menu/drop-down-menu.component';
import { RadioButtonComponent } from '../components/steps-maker/steps-dashboard/step-form-area/input-fields/radio-button/radio-button.component';
import { SelectorComponent } from '../components/steps-maker/steps-dashboard/step-form-area/input-fields/selector/selector.component';
import { FileUploaderComponent } from '../components/steps-maker/steps-dashboard/step-form-area/input-fields/file-uploader/file-uploader.component';
import { DataMatrixComponent } from '../components/steps-maker/steps-dashboard/step-form-area/input-fields/data-matrix/data-matrix.component';
import { RepeaterComponent } from '../components/steps-maker/steps-dashboard/step-form-area/input-fields/repeater/repeater.component';
import { TextEditorComponent } from '../components/steps-maker/steps-dashboard/step-form-area/input-fields/text-editor/text-editor.component';
import { DateSchedulingComponent } from '../components/steps-maker/steps-dashboard/step-form-area/input-fields/date-scheduling/date-scheduling.component';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { RecaptchaModule } from 'ng-recaptcha';
import {MatSelectModule} from '@angular/material/select';

import { RouterModule } from '@angular/router';
import { TopNavProfileCard } from './components/top-nav/profile-card/profile-card.component';
import { InputFieldComponent } from './components/input-field/input-field.component';
import { AppButtonComponent } from './components/app-button/app-button.component';
import { SvgDatabaseComponent } from './components/svgs/navicons/svg-database/svg-database.component';
import { SvgTemplatesComponent } from './components/svgs/navicons/svg-templates/svg-templates.component';
import { SvgDealfowOnepagerComponent } from './components/svgs/svg-dealflow-onepager/svg-dealflow-onepager.component';
import { SvgStartupEmailComponent } from './components/svgs/startupData/svg-email/svg-email.component';
import { SvgStartupLinkedinComponent } from './components/svgs/startupData/svg-linkedin/svg-linkedin.component';
import { SvgMenuComponent } from './components/svgs/startupData/svg-menu/svg-menu.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { AppCheckboxComponent } from './components/app-checkbox/app-checkbox.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SvgDatepickerComponent } from './components/svgs/svg-datepicker/svg-datepicker.component';
import {MatMenuModule} from '@angular/material/menu';
import { AppSelectComponent } from './components/app-select/app-select.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { TaskDialogComponent } from '../components/pages/startup/tasks/task-dialog/task-dialog.component';
import { AddTaskComponent } from '../components/pages/startup/tasks/add-task/add-task.component';
import { DateInputComponent } from './components/app-date/app-date.component';
import { SvgTrashCanComponent } from './components/svgs/app-svg-trashcan/app-svg-trashcan.component';
import { VideoInputComponent } from '../components/steps-maker/steps-dashboard/step-form-area/input-fields/video-input/video-input.component';
import { InputFieldBaseComponent } from '../components/steps-maker/steps-dashboard/step-form-area/input-fields/input-field-base.component';

// const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
//   direction: 'horizontal',
//   slidesPerView: 'auto',
//   spaceBetween: 10,
//   mousewheel: true,
// };

const COMPONENTS = [
  SideNavComponent,
  NavSeparatorComponent,
  NavItemComponent,
  TopNavComponent,
  TopNavNotificationComponent,
  TopNavTaskComponent,
  TopNavProfileCard,
  PopupComponent,
  TickerComponent,
  SvgArrowComponent,
  SvgNotifyComponent,
  SvgUserComponent,
  SvgTasksComponent,
  SvgLocationComponent,
  SvgOnepagerComponent,
  SvgStartupEmailComponent,
  SvgCategoryComponent,
  SvgFundsizeComponent,
  SvgStepComponent,
  SvgMenuComponent,
  SvgCalendarComponent,
  SvgWebComponent,
  SvgLinkedinComponent,
  SvgStartupLinkedinComponent,
  SvgDealfowOnepagerComponent,
  SvgBigplusComponent,
  SvgPhoneComponent,
  SvgMailComponent,
  SvgAddinternalComponent,
  SvgAddexternalComponent,
  SvgEditcircleComponent,
  SvgExternalComponent,
  SvgExtintComponent,
  SvgMainComponent,
  SvgStatisticsComponent,
  SvgDealflowComponent,
  SvgDatabaseComponent,
  SvgTemplatesComponent,
  SvgPfmgmtComponent,
  SvgNetworkComponent,
  SvgInboxComponent,
  SvgGeneralComponent,
  SvgStepsComponent,
  SvgUsersComponent,
  SvgHistoryComponent,
  SvgFileComponent,
  SvgDonerComponent,
  SvgTickComponent,
  SvgMilestoneComponent,
  SvgTractionComponent,
  SvgEventComponent,
  SvgReportComponent,
  SvgAddActionComponent,
  SvgTodoComponent,
  SvgSubtaskTickComponent,
  SvgRemoveGrComponent,
  SvgTagBadgeComponent,
  SvgTagHoverColorComponent,
  SvgTagHoverRemoveComponent,
  SvgEditComponent,
  SvgInternalComponent,
  SvgStepRemoveComponent,
  SvgStepAddComponent,
  SvgStepExternalComponent,
  SvgStepInternalExternalComponent,
  SvgTasksActiveComponent,
  SvgUserActiveComponent,
  SvgNotifyActiveComponent,
  SvgEmailComponent,
  SvgLogoutComponent,
  SvgDeleteComponent,
  SvgLeftArrowComponent,
  SvgRightArrowComponent,
  SvgEmailMessageComponent,
  SvgEditPencilComponent,
  SvgInvexLogoComponent,
  SvgDownloadFileComponent,
  SvgRequestedFileComponent,
  SvgAmountComponent,
  SvgPortfolioComponent,
  SvgStatDealflowComponent,
  SvgArrowBlueDownComponent,
  SvgXComponent,
  ArrangerComponent,
  DropDownInputComponent,
  TimelineTagComponent,
  AgreeDisagreeButtonsComponent,
  StartupHeaderComponent,
  FilePickerComponent,
  DropDownInputPopupComponent,
  HoverTagComponent,
  NoteComponent,
  NoteInputComponent,
  NotesComponent,
  TeamComponent,
  FilesComponent,
  TasksComponent,
  TaskDialogComponent,
  AddTaskComponent,
  SvgTrashCanComponent,
  PersonCardComponent,
  FileCardComponent,
  TaskCardComponent,
  StepsComponent,
  FadeScrollViewComponent,
  EditFileFormComponent,
  NewExternalMemberComponent,
  NewInternalMemberComponent,
  NewEmailMessageComponent,
  StepsDashboardComponent,
  StepFormAreaComponent,
  InputFieldBaseComponent,
  InputFieldComponent,
  AppButtonComponent,
  ShortTextLineComponent,
  LongTextLineComponent,
  DropDownMenuComponent,
  RadioButtonComponent,
  SelectorComponent,
  FileUploaderComponent,
  DataMatrixComponent,
  RepeaterComponent,
  TextEditorComponent,
  VideoInputComponent,
  DateSchedulingComponent,
  AppCheckboxComponent,
  SvgDatepickerComponent,
  AppSelectComponent,
  DateInputComponent
]

const MODULES = [
    DragScrollModule,
    NgxEditorModule,
    ImageCropperModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    AngularSvgIconModule,
    SwiperModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    DragDropModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    RecaptchaModule,
    RouterModule,
    MatMenuModule,
    MatSelectModule,
    NgxMatSelectSearchModule
]

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    SharedRoutingModule,
    MatCheckboxModule,
    ...MODULES,

  ],
  providers: [
    NgErrorHandler,
    ErrorHandler,
    MatDatepickerModule,
    MatNativeDateModule,
    { provide: ErrorHandler, useClass: NgErrorHandler },
    
  ],
  exports: [
    ...COMPONENTS,
    ...MODULES,
  ]
})
export class SharedModule { }

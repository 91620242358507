import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Utils } from 'src/app/common/utils';
import { StartupFilter, StartUp } from 'src/app/models/project/Startup.model';
import { StartupService } from 'src/app/services/project/startup.service';

@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.css']
})
export class ProjectCardComponent implements OnInit {

  @Input() startup:StartUp;
  filter: StartupFilter = new StartupFilter();

  constructor(
    private router: Router,
    public startupService: StartupService,
  ) {
    this.filter = startupService.filter;
  }

  ngOnInit(): void {
  }

  highlight(): string {
    if(this.filter.filter_search.length === 0) {
      return this.startup.project_name;
    }
    return this.startup.project_name.replace(new RegExp(this.filter.filter_search, "gi"), match => {
        return '<span class="highlight">' + match + '</span>';
    });
  }

  GetDateString(date:Date):string{
    return Utils.stringifyDate(new Date(date));
  }

  direct(){
    this.router.navigate(['startup', this.startup.project_id, 'notes']);
  }
}

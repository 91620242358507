import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { AuthService } from '../../../services/auth/auth.service';
import { Task } from 'src/app/models/Task.model';
import { TaskService } from 'src/app/services/project/tasks/tasks.service';
import { NotificationsService } from 'src/app/services/utility/notifications.service';
import { Router } from '@angular/router';
import { trigger, transition, animate, style, state } from '@angular/animations';
import { Notification } from 'src/app/models/Notification.model';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { TopNavProfileCard } from './profile-card/profile-card.component';

@Component({
  selector: 'app-top-nav',
  animations: [
    trigger('openClose', [
      state('open', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      state('closed', style({
        transform: 'translateX(200%)',
        opacity: 0
      })),
      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ]),
  ],
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit {

  testNotiList: Notification[] = [
    {
      id: 1,
      userid: 1,
      projectid: 1,
      project_menu:'project menu',
      title:'title 1',
      text:'You have a new task in your startup',
      date: new Date('2022-12-17T03:24:00'),
      notify_removed: false
    },
    {
      id: 1,
      userid: 1,
      projectid: 1,
      project_menu:'project menu',
      title:'title 2',
      text:'text 2',
      date: new Date('2022-12-21T03:24:00'),
      notify_removed: false
    }
  ];

  vw: number;
  top: boolean = true;
  currentLang: string = "hu";

  isTasksClicked:boolean=false;
  isNotificationsClicked:boolean=false;
  isUserClicked:boolean=false;

  flagColors: string[] = ['var(--light)', '#f7d733', 'rgb(255,121,121)'];
  flagColorsIndex: number = 0;
  allTasks: Task[] = [];
  allNotifs: Notification[] = [];

  @ViewChild(TopNavProfileCard) profileCard: TopNavProfileCard;

  constructor(
    private taskService: TaskService,
    private router: Router,
    private notifService: NotificationsService,
    private translate: TranslateService,
    private cookieService: CookieService
  ) {
    this.vw = window.innerWidth;
    this.notifService.getNotifyList(10, 0, "date desc", (res) => {
      // TODO: just for testing purposes
      this.allNotifs = this.testNotiList;
      // this.allNotifs = res;
    });
    this.taskService.subject.subscribe((tasks:Task[]) => {
      this.allTasks = tasks;
    });
    translate.setDefaultLang(this.cookieService.get("language") || "hu");
    this.currentLang = this.cookieService.get("language") || "hu";
  }

  ngOnInit(): void {

  }

  checkSeen(): boolean {
    if(!this.isNotificationsClicked && this.allNotifs.length > 0) {
      return this.notifService.seen < new Date(this.allNotifs[0].date);
    }
    return false;
  }

  SetFixed(): void {
    if(document.body.scrollHeight > document.body.clientHeight) {
      document.body.style.top = `-${window.scrollY}px`;
      (document.body.children[0].children[0] as HTMLElement).style.top = '0px';
      (document.body.children[0].children[1] as HTMLElement).style.top = '0px';
      document.body.style.position = 'fixed';
      document.body.style.overflowY = 'scroll';
    }
  }

  UnFix(): void {
    const scrollY = document.body.style.top;
    document.body.style.top = '';
    (document.body.children[0].children[0] as HTMLElement).style.top = '';
    (document.body.children[0].children[1] as HTMLElement).style.top = '';
    document.body.style.position = '';
    document.body.style.overflowY = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  }

  OnTasksClick(): void{
    //TODO: új taszk oldal navigálás
    //this.taskService.GetAllTasksBy(this.currentUser.id);
    this.isTasksClicked = true;
    this.isNotificationsClicked = false;
    this.isUserClicked = false;
    this.SetFixed();
    this.profileCard.handlePasswordReset();
  }

  OnNotificationClick(): void{
    this.notifService.getNotifyList(10, 0, "date desc", (res) => {
      // TODO: just for testing purposes
      this.allNotifs = this.testNotiList;
      // this.allNotifs = res;
      this.notifService.updateSeen();
    });
    this.isNotificationsClicked = true;
    this.isTasksClicked = false;
    this.isUserClicked = false;
    this.SetFixed();
    this.profileCard.handlePasswordReset();
  }

  OnUserClick(): void{
    this.profileCard.profileInit();
    this.isUserClicked = true;
    this.isTasksClicked = false;
    this.isNotificationsClicked = false;
    this.SetFixed();
    this.profileCard.handlePasswordReset();
  }

  CloseAll(): void {
    this.isTasksClicked = false;
    this.isNotificationsClicked = false;
    this.isUserClicked = false;
    this.UnFix();
    this.profileCard.handlePasswordReset();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    if(window.scrollY != 0) {
      this.top = false;
    } else {
      this.top = true;
    }
  }

  OnTaskTicked(index: number, tick: boolean, internal:boolean, id:number): void {
    //Később kijavítandó (?)
    /*if(internal) {
      if(tick) {
        transferArrayItem(this.filteredTaskListInternal, this.filteredTaskListInternalDone, id, 0);
        this.taskService.SortTasks(this.GetIds());
      } else {
        transferArrayItem(this.filteredTaskListInternalDone, this.filteredTaskListInternal, id, 0);
        this.taskService.SortTasks(this.GetIds());
      }
    } else {
      if(tick) {
        transferArrayItem(this.filteredTaskListExternal, this.filteredTaskListExternalDone, id, 0);
        this.taskService.SortTasks(this.GetIds());
      } else {
        transferArrayItem(this.filteredTaskListExternalDone, this.filteredTaskListExternal, id, 0);
        this.taskService.SortTasks(this.GetIds());
      }
    }*/
    this.taskService.TickTask(index, tick, () => {});
  }

  OnTaskFlagged(index: number, priority: number): void {
    this.taskService.FlagTask(index, priority, () => {});
  }

  OnNotifXed(index:number): void {
    this.notifService.deleteNotify(index, 10, 0, "date desc", (res) => {
      // TODO: just for testing purposes
      this.allNotifs = this.testNotiList;
      // this.allNotifs = res;
    });
  }

  OnGoToTask(id:number): void {
    this.router.navigate(['startup', id, 'tasks']);
    this.CloseAll();
  }

  OnGoToNotif(notification:Notification): void {
    this.router.navigate([notification.project.project_status ? 'portfolio' : 'startup', notification.projectid, notification.project_menu]);
    this.CloseAll();
  }

  useLanguage(language: string): void {
    this.cookieService.set("language", language)
    this.translate.use(language);
    this.currentLang = language;
  }

  isLanguage(language: string): boolean {
    return this.currentLang == language;
  }
}

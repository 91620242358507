import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { url } from 'inspector';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Utils } from 'src/app/common/utils';
import { ButtonType } from 'src/app/models/project/ButtonType.model';
import { User } from 'src/app/models/project/User.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PopupComponent } from '../../popup/popup.component';

@Component({
  selector: 'profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss']
})
export class TopNavProfileCard implements OnInit {

  currentName: string;
  currentPhone: string;
  currentEmail: string;
  currentUrl: string;
  currentImg: string;
  currentPos: string;
  isReadonly:boolean=true;
  editPassword:boolean=false;
  phoneRegex: string = '^\\+(9[976]\\d\\s*|8[987530]\\d\\s*|6[987]\\d\\s*|5[90]\\d\\s*|42\\d|3[875*]\\d\\s|2[98654321]\\d\\s*|9[8543210]\\s*|8[6421]\\s*|6[6543210]\\s*|5[87654321]\\s*|4[987654310]\\s*|3[9643210]\\s*|2[70]\\s*|7|1)(\\d\\s*){1,14}$';
  emailRegex: string = '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';
  linkedinRegex: string = '^https:\\/\\/[a-z]{2,3}\\.linkedin\\.com\\/.*$';
  passwordRegex: string = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{8,}$';
  validatedPassword1: boolean = false;
  validatedPassword2: boolean = false;
  phoneError:boolean = false;
  emailError:boolean = false;
  linkedinError:boolean = false;
  oldPasswordError:boolean = false;
  passwordError:boolean = false;
  matchError:boolean = false;

  readyToSend:boolean = false;
  cnfForm = {
    password: ""
  }

  initialValues;
  firstLoad: boolean = true;
  
  @ViewChild('addFilePopup') addFilePopup:PopupComponent;
  @ViewChild('confirmPopup') confirmPopup:PopupComponent;
  fileOriginalName:string = "";
  filePath:string;
  currentUser: User;
  formGroup: FormGroup;

  ButtonType = ButtonType;

  constructor (
    public formBuilder: FormBuilder,
    private authService: AuthService) 
    { }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
        phone: [this.currentPhone, [Validators.required, Validators.pattern(this.phoneRegex)]], 
        email: [this.currentEmail, [Validators.required, Validators.pattern(this.emailRegex)]],
        linkedin: [this.currentUrl, [Validators.required, Validators.pattern(this.linkedinRegex)]],
      });
      this.currentUser = this.authService.currentUserValue;
  }

  logout(){
    console.log("logout")
    // this.authService.logout();
  }

  profileInit(): void {
    this.currentName = this.currentUser.name;
    this.currentPhone = this.currentUser.phone;
    this.currentEmail = this.currentUser.email;
    this.currentUrl = this.currentUser.address;
    this.currentImg = this.currentUser.image_hash;
    this.currentPos = this.currentUser.jobTitle;
    this.filePath = this.currentUser.image_hash;
    this.formGroup.get("phone").setValue(this.currentPhone);
    this.formGroup.get("email").setValue(this.currentEmail);
    this.formGroup.get("linkedin").setValue(this.currentUrl);
    if(this.firstLoad) {
      this.initialValues = this.formGroup.value;
      this.firstLoad = false;
    }
  }

  filterByEmail(element): boolean {
    return (element.person.email == this.currentUser.email); 
  }

  OnPasswordClick(event): void {
    if(!this.editPassword) {
      this.handleReset();
      this.editPassword = true;
      this.formGroup.reset();
      this.formGroup.get("phone").setValidators([Validators.required, Validators.minLength(6)]);
      this.formGroup.get("email").setValidators([Validators.required, Validators.minLength(6)]);
      this.formGroup.get("linkedin").setValidators([Validators.required, Validators.minLength(6)]);
      this.formGroup.updateValueAndValidity();
    } else if(this.editPassword) {
      event.preventDefault();
      this.handlePasswordForm();
      if(this.readyToSend) {
        this.authService.changePassword({
          oldpassword: this.formGroup.get("phone").value,
          newpassword: this.formGroup.get("email").value
        }, (response) => {
          if(response.success == 1) {
            this.handlePasswordReset();
          }
        });
      }
    }
  }

  handleReset(): void {
    this.formGroup.get("phone").clearValidators();
    this.formGroup.get("email").clearValidators();
    this.formGroup.get("linkedin").clearValidators();
    this.phoneError = false;
    this.emailError = false;
    this.linkedinError = false;
    this.passwordError = false;
    this.matchError = false;
  }

  handlePasswordReset(): void {
    this.handleReset();
    this.editPassword = false;
    this.formGroup.reset(this.initialValues);
    this.formGroup.get("phone").setValidators([Validators.required, Validators.pattern(this.phoneRegex)]);
    this.formGroup.get("email").setValidators([Validators.required, Validators.pattern(this.emailRegex)]);
    this.formGroup.get("linkedin").setValidators([Validators.required, Validators.pattern(this.linkedinRegex)]);
    this.formGroup.updateValueAndValidity();
    this.readyToSend = false;
  }

  handleProfileForm(): void {
    if(this.currentUser.phone != this.formGroup.get("phone").value && this.formGroup.get("phone").valid) {
      this.authService.updateProfile({
        id: this.currentUser.id,
        name: this.currentUser.user_name,
        address: this.currentUser.address,
        phone: this.formGroup.get("phone").value,
        url: "",
        status: this.currentUser.status,
        removed: this.currentUser.removed,
        regdate: this.currentUser.regdate,
        lastlogin: this.currentUser.lastlogin
      }, (response) => {
          if(response.success == 1) {
            this.initialValues.phone = this.formGroup.get("phone").value;
            this.currentPhone = this.formGroup.get("phone").value;
          }
      });
      this.phoneError = false;
    } else if(this.currentUser.phone != this.formGroup.get("phone").value) {
      this.phoneError = true;
    }
    if(this.currentUser.email != this.formGroup.get("email").value && this.formGroup.get("email").valid) {
      this.confirmPopup.Open();
      this.emailError = false;
    } else if(this.currentUser.email != this.formGroup.get("email").value) {
      this.emailError = true;
    }
    if(this.currentUser.address != this.formGroup.get("linkedin").value && this.formGroup.get("linkedin").valid) {
      this.authService.updateProfile({
        id: this.currentUser.id,
        name: this.currentUser.user_name,
        address: this.formGroup.get("linkedin").value,
        phone: this.currentUser.phone,
        status: this.currentUser.status,
        removed: this.currentUser.removed,
        regdate: this.currentUser.regdate,
        lastlogin: this.currentUser.lastlogin
      }, (response) => {
          if(response.success == 1) {
            this.currentUrl = this.formGroup.get("linkedin").value;
          }
      });
      this.linkedinError = false;
    } else if(this.currentUser.address != this.formGroup.get("linkedin").value) {
      this.linkedinError = true;
    }
    if(this.formGroup.get("phone").valid && this.formGroup.get("email").valid && this.formGroup.get("linkedin").valid) {
      this.isReadonly = true;
      this.initialValues = this.formGroup.value;
    }
  }

  handlePasswordForm(): void {
    this.oldPasswordError = false;
    this.passwordError = false;
    this.matchError = false;
    if(this.formGroup.get("phone").invalid) {
      this.oldPasswordError = true;
    }
    if(this.formGroup.get("email").invalid || ! new RegExp(this.passwordRegex).test(this.formGroup.get("email").value)) {
      this.passwordError = true;
    }
    if(this.formGroup.get("linkedin").invalid || this.formGroup.get("email").value != this.formGroup.get("linkedin").value) {
      this.matchError = true;
    }
    if(!this.oldPasswordError && !this.passwordError && !this.matchError) {
      this.isReadonly = true;
      this.readyToSend = true;
    }
    this.validatedPassword1 = true;
    this.validatedPassword2 = true;
  }

  ValidatePassword():void{
    if(Utils.isEmptyString(this.formGroup.get("email").value)) {
      this.validatedPassword1 = false;
    } else {
      this.validatedPassword1 = true;
      if(new RegExp(this.passwordRegex).test(this.formGroup.get("email").value)){
        this.passwordError = false;
      } else {
        this.passwordError = true;
      }
    }

    if(Utils.isEmptyString(this.formGroup.get("linkedin").value)) {
      this.validatedPassword2 = false;
    } else {
      this.validatedPassword2 = true;
      if(new RegExp(this.passwordRegex).test(this.formGroup.get("linkedin").value) && this.formGroup.get("email").value == this.formGroup.get("linkedin").value){
        this.matchError = false;
      } else {
        this.matchError = true;
      }
    }
  }

  

  OnFileSelectInput(event:Event, nativeFileInput:HTMLInputElement): void {
    event.preventDefault();
    nativeFileInput.click();
  }

  imageChangedEvent: any = '';
  croppedImage: any = '';
  showCropper = false;

  OnFileInputchanged(event): void {
    const file = (event.target as HTMLInputElement).files[0];
    this.fileOriginalName = file.name;
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.filePath = event.base64;
  }

  imageLoaded() {
    this.showCropper = true;
  }

  AddFileSubmit(form: NgForm): void {
    if(form.valid){
      this.authService.changeImage({
        id: this.currentUser.id,
        image_hash: this.filePath.replace("data:image/png;base64,", "")
      }, (response) => {
          if(response.success == 1) {
            this.currentImg = this.filePath;
            this.currentUser.image_hash = this.filePath;
          }
      });
      form.resetForm();
      this.addFilePopup.Close();
    }
  }

  OnFormCancel(event:Event){
    event.preventDefault();
    this.addFilePopup.Close();
    this.confirmPopup.Close();
  }

  confirmChange(form:NgForm): void {
    if(form.valid) {
      this.authService.changeEmail({
        password: this.cnfForm.password,
        email: this.formGroup.get("email").value
      }, (response) => {
          if(response.success == 1) {
            this.currentEmail = this.formGroup.get("email").value;
          }
      });

      form.resetForm();
      this.confirmPopup.Close();
    }
  }
  
  OnDblClick(): void{
    this.isReadonly=false;
  }

  @HostListener('document:keydown.enter', ['$event'])
  onKeydownHandler(event) {
    event.preventDefault();
    if(!this.isReadonly && !this.editPassword) {
      this.handleProfileForm();
    }
  }
}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { EmailTemplate } from 'src/app/models/utility/EmailTemplate.model';
import { EmailService } from 'src/app/services/support/email.service';
import { PopupComponent } from 'src/app/shared/components/popup/popup.component';

@Component({
  selector: 'app-email-groups',
  templateUrl: './email-groups.component.html',
  styleUrls: ['./email-groups.component.css']
})
export class EmailGroupsComponent implements OnInit {

  emailGroups:string[] = [];

  constructor(
    private router:Router,
    public emailService:EmailService,
  ) { }

  ngOnInit(): void {
    this.RefreshGroups();
  }

  RefreshGroups(): void {
    this.emailGroups = [];
    this.emailService.GetEmailGroupList((response) => {
      response.data.list.forEach(group => {
        this.emailGroups.push(group.email_group_name);
      });
    });
  }

  @ViewChild('deletePopup') deletePopup:PopupComponent;
  OnRemoveClicked(idx:number):void{
    this.emailService.SelectGroup(idx, () => { this.deletePopup.Open(); });
  }

  @ViewChild('updatePopup') updatePopup:PopupComponent;
  OnEditClicked(idx:number):void{
    this.emailService.SelectGroup(idx, () => { this.updatePopup.Open(); });
  }

  OnPopupRemoveClicked():void{
    this.emailService.DeleteSelectedGroup();
  }

  @ViewChild('updateInput',{static:true}) input:ElementRef;
  OnUpdateSaveClicked():void{
    this.emailService.UpdateSelectedGroup((this.input.nativeElement as HTMLInputElement).value);
  }

  isMobileResolution(): boolean {
    if(window.innerWidth<=768){
      return true;
    } else {
      return false;
    }
  }
}

import { EventEmitter } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/project/User.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RecaptchaErrorParameters } from "ng-recaptcha";

@Component({
  selector: 'app-user-remove',
  templateUrl: './user-remove.component.html',
  styleUrls: ['./user-remove.component.css']
})
export class UserRemoveComponent implements OnInit {
  
  user:User;
  captcha: string = null;
  show = false;
  public event: EventEmitter<any> = new EventEmitter();

  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
  }

  SetFixed(): void {
    if(document.body.scrollHeight > document.body.clientHeight) {
      document.body.style.top = `-${window.scrollY}px`;
      (document.body.children[0].children[0] as HTMLElement).style.top = '0px';
      (document.body.children[0].children[1] as HTMLElement).style.top = '0px';
      document.body.style.position = 'fixed';
      document.body.style.overflowY = 'scroll';
    }
  }

  UnFix(): void {
    const scrollY = document.body.style.top;
    document.body.style.top = '';
    (document.body.children[0].children[0] as HTMLElement).style.top = '';
    (document.body.children[0].children[1] as HTMLElement).style.top = '';
    document.body.style.position = '';
    document.body.style.overflowY = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  }

  Close():void{
    this.show = false;
    this.UnFix();
  }

  removeUser(user:User){
    this.user = user;
    this.show = true;
    this.SetFixed();
  }

  removeClick(){
    this.authService.removeUser(this.user.id, this.captcha, () => {
      this.Close();
      this.event.emit();
    });
  }
  
  public resolved(captchaResponse: string): void {
    this.captcha = captchaResponse;
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  }
}

import { Injectable } from '@angular/core';
import { BaseService } from '../utility/base.service';
import { ApiResponse } from '../../models/utility/ApiResponse.model';
import { JWTToken } from '../../models/utility/JWTToken.model';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(
    protected baseService: BaseService
  ) { }

  getLogs(perPage: number, page: number, orderBy: string, callBack: (value: ApiResponse<JWTToken>) => void) {
    return this.baseService.packageQuery<JWTToken, object>('sup','/logs/getLogList', {
      perPage: perPage,
      page: page,
      orderBy: orderBy
    }, callBack);
  }

  FilterHistory(perPage: number, page: number, input:string, callBack: (value: ApiResponse<JWTToken>) => void){
    return this.baseService.packageQuery<JWTToken, object>('sup','/logs/getLogList', {
      perPage: perPage,
      page: page,
      orderBy: "date desc",
      filter: input
    }, callBack);
  }

}

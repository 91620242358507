<div style="height: 26px;">
  <svg id="traction" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
    <circle id="Ellipse_73" data-name="Ellipse 73" cx="13" cy="13" r="13" fill="#29487e"/>
    <path id="Path_507" data-name="Path 507" d="M194.275,143.811a.3.3,0,0,0,.07.6l.271-.031.276-.018h.006l.285-.026.284-.018.286-.013h.006l.289-.018.284-.005h.005l.293-.013.3-.005h.888l.882.018.289.013.286.018h.007l.284.013.284.024h.006l.28.018.557.049.027,0a.3.3,0,0,0,.027-.6l-.339-.031a12.447,12.447,0,0,0-.038-2.107,1.4,1.4,0,0,0-1.716-1.225l-.994.227h-.007a.684.684,0,0,1-.333,0h-.007l-.994-.227a1.4,1.4,0,0,0-1.721,1.225,12.58,12.58,0,0,0-.039,2.1h-.006Zm1.253-2.644a.825.825,0,0,1,.655-.126l1,.227a1.289,1.289,0,0,0,.612,0l1-.227a.8.8,0,0,1,.98.7,11.963,11.963,0,0,1,.037,2l-.194-.017H199.6l-.287-.013-.286-.018h-.007l-.3-.013h-.007l-.888-.018h-.908l-.3.005h-.007l-.293.013-.287.006h-.013l-.293.018-.288.013h-.007l-.271.018a11.868,11.868,0,0,1,.037-1.992A.831.831,0,0,1,195.528,141.167Zm0,0" transform="translate(-184.313 -130.538)" fill="#fff" stroke="#fff" stroke-width="0.1"/>
    <path id="Path_508" data-name="Path 508" d="M279.381-2.719A1.586,1.586,0,0,0,280.964-4.3a1.585,1.585,0,0,0-1.584-1.584A1.586,1.586,0,0,0,277.8-4.3,1.586,1.586,0,0,0,279.381-2.719Zm0-2.564a.981.981,0,0,1,.98.98.981.981,0,0,1-.98.98.981.981,0,0,1-.98-.98A.981.981,0,0,1,279.381-5.282Zm0,0" transform="translate(-266.18 12.411)" fill="#fff" stroke="#fff" stroke-width="0.1"/>
    <path id="Path_509" data-name="Path 509" d="M61.209,180.554a1.584,1.584,0,1,0-1.584-1.584A1.586,1.586,0,0,0,61.209,180.554Zm0-2.563a.98.98,0,1,1-.98.979A.981.981,0,0,1,61.209,177.991Zm0,0" transform="translate(-53.01 -166.66)" fill="#fff" stroke="#fff" stroke-width="0.1"/>
    <path id="Path_510" data-name="Path 510" d="M496.994,180.554a1.584,1.584,0,1,0-1.584-1.584A1.586,1.586,0,0,0,496.994,180.554Zm0-2.563a.98.98,0,1,1-.979.979A.981.981,0,0,1,496.994,177.991Zm0,0" transform="translate(-478.805 -166.66)" fill="#fff" stroke="#fff" stroke-width="0.1"/>
    <path id="Path_511" data-name="Path 511" d="M14.4,324.875a1.4,1.4,0,0,0-1.714-1.225l-1,.227H11.68a.683.683,0,0,1-.333,0h0l0,0-1-.227h0a1.4,1.4,0,0,0-1.714,1.225,12.724,12.724,0,0,0-.042,2.045l-.253.011H8.32l-.273.018-.277.013-.286.005H7.478l-.277.013H6.91l-.286.005H6.051l-.566-.013-.28-.013H5.2l-.284-.006-.277-.018H4.632l-.253-.011a12.559,12.559,0,0,0-.04-2.051,1.4,1.4,0,0,0-1.714-1.225l-1,.227a.011.011,0,0,0-.007,0,.676.676,0,0,1-.332,0H1.283l-1-.227h0a1.411,1.411,0,0,0-1.133.223,1.409,1.409,0,0,0-.58,1,12.624,12.624,0,0,0-.02,2.34.9.9,0,0,0,.738.811h0l1.007.17a.011.011,0,0,1,.007,0l1.02.145a.017.017,0,0,1,.009,0l1.02.114h.005l1.02.1h.009l1.02.062h.007l1.027.038h.008l1.027.013h.009l1.027-.018h.005l1.027-.038h.009l1.034-.068h.007l1.02-.1h.007l1.02-.119a.011.011,0,0,0,.007,0l1.014-.145a.012.012,0,0,0,.008,0l1.014-.17h0a.9.9,0,0,0,.727-.813A12.3,12.3,0,0,0,14.4,324.875ZM2.422,327.857l-1.013-.112L.4,327.6l-1-.168a.3.3,0,0,1-.243-.267,12.1,12.1,0,0,1,.018-2.228.806.806,0,0,1,.332-.573.8.8,0,0,1,.648-.127l1,.227a1.339,1.339,0,0,0,.306.037,1.32,1.32,0,0,0,.3-.037.006.006,0,0,0,.005,0l1-.227a.8.8,0,0,1,.98.7,11.974,11.974,0,0,1-.077,3.029l-.229-.015Zm6.1.15-1.02.039-1.02.018-1.018-.013-1.02-.037L4.268,328c.024-.157.044-.317.062-.476l.273.013.28.018H4.9l.286.005.28.013h.007l.573.013h.595l.286-.006h.3l.28-.013.286-.006h.007l.284-.013h.007l.273-.018.273-.013c.018.159.039.317.062.474Zm4.052-.425-1.007.145-1.013.119-1.012.093-.242.017a11.911,11.911,0,0,1-.075-3.019.8.8,0,0,1,.98-.7l1,.227a1.3,1.3,0,0,0,.612,0l1-.227a.8.8,0,0,1,.98.7,11.865,11.865,0,0,1,.02,2.208.293.293,0,0,1-.234.269Zm0,0" transform="translate(6.711 -309.532)" fill="#fff" stroke="#fff" stroke-width="0.1"/>
  </svg>
</div>

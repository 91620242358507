<div class="note-card">
    <div class="note-border" [ngStyle]="{'background-color': tagColor}"></div>
    <div style="display: flex; justify-content: space-between; align-items: center;" class="note-header">
        <div>{{note.title}}</div>
        <div style="display: flex; align-items: center; padding-bottom: 3px;">
            <div style="margin-right: 8px;">{{note.author.name}}</div>
            <img [src]="note.author.image_hash" [alt]="note.author.name">
        </div>
    </div>
    <div class="note-payload">
        {{note.payload}}
    </div>
    <div class="bottom-wrapper">
      <span id="date">{{dateString}}</span>
    <app-svg-menu [matMenuTriggerFor]="menu" style="cursor: pointer;"></app-svg-menu>
      <mat-menu #menu="matMenu">
        <button (click)="editNote()" mat-menu-item>Edit</button>
        <button (click)="removeNote()" mat-menu-item>Remove</button>
        <button mat-menu-item>Conver to task</button>
      </mat-menu>
    </div>
</div>

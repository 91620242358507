import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-svg-startup-linkedin',
  templateUrl: './svg-linkedin.component.html'
})
export class SvgStartupLinkedinComponent implements OnInit {

  @Input() height:number = 22.318;
  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-calendar',
  templateUrl: './svg-calendar.component.html'
})
export class SvgCalendarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-file',
  templateUrl: './svg-file.component.html',
  styles: [
  ]
})
export class SvgFileComponent implements OnInit {

  @Input() width:number= 29.989;
  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-tag-hover-color',
  templateUrl: './svg-tag-hover-color.component.html',
  styles: [
  ]
})
export class SvgTagHoverColorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DataMatrix, DateScheduling, DropDownMenu, FileUploader, InputField, InputFieldType, LongTextLine, RadioButton, Repeater, Selector, ShortTextLine, TextEditor, VideoInput } from 'src/app/models/dashboard/InputField.model';
import { ProcessForm } from 'src/app/models/utility/InvestmentProcess.model';
import { trigger, transition, animate, style, state } from '@angular/animations';
import { StepsMakerService } from 'src/app/services/project/form/steps-maker.service';
import { SvgArrowBlueDownComponent } from 'src/app/shared/components/svgs/steps/svg-arrow-blue-down/svg-arrow-blue-down.component';
import { Language } from 'src/app/models/Language.model';

@Component({
  selector: 'app-step-form-area',
  templateUrl: './step-form-area.component.html',
  styleUrls: ['./step-form-area.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        height: '*'
      })),
      state('closed', style({
        height: '0'
      })),
      transition('* => closed', [
        animate('0.3s')
      ]),
      transition('* => open', [
        animate('0.3s')
      ]),
    ]),
  ],
})
export class StepFormAreaComponent implements OnInit {

  constructor(
    private stepsService: StepsMakerService,
  ) { }

  @Input() form:ProcessForm;
  @Input() listId:string = '';
  @Input() selectedId:number = 0;
  @Input() stepFormIds:string[] = ['fields-list'];
  @Input() isStepEdit:boolean = false;
  @Input() currentLang:string;
  @Input() readonly:boolean = false;
  @Output() delete:EventEmitter<void> = new EventEmitter();
  @Output() deleteRow:EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('arrow', {static:true}) arrow: SvgArrowBlueDownComponent;

  InputFieldType = InputFieldType;

  inputFieldTypes:InputField[] = [
    new ShortTextLine(0, 0),
    new LongTextLine(0, 0),
    new DropDownMenu(0, 0),
    new Selector(0, 0),
    new RadioButton(0, 0),
    new FileUploader(0, 0),
    new DataMatrix(0, 0),
    new Repeater(0, 0),
    new TextEditor(0, 0),
    new DateScheduling(0, 0),
    new VideoInput(0, 0)
  ]

  ngOnInit(): void {
    this.arrow.Open();
  }

  OnEnter(event:KeyboardEvent):void{
    event.stopPropagation();
  }

  GetType(InputField):string{
    let str:string = typeof(InputField)
    return str;
  }

  onTypeChanged(val, i: number) {
    this.deleteRow.emit(this.form.inputFileds[i].id);
    var field = this.inputFieldTypes.find(x => x.type == val);
    var fieldOrder = this.form.inputFileds[i].order;
    this.stepsService.AddRow(this.form.id, field, (input) => {
      input.order = fieldOrder;
      this.stepsService.UpdateRowOrder(this.form.id, input);
      this.form.inputFileds.splice(i, 1, input);
    });
  }

  drop(event: CdkDragDrop<InputField[]>):void{
    event.previousContainer.data[event.previousIndex].order = event.currentIndex;
    if (event.previousContainer === event.container) {
      this.stepsService.UpdateRowOrder(this.form.id, event.previousContainer.data[event.previousIndex]);
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else if(event.previousContainer.id == 'fields-list') {
      this.stepsService.AddRow(this.form.id, event.previousContainer.data[event.previousIndex], (input) => {
        event.container.data.splice(event.currentIndex, 0, input);
      });
    } else {
      this.stepsService.UpdateGroupOfRow(this.selectedId, parseInt(event.container.id.substr(event.container.id.length - 1)), event.previousContainer.data[event.previousIndex], () => {});
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  onFormRemove():void{
    this.delete.emit();
  }

  // hDrop(event: CdkDragDrop<InputField[]>):void{
  //   event.previousContainer.data[event.previousIndex].order = this.form.inputFileds.length;
  //   if(event.previousContainer.id == 'fields-list') {
  //     this.stepsService.AddRow(this.form.id, event.previousContainer.data[event.previousIndex], (input) => {
  //       this.form.inputFileds.push(input);
  //     });
  //   } else {
  //     this.stepsService.UpdateGroupOfRow(this.selectedId, parseInt(event.container.id.substr(event.container.id.length - 1)), event.previousContainer.data[event.previousIndex], (input) => {
  //       this.form.inputFileds.push(input);
  //     });
  //   }
  //   this.arrow.Open();
  // }

  insertNewQuestion() {
    this.stepsService.AddRow(this.form.id, this.inputFieldTypes[0], (input) => {
      this.form.inputFileds.push(input);
    });
  }

  onHeaderClicked(event:Event):void{
    if(!(event.target instanceof HTMLInputElement)){
      this.arrow.Toggle();
    }
  }

  OnXClicked(id: number, i: number): void {
    this.form.inputFileds.splice(i, 1);
    this.deleteRow.emit(id);
  }

  OnNameChanged(input: InputField): void {
    this.stepsService.UpdateRowName(this.form.id, input);
  }

  OnDescriptionChanged(input: InputField): void {
    this.stepsService.UpdateRowDescription(this.form.id, input);
  }

  OnRequiredChanged(input: InputField): void {
    this.stepsService.UpdateRequired(this.form.id, input);
  }

  isMobileResolution(): boolean {
    if(window.innerWidth<=768){
      return true;
    } else {
      return false;
    }
  }
}
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { ReactiveFormsModule } from '@angular/forms';
import { SwiperModule } from 'swiper/angular';
import { HttpClient } from '@angular/common/http';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import { ColorPickerModule } from './color-picker/color-picker.module';

import { AuthGuard } from './auth.guard';
import { API_KEY } from './variable';
import { NgErrorHandler } from './common/ng-error-handler';
import { DragScrollModule } from 'ngx-drag-scroll';
import { NgxEditorModule } from 'ngx-editor';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';
import { RECAPTCHA_SETTINGS, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';

import { AuthService } from './services/auth/auth.service';
import { BaseService } from './services/utility/base.service';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { StartUpComponent } from './components/pages/startup/startup.component';
import { StartupNavigatorComponent } from './shared/startup-navigator/startup-navigator.component';
import { GeneralComponent } from './components/pages/general/general.component';
import { IndustriesComponent } from './components/pages/general/industries/industries.component';
import { DashboardEditorComponent } from './components/pages/general/dashboard-editor/dashboard-editor.component';
import { EmailSettingsComponent } from './components/pages/general/email-settings/email-settings.component';
import { EditFormsComponent } from './components/pages/general/dashboard-editor/edit-forms/edit-forms.component';
import { ColumnsFormComponent } from './components/pages/general/dashboard-editor/edit-forms/columns-form/columns-form.component';

import { StatusesFormComponent } from './components/pages/general/dashboard-editor/edit-forms/statuses-form/statuses-form.component';
import { ProjectsFormComponent } from './components/pages/general/dashboard-editor/edit-forms/projects-form/projects-form.component';

import { InquiryListComponent } from './inquiry/inquiry-list/inquiry-list.component';
import { InquiryDetailComponent } from './inquiry/inquiry-detail/inquiry-detail.component';
import { InquiryCategoryListComponent } from './inquiry/inquiry-category-list/inquiry-category-list.component';
import { InquiryCategoryComponent } from './inquiry/inquiry-category/inquiry-category.component';
import { InquiryReaderComponent } from './inquiry/inquiry-reader/inquiry-reader.component';
import { KnowledgebaseCategoryComponent } from './knowledgebase/knowledgebase-category/knowledgebase-category.component';
import { KnowledgebaseCategoryListComponent } from './knowledgebase/knowledgebase-category-list/knowledgebase-category-list.component';
import { KnowledgebaseDetailComponent } from './knowledgebase/knowledgebase-detail/knowledgebase-detail.component';
import { KnowledgebaseListComponent } from './knowledgebase/knowledgebase-list/knowledgebase-list.component';
import { KnowledgebaseReaderComponent } from './knowledgebase/knowledgebase-reader/knowledgebase-reader.component';
import { EmailTemplatePopupComponent } from './components/pages/general/dashboard-editor/email-template-popup/email-template-popup.component';
import { TagSettingsComponent } from './components/pages/general/tag-settings/tag-settings.component';
import { StepsMakerComponent } from './components/steps-maker/steps-maker.component';
import { HistoryComponent } from './components/pages/history/history.component';
import { UsersComponent } from './components/pages/users/users.component';
import { UserEditComponent } from './components/pages/users/user-edit/user-edit.component';
import { UserRemoveComponent } from './components/pages/users/user-remove/user-remove.component';
import { UserAdderComponent } from './components/pages/users/user-adder/user-adder.component';
import { EmailGroupsComponent } from './components/pages/general/email-groups/email-groups.component';
import { SharedModule } from './shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AuthModule } from './auth/auth.module';
import { ErrorCatchingInterceptor } from './common/http-interceptor';
import { NotificationsComponent } from './components/pages/notifications/notifications.component';
import { ProjectsComponent } from './components/pages/projects/projects.component';
import { ProjectCardComponent } from './components/pages/projects/project-card/project-card.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { ConfirmDeleteDialogComponent } from './components/steps-maker/steps-dashboard/confirm-delete-dialog/confirm-delete-dialog.component';


@NgModule({
  declarations: [
    AppComponent,
    StartUpComponent,
    
    StartupNavigatorComponent,
    GeneralComponent,
    IndustriesComponent,
    DashboardEditorComponent,
    EmailSettingsComponent,
    EditFormsComponent,
    ColumnsFormComponent,
    StatusesFormComponent,
    ProjectsFormComponent,
    InquiryDetailComponent,
    InquiryListComponent,
    InquiryCategoryListComponent,
    InquiryCategoryComponent,
    InquiryReaderComponent,
    KnowledgebaseCategoryComponent,
    KnowledgebaseCategoryListComponent,
    KnowledgebaseDetailComponent,
    KnowledgebaseListComponent,
    KnowledgebaseReaderComponent,
    EmailTemplatePopupComponent,
    TagSettingsComponent,
    StepsMakerComponent,
    ConfirmDeleteDialogComponent,
    HistoryComponent,
    UsersComponent,
    UserEditComponent,
    UserRemoveComponent,
    UserAdderComponent,
    EmailGroupsComponent,
    NotificationsComponent,
    ProjectsComponent,
    ProjectCardComponent,
    LandingPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DragScrollModule,
    HttpClientModule,
    FormsModule,
    AngularSvgIconModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: "en",
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    DragDropModule,
    MatNativeDateModule,
    MatInputModule,
    MatSelectModule,
    ColorPickerModule,
    ReactiveFormsModule,
    NgxEditorModule,
    SwiperModule,
    HttpClientModule,
    ImageCropperModule,
    SharedModule,
    RecaptchaModule,
    // StatisticsModule,
    // DealFlowModule,
    // DashboardsModule,
    // PortfolioManagementModule,
  ],
  providers: [
    AngularFirestoreModule,
    AuthGuard,
    NgErrorHandler,
    { provide: MAT_DATE_LOCALE, useValue: 'hu-HU' },
    {
      provide: API_KEY,
      useFactory: () => {
        return 'development_apikey';
      }
    },
    BaseService,
    { provide: ErrorHandler, useClass: NgErrorHandler },
    CookieService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: "6LdsXMMcAAAAAIvCWnChNGiniIi4-1_8syrk0w7o",
      } as RecaptchaSettings,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCatchingInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
import {Component, OnInit} from '@angular/core';
import Swal from 'sweetalert2';
import {InquiryService} from '../../services/support/inquiry.service';
import {InquiryItem} from '../../models/inquiry/InquiryItem.model';

@Component({
  selector: 'app-inquiry-list',
  templateUrl: './inquiry-list.component.html',
  styleUrls: ['./inquiry-list.component.css']
})
export class InquiryListComponent implements OnInit {

  constructor(
    private inquiryService: InquiryService
  ) { }

  public itemList: Array<InquiryItem>;

  ngOnInit(): void {
    this.inquiryService.getItemList(res => this.itemList = res.data.list);
  }

  delete(id) {
    Swal.fire({
      title: 'Törlésre készül !',
      text: 'Biztosan törli a cikket ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Igen, Törlöm'
    }).then((result) => {
      if (result.isConfirmed) {
        this.inquiryService.deleteItem(id, res => {
          this.inquiryService.getItemList(res => this.itemList = res.data.list);
        });
      }
    });
  }
}

import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appColorPicker]'
})
export class ColorPickerDirective {

  constructor(el: ElementRef) {}

  @HostListener('click') onMouseClick() {
    console.log("clicked");
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Utils } from 'src/app/common/utils';
import { trigger, transition, animate, style, state } from '@angular/animations';
import { Task } from 'src/app/models/Task.model';
import { StartUp } from 'src/app/models/project/Startup.model';
import { StartupService } from 'src/app/services/project/startup.service';

@Component({
  selector: 'app-top-nav-task',
  animations: [
    trigger('openClose', [
      state('open', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      state('closed', style({
        transform: 'translateX(200%)',
        opacity: 0,
        height: 0
      })),
      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ]),
  ],
  templateUrl: './top-nav-task.component.html',
  styleUrls: ['./top-nav-task.component.css']
})
export class TopNavTaskComponent implements OnInit {
  
  @Input() task: Task = new Task;
  @Input() show: boolean;
  startup: StartUp;

  @Output() gotoEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() tickChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() flagChange:EventEmitter<number> = new EventEmitter<number>();
  @Input() tick: boolean = false;
  flagColors: string[] = ['var(--light)', '#f7d733', 'rgb(255,121,121)'];
  dateString: string = '';

  constructor(
    private startupService: StartupService,
  ) { }

  ngOnInit(): void {
    this.dateString = Utils.stringifyDate(this.task.date);
    this.startupService.GetProject(this.task.startupId.toString(), (startup) => {
      this.startup = startup;
    });
  }

  OnFlagClicked(event:Event): void {
    event.stopPropagation();
    if(this.task.priority < this.flagColors.length-1) {
      this.task.priority++;
    } else {
      this.task.priority = 0;
    }
    this.flagChange.emit(this.task.priority);
  }

  OnDonerClicked(): void {
    this.tickChange.emit(this.tick);
  }

  ShowTick(): void {
    this.show = true;
  }

  HideTick(): void {
    this.show = false;
  }

  OpenTask(event): void {
    console.log(document.getElementsByClassName("flag")[0].contains(event.target));
    console.log(document.getElementsByClassName("flag")[0] == event.target);
    console.log(document.getElementsByClassName("flag")[0].contains(event.target) || document.getElementsByClassName("flag")[0] == event.target);
    if(!(document.getElementsByClassName("flag")[0].contains(event.target) || document.getElementsByClassName("flag")[0] == event.target) && !(window.innerWidth<=768)) {
      this.gotoEvent.emit(true);
    }
  }

  longPress:boolean;
  TouchStart(event): void {
    this.longPress = true;
    setTimeout(() => {
      if(this.longPress) {
        this.show = true;
        setTimeout(() => {
          this.show = false;
        }, 1000);
      } else {
        if(!document.getElementsByClassName("flag")[0].contains(event.target) || !document.getElementsByClassName("flag")[0] == event.target) {
          this.show = false;
          this.gotoEvent.emit(true);
        }
      }
    }, 200);
  }
  
  TouchEnd(): void {
    this.longPress = false;
  }

}

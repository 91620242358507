import { Component, Input, OnInit, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FileUploader } from 'src/app/models/dashboard/InputField.model';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.css']
})
export class FileUploaderComponent implements OnInit {

  constructor() { }

  @Input() fileUploader:FileUploader;
  @Input() currentLang:string;
  @Input() editmode:boolean = false;
  @Input() readonly:boolean = false;
  @Output() nameChanged:EventEmitter<string> = new EventEmitter<string>();
  @Output() descriptionChanged:EventEmitter<string> = new EventEmitter<string>();
  @Output() requiredChanged:EventEmitter<boolean> = new EventEmitter<boolean>();
  isLabelEdit:boolean = false;
  isDescriptionEdit:boolean = false;
  pickerValue:string = 'Select file...';

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.editmode && changes.editmode.currentValue == false) {
      this.isLabelEdit = false;
      this.isDescriptionEdit = false;
    }
  }

  imgURL:string = '';
  fileType:string = '';
  OnFileInputchanged(fileInput:HTMLInputElement):void{
    const file = fileInput.files[0];
    this.fileType = file.type;
    const reader = new FileReader();
    reader.onload = () => {
      this.imgURL = reader.result as string;
    }
    reader.readAsDataURL(file);
    this.pickerValue = fileInput.value.split(/(\\|\/)/g).pop();
    this.fileUploader.file.versions=[{
      version:'v1', fileName: this.pickerValue, uploadDate: new Date(), path: fileInput.value
    }];
  }

  labelCopy: string;
  OnLabelDoubleClick():void{
    if(this.editmode) {
      this.isLabelEdit = true;
      this.labelCopy = this.fileUploader.name[this.currentLang];
    }
  }

  OnLabelEnter():void{
    if(this.fileUploader.name[this.currentLang] != "") {
      this.nameChanged.emit();
    } else {
      this.fileUploader.name[this.currentLang] = this.labelCopy;
    }
    this.isLabelEdit = false;
  }

  descriptionCopy: string;
  OnDescriptionDoubleClick():void{
    if(this.editmode) {
      this.isDescriptionEdit = true;
      this.descriptionCopy = this.fileUploader.small[this.currentLang];
    }
  }

  OnDescriptionEnter():void{
    this.descriptionChanged.emit();
    this.isDescriptionEdit = false;
  }

  OnRequiredClicked():void{
    this.requiredChanged.emit();
  }
}

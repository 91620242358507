<div class="wrapper">
  <div class="header-row">
    <div translate>general.basic.title</div>
    <div translate>general.basic.text</div>
    <div *ngIf="!isMobileResolution()" translate>general.basic.action</div>
  </div>
  <div class="data-row" *ngFor="let email of emails; let i = index">
    <div>{{email.title}}</div> <div class="text">{{email.text}}</div>
    <div class="buttons">
      <button class="btn edit" (click)="OnEditClicked(i);" translate>common.edit</button>
      <button class="btn remove" (click)="OnRemoveClicked(i);" translate>common.remove</button>
    </div>
  </div>
</div>


<app-popup #deletePopup [width]="260">
  <p class="popup-main-text"
  *ngIf="emailService.selectedEmailId!=-1" translate>common.deleteQues<br>"{{emailService.selectedEmail.title}}"?</p>
  <p class="popup-secondary-text" translate>common.deleteAssure</p>
  <app-agree-disagree-buttons [agreeColor]="'var(--dangerColor)'" [agreeText]="'common.remove' | translate" [cancelText]="'common.cancel' | translate"
  (cancel)="deletePopup.Close()" (agree)="OnPopupRemoveClicked(); deletePopup.Close()"></app-agree-disagree-buttons>
</app-popup>

<app-email-template-popup #emailPopup [isNew]="false" (agree)="OnPopupSaveClicked($event)"></app-email-template-popup>


<div class="header">
  <h1 translate>users.name</h1>
  <app-drop-down-input class="dropdown fst" #unitDropDown [text]="'common.units' | translate" [widthString]='"100px"' [contentHeight]="unitOptions.length*24">
    <div *ngFor="let num of unitOptions" class="dropdown-item">
      <div (click)="perPageChange(num); unitDropDown.Close()">{{num}}</div>
    </div>
  </app-drop-down-input>
  <app-drop-down-input class="dropdown snd" #authDropDown [text]="'common.authority' | translate" [contentHeight]="authorities.length*24" [widthString]="width!=-1?width+'px':'200px'">
    <div *ngFor="let authority of authorities" class="dropdown-item">
      <div (click)="authChange(authority); authDropDown.Close()">{{authority}}</div>
    </div>
  </app-drop-down-input>
  <input type="text" placeholder="{{'common.search' | translate}}" class="search-input" #filterField (input)="OnInputField()">
  <app-svg-add-action style="box-shadow: var(--defaultBoxShadow); margin-left: 10px; cursor: pointer;" class="shadow" (click)="addUser()"></app-svg-add-action>
</div>

<app-user-edit></app-user-edit>
<app-user-remove></app-user-remove>
<app-user-adder></app-user-adder>

<div class="wrapper">
  <div class="header-row">
    <div *ngIf="!isMobileResolution()"></div>
    <div *ngIf="!isMobileResolution()"></div>
    <div translate>users.header.name</div>
    <div *ngIf="isMobileResolution()">AUTH</div>
    <div translate *ngIf="!isMobileResolution()">common.authority</div>
    <div *ngIf="isMobileResolution()">REG</div>
    <div translate *ngIf="!isMobileResolution()">users.header.registration</div>
    <div translate>users.header.active</div>
    <div *ngIf="!isMobileResolution()" translate>users.header.action</div>
  </div>
  <div class="data-row" *ngFor="let user of users; let i = index">
    <div *ngIf="!isMobileResolution()"><img class="profile-pic" [src]="user.image_hash" [alt]="user.id"></div> <div>{{user.name}}</div> <div>{{userAuthority}}</div> <div>{{getDate(user.regdate)}}</div> <div class="active" *ngIf="user.status==1">Active</div> <div class="inactive" *ngIf="user.status!=1">Inactive</div> <div class="buttons">
      <button class="button blue" (click)="editUser(user)" translate>common.edit</button>
      <button class="button pink" (click)="removeUser(user)" translate>common.remove</button>
    </div>
  </div>
</div>
<div *ngIf="pages.length > 1" class="arrows">
  <app-svg-left-arrow *ngIf="pageId>1" (click)="DecrementPage()"></app-svg-left-arrow>
  <div class="num">{{pageId}}. {{'common.page' | translate}}</div>
  <app-svg-right-arrow *ngIf="pageId<pages.length" (click)="IncrementPage()"></app-svg-right-arrow>
</div>
<div class="container-fluid pl-lg-0 data-content reader">
  <div class="row mt-3">
    <div class="pt-1" [class.col-lg-3]="catMenu" *ngIf="catMenu">
      <h5>Kategória</h5>
      <div class="card b-box category-box small-scroll">
        <div class="card-body">
          <ul class="navbar-nav">
            <li class="nav-item" style="cursor: pointer">
              <a class="nav-link" (click)="selectCategory(0)" [class.active-catmenu]="selectedCategory == 0">Minden cikk</a>
            </li>
            <li *ngFor="let cat of categoryList " class="nav-item" style="cursor: pointer">
              <a [class.active-catmenu]="cat.inc_id == selectedCategory" class="nav-link" (click)="selectCategory(cat.inc_id)"> {{cat.inc_name}}
                <span *ngIf="cat.inc_tag !='' " class="badge cat-tag">{{cat.inc_tag}}</span>
              </a>
            </li>
          </ul>

        </div>
      </div>
    </div>
    <div class="col-12" [class.col-lg-9]="catMenu">
      <h5 class="d-flex align-items-center">
        <a class="btn btn-link p-0" style="color: #707070" (click)="toggleCatMenu()" ngbTooltip="Kategória menü"><i class="fas fa-bars text-grey"></i></a>
        <span class="mx-3">Cikkek</span>
        <div class="input-group rounded">
          <input #searcField type="search" class="form-control rounded" placeholder="Keresés..." (keydown.enter)="search($event.target.value)"/>
          <span (click)="search(searcField.value)" style="cursor: pointer" class="input-group-text border-0" id="search-addon"><i class="fas fa-search"></i></span>
        </div>
      </h5>
      <ng-container *ngIf="!selectedItem then category else items"></ng-container>
    </div>
  </div>
</div>

<ng-template #items>
  <div class="card b-box">
    <div class="card-body">
      <div class="m-0 d-flex justify-content-end align-items-center">
        <span *ngIf="selectedItem.in_tag !=''" class="badge item-tag small mb-0">{{selectedItem.in_tag}}</span>
        <span class="mb-0 mt-1 mx-1 small" style="margin-bottom: 5px;">{{selectedItem.update_user.name}}</span>
        <span class="mb-0 mt-1 small" style="margin-bottom: 5px;">{{selectedItem.in_update }}</span>

      </div>
      <p class="item-label">{{selectedItem.in_name}}</p>
      <p class="item-shortdesc">{{selectedItem.in_shortdesc}}</p>
      <div [innerHTML]="selectedItem.in_desc"></div>
    </div>
  </div>
</ng-template>

<ng-template #category>
  <ng-container *ngIf="inIteimList?.length > 0">
    <div class="row">
      <div class="col-sm-6 col-xl-3 pl-2 pr-2" *ngFor="let item of inIteimList">
        <div class="b-box p-3">
          <div class="m-0 d-flex justify-content-between align-items-center">
            <span *ngIf="item.in_tag !=''" class="badge item-tag small">{{item.in_tag}}</span>
            <span class="small" style="margin-bottom: 5px;">{{item.name}}</span>
            <span class="small" style="margin-bottom: 5px;">{{item.in_update }}</span>
          </div>

          <a (click)="read(item.in_id)" class="border btn d-block pb-4 pl-3 pr-3 pt-3" style="height: 200px;overflow:hidden;">
            <h3 class="font-weight-normal h5 text-dark">{{item.in_name}}</h3>
            <p>{{item.in_shortdesc}}</p>
          </a>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<div class="header">
  <input *ngIf="isLabelEdit" class="label-input" type="text" [placeholder]="labelCopy" [(ngModel)]="selector.name[currentLang]" (keydown.enter)="OnLabelEnter()">
  <div class="label" *ngIf="!isLabelEdit && selector!=undefined" (dblclick)="OnLabelDoubleClick();">{{selector.GetName(currentLang)}}<span>{{selector.required?'*':''}}</span></div>
  <app-svg-add-action [width]="18" *ngIf="editmode" (click)="OnAddClicked()"></app-svg-add-action>
  <div class="important-container" *ngIf="editmode">
    <input type="checkbox" id="important" name="important" [(ngModel)]="selector.required" (change)="OnRequiredClicked()">
    <label class="importantLabel disableSelect">Important</label>
  </div>
</div>
<div class="description">
  <input *ngIf="isDescriptionEdit" class="label-input" type="text" [placeholder]="descriptionCopy" [(ngModel)]="selector.small[currentLang]" (keydown.enter)="OnDescriptionEnter()">
  <div class="desc" *ngIf="!isDescriptionEdit && selector!=undefined" (dblclick)="OnDescriptionDoubleClick();">{{editmode?(selector.GetDescription(currentLang)==""?('steps.input.doubleClick' | translate):selector.GetDescription(currentLang)):selector.GetDescription(currentLang)}}</div>
</div>
<div class="selector-wrapper">
  <div class="radio" *ngFor="let radio of selector.options; let i = index" [style.flex-grow]="selector.options.length">
    <input type="checkbox" [id]="'se'+i+selectorId" [name]="'se'" (change)="OnCheckClicked(i, $event.target)" [disabled]="readonly">
    <input class="text-input" type="text" [placeholder]="radio.GetValue(currentLang)" *ngIf="isOptionEdit==i" (keydown.enter)="OnOptionEnter($event, i)">
    <label [for]="'se'+i+selectorId" *ngIf="isOptionEdit!=i && selector!=undefined" (dblclick)="OnOptionDoubleClick(i);">{{radio.GetValue(currentLang)}}</label>
    <app-svg-step-remove [width]="18" *ngIf="editmode" (click)="OnRadioRemoveClicked(i)"></app-svg-step-remove>
  </div>
  <div class="other">
    <input type="checkbox" [id]="'rbo'" [name]="'rb'" [(ngModel)]="isOther" (change)="OnOtherCheckChanged()" [disabled]="readonly">
    <input #textinput class="text-input" type="text" style="display: block; width: 100%;"
    placeholder="Other" [disabled]="!isOther" [(ngModel)]="selector.otherValue.value[currentLang]">
  </div>
</div>

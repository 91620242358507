<div class="notif-container disableSelect" (click)="OpenNotif()" (touchstart)="TouchStart()" (touchend)="TouchEnd()" [@openClose]="ex ? 'closed' : 'open'">
  <div class="notification-header">
    <span class="notification-title" translate>notifications.name</span>
    <img class="notification-close" src="/assets/top-nav/notification/exit_x.svg" (click)="OnDonerClicked()">
  </div>
  <div>
    <span class="notification-text">{{notification.text.substring(0, 100)}}</span>
  </div>
  <div class="notification-footer">
    <span class="notification-navigate" translate>notifications.tasks</span>
    <img class="notification-arrow" src="/assets/top-nav/notification/right_arrow.svg">
    <span class="notification-time">{{notification.date.getHours() + ':' + notification.date.getMinutes()}}</span>
  </div>
</div>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-bigplus',
  templateUrl: './svg-bigplus.component.html'
})
export class SvgBigplusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-datepicker',
  templateUrl: './svg-datepicker.component.html'
})
export class SvgDatepickerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
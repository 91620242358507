import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/project/User.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserAdderComponent } from './user-adder/user-adder.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { UserRemoveComponent } from './user-remove/user-remove.component';
import { Utils } from 'src/app/common/utils';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  users: Array<User> = [];
  authorities:string[] = ["Investment Manager", "TO-DO"]
  //TODO: A usertől kell lekérni
  userAuthority:string = "TO-DO";
  maxNumber: number;
  pages: Array<number> = []
  perPage = 10;
  unitOptions:number[] = [10, 20, 30, 40, 50]
  pageId:number = 1;
  isSearchOn:boolean = false;
  width: number = -1;

  @ViewChild('filterField') filterInput: ElementRef;
  @ViewChild(UserEditComponent) userEditor: UserEditComponent;
  @ViewChild(UserRemoveComponent) userRemover: UserRemoveComponent;
  @ViewChild(UserAdderComponent) userAdder: UserAdderComponent;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.authService.getUserList(10, 1, res => {
      this.users = res.data.list;
      this.maxNumber = res.data.count;
      this.pageCount(10);
    });
    document.body.style.overflowY = 'scroll';
  }

  ngAfterViewInit() {
    if(window.innerWidth <= 768) {
      this.width = (document.getElementsByClassName("search-input")[0] as HTMLElement).offsetWidth;
    }
  }

  pageCount(perPage: number){
    this.pages.length = 0
    var pageNumber = Math.ceil(this.maxNumber / perPage)

    for(let i = 0; i < pageNumber; i++){
      this.pages.push(i+1)
    }
  }

  perPageChange(num:number){
    this.perPage = num;
    this.pageId = 1;
    this.isSearchOn = false;
    this.filterInput.nativeElement.value = null;
    this.authService.getUserList(this.perPage, 1, res => {
      this.users = res.data.list;
      this.maxNumber = res.data.count;
      this.pageCount(this.perPage);
    });
  }

  pageChange(page: number, callBack: () => void){
    this.authService.getUserList(this.perPage, page, res => {
      this.users = res.data.list;
      callBack();
    });
  }

  IncrementPage(): void {
    if(this.pageId < this.pages.length) {
      this.pageId++;
      if(!this.isSearchOn) {
        this.pageChange(this.pageId, () => {
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        });
      } else {
        this.authService.searchUserList(this.perPage, this.pageId, this.filterInput.nativeElement.value, res => {
          this.users = res.data.list;
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        });
      }
    }
  }

  DecrementPage(): void {
    if(this.pageId > 1) {
      this.pageId--;
      if(!this.isSearchOn) {
        this.pageChange(this.pageId, () => {
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        });
      } else {
        this.authService.searchUserList(this.perPage, this.pageId, this.filterInput.nativeElement.value, res => {
          this.users = res.data.list;
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        });
      }
    }
  }

  OnInputField():void{
    if(this.filterInput.nativeElement.value != '') {
      this.pageId = 1;
      this.isSearchOn = true;
      this.authService.searchUserList(this.perPage, 1, this.filterInput.nativeElement.value, res => {
        this.users = res.data.list;
        this.maxNumber = res.data.count;
        this.pageCount(this.perPage);
      });
    } else {
      this.perPageChange(this.perPage);
    }
  }

  authChange(authority:string){
    //this.pageId = 1;
    //this.isSearchOn = true;
    //this.authService.searchUserList(this.perPage, 1, this.filterInput.nativeElement.value, res => {
    //  this.users = res.data.list;
    //});
    //this.filteredUsers = this.users.filter(x=>this.userAuthority.toLowerCase().match(authority.toLowerCase())!=null);
  }

  editUser(user: User){
    this.userEditor.event.subscribe(() => {
      let currentUrl = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([currentUrl]);
        });
    })
    this.userEditor.editUser(user, this.authorities.indexOf(this.userAuthority));
  }

  removeUser(user: User){
    this.userRemover.event.subscribe(() => {
      let currentUrl = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([currentUrl]);
        });
    })
    this.userRemover.removeUser(user);
  }

  addUser(){
    this.userAdder.event.subscribe((newUser) => {
      this.authService.addUser(newUser, (response) => {
        let currentUrl = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([currentUrl]);
        });
      });
    })
    this.userAdder.userAdder();
  }

  getDate(date:Date): string {
    return Utils.stringifyDate(new Date(date.toString().replace(/-/g, "/")));
  }

  isMobileResolution(): boolean {
    if(window.innerWidth<=768){
      return true;
    } else {
      return false;
    }
  }
}
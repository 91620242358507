import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-fade-scroll-view',
  templateUrl: './fade-scroll-view.component.html',
  styleUrls: ['./fade-scroll-view.component.css']
})
export class FadeScrollViewComponent implements OnInit {


  @Input() height:number = 400;
  constructor() { }

  ngOnInit(): void {
    if(this.height>400){
      this.height=400;
    }
  }

}

import {Injectable} from '@angular/core';
import {ApiResponse} from '../../models/utility/ApiResponse.model';
import {ResultList} from '../../models/utility/ResultList.model';
import {ApiResponseItem} from '../../models/utility/ApiResponseItem.model';
import {KnowledgeBaseItem} from '../../models/knowledgebase/KnowledgeBaseItem.model';
import {BaseService} from '../utility/base.service';


@Injectable({
    providedIn: 'root'
})
export class InquiryService {

    constructor(
        protected baseService: BaseService,
    ) { }

    getItemList(callBack: (value: ApiResponse<ResultList<KnowledgeBaseItem>>) => void) {
        return this.baseService.packageQuery<ResultList<KnowledgeBaseItem>, object>('sup','inquiry/getItemList', {}, callBack);
    }

    getItem(id, callBack: (value: ApiResponse<ApiResponseItem<KnowledgeBaseItem>>) => void) {
        return this.baseService.packageQuery<ApiResponseItem<KnowledgeBaseItem>, object>('sup','inquiry/getItem', {in_id: id}, callBack);
    }

    deleteItem(id: Number, callBack: (value: ApiResponse<ApiResponseItem<any>>) => void) {
        return this.baseService.packageQuery<ApiResponseItem<any>, object>('sup','inquiry/deleteItem', {in_id: id}, callBack);
    }

    addItem(data , callBack: (value: ApiResponse<ApiResponseItem<any>>) => void) {
        return this.baseService.packageQuery<ApiResponseItem<any>, object>('sup','inquiry/addItem', data, callBack);
    }

    updateItem(data, callBack: (value: ApiResponse<ApiResponseItem<any>>) => void) {
        return this.baseService.packageQuery<ApiResponseItem<any>, object>('sup','inquiry/updateItem', data, callBack);
    }

    getCategoryList(callBack: (value: ApiResponse<ResultList<KnowledgeBaseItem>>) => void) {
        return this.baseService.packageQuery<ResultList<KnowledgeBaseItem>, object>('sup','inquiry/getCategoryList', {}, callBack);
    }

    getCategory(id, callBack: (value: ApiResponse<ApiResponseItem<KnowledgeBaseItem>>) => void) {
        return this.baseService.packageQuery<ApiResponseItem<KnowledgeBaseItem>, object>('sup','inquiry/getCategory', {inc_id: id}, callBack);
    }

    deleteCategory(id: Number, callBack: (value: ApiResponse<ApiResponseItem<any>>) => void) {
        return this.baseService.packageQuery<ApiResponseItem<any>, object>('sup','inquiry/deleteCategory', {inc_id: id}, callBack);
    }

    addCategory(data, callBack: (value: ApiResponse<ApiResponseItem<any>>) => void) {
        return this.baseService.packageQuery<ApiResponseItem<any>, object>('sup','inquiry/addCategory', data, callBack);
    }

    updateCategory(data, callBack: (value: ApiResponse<ApiResponseItem<any>>) => void) {
        return this.baseService.packageQuery<ApiResponseItem<any>, object>('sup','inquiry/updateCategory', data, callBack);
    }
}

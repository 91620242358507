<div class="form-header">
  <div class="menu-button disableSelect" [ngClass]="{'menu-button-active':selectedForm===0}" (click)="OnSetColumns()" translate>general.editForms.cols</div>
  <div class="menu-button disableSelect" [ngClass]="{'menu-button-active':selectedForm===1}" (click)="OnSetStatuses()" translate>general.editForms.statuses</div>
  <div class="menu-button disableSelect" [ngClass]="{'menu-button-active':selectedForm===2}" (click)="OnSetProjects()" translate>general.editForms.projects</div>
</div>

<div>
  <app-columns-form [cols]="dashboard.columns" *ngIf="selectedForm===0"></app-columns-form>
  <app-statuses-form [statuses]="dashboard.statuses" *ngIf="selectedForm===1"></app-statuses-form>
  <app-projects-form [projects]="dashboard.projects" *ngIf="selectedForm===2"></app-projects-form>
</div>
<div style="margin-top: 20px;">
  <app-agree-disagree-buttons [agreeText]="'common.save' | translate" [cancelText]="'common.cancel' | translate"  [agreeColor]="'var(--accentDark)'"
  (cancel)="cancel.emit()" (agree)="agree.emit()" [justifyContent]="'center'" [innerMargin]="20"></app-agree-disagree-buttons>
</div>

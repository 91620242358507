<div class="header">
  <input *ngIf="isLabelEdit" class="label-input" type="text" [placeholder]="labelCopy" [(ngModel)]="fileUploader.name[currentLang]" (keydown.enter)="OnLabelEnter()">
  <div class="label" *ngIf="!isLabelEdit && fileUploader!=undefined" (dblclick)="OnLabelDoubleClick();">{{fileUploader.GetName(currentLang)}}<span>{{fileUploader.required?'*':''}}</span></div>
  <div class="important-container" *ngIf="editmode">
    <input type="checkbox" id="important" name="important" [(ngModel)]="fileUploader.required" (change)="OnRequiredClicked()">
    <label class="importantLabel disableSelect">Important</label>
  </div>
</div>
<div class="description">
  <input *ngIf="isDescriptionEdit" class="label-input" type="text" [placeholder]="descriptionCopy" [(ngModel)]="fileUploader.small[currentLang]" (keydown.enter)="OnDescriptionEnter()">
  <div class="desc" *ngIf="!isDescriptionEdit && fileUploader!=undefined" (dblclick)="OnDescriptionDoubleClick();">{{editmode?(fileUploader.GetDescription(currentLang)==""?('steps.input.doubleClick' | translate):fileUploader.GetDescription(currentLang)):fileUploader.GetDescription(currentLang)}}</div>
</div>
<div class="file-wrapper">
  <div class="file-picker">
    <div class="file-name-field">{{pickerValue}}</div>
    <button (click)="$event.preventDefault(); fileInput.click()" translate>steps.input.fileUpload.choose</button>
  </div>
  <input id="file-name" type="text" [(ngModel)]="fileUploader.file.fileName" placeholder="{{'steps.input.fileUpload.choose' | translate}}" [disabled]="readonly">
</div>

<input #fileInput type="file" name="name" style="display: none;" (change)="OnFileInputchanged(fileInput)" [disabled]="readonly"/>
<div class="wrapper">

  <div class="header-row">
    <div translate>general.basic.name</div>
    <div *ngIf="!isMobileResolution()" translate>general.basic.action</div>
  </div>
  <div class="data-row" *ngFor="let dashboard of dashboards; let i = index">
    <div>{{dashboard.name}}</div>
    <div class="buttons">
      <button class="btn edit" (click)="OnEditClicked(dashboard);" translate>common.edit</button>
      <button class="btn remove" (click)="OnRemoveClicked(dashboard);" translate>common.remove</button>
    </div>
  </div>
</div>

<app-popup #deletePopup [width]="260">
  <p class="popup-main-text" *ngIf="dashboardService.GetSelectedDashboard()!=undefined" translate>common.deleteQues<br>
  "{{dashboardService.GetSelectedDashboard().name}}"?</p>
  <p class="popup-secondary-text" translate>common.deleteAssure</p>
  <app-agree-disagree-buttons [agreeColor]="'var(--dangerColor)'" [agreeText]="'common.remove' | translate" [cancelText]="'common.cancel' | translate"
  (cancel)="deletePopup.Close()" (agree)="OnPopupRemoveClicked()"></app-agree-disagree-buttons>
</app-popup>

<app-popup #editPopup [isOpen]="false" [width]="475">
  <app-edit-forms *ngIf="dashboardService.GetSelectedDashboard()" [dashboard]="dashboardService.GetSelectedDashboard()"
  (cancel)="editPopup.Close()" (agree)="OnPopupEditClicked()"></app-edit-forms>
</app-popup>

<div class="top-nav-background" [ngClass]="{shadow:!top}">
  <header>
    <button *ngIf="isLanguage('en')" (click)="useLanguage('hu')" class="languageButton hu"></button>
    <button *ngIf="isLanguage('hu')" (click)="useLanguage('en')" class="languageButton en"></button>
    <app-svg-tasks class="icon" (click)="OnTasksClick()" title="tasks" [ngClass]="{act:isTasksClicked}"></app-svg-tasks>
    <app-svg-tasks-active class="icon act" (click)="CloseAll()" title="tasks" [ngClass]="{active:isTasksClicked}"></app-svg-tasks-active>
    <app-svg-notify class="icon" (click)="OnNotificationClick()" title="notifications" [ngClass]="{act:isNotificationsClicked}"></app-svg-notify>
    <a *ngIf="checkSeen()" id="dot"></a>
    <app-svg-notify-active class="icon act" (click)="CloseAll()" title="notifications" [ngClass]="{active:isNotificationsClicked}"></app-svg-notify-active>
    <app-svg-user class="icon" (click)="OnUserClick()" title="preferences" [ngClass]="{act:isUserClicked}"></app-svg-user>
    <app-svg-user-active class="icon act" (click)="CloseAll()" title="preferences" [ngClass]="{active:isUserClicked}"></app-svg-user-active>
  </header>

  <div [ngClass]="{background:isTasksClicked || isUserClicked}" (click)="CloseAll()"></div>

  <div class="container" [@openClose]="isTasksClicked ? 'open' : 'closed'">
    <p class="title" translate>topNav.tasks</p>
    <div class="for-div disableScrollBar" *ngIf="allTasks.length">
      <div *ngFor="let task of allTasks; let i = index">
        <app-top-nav-task [task]="task" (tickChange)="OnTaskTicked(task.id, $event, false, i)" (flagChange)="OnTaskFlagged(task.id, $event)" (gotoEvent)="OnGoToTask(task.startupId)">
        </app-top-nav-task>
      </div>
    </div>
    <div class="subtitle" *ngIf="allTasks.length === 0" translate>topNav.emptyTasks</div>
  </div>

  <div class="background" [@openClose]="isNotificationsClicked ? 'open' : 'closed'" (click)="CloseAll()"></div>

  <div class="notification-container" [@openClose]="isNotificationsClicked ? 'open' : 'closed'">
    <div class="for-div" *ngIf="allNotifs.length">
      <div *ngFor="let notification of allNotifs">
        <app-top-nav-notification [notification]="notification" (gotoEvent)="OnGoToNotif(notification)" (XChange)="OnNotifXed(notification.id)">
        </app-top-nav-notification>
      </div>
    </div>
    <div *ngIf="allNotifs.length === 0" translate>topNav.emptyNotifs</div>
  </div>

  <div class="container" [@openClose]="isUserClicked ? 'open' : 'closed'">
    <profile-card></profile-card>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Notification } from 'src/app/models/Notification.model';
import { NotificationsService } from 'src/app/services/utility/notifications.service';
import { Utils } from 'src/app/common/utils';
import { Router } from '@angular/router';
import { trigger, transition, animate, style, state } from '@angular/animations';

@Component({
  selector: 'app-notifications',
  animations: [
    trigger('openClose', [
      state('open', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      state('closed', style({
        transform: 'translateX(200%)',
        opacity: 0
      })),
      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ]),
  ],
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  constructor(
    private notifService: NotificationsService,
    private router: Router,
  ) { }

  notifications: Notification[] = [];
  maxNumber: number;
  pages: Array<number> = []
  perPage = 10;
  unitOptions:number[] = [10, 20, 30, 40, 50]
  pageId:number = 0;
  closed: number = -1;

  ngOnInit(): void {
    this.notifService.getNotifyList(this.perPage, this.pageId, "date desc", (res) => {
      this.notifications = res;
      this.maxNumber = res.length;
      this.pageCount(this.perPage);
    });
    document.body.style.overflowY = 'scroll';
  }

  OnRedirect(notification: Notification): void {
    this.router.navigate([notification.project.project_status ? 'portfolio' : 'startup', notification.projectid, notification.project_menu]);
  }

  OnDonerClicked(id:number): void {
    this.closed = id;
    setTimeout(() => {
      this.notifService.deleteNotify(id, this.perPage, this.pageId, "date desc", (res) => {
        this.notifications = res;
        this.closed = -1;
      });
    }, 500);
  }

  pageCount(perPage: number){
    this.pages.length = 0
    var pageNumber = Math.ceil(this.maxNumber / perPage)

    for(let i = 0; i < pageNumber; i++){
      this.pages.push(i+1)
    } 
  }

  perPageChange(num:number){
    this.perPage = num;
    this.pageId = 0;
    this.notifService.getNotifyList(this.perPage, this.pageId, "date desc", (res) => {
      this.notifications = res;
      this.maxNumber = res.length;
      this.pageCount(this.perPage);
    });
  }

  pageChange(page: number, callBack: () => void){
    this.notifService.getNotifyList(this.perPage, page, "date desc", (res) => {
      this.notifications = res;
      callBack();
    });
  }

  IncrementPage(): void {
    if(this.pageId < this.pages.length) {
      this.pageId++;
      this.pageChange(this.pageId, () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      });
    }
  }

  DecrementPage(): void {
    if(this.pageId > 1) {
      this.pageId--;
      this.pageChange(this.pageId, () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      });
    }
  }

  getDate(date:Date): string {
    return Utils.stringifyDate(new Date(date));
  }

  isMobileResolution(): boolean {
    if(window.innerWidth<=768){
      return true;
    } else {
      return false;
    }
  }
}

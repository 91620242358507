import { Component, ElementRef, Input, OnInit, ViewChild, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormValue, Selector } from 'src/app/models/dashboard/InputField.model';
import { Language } from 'src/app/models/Language.model';
import { StepsMakerService } from 'src/app/services/project/form/steps-maker.service';

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.css']
})
export class SelectorComponent implements OnInit {

  constructor(
    private stepsService: StepsMakerService,
  ) { }

  @Input() selector:Selector;
  @Input() currentLang:string;
  @Input() editmode:boolean = false;
  @Input() readonly:boolean = false;
  @Input() selectorId:string = '';
  @Output() nameChanged:EventEmitter<string> = new EventEmitter<string>();
  @Output() descriptionChanged:EventEmitter<string> = new EventEmitter<string>();
  @Output() requiredChanged:EventEmitter<boolean> = new EventEmitter<boolean>();
  isLabelEdit:boolean = false;
  isDescriptionEdit:boolean = false;
  isOther:boolean = false;

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.editmode && changes.editmode.currentValue == false) {
      this.isLabelEdit = false;
      this.isDescriptionEdit = false;
      this.isOptionEdit = -1;
    }
  }

  OnAddClicked():void{
    let value = new FormValue(0, new Language("Érték" + (+this.selector.options.length+1), "Value" + (+this.selector.options.length+1)));
    this.stepsService.AddFormValue(this.selector.id, value, 0, backValue => {
      this.selector.options.push(backValue);
    });
  }

  OnOtherCheckChanged():void{
    if(this.isOther){
      this.selector.selectedValues.add(this.selector.otherValue);
    }else{
      this.selector.selectedValues.delete(this.selector.otherValue);
    }
  }

  OnCheckClicked(idx:number, event:HTMLInputElement):void{
    if(event.checked){
      this.selector.selectedValues.add(this.selector.options[idx]);
    }else{
      this.selector.selectedValues.delete(this.selector.options[idx]);
    }
  }

  labelCopy: string;
  OnLabelDoubleClick():void{
    if(this.editmode) {
      this.isLabelEdit = true;
      this.labelCopy = this.selector.name[this.currentLang];
    }
  }

  OnLabelEnter():void{
    if(this.selector.name[this.currentLang] != "") {
      this.nameChanged.emit();
    } else {
      this.selector.name[this.currentLang] = this.labelCopy;
    }
    this.isLabelEdit = false;
  }

  descriptionCopy: string;
  OnDescriptionDoubleClick():void{
    if(this.editmode) {
      this.isDescriptionEdit = true;
      this.descriptionCopy = this.selector.small[this.currentLang];
    }
  }

  OnDescriptionEnter():void{
    this.descriptionChanged.emit();
    this.isDescriptionEdit = false;
  }

  OnRequiredClicked():void{
    this.requiredChanged.emit();
  }

  OnRadioRemoveClicked(idx:number):void{
    this.stepsService.DeleteFormValue(this.selector.options[idx].id);
    this.selector.options.splice(idx,1);
  }

  isOptionEdit:number = -1;
  OnOptionDoubleClick(id:number):void{
    if(this.editmode)
      this.isOptionEdit = id;
  }

  OnOptionEnter(event:Event, id:number):void{
    if((event.target as HTMLInputElement).value != "") {
      this.selector.options[id].value[this.currentLang] = (event.target as HTMLInputElement).value;
      this.stepsService.UpdateFormValue(this.selector.options[id]);
    }
    this.isOptionEdit = -1;
  }
}

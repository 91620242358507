<div class="header">
  <h1 translate>notifications.name</h1>
  <app-drop-down-input class="dropdown" #dropDown [text]="'common.units' | translate" [widthString]='"100px"' [contentHeight]="unitOptions.length*24">
    <div *ngFor="let num of unitOptions" class="dropdown-item">
      <div (click)="perPageChange(num); dropDown.Close()">{{num}}</div>
    </div>
  </app-drop-down-input>
</div>
<div class="wrapper">
  <div class="header-row" *ngIf="!isMobileResolution()">
    <div></div>
    <div translate>notifications.header.startup</div>
    <div translate>notifications.header.title</div>
    <div translate>notifications.header.text</div>
    <div translate>notifications.header.date</div>
    <div></div>
  </div>
  <div class="data-row" *ngFor="let notification of notifications" [@openClose]="closed === notification.id ? 'closed' : 'open'" [ngClass]="{disableSelect: isMobileResolution()}">

    <div *ngIf="!isMobileResolution()"><img class="profile-pic pointer" [src]="notification.project.project_logo ?? '/assets/test-brand.png'" [alt]="notification.project.project_name" (click)="OnRedirect(notification)"></div>
    <div *ngIf="!isMobileResolution()" class="pointer" (click)="OnRedirect(notification)">{{notification.project.project_name}}</div>
    <div *ngIf="!isMobileResolution()" class="title">{{notification.title}}</div>
    <div *ngIf="!isMobileResolution()">{{notification.text}}</div>
    <div *ngIf="!isMobileResolution()">{{getDate(notification.date)}}</div>

    <div *ngIf="isMobileResolution()" class="card-wrapper" (click)="OnRedirect(notification)">
      <div class="card-header">
        <div class="left-side">
          <img class="profile-pic" [src]="notification.project.project_logo ?? '/assets/test-brand.png'" [alt]="notification.project.project_name">
          <span class="title">{{notification.title}}</span>
        </div>
        <div class="right-side disableSelect">
          {{getDate(notification.date)}}
        </div>
      </div>
      <div class="notiftext">
        <span>{{notification.text}}</span>
      </div>
    </div>

    <app-svg-delete class="pointer" (click)="OnDonerClicked(notification.id)" [ngClass]="{'x-icon': isMobileResolution()}"></app-svg-delete>
  </div>
</div>
<div *ngIf="pages.length > 1" class="buttons">
  <app-svg-left-arrow *ngIf="pageId>1" (click)="DecrementPage()"></app-svg-left-arrow>
  <div class="num">{{pageId+1}}. {{'common.page' | translate}}</div>
  <app-svg-right-arrow *ngIf="pageId<pages.length" (click)="IncrementPage()"></app-svg-right-arrow>
</div>
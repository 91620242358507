import { Component, Inject, Input, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ButtonType } from "src/app/models/project/ButtonType.model";
import { Task } from "src/app/models/Task.model";

@Component({
  selector: 'task-dialog',
  templateUrl: './task-dialog.component.html',
  styleUrls: ['./task-dialog.component.scss']
})
export class TaskDialogComponent implements OnInit {

  edit: boolean = false;
  task: Task;

  ButtonType = ButtonType;

  constructor(
    public dialogRef: MatDialogRef<TaskDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Task
  ) {
    if(data) {
      this.task = {...data};
      this.edit = true;
    }
  }

  ngOnInit(): void {
      
  }

  onClose() {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close(this.task);
  }
}
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-tag-badge',
  templateUrl: './svg-tag-badge.component.html',
  styles: [
  ]
})
export class SvgTagBadgeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { EmailTemplate } from 'src/app/models/utility/EmailTemplate.model';
import { EmailService } from 'src/app/services/support/email.service';
import { PopupComponent } from 'src/app/shared/components/popup/popup.component';

@Component({
  selector: 'app-email-settings',
  templateUrl: './email-settings.component.html',
  styleUrls: ['./email-settings.component.css']
})
export class EmailSettingsComponent implements OnInit {

  emails:EmailTemplate[] = [];
  
  constructor(
    public emailService:EmailService,
  ) {
    this.emailService.subject.subscribe(() => {
      this.emails = this.emailService.emails;
    });
  }

  ngOnInit(): void {
  }

  @ViewChild('emailPopup') emailPopup:PopupComponent;
  OnEditClicked(idx:number):void{
    this.emailService.SelectEmail(idx, () => { this.emailPopup.Open(); });
  }

  @ViewChild('deletePopup') deletePopup:PopupComponent;
  OnRemoveClicked(idx:number):void{
    this.emailService.SelectEmail(idx, () => { this.deletePopup.Open(); });
  }

  OnPopupRemoveClicked():void{
    this.emailService.DeleteSelectedEmail();
  }

  OnPopupSaveClicked(emailTemplate:EmailTemplate):void{
    this.emailService.UpdateSelectedEmail(emailTemplate);
  }

  isMobileResolution(): boolean {
    if(window.innerWidth<=768){
      return true;
    } else {
      return false;
    }
  }
}

import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Dashboard, DashboardData } from '../../models/dashboard/Dashboard.model';
import { Router } from '@angular/router';
import { BaseService } from '../utility/base.service';
import { JWTToken } from '../../models/utility/JWTToken.model';
import { ApiResponse } from '../../models/utility/ApiResponse.model';
import { Tag } from '../../models/portfolio-management/TimelineAction.model';
import { User } from 'src/app/models/project/User.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  dashboards:Dashboard[] = [];
  selectedDashboardId:number = -1;

  leader: User = {id: 1, name: "John Doe", email: "email@gmail.com"};

  constructor(
    protected baseService: BaseService,
    private router:Router,
  ) {
    this.RefreshDashboardList(() => {});
  }

  GetDashboardList(callBack: (value: ApiResponse<JWTToken>) => void) {
    return this.baseService.packageQuery<JWTToken, object>('sup', 'setting_dashboard/getDashboardList', {}, callBack);
  }

  CollectColumns(original): {id: number, name: string}[] {
    let columns: {id: number, name: string}[] = [];
    for(let og of original) {
      columns.push({id: og.dashboard_col_id, name: og.dashboard_col_name});
    }
    return columns;
  }

  CollectStatuses(original): Tag[] {
    let statuses: Tag[] = [];
    for(let og of original) {
      let tag: Tag = new Tag(og.dashboard_status_id, og.dashboard_status_title, og.dashboard_status_background);
      tag.textColor = og.dashboard_status_color;
      statuses.push(tag);
    }
    return statuses;
  }

  CollectDatas(original): DashboardData[] {
    let datas: DashboardData[] = [];
    for(let og of original) {
      let data: DashboardData = {id: og.dashboard_datas_id, project_id: og.dashboard_datas_project_id, col_id: og.dashboard_datas_col_id, status_id: og.dashboard_datas_status_id, deadline: og.dashboard_datas_deadline};
      datas.push(data);
    }
    return datas;
  }

  ReturnStatuses(): {dashboard_status_id: number, dashboard_status_title: string, dashboard_status_color: string, dashboard_status_background: string}[] {
    let statuses: {dashboard_status_id: number, dashboard_status_title: string, dashboard_status_color: string, dashboard_status_background: string}[] = [];
    for(let og of this.GetSelectedDashboard().statuses) {
      let tag = {dashboard_status_id: og.id, dashboard_status_title: og.text, dashboard_status_color: og.textColor, dashboard_status_background: og.color};
      statuses.push(tag);
    }
    return statuses;
  }

  ReturnProjects(): number[] {
    let projectIds: number[] = [];
    for(let og of this.GetSelectedDashboard().projects) {
      projectIds.push(og.id);
    }
    return projectIds;
  }

  RefreshDashboardList(callBack: () => void) : void {
    this.GetDashboardList((response) => {
      this.dashboards = [];
      response.data.list.forEach(dashboard => {
        this.dashboards.push({id: dashboard.dashboard_id, name: dashboard.dashboard_name, columns: this.CollectColumns(dashboard.columns), statuses: this.CollectStatuses(dashboard.statuses), projects: [
          // TODO: jelenleg be vannak égetve a megjelenő projectek
          {id: 1, startup:'CMO24', internalLeader:this.leader},
          {id: 2, startup:'ROCKET LAUNCHER', internalLeader:this.leader},
          {id: 3, startup:'VERDANSK', internalLeader:this.leader},
        ], datas: this.CollectDatas(dashboard.datas)});
      });
      callBack();
    });
  }

  SelectDashboard(idx:number, callBack: () => void):void{
    this.selectedDashboardId = idx;
    callBack();
  }

  GetSelectedDashboard():Dashboard{
    return this.dashboards.find(dashboard => dashboard.id == this.selectedDashboardId);
  }

  AddDashboard(dashboardName:string) {
    return this.baseService.packageQuery<JWTToken, object>('sup', 'setting_dashboard/addDashboard', {dashboard_name: dashboardName}, () => {
      this.RefreshDashboardList(() => {
        let currentUrl = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([currentUrl]);
        });
      });
    });
  }

  DeleteSelectedDashboard(callBack: () => void):void{
    if(this.selectedDashboardId!=-1) {
      this.baseService.packageQuery<JWTToken, object>('sup', 'setting_dashboard/deleteDashboard', {dashboard_id: this.selectedDashboardId}, () => {
        this.selectedDashboardId = -1;
        callBack();
      });
    } else throwError("No dashboard selected!!");
  }

  UpdateSelectedDashboard(callBack: () => void):void{
    if(this.selectedDashboardId!=-1) {
      this.baseService.packageQuery<JWTToken, object>('sup', 'setting_dashboard/updateDashboard', {dashboard_id: this.selectedDashboardId, dashboard_columns: this.GetSelectedDashboard().columns, dashboard_status: this.ReturnStatuses(), dashboard_projects: this.ReturnProjects()}, () => {
        this.selectedDashboardId = -1;
        callBack();
      });
    } else throwError("No dashboard selected!!");
  }

  ChangeColor(idx:number, color:string){
    this.dashboards.find(dashboard => dashboard.id == this.selectedDashboardId).statuses[idx].color = color;
  }
  ChangeTextColor(idx:number, color:string){
    this.dashboards.find(dashboard => dashboard.id == this.selectedDashboardId).statuses[idx].textColor = color;
  }

  AddDashboardData(dashboard_id: number, project_id: number, col_id: number, status_id: number, callBack: (value: DashboardData) => void) {
    return this.baseService.packageQuery<JWTToken, object>('sup', 'dashboard/addData', {dashboard_datas_dashboard_id: dashboard_id, dashboard_datas_project_id: project_id, dashboard_datas_col_id: col_id, dashboard_datas_status_id: status_id}, (response) => {
      callBack({id: response.data.item, project_id: project_id, col_id: col_id, status_id: status_id, deadline: new Date('0000-00-00')});
    });
  }

  DeleteDashboardData(dashboard_id: number, data_id: number, callBack: () => void): void {
    this.baseService.packageQuery<JWTToken, object>('sup', 'dashboard/deleteData', {dashboard_datas_id: data_id}, callBack);
  }

  UpdateDashboardDataStatus(dashboard_id: number, data_id: number, status_id: number) {
    return this.baseService.packageQuery<JWTToken, object>('sup', 'dashboard/updateData', {dashboard_datas_id: data_id, dashboard_datas_status_id: status_id}, () => {
      this.dashboards.find(dashboard => dashboard.id == dashboard_id).datas.find(data => data.id == data_id).status_id = status_id;
    });
  }

  UpdateDashboardDataDate(data: DashboardData, date: Date) {
    let copy = new Date(date);
    copy = new Date(copy.setDate(copy.getDate() + 1));
    return this.baseService.packageQuery<JWTToken, object>('sup', 'dashboard/updateData', {dashboard_datas_id: data.id, dashboard_datas_deadline: copy.toISOString()}, () => {
      data.deadline = date;
    });
  }
}

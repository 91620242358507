export class InvexFile{
  id:number;
  projectFileId:number;
  fileName:string;
  description?:string;
  loading:boolean;
  versions:{
    fileName:string,
    uploadDate:Date,
    version:string,
    path:string
  }[];
}

export class FileGroup{
  id: number;
  name: string;
  internal: InvexFile[];
  external: InvexFile[];
  internalFiltered: InvexFile[];
  externalFiltered: InvexFile[];

  constructor(id = 0, name = ""){
    this.id = id;
    this.name = name;
    this.internal = [];
    this.external = [];
    this.internalFiltered = [];
    this.externalFiltered = [];
  }

  notEmptyInternal(): boolean {
    return this.internalFiltered.length > 0;
  }

  notEmptyExternal(): boolean {
    return this.externalFiltered.length > 0;
  }
}
<div class="wrapper">
  <div class="header-row">
    <div translate>general.basic.order</div>
    <div translate>general.basic.name</div>
    <div *ngIf="!isMobileResolution()" translate>general.basic.action</div>
  </div>
  <div class="data-row" *ngFor="let industry of industries; let i = index">
    <div>{{i+1}}</div> <div>{{industry.industry_name}}</div>
    <div class="buttons">
      <button class="btn edit" (click)="OnEditClicked(i);" translate>common.edit</button>
      <button class="btn remove" (click)="OnRemoveClicked(i);" translate>common.remove</button>
    </div>
  </div>
</div>

<app-popup #deletePopup [width]="260">
  <p class="popup-main-text" translate>common.deleteQues<br>"{{industryService.selected.industry_name}}"?</p>
  <p class="popup-secondary-text" translate>common.deleteAssure</p>
  <app-agree-disagree-buttons [agreeColor]="'var(--dangerColor)'" [agreeText]="'common.remove' | translate" [cancelText]="'common.cancel' | translate"
  (cancel)="deletePopup.Close()" (agree)="OnPopupRemoveClicked(); deletePopup.Close()"></app-agree-disagree-buttons>
</app-popup>

<app-popup #updatePopup [width]="300">
  <input ngModel={{industryService.selected.industry_name}} #updateInput type="text" class="popup-input" [placeholder]="industryService.selected.industry_name">
  <app-agree-disagree-buttons [justifyContent]="'space-evenly'" [agreeColor]="'var(--accentDark)'" [agreeText]="'common.save' | translate" [cancelText]="'common.cancel' | translate"
  (cancel)="updatePopup.Close()" (agree)="OnUpdateSaveClicked(); updatePopup.Close()"></app-agree-disagree-buttons>
</app-popup>

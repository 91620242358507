
<div class="header">
  <h1 translate>general.name</h1>
  <app-svg-add-action style="box-shadow: var(--defaultBoxShadow); cursor: pointer;" (click)="OnAddButtonClicked()" ></app-svg-add-action>
</div>

<div class="container">
  <div class="general-menu disableSelect">
    <app-nav-separator [text]="'general.venture.title' | translate" [color]="'var(--dark)'" [fontWeight]="'bold'"></app-nav-separator>

    <div class="sidenav-item" style="margin-top: 15px;" [routerLink]="['industries']" routerLinkActive #rli="routerLinkActive"
      [ngClass]="{'active':rli.isActive}" translate>general.venture.industries</div>
    <div class="sidenav-item" style="margin-bottom: 15px;"
    [routerLink]="['dashboard']" routerLinkActive #rld="routerLinkActive" [ngClass]="{'active':rld.isActive}" translate>general.venture.dashboard</div>

    <app-nav-separator [text]="'general.email.title' | translate" [color]="'var(--dark)'" [fontWeight]="'bold'"></app-nav-separator>

    <div class="sidenav-item" style="margin-top: 15px;"
    [routerLink]="['emailGroups']" routerLinkActive #rldeg="routerLinkActive" [routerLinkActiveOptions]="{exact: true}" [ngClass]="{'active':rldeg.isActive}" translate>general.email.emailGroups</div>

    <div *ngFor="let group of emailGroups; let i = index">
      <div class="sidenav-item" (click)="RefreshEmails(i+1)"
      [routerLink]="['emailGroups/' + group.replaceAll(' ', '').replaceAll('-', '')]" routerLinkActive #rlde="routerLinkActive" [ngClass]="{'active':rlde.isActive}">{{group}}</div>
    </div>

    <app-nav-separator [text]="'general.tags.title' | translate" [color]="'var(--dark)'" [fontWeight]="'bold'"></app-nav-separator>

    <div class="sidenav-item" style="margin-top: 15px;"
    [routerLink]="['dealflowTags']" routerLinkActive #rladt="routerLinkActive"  [ngClass]="{'active':rladt.isActive}" translate>general.tags.dealFlow</div>
    <div class="sidenav-item" style="margin-bottom: 15px;"
    [routerLink]="['portfolioManagement']" routerLinkActive #rlp="routerLinkActive"  [ngClass]="{'active':rlp.isActive}" translate>general.tags.pm</div>
    <app-nav-separator [text]="'general.timeline.title' | translate" [color]="'var(--dark)'" [fontWeight]="'bold'"></app-nav-separator>
    <div class="sidenav-item" style="margin-top: 15px;"
    [routerLink]="['categories']" routerLinkActive #rlc="routerLinkActive" [ngClass]="{'active':rlc.isActive}" translate>general.timeline.categories</div>
  </div>

  <div class="content">
    <router-outlet></router-outlet>
  </div>
</div>

<app-popup #updatePopup [width]="300">
  <input #updateInput ngModel='' type="text" class="popup-input"
  [placeholder]="rli.isActive ? 'New Industry' : (rld.isActive ? 'New dashboard' : 'New Email Group')" style="margin-bottom: 20px;">
  <app-agree-disagree-buttons [justifyContent]="'space-evenly'" [agreeColor]="'var(--accentDark)'" [agreeText]="'common.save' | translate"
  (cancel)="updatePopup.Close()" (agree)="OnUpdateSaveClicked(); updateInput.value=''; updatePopup.Close()"></app-agree-disagree-buttons>
</app-popup>

<app-email-template-popup #emailPopup [isNew]="true" (agree)="OnEmailSaveClicked($event)"></app-email-template-popup>

<app-popup #tagAddPopup>
  <input #tagTextInput ngModel='' type="text" class="popup-input"
  [placeholder]="'New category'" style="margin-bottom: 20px;">
  <div style="margin-bottom: 20px;"><app-color-picker #colorPicker [text]="colorPicker.color" [outputFormat]="'hex'" ></app-color-picker></div>
  <app-agree-disagree-buttons [justifyContent]="'space-evenly'" [agreeColor]="'var(--accentDark)'" [agreeText]="'common.save' | translate" [cancelText]="'common.cancel' | translate" (cancel)="tagAddPopup.Close()" (agree)="OnTagAddClicked(tagTextInput.value, colorPicker.color );tagTextInput.value='';"
  [innerMargin]="20"></app-agree-disagree-buttons>
</app-popup>

<div class="container-fluid p-0 data-content">
    <div class="row mt-3 justify-content-between">
        <div class="col-6 pl-2"></div>
        <div class="col-6">
            <div class="row justify-content-end pr-3">
                <div class="button-container">
                    <button class="btn btn-danger" (click)="deleteItem()"><i class="fa fa-check"></i></button>
                    <p>Törlés</p>
                </div>
                <div class="button-container">
                    <button class="btn btn-success" (click)="saveForm(kbForm)"><i class="fa fa-check"></i></button>
                    <p>Mentés</p>
                </div>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-body">
            <form #kbForm="ngForm">
                <input type="hidden" name="inc_id" [ngModel]="selectedCategory?.inc_id"/>
                <div class="form-group">
                    <label>Név</label>
                    <input type="text" [ngModel]="selectedCategory?.inc_name" name="inc_name" class="form-control">
                </div>
                <div class="form-group">
                    <label>Tag</label>
                    <input type="text" [ngModel]="selectedCategory?.inc_tag" name="inc_tag" class="form-control">
                </div>
            </form>
        </div>
    </div>
</div>

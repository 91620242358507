import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav-separator',
  templateUrl: './nav-separator.component.html',
  styleUrls: ['./nav-separator.component.scss']
})
export class NavSeparatorComponent implements OnInit {

  @Input() hide: boolean = false;
  @Input() text:string;
  @Input() color:string ="var(--dark)";
  @Input() fontsize:string = "12px"
  @Input() fontWeight:string = "normal"
  constructor() { }

  ngOnInit(): void {
  }

}

<div class="panels">
  <div *ngIf="authService.currentUserValue.main_permission == 1" class="internal panel">
      <div class="title">
          <h3 translate>startup.internal</h3>
          <app-button style="margin-right: 20px;" [type]="ButtonType.SecondaryLight" text="{{'startup.tasks.addInternal' | translate}}" (onClick)="openInternalDialog()"></app-button>
      </div>
      <div class="task-list" #internalTaskList >
        <div cdkDropList
            #internalTaskDropList="cdkDropList"
            [cdkDropListConnectedTo]="[internalDoneList]"
            [cdkDropListData]="filteredTaskListInternal"
            (cdkDropListDropped)="drop($event)"
            class="not-done">
          <div cdkDrag *ngFor="let task of filteredTaskListInternal; let i = index" class="dragitem">
            <app-task-card [isInternal]="true" [(tick)]="task.isDone" [task]="task" (tickChange)="OnTaskTicked(task.id, $event, true, i)"
            (flagChange)="OnTaskFlagged(task.id, $event)" (editTask)="openInternalDialog($event)" (removeTask)="removeInternal($event)">
              <div style="width: 10px;" cdkDragHandle>
                <svg-icon src="/assets/startupDataboard/tasks/arranger.svg" [svgStyle]="{'width.%':100, 'height.%':100}"
                style="stroke: none; cursor: grab;"></svg-icon>
              </div>
            </app-task-card>
          </div>
          <div *ngIf="filteredTaskListInternal.length === 0" translate>startup.tasks.internalEmpty</div>
        </div>
        <div style="margin-bottom: 15px;" *ngIf="filteredTaskListInternalDone.length > 0">
          <app-nav-separator class="filegroup-tag" [text]="'common.done' | translate"
           [color]="'var(--dark)'" [fontsize]="'inherit'" >
          </app-nav-separator>
        </div>
        <div cdkDropList
            #internalDoneList="cdkDropList"
            [cdkDropListConnectedTo]="[internalTaskDropList]"
            [cdkDropListData]="filteredTaskListInternalDone"
            (cdkDropListDropped)="drop($event)"
            class="done">
          <div cdkDrag *ngFor="let task of filteredTaskListInternalDone; let i = index" class="dragitem">
            <app-task-card [isInternal]="true" [(tick)]="task.isDone" [task]="task" (tickChange)="OnTaskTicked(task.id, $event, true, i)"
            (flagChange)="OnTaskFlagged(task.id, $event)" (editTask)="openInternalDialog($event)" (removeTask)="removeInternal($event)">
            <div style="width: 10px;" cdkDragHandle>
              <svg-icon src="/assets/startupDataboard/tasks/arranger.svg" [svgStyle]="{'width.%':100, 'height.%':100}"
              style="stroke: none; cursor: grab;"></svg-icon>
            </div>
            </app-task-card>
          </div>
        </div>
      </div>
  </div>
  <div *ngIf="authService.currentUserValue.main_permission == 1" class="line"></div>
  <div [ngClass]="{single: authService.currentUserValue.main_permission == 2}" class="external panel">
      <div [ngClass]="{single: authService.currentUserValue.main_permission == 2}" class="title">
          <h3 *ngIf="authService.currentUserValue.main_permission == 1" translate>startup.external</h3>
          <app-button style="margin-right: 20px;" [type]="ButtonType.SecondaryLight" text="{{'startup.tasks.addExternal' | translate}}" (onClick)="openExternalDialog()"></app-button>
      </div>
      <div class="task-list" #externalTaskList>
          <div cdkDropList
              #externalTaskDropList="cdkDropList"
              [cdkDropListConnectedTo]="[externalDoneList]"
              [cdkDropListData]="filteredTaskListExternal"
              (cdkDropListDropped)="drop($event)"
              class="not-done">
            <div cdkDrag *ngFor="let task of filteredTaskListExternal, let i = index" class="dragitem">
              <app-task-card [isInternal]="false" [(tick)]="task.isDone" [task]="task" (tickChange)="OnTaskTicked(task.id, $event, false, i)"
              (flagChange)="OnTaskFlagged(task.id, $event)" (editTask)="openExternalDialog($event)" (removeTask)="removeExternal($event)">
              <div style="width: 10px;" cdkDragHandle>
                <app-arranger style="cursor: grab;"></app-arranger>
              </div>
              </app-task-card>
            </div>
            <div *ngIf="filteredTaskListExternal.length === 0" translate>startup.tasks.externalEmpty</div>
          </div>
          <div style="margin-bottom: 15px;" *ngIf="filteredTaskListExternalDone.length > 0">
            <app-nav-separator class="filegroup-tag" [text]="'common.done' | translate"
             [color]="'var(--dark)'" [fontsize]="'inherit'" >
            </app-nav-separator>
          </div>
          <div cdkDropList
              (cdkDropListDropped)="drop($event)"
              #externalDoneList="cdkDropList"
              [cdkDropListConnectedTo]="[externalTaskDropList]"
              [cdkDropListData]="filteredTaskListExternalDone"
              class="done">
            <div cdkDrag *ngFor="let task of filteredTaskListExternalDone; let i = index" class="dragitem">
              <app-task-card [isInternal]="false" [(tick)]="task.isDone" [task]="task" (tickChange)="OnTaskTicked(task.id, $event, false, i)"
              (flagChange)="OnTaskFlagged(task.id, $event)" (editTask)="openExternalDialog($event)" (removeTask)="removeExternal($event)">
              <div style="width: 10px;" cdkDragHandle>
                <app-arranger style="cursor: grab;"></app-arranger>
              </div>
              </app-task-card>
            </div>
          </div>
      </div>
  </div>
</div>
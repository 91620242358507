import {Component, OnInit} from '@angular/core';
import {KnowledgeBaseService} from '../../services/support/knowledge-base.service';
import {KnowledgeBaseCategory} from '../../models/knowledgebase/KnowledgeBaseCategory.model';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-knowledgebase-category-list',
  templateUrl: './knowledgebase-category-list.component.html',
  styleUrls: ['./knowledgebase-category-list.component.scss']
})
export class KnowledgebaseCategoryListComponent implements OnInit {

  constructor(
    private knowledgeBaseService: KnowledgeBaseService,
    private router: Router,
  ) { }

  categoryList: Array<KnowledgeBaseCategory>;

  ngOnInit(): void {
    this.getCategoryList();
  }

  getCategoryList() {
    this.knowledgeBaseService.getCategoryList(res => {
      this.categoryList = res.data.list;
    });
  }

  deleteItem(id) {
    Swal.fire({
      title: 'Törlésre készül !',
      text: 'Biztosan törli a kategóriát ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Igen, Törlöm'
    }).then((result) => {
      if (result.isConfirmed) {
        this.knowledgeBaseService.deleteCategory(id, res => {
          this.getCategoryList();
        });
      }
    });
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PopupComponent } from 'src/app/shared/components/popup/popup.component';
import { InternalPersonsService } from 'src/app/services/project/internal-persons.service';
import { TeamService } from 'src/app/services/project/team/team.service';
import { Subscription } from 'rxjs';
import { FilterService } from 'src/app/services/utility/filter.service';
import { NewInternalMemberComponent } from './new-internal-member/new-internal-member.component';
import { User } from 'src/app/models/project/User.model';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {

  isEditModeInternal:boolean=false;
  isEditModeExternal:boolean=false;

  filteredInternalTeam:User[] = [];
  filteredExternalTeam:User[] = [];

  personOptionsList:User[] = [];
  projectId:string;
  projectStatus:string;
  isAdmin: boolean = false;

  @ViewChild('addExternalPopup',{static:true}) addExternalPopup:PopupComponent;
  @ViewChild('addInternalPopup',{static:true}) addInternalPopup:PopupComponent;
  @ViewChild('newInternalMember',{static:true}) newInternalMember:NewInternalMemberComponent;

  private routeSub: Subscription;

  constructor(
    private teamService: TeamService,
    private router:Router,
    private route: ActivatedRoute,
    public internalPersonService:InternalPersonsService,
    private filterService:FilterService,
    public authService: AuthService,
  ) {
    this.isAdmin = this.authService.currentUserValue.main_permission == 1;
    this.sub = this.filterService.updated.subscribe((x:string)=>{
      if(this.filteredInternalTeam!=undefined) this.OnSearchChanged(x);
    });
    this.routeSub = this.route.parent.params.subscribe(params => {
      this.projectId = params['id'];
      this.projectStatus = this.router.url.split('/')[1] == "portfolio" ? "1" : "0";
      this.teamService.RefreshTeamList(this.projectId, this.projectStatus, () => {
        if(this.isAdmin) {
          this.filteredInternalTeam = this.teamService.internalTeam;
        }
        this.filteredExternalTeam = this.teamService.externalTeam;
      });
    });
  }

  sub:Subscription;

  ngOnInit(): void {
    this.RefreshOptions(() => {});
  }

  ngOnDestroy() {
    if(this.routeSub) {
      this.routeSub.unsubscribe();
    }
    if(this.sub) {
      this.sub.unsubscribe();
    }
  }

  RefreshOptions(callBack: () => void) {
    this.internalPersonService.RefreshTeamList(() => {
      if(this.isAdmin) {
        this.filteredInternalTeam = this.teamService.internalTeam;
      }
      this.filteredExternalTeam = this.teamService.externalTeam;

      this.personOptionsList = [];
      for(let i = 0; i < this.internalPersonService.internalTeamOptions.length; i++) {
        if(this.internalPersonService.internalTeamProjectIds[i] == -1) {
          let found = false;
          console.log("----------------------------");
          for(let internal of this.filteredInternalTeam) {
            console.log(internal);
            console.log(this.internalPersonService.internalTeamOptions[i]);
            if(internal.email === this.internalPersonService.internalTeamOptions[i].email) {
              found = true;
            }
          }
          
          console.log(found);

          console.log("----------------------------");
          
          if(!found) {
            this.personOptionsList.push(this.internalPersonService.internalTeamOptions[i]);
          }
        }
      }
      this.newInternalMember.personList = this.personOptionsList;
      callBack();
    });
  }

  OnSearchChanged(input:string):void{
    if(this.isAdmin) {
      this.filteredInternalTeam = this.teamService.FilterTeam(input, true);
    }
    this.filteredExternalTeam = this.teamService.FilterTeam(input, false);
  }

  AddPersonInternal(person:User):void{
    let id = this.internalPersonService.internalTeamOptions.findIndex(p => p.email===person.email);
    
    this.teamService.AddPersonInternal( person, this.projectId, this.projectStatus, () => {
      this.filteredInternalTeam = this.teamService.internalTeam;
    });

    /*this.teamService.AddPersonInternal(this.internalPersonService.internalTeamIds[id], person, this.projectId, () => {
      this.filteredInternalTeam = this.teamService.internalTeam;
    });*/
  }

  AddPersonExternal(email:string):void{
    this.teamService.AddPersonExternal(email, this.projectId, this.projectStatus, () => {
      this.filteredExternalTeam = this.teamService.externalTeam;
    });
    this.addExternalPopup.Close();
  }

  OnPlusExternalClicked():void{
    this.addExternalPopup.Open();
  }
  OnPlusInternalClicked():void{
    this.RefreshOptions(() => {
      this.addInternalPopup.Open();
    });
  }
  RemovePersonInternal(index:number){
    this.teamService.RemovePersonInternal(index, this.projectId, this.projectStatus, () => {
      this.filteredInternalTeam = this.teamService.internalTeam;
    });
  }
  RemovePersonExternal(index:number){
    this.teamService.RemovePersonExternal(index, () => {
      this.filteredExternalTeam = this.teamService.externalTeam;
    });
  }

  OnEditPressedInternal():void{
    this.isEditModeInternal=!this.isEditModeInternal;
  }
  OnEditPressedExternal():void{
    this.isEditModeExternal=!this.isEditModeExternal;
  }

  OnTagChangeInternal(index:number){
    this.teamService.TagPersonInternal(index, this.projectId, this.projectStatus, () => {
      this.filteredInternalTeam = this.teamService.internalTeam;
    });
  }

  OnTagChangeExternal(index:number){
    this.teamService.TagPersonExternal(index, this.projectId, this.projectStatus, () => {
      this.filteredExternalTeam = this.teamService.externalTeam;
    });
  }
}

<div class="navigator-item" (click)="OnClicked()" (touchstart)="TouchStart($event)" (touchend)="TouchEnd()" [ngClass]="{'selected': isSelected}">
  <div class="nav-link-logo">
    <app-svg-statistics *ngIf="iconString==='statistics'" class="icon"></app-svg-statistics>
    <app-svg-database *ngIf="iconString==='database'" class="icon"></app-svg-database>
    <app-svg-dealflow *ngIf="iconString==='dealflow'" class="icon"></app-svg-dealflow>
    <app-svg-pfmgmt *ngIf="iconString==='pfmgmt'" class="icon"></app-svg-pfmgmt>
    <app-svg-network *ngIf="iconString==='network'" class="icon"></app-svg-network>
    <app-svg-inbox *ngIf="iconString==='inbox'" class="icon"></app-svg-inbox>
    <app-svg-general *ngIf="iconString==='general'" class="icon"></app-svg-general>
    <app-svg-templates *ngIf="iconString==='templates'" class="icon"></app-svg-templates>
    <app-svg-steps *ngIf="iconString==='steps'" class="icon"></app-svg-steps>
    <app-svg-users *ngIf="iconString==='users'" class="icon"></app-svg-users>
    <app-svg-notify *ngIf="iconString==='notifications'"></app-svg-notify>
    <app-svg-history *ngIf="iconString==='history'" class="icon"></app-svg-history>
  </div>
  <div class="nav-link-text disableSelect" [ngClass]="{hide:hide}">
    <span>{{text}}</span>
  </div>
</div>
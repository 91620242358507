import { Component, OnInit, Input } from '@angular/core';
import { StartUp } from 'src/app/models/project/Startup.model';
import { Utils } from 'src/app/common/utils';
import { start } from 'repl';

@Component({
  selector: 'app-startup-header',
  templateUrl: './startup-header.component.html',
  styleUrls: ['./startup-header.component.scss']
})
export class StartupHeaderComponent implements OnInit {

  @Input() startup:StartUp;

  constructor() { }

  ngOnInit(): void {
  }

  //TODO: Javításra szorul a későbbiekben
  getStep(startup:StartUp): string {
    return "Due Diligence";
  }

  getDate(date:Date): string {
    return Utils.stringifyDate(new Date(date));
  }

}

<div class="container-fluid pl-0 pr-0 data-content">
    <div class="table-responsive">
        <table class="table a-table">
            <thead>
            <tr>
                <th>#</th>
                <th>Név</th>
                <th>Tag</th>
                <th width="150">
                    <button class="btn btn-sm btn-primary"  style="width: 50px;line-height: 0px;" routerLink="/inquiry/category/add" ngbTooltip="Új létrehozása"><i class="fa fa-plus"></i></button>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr class="a-tr" *ngFor="let item of categoryList">
                <td>
                    {{item.inc_id}}
                </td>
                <td>
                    {{item.inc_name}}
                </td>
                <td nowrap>
                    <span *ngIf="item.inc_tag !='' " class="badge badge-tag">{{item.inc_tag}}</span>
                </td>
                <td nowrap>
                    <button class="btn  btn-sm btn-danger mr-2" style="min-width: 50px; line-height: 12px; border-radius: 8px;" (click)="deleteItem(item.inc_id)"><i class="fa-trash-alt far"></i></button>
                    <button ngbTooltip="részletek" routerLink="/inquiry/category/{{item.inc_id}}" class="btn btn-sm btn-light ch-icon"><i class="fas fa-chevron-right text-primary"></i></button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>

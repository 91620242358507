import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { Utils } from "src/app/common/utils";
import { InputFieldType } from "src/app/models/dashboard/InputField.model";
import { SelectOption } from "src/app/models/project/SelectOption.model";

@Component({
    selector: 'input-field-base',
    templateUrl: './input-field-base.component.html',
    styleUrls: ['./input-field-base.component.scss']
})
export class InputFieldBaseComponent implements OnInit, OnDestroy {

    constructor(private translateService: TranslateService){
        
    }

    subscriptions: Subscription[] = [];
    @Input() label: string = "";
    @Output() labelChange = new EventEmitter<string>();

    @Input() type: InputFieldType;
    @Output() typeChange = new EventEmitter<InputFieldType>();

    @Input() isStepEdit = false;

    inputFieldTypes: SelectOption[] = [];

    ngOnInit(): void {
        this.inputFieldTypes = Utils.inputFieldTypeSelectList(this.translateService);
        this.subscriptions.push(this.translateService.onLangChange.subscribe(s => {
            this.inputFieldTypes = Utils.inputFieldTypeSelectList(this.translateService);
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => {s.unsubscribe();})
    }

    onTypeChanged(val) {
        this.typeChange.emit(val);
    }
}
import { Component, Input, OnInit, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Repeater } from 'src/app/models/dashboard/InputField.model';

@Component({
  selector: 'app-repeater',
  templateUrl: './repeater.component.html',
  styleUrls: ['./repeater.component.css']
})
export class RepeaterComponent implements OnInit {

  constructor() { }

  @Input() repeater:Repeater;
  @Input() currentLang:string;
  @Input() editmode:boolean = false;
  @Input() readonly:boolean = false;
  @Output() nameChanged:EventEmitter<string> = new EventEmitter<string>();
  @Output() descriptionChanged:EventEmitter<string> = new EventEmitter<string>();
  @Output() requiredChanged:EventEmitter<boolean> = new EventEmitter<boolean>();
  isLabelEdit:boolean = false;
  isDescriptionEdit:boolean = false;
  newValue:string = '';

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.editmode && changes.editmode.currentValue == false) {
      this.isLabelEdit = false;
      this.isDescriptionEdit = false;
    }
  }

  labelCopy: string;
  OnLabelDoubleClick():void{
    if(this.editmode) {
      this.isLabelEdit = true;
      this.labelCopy = this.repeater.name[this.currentLang];
    }
  }

  OnLabelEnter():void{
    if(this.repeater.name[this.currentLang] != "") {
      this.nameChanged.emit();
    } else {
      this.repeater.name[this.currentLang] = this.labelCopy;
    }
    this.isLabelEdit = false;
  }

  descriptionCopy: string;
  OnDescriptionDoubleClick():void{
    if(this.editmode) {
      this.isDescriptionEdit = true;
      this.descriptionCopy = this.repeater.small[this.currentLang];
    }
  }

  OnDescriptionEnter():void{
    this.descriptionChanged.emit();
    this.isDescriptionEdit = false;
  }

  OnRequiredClicked():void{
    this.requiredChanged.emit();
  }

  OnEnter(input:HTMLInputElement):void{
    if(input.value!=''){
      this.repeater.values.push(input.value);
      input.value='';
    }
  }

  OnBackspace(idx:number):void{
    if(this.repeater.values[idx]==''){
      this.repeater.values.splice(idx,1);
    }
  }

  trackByIndex(index: number): number {
    return index;
  };
}

<div class="title" translate>startup.team.newEmail</div>
<div class="checkbox-container" [ngClass]="{invalid: invalidObj.checkboxInvalid}">
	<div style="display: flex; justify-content: center; align-items: center">
		<input #internal type="checkbox" id="internalCheckbox" name="internalCheckbox"/>
		<label for="internalCheckbox" style="margin-left: 2px" translate>startup.team.intMems</label>
	</div>
	<div style="display: flex; justify-content: center; align-items: center">
		<input #external type="checkbox" id="externalCheckbox" name="externalCheckbox"/>
		<label for="externalCheckbox" style="margin-left: 2px" translate>startup.team.exMems</label>
	</div>
</div>
<input #subject class="subject-input" type="text" placeholder="{{'startup.team.subject' | translate}}" [ngClass]="{invalid: invalidObj.subjectInvalid}">
<textarea #message class="info" rows="10" placeholder="{{'startup.team.message' | translate}}" [ngClass]="{invalid: invalidObj.messageInvalid}"></textarea>
<div style="display: flex; justify-content: space-around;">
    <button style="background-color: var(--cancelColor);" (click)="OnCancelClicked()" translate>common.cancel</button>
    <button style="background-color: var(--tagDarkAqua);" (click)="OnSendClicked()" translate>common.send</button>
</div>
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { PopupComponent } from 'src/app/shared/components/popup/popup.component';
import { InvexFile, FileGroup } from 'src/app/models/utility/File.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FilesService } from 'src/app/services/project/files/files.service';
import { FilterService } from 'src/app/services/utility/filter.service';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.css']
})
export class FilesComponent implements OnInit, OnDestroy {

  fileGroups:FileGroup[] = [];
  projectId:string;

  selectedFile:{index:number, list:InvexFile[]} = {index: 0, list:[]};
  @ViewChild('deletePopup') dPopup:PopupComponent;
  @ViewChild('addFilePopup') aPopup:PopupComponent;
  @ViewChild('editFilePopup') ePopup:PopupComponent;
  isEditablePopup:boolean = false;

  private sub:Subscription;
  private routeSub: Subscription;

  constructor(
    public authService:AuthService,
    private filterService:FilterService,
    private filesService: FilesService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.sub = this.filterService.updated.subscribe((x:string)=>{
      this.OnSearchChanged(x);
    });
    this.routeSub = this.route.parent.params.subscribe(params => {
      this.projectId = params['id'];
      this.filesService.GetFileGroups(this.projectId, () => {
        this.fileGroups = this.filesService.fileGroups;
      });
    });
  }

  ngOnDestroy():void{
    if(this.routeSub) {
      this.routeSub.unsubscribe();
    }
    if(this.sub) {
      this.sub.unsubscribe();
    }
  }

  OnFileSelectInput(event:Event, nativeFileInput:HTMLInputElement):void{
    event.preventDefault();
    nativeFileInput.click();
  }

  fileOriginalName:string = "";
  filePath:string = '';
  imgURL:string = '';
  fileType:string = ''
  OnFileInputchanged(fileInput:HTMLInputElement):void{
    const file = fileInput.files[0];
    this.fileType = file.type;
    const reader = new FileReader();
    reader.onload = () => {
      this.imgURL = reader.result as string;
    }
    reader.readAsDataURL(file)
    this.filePath=fileInput.value;
    this.fileOriginalName = fileInput.value.split(/(\\|\/)/g).pop();
  }

  toInternal:boolean;
  AddFileSubmit(form:NgForm):void{
    if(form.valid){
      let newFile:InvexFile = new InvexFile();
      newFile.fileName = form.value.fileName;
      newFile.versions=[{
        version:'v1', fileName: form.value.fileOriginalName, uploadDate: new Date(), path:this.filePath
      }];
      newFile.description = form.value.fileDesc;
      newFile.loading = true;
      if(this.toInternal) {
        this.fileGroups[this.fileGroups.length-1].internalFiltered.push(newFile);
      } else {
        this.fileGroups[this.fileGroups.length-1].externalFiltered.push(newFile);
      }
      this.filesService.AddFileOther(this.projectId, this.toInternal, newFile, this.fileType, this.imgURL, () => {
        this.fileGroups = this.filesService.fileGroups;
      });
      form.resetForm();
      this.aPopup.Close();
    }
  }

  OnFormCancel(event:Event){
    event.preventDefault();
    this.aPopup.Close();
  }

  OnFileDelete(i:number, fileList:InvexFile[]){
    this.selectedFile = {index:i, list:fileList};
    this.dPopup.Open();
  }

  RemoveFile():void{
    this.filesService.RemoveFile(this.selectedFile.list[this.selectedFile.index], false, () => {
      this.selectedFile.list.splice(this.selectedFile.index, 1);
      this.selectedFile = {index: 0, list: []};
      this.dPopup.Close();
    });
  }

  OnFileEdit(i:number, fileList:InvexFile[], isEditable:boolean){
    this.isEditablePopup = isEditable;
    this.selectedFile = {index:i, list:fileList};
    this.ePopup.Open();
  }

  OnFileSaved(file:InvexFile):void{
    this.filesService.EditFile(file, () => {
      this.selectedFile = {index: 0, list: []};
      this.ePopup.Close();
    });
  }

  OnSearchChanged(input:string):void{
    this.filesService.FilterFiles(input, (fileGroups) => {
      this.fileGroups = fileGroups;
    });
  }

  IsEmptyInternal(): boolean {
    for(let group of this.fileGroups) {
      if(group.notEmptyInternal()) {
        return false;
      }
    }
    return true;
  }

  IsEmptyExternal(): boolean {
    for(let group of this.fileGroups) {
      if(group.notEmptyExternal()) {
        return false;
      }
    }
    return true;
  }
}

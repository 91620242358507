import { Injectable } from '@angular/core';
import {BaseService} from '../utility/base.service';
import {ResultList} from '../../models/utility/ResultList.model';
import {ApiResponseItem} from '../../models/utility/ApiResponseItem.model';
import {ApiResponse} from '../../models/utility/ApiResponse.model';
import {Permission} from '../../models/Permission.model';
import {PermissionGroup} from '../../models/PermissionGroup.model';
import {StartUp} from 'src/app/models/project/Startup.model';
import {PermissionList} from 'src/app/models/utility/PermissionList.model';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  constructor(
    protected baseService : BaseService
  ) {}


  public getPermissionList(req,callBack: (value: ApiResponse<ResultList<Permission>>) => void) {
    return this.baseService.packageQuery<ResultList<Permission>, object>('auth','permissions/getPermissionsList', req, callBack);
  }

  public getPermissionFromNgUrl(url,callBack: (value: ApiResponse<ApiResponseItem<Permission>>) => void) {
    return this.baseService.packageQuery<ApiResponseItem<Permission>, object>('auth','permissions/getPermissionFromNgUrl',{permission_ngurl:url}, callBack);
  }

  public getPermission(permissionId: number, callBack: (value: ApiResponse<ApiResponseItem<Permission>>) => void) {
    return this.baseService.packageQuery<ApiResponseItem<Permission>, object>('auth','permissions/getPermission', {permission_id: permissionId}, callBack);
  }

  public addPermission(data, callBack: (value: ApiResponse<ApiResponseItem<any>>) => void) {
    return this.baseService.packageQuery<ApiResponseItem<any>, object>('auth','permissions/addPermission', data, callBack);
  }

  public updatePermission(data, callBack: (value: ApiResponse<ApiResponseItem<any>>) => void) {
    return this.baseService.packageQuery<ApiResponseItem<any>, object>('auth','permissions/updatePermission', data, callBack);
  }

  public deletePermission(permissionId, callBack: (value: ApiResponse<ApiResponseItem<any>>) => void) {
    return this.baseService.packageQuery<ApiResponseItem<any>, object>('auth','permissions/deletePermission', {permission_id : permissionId}, callBack);
  }

  public getApiMethods(callBack: (value: ApiResponse<ResultList<Permission>>) => void) {
    return this.baseService.packageQuery<ResultList<Permission>, object>('auth','permissions/getMethods', {}, callBack);
  }



  // jogkör csoport

  public getPermissionGroupList(callBack: (value: ApiResponse<ResultList<PermissionGroup>>) => void) {
    return this.baseService.packageQuery<ResultList<PermissionGroup>, object>('auth','Permission_group/getPermissionGroupList', {}, callBack);
  }

  public getPermissionGroup(permissionGroupId: number, callBack: (value: ApiResponse<ApiResponseItem<PermissionGroup>>) => void) {
    return this.baseService.packageQuery<ApiResponseItem<PermissionGroup>, object>('auth','Permission_group/getPermissionGroup', {pg_id: permissionGroupId}, callBack);
  }

  public addPermissionGroup(data, callBack: (value: ApiResponse<ApiResponseItem<any>>) => void) {
    return this.baseService.packageQuery<ApiResponseItem<any>, object>('auth','Permission_group/addPermissionGroup', data, callBack);
  }

  public updatePermissionGroup(data, callBack: (value: ApiResponse<ApiResponseItem<any>>) => void) {
    return this.baseService.packageQuery<ApiResponseItem<any>, object>('auth','Permission_group/updatePermissionGroup', data, callBack);
  }

  public deletePermissionGroup(permissionGroupId, callBack: (value: ApiResponse<ApiResponseItem<any>>) => void) {
    return this.baseService.packageQuery<ApiResponseItem<any>, object>('auth','Permission_group/deletePermissionGroup', {pg_id : permissionGroupId}, callBack);
  }


  // jogkör a csoportban

  public getPermissionListFromGroup(permissionGroupId,callBack: (value: ApiResponse<ResultList<PermissionGroup>>) => void) {
    return this.baseService.packageQuery<ResultList<PermissionGroup>, object>('auth','Permission_in_group/getPermissionListFromGroup', {pig_pg_id : permissionGroupId}, callBack);
  }

  public getPermissionFromGroup(id: number, callBack: (value: ApiResponse<ApiResponseItem<PermissionGroup>>) => void) {
    return this.baseService.packageQuery<ApiResponseItem<PermissionGroup>, object>('auth','Permission_in_group/getPermissionFromGroup', {pig_id: id}, callBack);
  }

  public addPermissionToGroup(data, callBack: (value: ApiResponse<ApiResponseItem<any>>) => void) {
    return this.baseService.packageQuery<ApiResponseItem<any>, object>('auth','Permission_in_group/addPermissionToGroup', data, callBack);
  }

  public updatePermissionToGroup(data, callBack: (value: ApiResponse<ApiResponseItem<any>>) => void) {
    return this.baseService.packageQuery<ApiResponseItem<any>, object>('auth','Permission_in_group/updatePermissionToGroup', data, callBack);
  }

  public deletePermissionFromGroup(id, callBack: (value: ApiResponse<ApiResponseItem<any>>) => void) {
    return this.baseService.packageQuery<ApiResponseItem<any>, object>('auth','Permission_in_group/deletePermissionFromGroup', {pig_id : id}, callBack);
  }

  // projektekhez tartozó

  getMenuProjectList(callBack: (value: StartUp[]) => void) {
    this.baseService.packageQuery<ResultList<StartUp>, object>('project','project/getMenuProjectList', {}, (response) => {
      callBack(response.data.list);
    });
  }

  checkProjectPermission(id: number, callBack: (value: string[]) => void) {
    this.baseService.packageQuery<PermissionList, object>('project','Permission/checkProjectPermission', {project_id: id.toString()}, (response) => {
      callBack(response.data.permissions);
    });
  }
}

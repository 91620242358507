import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-agree-disagree-buttons',
  templateUrl: './agree-disagree-buttons.component.html',
  styleUrls: ['./agree-disagree-buttons.component.css']
})
export class AgreeDisagreeButtonsComponent implements OnInit {

  @Input() cancelText:string=this.translateService.instant('common.cancel');
  @Input() agreeText:string='Ok';
  @Input() cancelColor:string='var(--cancelColor)';
  @Input() agreeColor:string='var(--okColor)';
  @Input() innerMargin:number=0;
  @Input() justifyContent:'space-between'|'space-around'|'space-evenly'|'center'='space-between';
  @Input() agreeDisabled:boolean=false;

  @Output() cancel:EventEmitter<void> = new EventEmitter<void>();
  @Output() agree:EventEmitter<void> = new EventEmitter<void>();
  constructor(
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
  }

}

import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {InquiryService} from '../../services/support/inquiry.service';
import {InquiryCategory} from '../../models/inquiry/InquiryCategory.model';
import {InquiryItem} from '../../models/inquiry/InquiryItem.model';

@Component({
  selector: 'app-inquiry-reader',
  templateUrl: './inquiry-reader.component.html',
  styleUrls: ['./inquiry-reader.component.scss']
})
export class InquiryReaderComponent implements OnInit {

  constructor(
    private inquiryService: InquiryService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
  ) { }

  @ViewChild('searcField') shField: ElementRef;
  catMenu = true;
  categoryList: Array<InquiryCategory>;
  inIteimList: Array<InquiryItem>;
  selectedCategory = 0;
  searchStr = '';
  selectedItem: InquiryItem;

  ngOnInit(): void {
    this.getCategoryList();
    this.getInItemList();

    let id = +this.route.snapshot.paramMap.get('id');
    if (id > 0) {
      this.read(id);
    }
  }

  read(id) {
    this.location.replaceState('/inquiry/' + id);
    this.inquiryService.getItem(id, res => {
      this.selectedItem = res.data.item;
      this.selectedCategory = this.selectedItem.in_cat_id;
    });
  }

  toggleCatMenu() {
    this.catMenu = !this.catMenu;
  }

  getCategoryList() {
    this.inquiryService.getCategoryList(res => {
      this.categoryList = res.data.list;
    });
  }

  search(str) {
    this.searchStr = str;
    this.selectedCategory = 0;
    this.selectedItem = null;
    this.getInItemList();
  }

  selectCategory(id) {
    this.selectedCategory = id;
    this.selectedItem = null;
    this.searchStr = '';
    this.shField.nativeElement.value = '';
    this.location.replaceState('/inquiry/list');
    this.getInItemList();
  }

  getInItemList() {
    this.inquiryService.getItemList(res => {
      this.inIteimList = res.data.list;
    });
  }
}

import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-arrow-blue-down',
  templateUrl: './svg-arrow-blue-down.component.html',
  styles: [
  ],
  animations:[
    trigger('openClose',[
      state('open', style({
        transform: 'rotate(90deg)'
      })),
      state('closed', style({
        transform: 'rotate(0deg)'
      })),
      transition('open <=> closed', animate('0.3s ease-in-out'))
    ])

  ]
})
export class SvgArrowBlueDownComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input() isOpen:boolean = true;

  Toggle() {
    this.isOpen = !this.isOpen;
  }
  Open():void{
    this.isOpen = false;
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-add-action',
  templateUrl: './svg-add-action.component.html',
  styles: [
  ]
})
export class SvgAddActionComponent implements OnInit {

  @Input() width:string = '33px';
  constructor() { }

  ngOnInit(): void {
  }

}

<div class="container-fluid pr-0 pl-0 data-content">
  <div class="table-responsive">
    <table class="table a-table">
      <thead>
      <tr class="filter-row">
        <th>
          <input type="text" class="form-control form-control-sm " placeholder="Keresés...">
        </th>
        <th>
          <input type="text" class="form-control form-control-sm " placeholder="Keresés...">
        </th>
        <th>
          <input type="text" class="form-control form-control-sm " placeholder="Keresés...">
        </th>
        <th>
          <input type="text" class="form-control form-control-sm " placeholder="Keresés...">
        </th>
        <th></th>
        <th></th>
        <th></th>
        <th width="150">
          <button class="btn btn-sm btn-primary mx-1" style="width: 30px"><i class="far fa-trash-alt"></i></button>
          <button class="btn btn-sm btn-primary" style="width: 50px" routerLink="/knowledgebase/add" ngbTooltip="Új létrehozása"><i class="fa fa-plus"></i></button>
        </th>
      </tr>

      <tr>
        <th>#</th>
        <th>Cím</th>
        <th>Kategória</th>
        <th>Rövid leírás</th>
        <th>Létrehozva</th>
        <th>Frissítve</th>
        <th>Tag</th>
        <th width="150"></th>
      </tr>
      </thead>
      <tbody>
      <tr class="a-tr" *ngFor="let item of itemList ">
        <td>
          {{item.kb_id}}
        </td>
        <td nowrap>
          {{item.kb_name}}
        </td>
        <td nowrap>
          {{item.kbc_name || '-'}}
        </td>
        <td>
          {{item.kb_shortdesc}}
        </td>
        <td nowrap>
          {{item.kb_date }}
        </td>
        <td nowrap>
          {{item.kb_update != '0000-00-00 00:00:00' ? item.kb_update : '-'}}
        </td>
        <td nowrap>
          <span *ngIf="item.kb_tag !='' " class="badge badge-tag">{{item.kb_tag}}</span>
        </td>
        <td nowrap>
          <button class="btn  btn-sm btn-danger mr-2" style="min-width: 50px; line-height: 12px; border-radius: 8px;" (click)="delete(item?.kb_id)"><i class="fa-trash-alt far"></i></button>
          <button ngbTooltip="részletek" routerLink="/knowledgebase/{{item?.kb_id}}" class="btn btn-sm btn-light ch-icon"><i class="fas fa-chevron-right text-primary"></i></button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

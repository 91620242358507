import {Injectable} from '@angular/core';
import {BaseService} from '../utility/base.service';
import {ApiResponseItem} from '../../models/utility/ApiResponseItem.model';
import {ApiResponse} from '../../models/utility/ApiResponse.model';

@Injectable({
  providedIn: 'root'
})
export class NgLoggerService {
  constructor(
    private baseService: BaseService
  ) {}

  public logError(error, callBack: (value: ApiResponse<any>) => void) {
    return this.baseService.packageQuery<ApiResponseItem<any>, object>('auth', '/ng_logger/addError', {message: JSON.stringify(error)}, callBack);
  }

}

<div class="panels">
  <div *ngIf="authService.currentUserValue.main_permission == 1" class="internal panel">
      <div class="title">
        <h3 translate>startup.internal</h3>
        <app-svg-addinternal class="plus" (click)="addFilePopup.Open(); toInternal=true"></app-svg-addinternal>
      </div>
      <div class="file-list" #internalFcardList *ngIf="!IsEmptyInternal()">
        <div *ngFor="let group of fileGroups">
          <h4 class="filegroup-tag" *ngIf="group.notEmptyInternal() && group.id != 0">{{group.name}}</h4>
          <app-nav-separator class="filegroup-tag" *ngIf="group.id == 0" [text]="'startup.files.other' | translate" [color]="'var(--dark)'" [fontsize]="'inherit'">
          </app-nav-separator>
          <div class="files" >
            <app-file-card *ngFor="let file of group.internalFiltered; let i = index"
            [file]="file"
            (deleteClick)="OnFileDelete(i, group.internalFiltered)"
            (editClick)="OnFileEdit(i, group.internalFiltered, true)"
            (fileClick)="OnFileEdit(i, group.internalFiltered, false)"></app-file-card>
          </div>
        </div>
      </div>
      <div class="subtitle" *ngIf="IsEmptyInternal()" translate>startup.files.internalEmpty</div>
  </div>
  <div *ngIf="authService.currentUserValue.main_permission == 1" class="line"></div>
  <div [ngClass]="{single: authService.currentUserValue.main_permission == 2}" class="external panel">
      <div [ngClass]="{single: authService.currentUserValue.main_permission == 2}" class="title">
        <h3 *ngIf="authService.currentUserValue.main_permission == 1" translate>startup.external</h3>
        <app-svg-addexternal class="plus" (click)="addFilePopup.Open(); toInternal=false"></app-svg-addexternal>
      </div>
      <div class="file-list" #externalFcardList *ngIf="!IsEmptyExternal()">
        <div *ngFor="let group of fileGroups" >
          <h4 class="filegroup-tag" *ngIf="group.notEmptyExternal() && group.id != 0">{{group.name}}</h4>
          <app-nav-separator class="filegroup-tag" *ngIf="group.id == 0" [text]="'startup.files.other' | translate" [color]="'var(--dark)'" [fontsize]="'inherit'">
          </app-nav-separator>
          <div class="files">
            <app-file-card *ngFor="let file of group.externalFiltered; let i = index"
            (deleteClick)="OnFileDelete(i, group.externalFiltered)" [file]="file"
            (editClick)="OnFileEdit(i, group.externalFiltered, true)"
            (fileClick)="OnFileEdit(i, group.externalFiltered, false)"
            ></app-file-card>
          </div>
        </div>
      </div>
      <div class="subtitle" *ngIf="IsEmptyExternal()" translate>startup.files.externalEmpty</div>
  </div>
</div>

<app-popup #addFilePopup>
    <form #addFileForm="ngForm" (ngSubmit)="AddFileSubmit(addFileForm)">
      <input class="popupInput" name="fileName" placeholder="{{'startup.files.placeholder.filename' | translate}}" type="text" ngModel required #fNameInput="ngModel"
      [ngClass]="{invalid: !fNameInput.valid &&addFileForm.submitted}">
      <textarea class="popupTextarea" name="fileDesc" placeholder="{{'startup.files.placeholder.description' | translate}}" id="fd" rows="4" ngModel #fDescInput
      ></textarea>
      <div style="display: flex;">
        <input #fileInput type="file" name="name" style="display: none;" (change)="OnFileInputchanged(fileInput)" />
        <button class="addFileButton" (click)="OnFileSelectInput($event, fileInput)"><span translate>common.addFile</span></button>
        <input class="fnamebox" type="text" name="fileOriginalName" [(ngModel)]="fileOriginalName" required #fONameInput="ngModel"
        [ngClass]="{invalid: !fONameInput.valid &&addFileForm.submitted}" />
      </div>
      <div style="display: flex; justify-content: space-between; margin-top: 30px;">
        <button style="background-color: var(--cancelColor);"  (click)="OnFormCancel($event); addFileForm.resetForm()" translate>common.cancel</button>
        <button style="background-color: var(--okColor);" translate>common.save</button>
      </div>
    </form>
</app-popup>

<app-popup #deletePopup>
  <div class="delete-popup">
    <div>
      <div style="font-weight: bold;" translate>common.deleteQues</div>
      <div *ngIf="selectedFile.list.length>0" style="font-weight: bold;">
        "{{selectedFile.list[selectedFile.index]!=undefined?selectedFile.list[selectedFile.index].fileName:''}}"?
      </div>
    </div>
    <div style="color: var(--light); font-size: 12px; margin: 15px 0 18px 0;" translate>common.deleteAssure</div>
    <div style="display: flex; justify-content: space-between; width: 280px;">
      <button style="background-color: var(--cancelColor);" (click)="deletePopup.Close()" translate>common.cancel</button>
      <button style="background-color: var(--dangerColor);" (click)="RemoveFile()" translate>common.remove</button>
    </div>
  </div>
</app-popup>

<app-popup #editFilePopup [width]="450">
  <app-edit-file-form *ngIf="selectedFile.list[selectedFile.index]!=null"
  [file]="selectedFile.list[selectedFile.index]" [isEditAllowed]="isEditablePopup"
  (cancel)="editFilePopup.Close()" (save)="OnFileSaved($event)"></app-edit-file-form>
</app-popup>
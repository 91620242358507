import { Component, Input, OnInit, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormValue, DataMatrix } from 'src/app/models/dashboard/InputField.model';
import { Language } from 'src/app/models/Language.model';
import { StepsMakerService } from 'src/app/services/project/form/steps-maker.service';

@Component({
  selector: 'app-data-matrix',
  templateUrl: './data-matrix.component.html',
  styleUrls: ['./data-matrix.component.css']
})
export class DataMatrixComponent implements OnInit {

  constructor(
    private stepsService: StepsMakerService,
  ) { }

  @Input() dataMatrix:DataMatrix;
  @Input() currentLang:string;
  @Input() editmode:boolean = false;
  @Input() readonly:boolean = false;
  @Output() nameChanged:EventEmitter<void> = new EventEmitter<void>();
  @Output() descriptionChanged:EventEmitter<void> = new EventEmitter<void>();
  @Output() requiredChanged:EventEmitter<void> = new EventEmitter<void>();
  isLabelEdit:boolean = false;
  isDescriptionEdit:boolean = false;
  
  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.editmode && changes.editmode.currentValue == false) {
      this.isLabelEdit = false;
      this.isDescriptionEdit = false;
      this.isOptionEdit = {type:-1, id:-1};
    }
  }

  labelCopy: string;
  OnLabelDoubleClick():void{
    if(this.editmode) {
      this.isLabelEdit = true;
      this.labelCopy = this.dataMatrix.name[this.currentLang];
    }
  }

  OnLabelEnter():void{
    if(this.dataMatrix.name[this.currentLang] != "") {
      this.nameChanged.emit();
    } else {
      this.dataMatrix.name[this.currentLang] = this.labelCopy;
    }
    this.isLabelEdit = false;
  }

  descriptionCopy: string;
  OnDescriptionDoubleClick():void{
    if(this.editmode) {
      this.isDescriptionEdit = true;
      this.descriptionCopy = this.dataMatrix.small[this.currentLang];
    }
  }

  OnDescriptionEnter():void{
    this.descriptionChanged.emit();
    this.isDescriptionEdit = false;
  }

  OnRequiredClicked():void{
    this.requiredChanged.emit();
  }

  OnChecked(i:number, j:number, checkbox:HTMLInputElement):void{
    if(checkbox.checked){
      this.dataMatrix.values[i][j]=true;
    }else{
      this.dataMatrix.values[i][j]=false;
    }
  }

  OnAddClicked(type: number):void{
    if(type==1) {
      let value: FormValue = new FormValue(0, new Language("Oszlop" + (+this.dataMatrix.labelsHorizontal.length+1), "Column" + (+this.dataMatrix.labelsHorizontal.length+1)));
      this.stepsService.AddFormValue(this.dataMatrix.id, value, type, backValue => {
        this.dataMatrix.labelsHorizontal.push(backValue);
        for(let row of this.dataMatrix.values) {
          row.push(false);
        }
      });
    } else if(type==0) {
      let value: FormValue = new FormValue(0, new Language("Sor" + (+this.dataMatrix.labelsVertical.length+1), "Row" + (+this.dataMatrix.labelsVertical.length+1)));
      this.dataMatrix.labelsVertical.push();
      this.stepsService.AddFormValue(this.dataMatrix.id, value, type, backValue => {
        this.dataMatrix.labelsVertical.push(backValue);
        let row: boolean[] = []
        for (let j = 0; j < this.dataMatrix.labelsHorizontal.length; j++) {
          row.push(false);
        }
        this.dataMatrix.values.push(row);
      });
    }
  }

  OnRadioRemoveClicked(type: number, idx: number):void{
    if(type==1) {
      this.stepsService.DeleteFormValue(this.dataMatrix.labelsHorizontal[idx].id);
      this.dataMatrix.labelsHorizontal.splice(idx,1);
      for(let row of this.dataMatrix.values) {
        row.splice(idx,1);
      }
    } else if(type==0) {
      this.stepsService.DeleteFormValue(this.dataMatrix.labelsVertical[idx].id);
      this.dataMatrix.labelsVertical.splice(idx,1);
      this.dataMatrix.values.splice(idx,1);
    }
  }

  isOptionEdit:{type: number, id: number} = {type:-1, id:-1};
  OnOptionDoubleClick(type: number, id: number):void{
    if(this.editmode)
      this.isOptionEdit = {type:type, id:id};
  }

  OnOptionEnter(event:Event, id:number):void{
    if((event.target as HTMLInputElement).value != "") {
      if(this.isOptionEdit.type==1){
        this.dataMatrix.labelsHorizontal[id].value[this.currentLang] = (event.target as HTMLInputElement).value;
        this.stepsService.UpdateFormValue(this.dataMatrix.labelsHorizontal[id]);
      } else if(this.isOptionEdit.type==0) {
        this.dataMatrix.labelsVertical[id].value[this.currentLang] = (event.target as HTMLInputElement).value;
        this.stepsService.UpdateFormValue(this.dataMatrix.labelsVertical[id]);
      }
    }
    this.isOptionEdit = {type:-1, id:-1};
  }

  isMobileResolution(): boolean {
    if(window.innerWidth<=768){
      return true;
    } else {
      return false;
    }
  }
}

<div class="header">
  <h1 translate>projects.name</h1>
  <input type="text" placeholder="{{'common.search' | translate}}" class="search-input" #filterField (input)="OnInputFiled(filterField.value)">
</div>

<div *ngIf="loading" class="loader-wrapper">
  <div class="loader" id="loader" name="loader"></div>
</div>

<div id="card-list" *ngFor="let step of steps">
  <app-project-card class="card disableSelect" *ngFor="let startup of step.startups; let i = index" [startup]="startup"></app-project-card>
</div>
<div style="display: flex; align-content: center; margin-bottom: 15px;">
  <div class="wrapper">
    <div class="popup-input dropdown">
      <input #inputField type="text" placeholder="{{'general.editForms.placeholder.addNew' | translate}}" class="popup-input" (input)="filterProjects()">
      <div class="dropdown-content" [ngStyle]="{'height.px': isDown?getHeight():0}">
        <div *ngFor="let project of filteredProjects" (click)="OnSelect(project)">
          <div>{{project.startup}}</div>
        </div>
      </div>
    </div>
  </div>
</div>

<div style="height: 250px; overflow-y: auto; font-size: 12px;" class="disableScrollBar disableSelect example-list">
  <div *ngFor="let project of projectsCopy; let i = index" class="col-item">
    <div>{{project.startup}}</div>
    <div>{{project.internalLeader?project.internalLeader.email:""}}</div>
    <button *ngIf="projects.includes(project)" (click)="OnBtnClick(project)" translate>general.editForms.active</button>
    <button *ngIf="!projects.includes(project)" [ngClass]="{'button-inactive': !projects.includes(project)}" (click)="OnBtnClick(project)" translate>general.editForms.inactive</button>
  </div>
</div>

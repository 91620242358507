import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.css']
})
export class ColorPickerComponent implements OnInit {

  constructor() { }

  @Input() text: string = 'color';
  @Input() outputFormat: 'rgb' | 'hex' = 'rgb';
  @Input() isDefaultBase: boolean = true;

  @Input() left: number = 4;
  @Input() top: number = 20;

  selectedwidth: number;
  isOpen: boolean = false;


  @Input() color: string = '#FFFFFF';
  @Output() colorChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() closed: EventEmitter<string> = new EventEmitter<string>();
  ngOnInit(): void {
  }

  OnColorChange(color: string): void {
    if (this.isOpen) {
      this.color = color;
      this.colorChanged.emit(this.color);
    }
  }

  OnInvClicked(): void {
    this.Close();
  }

  Open(): void {
    this.isOpen = true;
  }

  Close(): void {
    this.isOpen = false;
    this.closed.emit(this.color);
  }

}

import { Subject } from 'rxjs';
import { ResultList } from "src/app/models/utility/ResultList.model";
import { Task } from "src/app/models/Task.model";
import { TaskResponse } from "src/app/models/TaskResponse.model";
import { BaseService } from "../../utility/base.service";
import { JWTToken } from 'src/app/models/utility/JWTToken.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class TaskService{

  taskListInternal:Task[] = [];
  taskListInternalDone:Task[] = [];
  taskListExternal:Task[] = [];
  taskListExternalDone:Task[] = [];

  subject = new Subject();

  constructor(
    protected baseService: BaseService,
    protected authService: AuthService,
  ){ }

  GetAllTasksBy(id:number) {
    let taskList:Task[] = [];
    this.baseService.packageQuery<JWTToken, object>('project','task/getTaskList', {task_responsible: id.toString()}, (response) => {
      response.data.list.forEach(task => {
        var actual = new Task();
        actual.id = task.task_id;
        actual.sort = task.task_sort;
        actual.startupId = task.task_project_id;
        actual.label = task.task_title;
        actual.date = new Date(task.task_deadline);
        actual.isDone = task.task_completed == "1";
        actual.priority = task.task_priority;
        actual.assignedTo = task.task_responsible_user;
        if(!actual.isDone) {
          taskList.push(actual);
        }
      });
      taskList.sort((a:Task, b:Task) => a.date.getTime() - b.date.getTime());
      this.subject.next(taskList);
    });
  }

  RefreshTaskList(id:string, status:string, callBack: () => void) {
    this.baseService.packageQuery<JWTToken, object>('project','task/getTaskList', {task_project_id: id, task_status: status}, (response) => {
      this.taskListInternal = [];
      this.taskListInternalDone = [];
      this.taskListExternal = [];
      this.taskListExternalDone = [];
      response.data.list.forEach(task => {
        var actual = new Task();
        actual.id = task.task_id;
        actual.sort = task.task_sort;
        actual.startupId = parseInt(id);
        actual.label = task.task_title;
        actual.date = task.task_deadline;
        actual.isDone = task.task_completed == "1";
        actual.priority = task.task_priority;
        actual.assignedTo = task.task_responsible_user;
        if(task.task_internal == 1) {
          actual.isDone ? this.taskListInternalDone.push(actual) : this.taskListInternal.push(actual);
        } else {
          actual.isDone ? this.taskListExternalDone.push(actual) : this.taskListExternal.push(actual);
        }
      });
      this.taskListInternal.sort((a:Task, b:Task) => a.sort - b.sort);
      this.taskListInternalDone.sort((a:Task, b:Task) => a.sort - b.sort);
      this.taskListExternal.sort((a:Task, b:Task) => a.sort - b.sort);
      this.taskListExternalDone.sort((a:Task, b:Task) => a.sort - b.sort);
      callBack();
    });
  }

  FilterTasks(input:string, isInternal:boolean):{inProc:Task[], isDone:Task[]}{
    let result:{inProc:Task[], isDone:Task[]} = {inProc:[], isDone:[]};
    if(isInternal){
      result.inProc = this.taskListInternal.filter(x=>x.label.toLowerCase().match(input.toLowerCase())!=null);
      result.isDone = this.taskListInternalDone.filter(x=>x.label.toLowerCase().match(input.toLowerCase())!=null);
    }else{
      result.inProc = this.taskListExternal.filter(x=>x.label.toLowerCase().match(input.toLowerCase())!=null);
      result.isDone = this.taskListExternalDone.filter(x=>x.label.toLowerCase().match(input.toLowerCase())!=null);
    }
    return result;
  }

  addTask(id:string, status:string, author: number, task: Task, internal: string, callBack: () => void) {
    this.baseService.packageQuery<ResultList<TaskResponse>, object>('project','task/addTask', {
      task_creating_user: author,
      task_responsible: author,     //TODO: nincs meg az adat. Person-ök listából választunk, nekik nincs ID-jük
      task_deadline: task.date,
      task_priority: task.priority.toString(),
      task_title: task.label,
      task_internal: internal,
      task_completed: task.isDone ? "1" : "0",
      task_sort: "0",
      task_project_id: id,
      task_status: status
    }, () => {
      //TODO: ellenőrizni kéne, hogy tényleg a végére kerül-e
      if(internal === "1" && task.isDone) {
        this.taskListInternalDone.push(task);
      } else if(internal === "1") {
        this.taskListInternal.push(task);
      } else if(task.isDone) {
        this.taskListExternalDone.push(task);
      } else {
        this.taskListExternal.push(task);
      }
      callBack();
    });
  }

  TickTask(index:number, tick: boolean, callBack: () => void){
    this.baseService.packageQuery<JWTToken, object>('project', 'task/updateTask', {task_id: index.toString(), task_completed: tick}, () => {
      callBack();
    });
  }

  FlagTask(index:number, priority: number, callBack: () => void){
    this.baseService.packageQuery<JWTToken, object>('project', 'task/updateTask', {task_id: index.toString(), task_priority: priority}, () => {
      callBack();
    });
  }

  UpdateTask(author: number, task: Task, internal: string, callBack: () => void){
    this.baseService.packageQuery<JWTToken, object>('project', 'task/updateTask', {
      task_id: task.id,
      task_responsible: author,     //TODO: nincs meg az adat. Person-ök listából választunk, nekik nincs ID-jük
      task_deadline: task.date,
      task_priority: task.priority,
      task_title: task.label,
      task_internal: internal,
      task_completed: task.isDone ? "1" : "0"
    }, () =>{
      if(internal == "1") {
        if(task.isDone) {
          this.taskListInternalDone[this.taskListInternalDone.findIndex(t => t.id == task.id)] = task;
        }
        else {
          this.taskListInternal[this.taskListInternal.findIndex(t => t.id == task.id)] = task;
        }
      }
      else {
        if(task.isDone) {
          this.taskListExternalDone[this.taskListExternalDone.findIndex(t => t.id == task.id)] = task;
        }
        else {
          this.taskListExternal[this.taskListExternal.findIndex(t => t.id == task.id)] = task;
        }
      }
      callBack();
    });
  }

  SortTasks(tasks:number[]){
    this.baseService.packageQuery<JWTToken, object>('project', 'task/setSortOrder', {sortOrder: tasks}, () => {});
  }
}

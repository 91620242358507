import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-timeline-tag',
  templateUrl: './timeline-tag.component.html',
  styleUrls: ['./timeline-tag.component.css']
})
export class TimelineTagComponent implements OnInit {

  constructor() { }

  @Input() color:string="var(--okColor)";

  ngOnInit(): void {
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { StartupFilter, Step } from 'src/app/models/project/Startup.model';
import { StartupService } from 'src/app/services/project/startup.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {

  width: number = -1;
  currentLang: string;
  loading: boolean = true;
  steps: Step[] = [];
  startupFilter:StartupFilter = new StartupFilter();
  @Input() controlColor:string='var(--accentNormal)';

  constructor(
    public startupService: StartupService,
    private cookieService: CookieService,
    private router: Router,
  ) {
    this.startupService.GetStartupsBySteps((steps: Step[]) => {
      this.steps = steps;
      this.loading = false;
    });
    this.currentLang = this.cookieService.get("language") || "hu";
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    if(window.innerWidth <= 768) {
      this.width = (document.getElementsByClassName("filter-form")[0] as HTMLElement).offsetWidth;
    }
  }

  OnInputFiled(input:string):void{
    this.startupFilter.filter_search = input;
    this.startupService.FilterStartups(this.startupFilter, (steps: Step[]) => {
      this.steps = steps;
    });
  }

  OnCardClick(i:number){
    this.router.navigate(['startup', i, 'notes']);
  }
}

<div class="header">
  <input *ngIf="isLabelEdit" class="label-input" type="text" [placeholder]="labelCopy" [(ngModel)]="radioButton.name[currentLang]" (keydown.enter)="OnLabelEnter()">
  <div class="label" *ngIf="!isLabelEdit && radioButton!=undefined" (dblclick)="OnLabelDoubleClick();">{{radioButton.GetName(currentLang)}}<span>{{radioButton.required?'*':''}}</span></div>
  <app-svg-add-action [width]="18" *ngIf="editmode && radioButton.options.length<7" (click)="OnAddClicked()"></app-svg-add-action>
  <div class="important-container" *ngIf="editmode">
    <input type="checkbox" id="important" name="important" [(ngModel)]="radioButton.required" (change)="OnRequiredClicked()">
    <label class="importantLabel disableSelect">Important</label>
  </div>
</div>
<div class="description">
  <input *ngIf="isDescriptionEdit" class="label-input" type="text" [placeholder]="descriptionCopy" [(ngModel)]="radioButton.small[currentLang]" (keydown.enter)="OnDescriptionEnter()">
  <div class="desc" *ngIf="!isDescriptionEdit && radioButton!=undefined" (dblclick)="OnDescriptionDoubleClick();">{{editmode?(radioButton.GetDescription(currentLang)==""?('steps.input.doubleClick' | translate):radioButton.GetDescription(currentLang)):radioButton.GetDescription(currentLang)}}</div>
</div>
<div class="radio-wrapper">
  <div class="radio" *ngFor="let radio of radioButton.options; let i = index" [style.flex-grow]="radioButton.options.length">
    <input type="radio" [id]="'rb'+i+radioId" [name]="'rb'+radioId" (click)="OnCheckClicked(i)" [disabled]="readonly">
    <input class="text-input" type="text" [placeholder]="radio.GetValue(currentLang)" *ngIf="isOptionEdit==i" (keydown.enter)="OnOptionEnter($event, i)">
    <label [for]="'rb'+i+radioId" *ngIf="isOptionEdit!=i && radioButton!=undefined" (dblclick)="OnOptionDoubleClick(i);">{{radio.GetValue(currentLang)}}</label>
    <app-svg-step-remove [width]="18" *ngIf="editmode" (click)="OnRadioRemoveClicked(i)"></app-svg-step-remove>
  </div>
  <div class="other">
    <input type="radio" [id]="'rbo'" [name]="'rb'+radioId" (click)="OnOtherCheckChanged()" [disabled]="readonly">
    <input #textinput class="text-input" type="text" placeholder="Other" [disabled]="!isOther" [(ngModel)]="radioButton.otherValue.value[currentLang]">
  </div>
</div>

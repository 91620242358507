import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { BaseService } from '../utility/base.service';
import {JWTToken} from '../../models/utility/JWTToken.model';
import { ApiResponse } from '../../models/utility/ApiResponse.model';
import { Industry } from '../../models/project/Industry.model';
import { ResultList } from '../../models/utility/ResultList.model';
import { ApiResponseItem } from '../../models/utility/ApiResponseItem.model';

@Injectable({
  providedIn: 'root'
})
export class IndustryService {

  industries:Industry[] = [];
  industryStrings:string[] = []
  industryIds:number[] = [];
  selected:Industry = {};
  constructor(
    protected baseService: BaseService,
    private router:Router,
  ) {
    this.GetIndustriesList(() => {})
  }

  GetIndustriesList(callBack: (value: ApiResponse<ResultList<Industry>>) => void) {
    return this.baseService.packageQuery<ResultList<Industry>, object>('sup', 'Setting_industries/getIndustriesList', {}, (response) => {
      this.industries = response.data.list;
      this.industryStrings = [];
      this.industryIds = [];
      response.data.list.forEach(industry => {
        this.industryStrings.push(industry.industry_name);
        this.industryIds.push(industry.industry_id);
      });
      callBack(response);
    });
  }

  AddIndustry(industry:string) {
    return this.baseService.packageQuery<JWTToken, object>('sup', 'Setting_industries/addIndustry', {industry_name: industry}, (response) => {
      this.industryStrings.splice(response.data.item, 0, industry);
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });
    });
  }

  SelectIndustry(idx:number, callBack: () => void){
    this.baseService.packageQuery<JWTToken, object>('sup', 'Setting_industries/getIndustry', {industry_id: this.industryIds[idx]}, (response) => {
      this.selected = response.data.item;
      callBack();
    });
  }

  getIndustry(id: number, callBack: (value: ApiResponse<ApiResponseItem<Industry>>) => void) {
    return this.baseService.packageQuery<ApiResponseItem<Industry>, object>('sup', 'Setting_industries/getIndustry', {industry_id: id}, callBack);
  }

  DeleteSelectedIndustry(callBack: () => void) {
    if(this.selected!={}) {
      this.baseService.packageQuery<JWTToken, object>('sup', 'Setting_industries/deleteIndustry', {industry_id: this.selected.industry_id}, () => {
        this.selected = {};
        this.industryStrings.splice(this.selected.industry_id+1, 1);
        callBack();
      });
    } else throwError("No industry selected!!");
  }

  UpdateSelectedIndustry(industry:string, callBack: () => void) {
    return this.baseService.packageQuery<JWTToken, Industry>('sup', 'Setting_industries/updateIndustry', {industry_id: this.selected.industry_id, industry_name: industry, industry_removed: this.selected.industry_removed}, () => {
      this.selected = {};
      callBack();
    });
  }
}
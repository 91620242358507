<div class="note-card" [ngClass]="{'done': tick}">
  <div class="start">
    <ng-content></ng-content>
    <app-ticker [(tick)]="tick" (tickChange)="onDonerClicked()"></app-ticker>
  </div>
  <div class="content">
    <div class="top">
      <span class="title">{{task.label}}</span>
      <span class="startup-title" *ngIf="!isStartup && !tick">{{task.startupName}}</span>
    </div>
    <div class="middle">
      <span class="description">{{task.description}}</span>
      <img class="assigned-img" [src]="task.assignedTo.image_hash">
    </div>
    <div class="bottom">
      <span>{{Utils.stringifyDateEurope(task.dueDate)}}</span>
      <div class="divider"></div>
      <span>{{TaskTypeDisplay[task.type]}}</span>
      <app-svg-menu class="menu" [matMenuTriggerFor]="menu"></app-svg-menu>
      <mat-menu #menu="matMenu">
        <button (click)="emitEditTask()" mat-menu-item>Edit</button>
        <button (click)="emitRemoveTask()" mat-menu-item>Remove</button>
      </mat-menu>
    </div>
  </div>
</div>

<div class="container" (click)="direct()">
  <div class="top-head">
    <div class="datas">
      <img [src]="startup.project_logo ?? '/assets/test-brand.png'" alt="image">
      <div>
        <span class="title" [innerHTML]="highlight()"></span>
        <span class="industry" [ngClass]="{'highlight':filter.filter_industry.includes(startup.details.industry.industry_id)}">{{startup.details.industry?.industry_name}}</span>
      </div>
    </div>
    <img id="ppic" [src]="startup.details.project_manager_details.image_hash ?? '/assets/test-profile-pic.png'" alt="pic" [ngClass]="{'highlight':filter.filter_manager.includes(startup.details.project_manager_details.id)}">
  </div>

  <div class="info">
    <div class="info-row">
      <span class="lighet" translate>dealFlow.flowStep.startUpCard.fundSize</span>
      <span class="bold">€ {{startup.project_fund}}</span>
    </div>
    <div class="info-row">
      <span class="lighet" translate>dealFlow.flowStep.startUpCard.country</span>
      <span class="bold">{{startup.project_country}}</span>
    </div>
    <div class="info-row">
      <span class="lighet" [ngClass]="{'highlight':filter.filter_industry.includes(startup.details.industry.industry_id)}" translate>dealFlow.flowStep.startUpCard.industry</span>
      <span class="bold" [ngClass]="{'highlight':filter.filter_industry.includes(startup.details.industry.industry_id)}">{{startup.details.industry?.industry_name}}</span>
    </div>
    <div class="info-row">
      <span class="lighet" translate>dealFlow.flowStep.startUpCard.dueDate</span>
      <span class="bold">{{GetDateString(startup.project_step_due_date)}}</span>
    </div>
  </div>
</div>
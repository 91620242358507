import { Injectable } from '@angular/core';
import { BaseService } from '../../utility/base.service';
import { JWTToken } from 'src/app/models/utility/JWTToken.model';
import { User } from "src/app/models/project/User.model";

@Injectable({
  providedIn: 'root'
})
export class TeamService{

  internalTeam:User[] = [];
  internalTeamIds:number[] = [];
  externalTeam:User[] = [];
  externalTeamIds:number[] = [];

  constructor(
    protected baseService: BaseService,
  ){ }

  RefreshTeamList(id:string, status:string, callBack: () => void) {
    return this.baseService.packageQuery<JWTToken, object>('project', 'team/getTeamList', {project_id: id}, (response) => {
      this.internalTeam = [];
      this.internalTeamIds = [];
      this.externalTeam = [];
      this.externalTeamIds = [];
      response.data.list.forEach(team => {
        if(team.team_project_status == status) {
          let person: User = {};
          person.name = team.team_name;
          if(team.picture) {
            person.picture = team.picture;
          } else {
            person.picture = '/assets/test-profile-pic.png'
          }
          if(team.user_id) {
            person.id = parseInt(team.user_id);
          }

          person.email = team.team_email;
          person.phone = team.team_phone;
          person.url = team.team_linkedin;
          person.jobTitle = team.team_position;
          person.isTaged = team.team_contact == 0 ? false : true;
          if(team.team_internal == 1) {
            if(person.isTaged) {
              this.internalTeam.unshift(person);
              this.internalTeamIds.unshift(team.team_id);
            } else {
              this.internalTeam.push(person);
              this.internalTeamIds.push(team.team_id);
            }
          } else {
            if(person.isTaged) {
              this.externalTeam.unshift(person);
              this.externalTeamIds.unshift(team.team_id);
            } else {
              this.externalTeam.push(person);
              this.externalTeamIds.push(team.team_id);
            }
          }
        }
      });
      callBack();
    });
  }

  // Tényleges hozzáadás
  AddPersonInternal(person:User, id:string, status: string, callBack: () => void):void{
    console.log(person);
    
    this.baseService.packageQuery<JWTToken, object>('project', 'team/addTeamFromInternal', {team_name: person.name, user_id: person.id, team_position: person.jobTitle, team_phone: person.phone, team_email: person.email, team_linkedin: person.url, team_project_status: status, project_id: id}, () => {
      this.RefreshTeamList(id, status, () => {
        callBack();
      });
    });
  }
/*
  // Projekthez való hozzáadás
  AddPersonInternal(index:number, person:User, id:string, callBack: () => void):void{
    this.baseService.packageQuery<JWTToken, object>('project', 'team/updateTeam', {team_id: index, team_name: person.name, team_position: person.jobTitle, team_phone: person.phone, team_email: person.email, team_linkedin: person.url, team_internal: "1", team_contact: false, project_id: id}, () => {
      this.internalTeam.push(person);
      this.internalTeamIds.push(index);
      callBack();
    });
  }*/

  AddPersonExternal(email:string, id:string, status:string, callBack: () => void):void{
    this.baseService.packageQuery<JWTToken, object>('project', 'team/addTeamFromExternal', {team_email: email, team_project_status: status, project_id: id}, () => {
      callBack();
    });
  }

  // Tényleges törlése az embereknek
  RemovePersonInternal(index:number, id:string, status:string, callBack: () => void){
    this.baseService.packageQuery<JWTToken, object>('project', 'team/deleteTeam', {team_id: this.internalTeamIds[index]}, () => {
      this.RefreshTeamList(id, status, () => {
        callBack();
      });
    });
  }
/*
  // Projektből való törlés
  RemovePersonInternal(index:number, callBack: () => void){
    this.baseService.packageQuery<JWTToken, object>('project', 'team/updateTeam', {team_id: this.internalTeamIds[index], team_name: this.internalTeam[index].name, team_position: this.internalTeam[index].jobTitle, team_phone: this.internalTeam[index].phone, team_email: this.internalTeam[index].email, team_linkedin: this.internalTeam[index].url, team_internal: "1", team_contact: false, project_id: "-1"}, () => {
      this.internalTeam.splice(index, 1);
      this.internalTeamIds.splice(index, 1);
      callBack();
    });
  }
*/
  RemovePersonExternal(index:number, callBack: () => void){
    this.baseService.packageQuery<JWTToken, object>('project', 'team/deleteTeam', {team_id: this.externalTeamIds[index]}, () => {
      this.externalTeam.splice(index, 1);
      this.externalTeamIds.splice(index, 1);
      callBack();
    });
  }

  TagPersonInternal(index:number, projectId:string, status:string, callBack: () => void){
    let id = this.GetTaggedInternal();
    if(id != null) {
      this.baseService.packageQuery<JWTToken, object>('project', 'team/updateTeam', {team_id: this.internalTeamIds[id], team_name: this.internalTeam[id].name, team_position: this.internalTeam[id].jobTitle, team_phone: this.internalTeam[id].phone, team_email: this.internalTeam[id].email, team_linkedin: this.internalTeam[id].url, team_internal: "1", team_contact: !this.internalTeam[id].isTaged, project_id: projectId}, () => {
        this.baseService.packageQuery<JWTToken, object>('project', 'team/updateTeam', {team_id: this.internalTeamIds[index], team_name: this.internalTeam[index].name, team_position: this.internalTeam[index].jobTitle, team_phone: this.internalTeam[index].phone, team_email: this.internalTeam[index].email, team_linkedin: this.internalTeam[index].url, team_internal: "1", team_contact: !this.internalTeam[index].isTaged, project_id: projectId}, () => {
          this.RefreshTeamList(projectId, status, () => {
            callBack();
          });
        });
      });
    } else {
      this.baseService.packageQuery<JWTToken, object>('project', 'team/updateTeam', {team_id: this.internalTeamIds[index], team_name: this.internalTeam[index].name, team_position: this.internalTeam[index].jobTitle, team_phone: this.internalTeam[index].phone, team_email: this.internalTeam[index].email, team_linkedin: this.internalTeam[index].url, team_internal: "1", team_contact: !this.internalTeam[index].isTaged, project_id: projectId}, () => {
        this.RefreshTeamList(projectId, status, () => {
          callBack();
        });
      });
    }
  }

  TagPersonExternal(index:number, projectId:string, status:string, callBack: () => void){
    let id = this.GetTaggedExternal();
    if(id != null) {
      this.baseService.packageQuery<JWTToken, object>('project', 'team/updateTeam', {team_id: this.externalTeamIds[id], team_name: this.externalTeam[id].name, team_position: this.externalTeam[id].jobTitle, team_phone: this.externalTeam[id].phone, team_email: this.externalTeam[id].email, team_linkedin: this.externalTeam[id].url, team_internal: "0", team_contact: !this.externalTeam[id].isTaged, project_id: projectId}, () => {
        this.baseService.packageQuery<JWTToken, object>('project', 'team/updateTeam', {team_id: this.externalTeamIds[index], team_name: this.externalTeam[index].name, team_position: this.externalTeam[index].jobTitle, team_phone: this.externalTeam[index].phone, team_email: this.externalTeam[index].email, team_linkedin: this.externalTeam[index].url, team_internal: "0", team_contact: !this.externalTeam[index].isTaged, project_id: projectId}, () => {
          this.RefreshTeamList(projectId, status, () => {
            callBack();
          });
        });
      });
    } else {
      this.baseService.packageQuery<JWTToken, object>('project', 'team/updateTeam', {team_id: this.externalTeamIds[index], team_name: this.externalTeam[index].name, team_position: this.externalTeam[index].jobTitle, team_phone: this.externalTeam[index].phone, team_email: this.externalTeam[index].email, team_linkedin: this.externalTeam[index].url, team_internal: "0", team_contact: !this.externalTeam[index].isTaged, project_id: projectId}, () => {
        this.RefreshTeamList(projectId, status, () => {
          callBack();
        });
      });
    }
  }

  GetTaggedInternal(): number {
    for (let i = 0; i < this.internalTeam.length; i++) {
      if(this.internalTeam[i].isTaged) {
        return i;
      }
    }
    return null;
  }

  GetTaggedExternal(): number {
    for (let i = 0; i < this.externalTeam.length; i++) {
      if(this.externalTeam[i].isTaged) {
        return i;
      }
    }
    return null;
  }

  FilterTeam(input:string, isInternal:boolean):User[]{
    let result:User[] = [];
    if(isInternal){
      result = this.internalTeam.filter(x=>x.name.toLowerCase().match(input.toLowerCase())!=null);
    }else{
      result = this.externalTeam.filter(x=>x.name.toLowerCase().match(input.toLowerCase())!=null);
    }
    return result;
  }
}

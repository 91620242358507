import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { StartUp } from 'src/app/models/project/Startup.model';
import { FilterService } from 'src/app/services/utility/filter.service';
import { InternalPersonsService } from 'src/app/services/project/internal-persons.service';
import { StartupService } from 'src/app/services/project/startup.service';
import { PopupComponent } from '../../../shared/components/popup/popup.component';
import { Subscription } from 'rxjs';
import { PermissionService } from 'src/app/services/auth/permission.service';

@Component({
  selector: 'app-startup',
  templateUrl: './startup.component.html',
  styleUrls: ['./startup.component.scss']
})
export class StartUpComponent implements OnInit {

  startup: StartUp;
  @ViewChild('newEmailPopup') newEmailPopup: PopupComponent;

  notesPermission: boolean = false;
  teamPermission: boolean = false;
  filesPermission: boolean = false;
  tasksPermission: boolean = false;
  stepsPermission: boolean = false;

  isSettingsClicked: boolean = false;
  private routeSub: Subscription;
  constructor(
    public internalPersonService: InternalPersonsService,
    private router: Router,
    private route: ActivatedRoute,
    private filterService: FilterService,
    private startupService: StartupService,
    private permissionService: PermissionService,
  ) {
    this.routeSub = this.route.params.subscribe(params => {
      this.startupService.GetProject(params['id'], (startup) => {
        this.startup = startup;
      });
      this.permissionService.checkProjectPermission(params['id'], (list) => {
        this.notesPermission = list.includes("menu_notes");
        this.teamPermission = list.includes("menu_team");
        this.filesPermission = list.includes("menu_files");
        this.tasksPermission = list.includes("menu_tasks");
        this.stepsPermission = list.includes("menu_steps");
      });
    });
  }

  ngOnInit(): void {
  }
  ngOnDestroy() {
    if(this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }
  OnSettingsClicked(): void {
    this.isSettingsClicked = !this.isSettingsClicked;
  }
  OnPortfolioManagerSelected(): void {
    this.router.navigate(['portfolio']);
  }
  OnSearchChangedInternal(value: string): void {
    this.filterService.UpdateInput(value);
  }

  NewGroupMessage(event: Event): void {
    console.log(event);
    this.newEmailPopup.Close();
  }

}

import { User } from "./project/User.model";

export class Task {
  id: number;
  sort: number;
  label: string;
  description: string = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dui diam venenatis aliquet quis donec tortor et pretium. Sit purus, accumsan feugiat dictumst dignissim accumsan ornare ullamcorper. Iaculis pellentesque convallis consectetur mi metus vitae. Gravida mattis suspendisse aliquam quisque. Convallis at nibh fringilla mauris ultrices eros, porttitor. Dignissim id mauris rhoncus aenean interdum varius sit. Consectetur pulvinar tincidunt at at lorem sodales mauris enim nibh. Odio dui, amet mattis habitant velit netus donec. Orci sapien, rutrum posuere id tempus sem lectus amet. Proin sollicitudin ornare in aenean semper odio convallis in tristique. Ipsum purus condimentum aenean nullam ipsum est adipiscing. In semper malesuada pellentesque sollicitudin nibh nec, adipiscing iaculis. Malesuada gravida ultrices at elementum. Lorem quis nulla enim hendrerit morbi condimentum nunc.";
  type: TaskType = TaskType.Penzugyi;
  date: Date;
  dueDate: Date = new Date();
  assignedTo: User;
  template: string; //TODO: mi ez
  priority: number = 0;
  isDone: boolean = false;
  startupId: number;
  startupName: string = "TestName Comp";
  severity: number;//TODO: mi ez
}

export let emptyTask: Task = {
  assignedTo: undefined,
  date: new Date(),
  description: "",
  dueDate: new Date(),
  id: -1,
  isDone: false,
  label: "",
  priority: 0,
  severity: undefined,
  sort: undefined,
  startupId: undefined,
  startupName: undefined,
  template: undefined,
  type: undefined
};

export enum TaskType {
  Penzugyi,
  Jogi
}

export let TaskTypeDisplay = ["Pénzügyi", "Jogi"];
<div class="startup-navigator" style="display: flex; justify-content: space-between; align-items: center; ">
  <div style=" height: auto;">
    <span [routerLink]="['notes']" routerLinkActive #rln="routerLinkActive" [ngClass]="{'notes-active':rln.isActive}" >NOTES</span>
    <span [routerLink]="['team']" routerLinkActive #rlt="routerLinkActive" [ngClass]="{'team-active':rlt.isActive}">TEAM</span>
    <span [routerLink]="['files']" routerLinkActive #rlf="routerLinkActive" [ngClass]="{'files-active':rlf.isActive}">FILES</span>
    <span [routerLink]="['tasks']" routerLinkActive #rlta="routerLinkActive" [ngClass]="{'tasks-active':rlta.isActive}">TASKS</span>

    <span *ngIf="!isPortfolio" [routerLink]="['steps/appquest']" routerLinkActive #rlts="routerLinkActive" [ngClass]="{'steps-active':rlts.isActive}">STEPS</span>
  </div>
  <div class="settings-wrap disableSelect">
    <svg-icon class="settings" src="/assets/startupDataboard/settings.svg" [svgStyle]="{'width.%':100, 'height.%':100}"
    (click)="OnSettingsClicked()" [ngClass]="{rotate: isSettingsClicked}" style="width: 25px; height: 25px; stroke: none;"></svg-icon>
    <div class="settings-dropdown" [ngClass]="{closed:!isSettingsClicked}">
      <ng-content></ng-content>
    </div>
  </div>
</div>

import { AfterContentChecked, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-drop-down-input-popup',
  templateUrl: './drop-down-input-popup.component.html',
  styleUrls: ['./drop-down-input-popup.component.css']
})
export class DropDownInputPopupComponent implements OnInit, OnChanges{

  @Input() placeholder:string ="TYPE";
  noneValue:string = this.placeholder;
  isDown:boolean= false;
  @Input() widthString:string = '200px';
  @Input() padding:number = 12;
  @Input() datas:string[] = [];
  @Input() designType:'timeline'|'step'|'users' = 'timeline';
  @Input() itemHeight:number = 40;
  @Input() defaultSelect:boolean;
  @Input() defaultId:number;
  @Input() invalid:boolean = false;
  @Input() borderRadius:number = 8;
  @Input() borderColor:string = "var(--dark)";
  @Input() isHoverEffect:boolean = true;

  height:number = 144;

  @Output() selected:EventEmitter<string> = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges():void{
    if(this.defaultSelect){
      if(this.defaultId) {
        this.OnSelect(this.datas[this.defaultId], this.defaultId);
        this.datas.splice(this.defaultId, 1);
      } else {
        this.OnSelect(this.datas[0], 0);
        this.datas.splice(0, 1);
      }
    }else{
      this.noneValue = 'NONE';
    }
  }

  OnClick():void{
    this.isDown=!this.isDown;
  }

  OnSelect(item:string, idx:number):void{
    this.selected.emit(item);
    let tmp:string = this.noneValue;
    this.noneValue = item;
    this.datas[idx]=tmp;
    this.isDown = false;
  }

  Clear(){
    if(!this.defaultSelect){
      this.datas.push(this.noneValue);
      this.noneValue = this.placeholder;
      this.isDown = false;
      this.selected.emit('NONE');
    }
  }
}

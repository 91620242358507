import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-subtask-tick',
  templateUrl: './svg-subtask-tick.component.html',
  styles: [
  ]
})
export class SvgSubtaskTickComponent implements OnInit {

  @Input() width:number = 8.038;
  constructor() { }

  ngOnInit(): void {
  }

}

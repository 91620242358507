import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { ContainerComponent, DraggableComponent, DropResult } from 'ngx-smooth-dnd';

@Component({
  selector: 'app-columns-form',
  templateUrl: './columns-form.component.html',
  styleUrls: ['./columns-form.component.css']
})
export class ColumnsFormComponent implements OnInit {


  @Input() cols: {id: number, name: string}[] = [];
  constructor() { }

  ngOnInit(): void {
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.cols, event.previousIndex, event.currentIndex);
  }

  OnAdd(inputFiled:HTMLInputElement):void{
    if(inputFiled.value==='') return;
    this.cols.unshift({id: -1, name: inputFiled.value});
    inputFiled.value = '';
  }

  OnDelete(idx:number):void{
    this.cols.splice(idx, 1);
  }
}

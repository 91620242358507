import { Component, EventEmitter, Input, OnInit, Output, } from '@angular/core';

@Component({
  selector: 'input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss']
})
export class InputFieldComponent implements OnInit {

  @Input() placeholder: string = "";
  @Input() textarea: boolean = false;
  @Input() value: string;
  @Input() label: string | null = null;
  @Input() minHeight: number | undefined = undefined;
  @Input() invalid: boolean = false;
  @Input() isSearchInput: boolean = false;
  @Input() disabled: boolean = false;
  @Output() valueChange = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-onepager',
  templateUrl: './svg-onepager.component.html'
})
export class SvgOnepagerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-requested-file',
  templateUrl: './svg-requested-file.component.html',
  styleUrls: []
})
export class SvgRequestedFileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="wrapper">
  <div *ngIf="!isPlus">
    <svg-icon *ngIf="isEdit" class="minus" src="/assets/startupDataboard/minus.svg"
    [svgStyle]="{'width.%':100}" style="fill:rgb(255,121,121); stroke:none; cursor: pointer;" (click)="OnMinusClicked()"></svg-icon>

    <div class="card disableSelect">
      <img src={{person.picture}} width="70%" alt="picture" class="internal-picture">
      <div>
        <span style="font-size: 14px;">{{person.name|uppercase}}</span>
      </div>
      <div>
        <span style="font-size: 10px;">- {{person.jobTitle|uppercase}} -</span>
      </div>
      <div class="icons">
        <div>
          <a class="svg-container svg-phone" [href]="'tel:'+person.phone"><app-svg-phone></app-svg-phone></a>
          <div class="hover-panel phone">
            <div>{{person.phone}}</div>
          </div>
        </div>
        <div>
          <a class="svg-container svg-mail" [href]="'mailto:'+person.email"><app-svg-mail></app-svg-mail></a>
          <div class="hover-panel email">
            <div translate>startup.team.clickLink</div>
          </div>
        </div>
        <div>
          <a class="svg-container svg-linkedin" [href]="person.url" target="_blank"><app-svg-linkedin ></app-svg-linkedin></a>
          <div class="hover-panel linkedin">
            <div translate>startup.team.clickLink</div>
          </div>
        </div>
      </div>
      <div class="tag" *ngIf="isEdit||person.isTaged" (click)="OnTagClicked()" [ngStyle]="{'background-color': person.isTaged?selectColor:tagColor}">
      </div>
    </div>
  </div>
  <div *ngIf="isPlus" class="card" style="cursor: pointer;">
    <svg-icon src="/assets/big-plus.svg" class="plus" [svgStyle]="{'width.%':70}"></svg-icon>
  </div>
</div>

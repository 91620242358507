<div style="height: 18px;">
  <svg id="arranger" xmlns="http://www.w3.org/2000/svg" width="9" height="18" viewBox="0 0 9 12">
    <g id="Group_41" data-name="Group 41">
      <circle id="Ellipse_18" data-name="Ellipse 18" cx="1.5" cy="1.5" r="1.5" fill="#e3e3e3"/>
      <circle id="Ellipse_21" data-name="Ellipse 21" cx="1.5" cy="1.5" r="1.5" transform="translate(0 6)" fill="#e3e3e3"/>
      <circle id="Ellipse_22" data-name="Ellipse 22" cx="1.5" cy="1.5" r="1.5" transform="translate(0 12)" fill="#e3e3e3"/>
      <circle id="Ellipse_19" data-name="Ellipse 19" cx="1.5" cy="1.5" r="1.5" transform="translate(6)" fill="#e3e3e3"/>
      <circle id="Ellipse_20" data-name="Ellipse 20" cx="1.5" cy="1.5" r="1.5" transform="translate(6 6)" fill="#e3e3e3"/>
      <circle id="Ellipse_23" data-name="Ellipse 23" cx="1.5" cy="1.5" r="1.5" transform="translate(6 12)" fill="#e3e3e3"/>
    </g>
  </svg>
</div>

import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  input: string = '';
  constructor() {}

  updated:EventEmitter<string> = new EventEmitter<string>();
  UpdateInput(value:string){
    this.input = value;
    this.updated.emit(this.input);
  }

  // dfInput:string = '';
  // pmInput:string = '';
  // constructor() { }

  // dfUpdated:EventEmitter<string> = new EventEmitter<string>();
  // pmUpdated:EventEmitter<string> = new EventEmitter<string>();
  // UpdateDfInput(value:string){
  //   this.dfInput=value;
  //   this.dfUpdated.emit(this.dfInput)
  // }

  // UpdatePmInput(value:string){
  //   this.pmInput=value;
  //   this.pmUpdated.emit(this.pmInput)
  // }
}

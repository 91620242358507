import { AuthService } from './services/auth/auth.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { PermissionService } from './services/auth/permission.service';
import { StartupService } from './services/project/startup.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    public authService: AuthService,
    public permissionService: PermissionService,
    private startupService: StartupService,
    public router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let permission = route.data.permission;
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      if (permission && !currentUser.permissionKeys.find((item) => item == permission)) {
        console.log('access denied !');
        Swal.fire({
          title: 'Hozzáférés megtagadva !',
          icon: 'warning',
        });
        // this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
        return false;
      }

      this.permissionService.getPermissionFromNgUrl(state.url, (res) => {
        if (res.data.item) {
          if (!currentUser.permissionKeys.find((item) => item == res.data.item.permission_key)) {
            console.log('url access denied !');
            Swal.fire({
              title: 'Hozzáférés megtagadva !',
              icon: 'warning',
            });
            this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      });

      if(currentUser.main_permission == 1) {
        if(route.routeConfig.path == 'projects' || route.routeConfig.path == 'notifications') {
          console.log('url access denied !');
          Swal.fire({
            title: 'Hozzáférés megtagadva !',
            icon: 'warning',
          });
          this.router.navigate(['dealflow'], { queryParams: { returnUrl: state.url } });
          return false;
        }
      } else if(currentUser.main_permission == 2) {
        if(route.routeConfig.path != 'projects' && route.routeConfig.path != 'notifications' && route.routeConfig.path != 'history') {
          console.log('url access denied !');
          Swal.fire({
            title: 'Hozzáférés megtagadva !',
            icon: 'warning',
          });
          this.router.navigate(['projects'], { queryParams: { returnUrl: state.url } });
          return false;
        }
      }

      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }


  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let permission = route.data.permission;
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      if (permission && !currentUser.permissionKeys.find((item) => item == permission)) {
        console.log('access denied !');
        Swal.fire({
          title: 'Hozzáférés megtagadva !',
          icon: 'warning',
        });
        // this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
        return false;
      }

      this.permissionService.getPermissionFromNgUrl(state.url, (res) => {
        if (res.data.item) {
          if (!currentUser.permissionKeys.find((item) => item == res.data.item.permission_key)) {
            console.log('url access denied !');
            Swal.fire({
              title: 'Hozzáférés megtagadva !',
              icon: 'warning',
            });
            this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      });

      if(route.parent.routeConfig.path.includes(':id')) {
        let status = state.url.split('/')[1] == "portfolio" ? "1" : "0";
        this.startupService.GetProjectExists(route.parent.params['id'], status, (item) => {
          if(!item) {
            if(currentUser.main_permission == 1) {
              this.router.navigate(['dealflow']);
            } else if(currentUser.main_permission == 2) {
              this.router.navigate(['projects']);
            }
            return false;
          }

          this.permissionService.checkProjectPermission(route.parent.params['id'], (list) => {
            if(list.length == 0) {
              if(currentUser.main_permission == 1) {
                this.router.navigate(['dealflow']);
              } else if(currentUser.main_permission == 2) {
                this.router.navigate(['projects']);
              }
              return false;
            }
            switch (route.routeConfig.path) {
              case 'notes': {
                if(!list.includes("menu_notes")) {
                  this.router.navigate([state.url.replace(route.routeConfig.path, list[0].replace('menu_', ''))]);
                  return false;
                }
                return true;
              }
              case 'team': {
                if(!list.includes("menu_team")) {
                  this.router.navigate([state.url.replace(route.routeConfig.path, list[0].replace('menu_', ''))]);
                  return false;
                }
                return true;
              }
              case 'files': {
                if(!list.includes("menu_files")) {
                  this.router.navigate([state.url.replace(route.routeConfig.path, list[0].replace('menu_', ''))]);
                  return false;
                }
                return true;
              }
              case 'tasks': {
                if(!list.includes("menu_tasks")) {
                  this.router.navigate([state.url.replace(route.routeConfig.path, list[0].replace('menu_', ''))]);
                  return false;
                }
                return true;
              }
              case 'steps': {
                if(!list.includes("menu_steps")) {
                  this.router.navigate([state.url.replace(route.routeConfig.path, list[0].replace('menu_', ''))]);
                  return false;
                }
                return true;
              }
              case 'timeline': {
                if(!list.includes("menu_timeline")) {
                  this.router.navigate([state.url.replace(route.routeConfig.path, list[0].replace('menu_', ''))]);
                  return false;
                }
              }
            }
          });
        });
      }
      
      //url
      this.permissionService.getPermissionFromNgUrl(state.url, res => {
        if (res.data.item) {
          if (!currentUser.permissionKeys.find((item) => item == res.data.item.permission_key)) {
            console.log('url access denied !');
            Swal.fire({
              title: 'Hozzáférés megtagadva !',
              icon: 'warning',
            });
            this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
            return false;
          } else {
            return true;
          }
        }
      });

      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}



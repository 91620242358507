import { Component, Input, OnInit, SimpleChanges, Output, EventEmitter, OnChanges } from '@angular/core';
import { DropDownMenu, FormValue } from 'src/app/models/dashboard/InputField.model';
import { Language } from 'src/app/models/Language.model';
import { StepsMakerService } from 'src/app/services/project/form/steps-maker.service';

@Component({
  selector: 'app-drop-down-menu',
  templateUrl: './drop-down-menu.component.html',
  styleUrls: ['./drop-down-menu.component.css']
})
export class DropDownMenuComponent implements OnInit, OnChanges {

  constructor(
    private stepsService: StepsMakerService,
  ) { }

  @Input() dropDown:DropDownMenu;
  @Input() currentLang:string;
  @Input() editmode:boolean = false;
  @Input() readonly:boolean = false;
  @Output() nameChanged:EventEmitter<string> = new EventEmitter<string>();
  @Output() descriptionChanged:EventEmitter<string> = new EventEmitter<string>();
  @Output() requiredChanged:EventEmitter<boolean> = new EventEmitter<boolean>();
  isLabelEdit:boolean = false;
  isDescriptionEdit:boolean = false;
  placeholder:string = "Select...";
  noneValue:FormValue = new FormValue(0, new Language("SEMMI", "NONE"));
  isDown:boolean = false;
  datas: FormValue[] = [];
  defaultSelect:boolean;
  height:number = 144;
  isSingleClick: Boolean = true;

  ngOnInit(): void {
    this.datas = [...this.dropDown.options];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.editmode && changes.editmode.currentValue == false) {
      this.isLabelEdit = false;
      this.isDescriptionEdit = false;
    }
    if(this.defaultSelect){
      this.OnSelect(this.datas[0], 0);
      this.datas.splice(0, 1);
    }else{
      this.noneValue = new FormValue(0, new Language("SEMMI", "NONE"));
    }
  }

  labelCopy: string;
  OnLabelDoubleClick():void{
    if(this.editmode) {
      this.isLabelEdit = true;
      this.labelCopy = this.dropDown.name[this.currentLang];
    }
  }

  OnLabelEnter():void{
    if(this.dropDown.name[this.currentLang] != "") {
      this.nameChanged.emit();
    } else {
      this.dropDown.name[this.currentLang] = this.labelCopy;
    }
    this.isLabelEdit = false;
  }

  descriptionCopy: string;
  OnDescriptionDoubleClick():void{
    if(this.editmode) {
      this.isDescriptionEdit = true;
      this.descriptionCopy = this.dropDown.small[this.currentLang];
    }
  }

  OnDescriptionEnter():void{
    this.descriptionChanged.emit();
    this.isDescriptionEdit = false;
  }

  OnRequiredClicked():void{
    this.requiredChanged.emit();
  }

  OnClick():void{
    if(!this.readonly) {
      this.isDown=!this.isDown;
    }
  }

  OnSelect(item:FormValue, idx:number):void{
    if(this.isOptionEdit!=idx) {
      this.isSingleClick = true;
      setTimeout(()=>{
        if(this.isSingleClick){
          this.dropDown.selectedValue = item;
          let tmp:FormValue = this.noneValue;
          this.noneValue = item;
          this.datas[idx] = tmp;
          this.isDown = false;
        }
      },250)
    }
  }

  OnRadioRemoveClicked(idx:number, event:Event):void{
    this.stepsService.DeleteFormValue(this.dropDown.options[idx].id);
    this.dropDown.options.splice(idx,1);
    this.datas.splice(idx,1);
    event.stopPropagation();
  }

  OnAddClicked():void{
    let value = new FormValue(0, new Language("Érték" + (+this.dropDown.options.length+1), "Value" + (+this.dropDown.options.length+1)));
    this.stepsService.AddFormValue(this.dropDown.id, value, 0, backValue => {
      this.dropDown.options.push(backValue);
      this.datas.push(value);
    });
  }

  isOptionEdit: number = -1;     
  OnOptionDoubleClick(id:number):void{
    this.isSingleClick = false;
    setTimeout(()=>{
      if(this.editmode){
        this.isOptionEdit = id;
      }
    },250)
  }

  OnOptionEnter(event:Event, id:number):void{
    if((event.target as HTMLInputElement).value != "") {
      this.dropDown.options[id].value[this.currentLang] = (event.target as HTMLInputElement).value;
      this.stepsService.UpdateFormValue(this.dropDown.options[id]);
    }
    this.isOptionEdit = -1;
  }

  zIndex: boolean = false;
  DropDownToggle(): void {
    this.zIndex = !this.zIndex;
  }
}

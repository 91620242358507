import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Tag } from 'src/app/models/portfolio-management/TimelineAction.model';
import { TagService } from 'src/app/services/project/tag.service';

@Component({
  selector: 'app-tag-settings',
  templateUrl: './tag-settings.component.html',
  styleUrls: ['./tag-settings.component.css']
})
export class TagSettingsComponent implements OnInit {

  tags: Tag[] = [];
  constructor(
    private tagService: TagService,
    private router: Router,
  ) { }

  isCategories: boolean = false;
  ngOnInit(): void {
    if (this.router.url.includes('/portfolioManagement')) {
      this.tags = this.tagService.portfolioManagementTags;
    } else if (this.router.url.includes('/dealflowTags')) {
      this.tagService.getTagList((response) => {
        this.tags = []
        response.data.item.forEach(tag => {
          this.tags.push({ 
            id: tag.tag_id,
            text: tag.tag_name, 
            color: tag.tag_color,
            textColor: tag.tag_textcolor
          });
        });
      });
    } else {
      this.RefreshTimelineTags();
      this.isCategories = true;
    }
  }

  RefreshTimelineTags(): void {
    this.tagService.GetTimelineCategories((response) => {
      this.tags = []
      response.data.list.forEach(tag => {
        this.tags.push({ text: tag.category, color: tag.category_color });
      });
    });
  }

  OnRemoveClicked(idx: number): void {
    if (this.router.url.includes('/portfolioManagement')) {
      this.tagService.RemoveFromPmTags(idx);
    } else if (this.router.url.includes('/dealflowTags')) {
      this.tagService.RemoveFromDfTags(idx);
    } else {
      this.tagService.RemoveFromTimelineTags(idx, () => { this.RefreshTimelineTags(); });
    }
  }

  isMobileResolution(): boolean {
    if (window.innerWidth <= 768) {
      return true;
    } else {
      return false;
    }
  }
}

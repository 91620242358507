import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InvexFile } from 'src/app/models/utility/File.model';

@Component({
  selector: 'app-file-card',
  templateUrl: './file-card.component.html',
  styleUrls: ['./file-card.component.css']
})
export class FileCardComponent implements OnInit {

  @Input() file:InvexFile = null;
  @Output() deleteClick:EventEmitter<void> = new EventEmitter<void>();
  @Output() editClick:EventEmitter<void> = new EventEmitter<void>();
  @Output() fileClick:EventEmitter<void> = new EventEmitter<void>();

  isHover:boolean = false;

  fileColors:string[] = ['var(--dark)','#f7d733','rgb(255,121,121)'];
  fileColorsIndex:number = 0;
  constructor() { }

  ngOnInit(): void {

  }

  MouseEnter():void{
    this.isHover=true;
  }
  MouseLeave():void{
    this.isHover=false;
  }

  prevent:boolean=false;
  OnIconClicked(){
    this.fileColorsIndex++;
    this.prevent=true;
  }

  OnDeleteClicked():void{
    this.deleteClick.emit();
  }
  OnEditClicked():void{
    this.editClick.emit();
  }
  OnFileClicked():void{
    if(!this.prevent){
      this.fileClick.emit();
    }else{
      this.prevent=false;
    }
  }
}

import { InvexFile } from "../utility/File.model";
import { Language } from "../Language.model";

export class FormValue{
  id?:number;
  value:Language;

  constructor(id:number=0, value:Language=new Language('', '')){
    this.id = id;
    this.value = value;
  }

  GetValue(lang: string): string {
    return this.value.GetLanguage(lang);
  }

  isEmpty(): boolean {
    return this.value.hu == "" && this.value.en == "";
  }

  isNone(): boolean {
    return this.value.hu == "SEMMI" && this.value.en == "NONE";
  }
}

export interface InputField {
  id?:number;
  label: string;
  order?:number;
  name:Language;
  small:Language;
  required:boolean;
  type: InputFieldType;
  GetName(lang: string): string;
  GetDescription(lang: string): string;
}

export enum InputFieldType {
  ShortTextLine = "ShortTextLine",
  LongTextLine = "LongTextLine",
  DropDownMenu = "DropDownMenu",
  RadioButton = "RadioButton",
  Selector = "Selector",
  FileUploader = "FileUploader",
  DataMatrix = "DataMatrix",
  Repeater = "Repeater",
  TextEditor = "TextEditor",
  DateScheduling = "DateScheduling",
  VideoInput = "VideoInput"
}

export class ShortTextLine implements InputField{
  id?:number;
  label: string;
  order?:number;
  name:Language;
  small:Language;
  required:boolean;
  value: string;
  type = InputFieldType.ShortTextLine;

  constructor(id:number, order:number, name:Language=new Language('Rövid Szövegmező Címkéje', 'Short Text Line Label'), small:Language=new Language('Írj egy rövid leírást', 'Write a small description'), required:boolean=false){
    this.id = id;
    this.order = order;
    this.name = name;
    this.small = small;
    this.required = required;
    this.value = '';
  }

  GetName(lang: string): string {
    return this.name.GetLanguage(lang);
  }

  GetDescription(lang: string): string {
    return this.small.GetLanguage(lang);
  }
}

export class LongTextLine implements InputField{
  id?:number;
  label: string;
  order?:number;
  name:Language;
  small:Language;
  required:boolean;
  value: string;
  type = InputFieldType.LongTextLine;

  constructor(id:number, order:number, name:Language=new Language('Hosszú Szövegmező Címkéje', 'Long Text Line Label'), small:Language=new Language('Írj egy rövid leírást', 'Write a small description'), required:boolean=false){
    this.id = id;
    this.order = order;
    this.name = name;
    this.small = small;
    this.required = required;
    this.value = '';
  }

  GetName(lang: string): string {
    return this.name.GetLanguage(lang);
  }

  GetDescription(lang: string): string {
    return this.small.GetLanguage(lang);
  }
}

export class DropDownMenu implements InputField{
  id?:number;
  label: string;
  order?:number;
  name:Language;
  small:Language;
  required:boolean;
  options: FormValue[];
  defaultValueId:number;
  selectedValue:FormValue = new FormValue(0, new Language("SEMMI", "NONE"));
  type = InputFieldType.DropDownMenu;

  constructor(id:number, order:number, name:Language=new Language('Legördülő Menü Címkéje', 'Drop Down Menu Label'), small:Language=new Language('Írj egy rövid leírást', 'Write a small description'), required:boolean=false, options:FormValue[]=[]){
    this.id = id;
    this.order = order;
    this.name = name;
    this.small = small;
    this.required = required;
    this.options = options;
    this.defaultValueId = 0;
  }

  GetName(lang: string): string {
    return this.name.GetLanguage(lang);
  }

  GetDescription(lang: string): string {
    return this.small.GetLanguage(lang);
  }
}

export class RadioButton implements InputField{
  id?:number;
  label: string;
  order?:number;
  name:Language;
  small:Language;
  required:boolean;
  options: FormValue[];
  otherValue:FormValue;
  selectedValue:FormValue;
  type = InputFieldType.RadioButton;

  constructor(id:number, order:number, name:Language=new Language('Rádió Gomb Címkéje', 'Radio Button Label'), small:Language=new Language('Írj egy rövid leírást', 'Write a small description'), required:boolean=false, options:FormValue[]=[]){
    this.id = id;
    this.order = order;
    this.name = name;
    this.small = small;
    this.required = required;
    this.options = options;
    this.otherValue = new FormValue(0, new Language("Egyéb", "Other"));
  }

  GetName(lang: string): string {
    return this.name.GetLanguage(lang);
  }

  GetDescription(lang: string): string {
    return this.small.GetLanguage(lang);
  }
}

export class Selector implements InputField{
  id?:number;
  label: string;
  order?:number;
  name:Language;
  small:Language;
  required:boolean;
  options: FormValue[];
  otherValue:FormValue;
  selectedValues:Set<FormValue>;
  type = InputFieldType.Selector;

  constructor(id:number, order:number, name:Language=new Language('Választó Gomb Címkéje', 'Selector Button Label'), small:Language=new Language('Írj egy rövid leírást', 'Write a small description'), required:boolean=false, options:FormValue[]=[]){
    this.id = id;
    this.order = order;
    this.name = name;
    this.small = small;
    this.required = required;
    this.options = options;
    this.otherValue = new FormValue(0, new Language("Egyéb", "Other"));
    this.selectedValues = new Set<FormValue>();
  }

  GetName(lang: string): string {
    return this.name.GetLanguage(lang);
  }

  GetDescription(lang: string): string {
    return this.small.GetLanguage(lang);
  }
}

export class FileUploader implements InputField{
  id?:number;
  label: string;
  order?:number;
  name:Language;
  small:Language;
  required:boolean;
  file:InvexFile = new InvexFile();
  type = InputFieldType.FileUploader;

  constructor(id:number, order:number, name:Language=new Language('Fájl Feltöltő Címkéje', 'File Uploader Label'), small:Language=new Language('Írj egy rövid leírást', 'Write a small description'), required:boolean=false){
    this.id = id;
    this.order = order;
    this.name = name;
    this.small = small;
    this.required = required;
  }

  GetName(lang: string): string {
    return this.name.GetLanguage(lang);
  }

  GetDescription(lang: string): string {
    return this.small.GetLanguage(lang);
  }
}

export class DataMatrix implements InputField{
  id?:number;
  label: string;
  order?:number;
  name:Language;
  small:Language;
  required:boolean;
  labelsVertical:FormValue[];
  labelsHorizontal:FormValue[];
  values:boolean[][] = [];
  type = InputFieldType.DataMatrix;

  constructor(id:number, order:number, name:Language=new Language('Adat Mátrix Címkéje', 'Data Matrix Label'), small:Language=new Language('Írj egy rövid leírást', 'Write a small description'), required:boolean=false, labelsVertical:FormValue[]=[], labelsHorizontal:FormValue[]=[]){
    this.id = id;
    this.order = order;
    this.name = name;
    this.small = small;
    this.required = required;
    this.labelsVertical = labelsVertical;
    this.labelsHorizontal = labelsHorizontal;
    for (let i = 0; i < this.labelsVertical.length; i++) {
      let row:boolean[] = []
      for (let j = 0; j < this.labelsHorizontal.length; j++) {
        row.push(false);
      }
      this.values.push(row);
    }
  }

  GetName(lang: string): string {
    return this.name.GetLanguage(lang);
  }

  GetDescription(lang: string): string {
    return this.small.GetLanguage(lang);
  }
}

export class Repeater implements InputField{
  id?:number;
  label: string;
  order?:number;
  name:Language;
  small:Language;
  required:boolean;
  values:string[] = [];
  type = InputFieldType.Repeater;

  constructor(id:number, order:number, name:Language=new Language('Ismétlő Címkéje', 'Repeater Label'), small:Language=new Language('Írj egy rövid leírást', 'Write a small description'), required:boolean=false){
    this.id = id;
    this.order = order;
    this.name = name;
    this.small = small;
    this.required = required;
  }

  GetName(lang: string): string {
    return this.name.GetLanguage(lang);
  }

  GetDescription(lang: string): string {
    return this.small.GetLanguage(lang);
  }
}

export class TextEditor implements InputField{
  id?:number;
  label: string;
  order?:number;
  name:Language;
  small:Language;
  required:boolean;
  value:string = '';
  type = InputFieldType.TextEditor;

  constructor(id:number, order:number, name:Language=new Language('Szöveg Szerkesztő Címkéje', 'Text Editor Label'), small:Language=new Language('Írj egy rövid leírást', 'Write a small description'), required:boolean=false){
    this.id = id;
    this.order = order;
    this.name = name;
    this.small = small;
    this.required = required;
  }

  GetName(lang: string): string {
    return this.name.GetLanguage(lang);
  }

  GetDescription(lang: string): string {
    return this.small.GetLanguage(lang);
  }
}

export class DateScheduling implements InputField{
  id?:number;
  label: string;
  order?:number;
  name:Language;
  small:Language;
  required:boolean;
  valueStart:Date;
  valueEnd:Date;
  type = InputFieldType.DateScheduling;

  constructor(id:number, order:number, name:Language=new Language('Időpont választó Címkéje', 'Date Scheduling Label'), small:Language=new Language('Írj egy rövid leírást', 'Write a small description'), required:boolean=false){
    this.id = id;
    this.order = order;
    this.name = name;
    this.small = small;
    this.required = required;
  }

  GetName(lang: string): string {
    return this.name.GetLanguage(lang);
  }

  GetDescription(lang: string): string {
    return this.small.GetLanguage(lang);
  }
}

export class VideoInput implements InputField {
  id?: number;
  label: string;
  order?: number;
  name: Language;
  small: Language;
  required: boolean;
  type = InputFieldType.VideoInput;

  constructor(id:number, order:number, name:Language=new Language('Videó Rögzítő Címkéje', 'Video Input Label'), small:Language=new Language('Írj egy rövid leírást', 'Write a small description'), required:boolean=false){
    this.id = id;
    this.order = order;
    this.name = name;
    this.small = small;
    this.required = required;
  }

  GetName(lang: string): string {
    return this.name.GetLanguage(lang);
  }

  GetDescription(lang: string): string {
    return this.small.GetLanguage(lang);
  }
}
<div class="header">
 <h1 translate>steps.title</h1>
</div>

<div class="steps-wrapper">
  <!-- <div class="top-card" (click)="OnDoneClicked()">
    <app-svg-edit *ngIf="!isEditmode" style="cursor: pointer;"></app-svg-edit>
    <div *ngIf="isEditmode" class="done-btn" translate>common.done</div>
  </div> -->

  <app-steps-dashboard [editmode]="true"></app-steps-dashboard>
</div>
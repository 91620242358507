import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-user-active',
  templateUrl: './svg-user-active.component.html',
  styleUrls: ['./svg-user-active.component.css']
})
export class SvgUserActiveComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

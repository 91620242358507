import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonType } from 'src/app/models/project/ButtonType.model';

@Component({
  selector: 'app-button',
  templateUrl: './app-button.component.html',
  styleUrls: ['./app-button.component.scss'],
})
export class AppButtonComponent implements OnInit {
  @Input() text: string = '';
  @Input() disabled: boolean = false;
  @Input() type: ButtonType = ButtonType.Primary;
  @Output() onClick = new EventEmitter();

  public ButtonType = ButtonType;

  public btnClass = '';

  constructor() {}

  ngOnInit(): void {
    
  }
}

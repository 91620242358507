<div class="select">
    <label for="audioSource">Audio input source: </label>
    <app-select [(value)]="selectedAudioDevice" [options]="audioDevices"></app-select>
    <!-- <select #audioSource (change)="start()"></select> -->
</div>

<div class="select">
    <label for="videoSource">Video source: </label>
    <app-select [(value)]="selectedVideoDevice" [options]="videoDevices"></app-select>
    <!-- <select #videoSource (change)="start()"></select> -->
</div>

<button (click)="record_start()">Start</button>
<button (click)="record_stop()">Stop</button>
<button (click)="record_save()">Save</button>

<video id="your-video-id" autoplay playsinline style="display: none"></video>
<video id="your-video-id2" controls  style="display: none"></video>

<script type="text/javascript">
    
</script>


<footer style="margin-top: 20px;"><small id="send-message"></small></footer>
<script src="/recorder_main.js" async></script>

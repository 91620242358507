import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ButtonType } from "src/app/models/project/ButtonType.model";

@Component({
    selector: 'confirm-delete-dialog',
    templateUrl: './confirm-delete-dialog.component.html',
    styleUrls: ['./confirm-delete-dialog.component.scss']
})
export class ConfirmDeleteDialogComponent {
    stepData: DeleteStepData;

    ButtonType = ButtonType;

    constructor(
        public dialogRef: MatDialogRef<ConfirmDeleteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DeleteStepData
    ) {
        this.stepData = data;
    }

    onClose(result: boolean) {
        var d: DeleteStepResult = {
            result: result,
            data: this.stepData
        }
        this.dialogRef.close(d);
    }
}

export interface DeleteStepData {
    id: number;
    i: number;
}

export interface DeleteStepResult {
    result: boolean;
    data: DeleteStepData;
}
import { EventEmitter } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Utils } from 'src/app/common/utils';

@Component({
  selector: 'app-user-adder',
  templateUrl: './user-adder.component.html',
  styleUrls: ['./user-adder.component.css']
})
export class UserAdderComponent implements OnInit {
  
  show = false;
  password = "";
  newUser: {name:string, email:string, password:string, authority:string, status:string} = {name: "", email: "", password: "", authority: "", status: ""};
  invalidObj: {nameInvalid:boolean, emailInvalid:boolean} = {nameInvalid: false, emailInvalid: false};
  public event: EventEmitter<any> = new EventEmitter();

  constructor() { }
  
  ngOnInit(): void {
  }

  SetFixed(): void {
    if(document.body.scrollHeight > document.body.clientHeight) {
      document.body.style.top = `-${window.scrollY}px`;
      (document.body.children[0].children[0] as HTMLElement).style.top = '0px';
      (document.body.children[0].children[1] as HTMLElement).style.top = '0px';
      document.body.style.position = 'fixed';
      document.body.style.overflowY = 'scroll';
    }
  }

  UnFix(): void {
    const scrollY = document.body.style.top;
    document.body.style.top = '';
    (document.body.children[0].children[0] as HTMLElement).style.top = '';
    (document.body.children[0].children[1] as HTMLElement).style.top = '';
    document.body.style.position = '';
    document.body.style.overflowY = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  }

  userAdder(){
    this.show = true;
    this.newUser.password = this.generatePassword();
    this.SetFixed();
  }

  OnAuthoritySelected(authority:string): void {
    this.newUser.authority = authority;
  }

  OnActiveSelected(active:string): void {
    this.newUser.status = active=='Yes'?"1":"0";
  }

  OnSaveClicked(): void {
    if(this.isFormValid()){
      this.event.emit(this.newUser);
      this.Close();
      this.invalidObj = {nameInvalid:false, emailInvalid:false};
    }
  }

  Close(): void{
    this.show = false;
    this.UnFix();
  }

  private isFormValid():boolean{
    let isValid:boolean = true;
    if(Utils.isEmptyString(this.newUser.name)){
      this.invalidObj.nameInvalid = true;
      isValid = false;
    }
    if(Utils.isEmptyString(this.newUser.email)){
      this.invalidObj.emailInvalid = true;
      isValid = false;
    }
    return isValid;
  }
 
  copy(){
    navigator.clipboard.writeText(this.newUser.password)
  }

  generatePassword() {
    var length = 8,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }
}

import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Note } from 'src/app/models/Note.model';
import { FilterService } from 'src/app/services/utility/filter.service';
import { NotesService } from 'src/app/services/project/notes/notes.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ButtonType } from 'src/app/models/project/ButtonType.model';
import { NoteInputComponent } from './note-input/note-input.component';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit, OnDestroy {

  filteredInternalNotes: Note[] = [];
  filteredExternalNotes: Note[] = [];

  addNoteInternalActive: boolean = false;
  addNoteExternalActive: boolean = false;

  loaded: boolean = false;
  isAdmin: boolean = false;
  editNote: Note | undefined = undefined;

  subscriptions: Subscription[] = [];

  @ViewChild('internalNoteList') noteListElementI: ElementRef;
  @ViewChild('externalNoteList') noteListElementE: ElementRef;
  //ngIf miatt feliratkozni kell changes-re
  @ViewChildren("internalInput")
  public internalInputs: QueryList<NoteInputComponent>
  @ViewChildren("externalInput")
  public externalInputs: QueryList<NoteInputComponent>

  private internalInput: NoteInputComponent
  private externalInput: NoteInputComponent

  public ngAfterViewInit(): void
  {
    this.subscriptions.push(this.internalInputs.changes.subscribe((comps: QueryList <NoteInputComponent>) =>
    {
      this.internalInput = comps.first;
      if(this.editNote) {
        this.internalInput.note = this.editNote;
        this.editNote = undefined;
      }
    }));

    this.subscriptions.push(this.externalInputs.changes.subscribe((comps: QueryList <NoteInputComponent>) =>
    {
      this.externalInput = comps.first;
      if(this.editNote) {
        this.externalInput.note = this.editNote;
        this.editNote = undefined;
      }
    }));
  }

  private sub: Subscription;
  private routeSub: Subscription;

  projectId:string;
  projectStatus:string;

  public ButtonType = ButtonType;

  constructor(
    private filterService: FilterService,
    private notesService: NotesService,
    private router: Router,
    private route: ActivatedRoute,
    public authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.isAdmin = this.authService.currentUserValue.main_permission == 1;
    this.sub = this.filterService.updated.subscribe((x: string) => {
      if (this.filteredInternalNotes != undefined) this.OnSearchChanged(x);
    });
    this.routeSub = this.route.parent.params.subscribe(params => {
      this.projectId = params['id'];
      this.projectStatus = this.router.url.split('/')[1] == "portfolio" ? "1" : "0";
      this.LoadNotes();
    });
  }

  ngOnDestroy(): void {
    if(this.routeSub) {
      this.routeSub.unsubscribe();
    }
    if(this.sub) {
      this.sub.unsubscribe();
    }
  }

  removeNote(id) {
    this.notesService.deleteNote(id, () => {
      this.loaded = false;
      this.LoadNotes();
    })
  }

  editInternalNote(note: Note) {
    this.editNote = note;
    this.ActivatePlusInternal();
  }

  editExternalNote(note: Note) {
    this.editNote = note;
    this.ActivatePlusExternal();
  }

  OnSearchChanged(input: string): void {
    if(this.isAdmin) {
      this.filteredInternalNotes = this.notesService.FilterNotes(input, true);
    }
    this.filteredExternalNotes = this.notesService.FilterNotes(input, false);
  }

  ActivatePlusInternal(): void {
    this.addNoteInternalActive = true;
    this.noteListElementI.nativeElement.scrollTop = 0;
  }

  ActivatePlusExternal(): void {
    this.addNoteExternalActive = true;
    this.noteListElementE.nativeElement.scrollTop = 0;
  }

  AddNoteInternal(newNote: Note) {
    if(newNote.id == "-1") {
      this.notesService.addNote(newNote, this.projectId, this.projectStatus , "1", () => {
        this.addNoteInternalActive = false;
        this.loaded = false;
        this.LoadNotes();
      });
    } else {
      this.notesService.updateNote(newNote, this.projectId, this.projectStatus, "1", () => {
        this.addNoteInternalActive = false;
        this.loaded = false;
        this.LoadNotes();
      })
    }
  }

  AddNoteExternal(newNote: Note) {
    if(newNote.id == "-1") {
      this.notesService.addNote(newNote, this.projectId, this.projectStatus , "0", () => {
        this.addNoteExternalActive = false;
        this.loaded = false;
        this.LoadNotes();
      });
    } else {
      this.notesService.updateNote(newNote, this.projectId, this.projectStatus, "0", () => {
        this.addNoteExternalActive = false;
        this.loaded = false;
        this.LoadNotes();
      })
    }
  }

  LoadNotes() {
    this.notesService.getNotes(this.projectId, this.projectStatus, res => {
      this.loaded = res;
      if(this.isAdmin) {
        this.filteredInternalNotes = this.notesService.internalNotes;
      }
      this.filteredExternalNotes = this.notesService.externalNotes;
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-x',
  templateUrl: './svg-x.component.html',
  styles: [
  ]
})
export class SvgXComponent implements OnInit {

  constructor() { }

  @Input() width:string = '15.033px';

  ngOnInit(): void {
  }

}

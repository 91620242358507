import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-user',
  templateUrl: './svg-user.component.html'
})
export class SvgUserComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

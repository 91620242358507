import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import Swal from "sweetalert2";
import {InquiryService} from "../../services/support/inquiry.service";
import {InquiryCategory} from '../../models/inquiry/InquiryCategory.model';

@Component({
    selector: 'app-inquiry-category-list',
    templateUrl: './inquiry-category-list.component.html',
    styleUrls: ['./inquiry-category-list.component.css']
})
export class InquiryCategoryListComponent implements OnInit {

    constructor(
        private inquiryService: InquiryService,
        private router: Router,
    ) { }

    categoryList: Array<InquiryCategory>

    ngOnInit(): void {
      this.inquiryService.getCategoryList(res => this.categoryList = res.data.list);
    }

    deleteItem(id) {
        Swal.fire({
            title: 'Törlésre készül !',
            text: 'Biztosan törli a kategóriát ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Igen, Törlöm'
        }).then((result) => {
            if (result.isConfirmed) {
                this.inquiryService.deleteCategory(id, res => {
                  this.inquiryService.getCategoryList(res => this.categoryList = res.data.list);
                });
            }
        });
    }

}

import { AuthService } from './../../services/auth/auth.service';
import { PermissionService } from './../../services/auth/permission.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  constructor(
    public authService: AuthService,
    public permissionService: PermissionService,
    public router: Router) { }

  ngOnInit(): void {
    const currentUser = this.authService.currentUserValue;
    if (currentUser && currentUser.id > 0) {
      this.router.navigate(['dealflow']);
    } else {
      this.router.navigate(['auth/login']);
    }
  }

}

<div class="note-card">
  <form #noteForm="ngForm">
    <div style="display: flex; justify-content: space-between; align-items: center;" class="note-header" >
      <!-- <input type="text" placeholder="{{'startup.notes.placeholder' | translate}}" class="input-title" ngModel #tittleInput name="titleText" required
      [ngClass]="{invalid: titleInvalid &&noteForm.submitted}" (focusout)="fout()"> -->
      <input-field [invalid]="titleText == '' && noteForm.submitted" class="input-title" placeholder="{{'startup.notes.placeholder' | translate}}" [(value)]="titleText"></input-field>
      <div class="date-input-wrapper">
        <!-- <mat-form-field>
          <input-field [disabled]="true" [invalid]="dueDate == undefined && noteForm.submitted" class="input-due-date" placeholder="{{'common.date' | translate}}" [(value)]="dueDate"></input-field>
          <app-svg-datepicker class="date-input-icon"></app-svg-datepicker>
          <input #ref (dateChange)="onDueDateChanged(ref.value)" style="display: none;" matInput [matDatepicker]="picker">
          <mat-datepicker-toggle style="display: none;" matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field> -->
        <app-date-input [(date)]="dueDate" [invalid]="dueDate == undefined && noteForm.submitted"></app-date-input>
      </div>
    </div>
    <div>
      <textarea style="padding: 10px;" placeholder="{{'startup.notes.note' | translate}}" id="payloadInput" rows="6" [(ngModel)]="payloadText" ></textarea>
    </div>
    <div style="display: flex; justify-content: space-between;">
      <!-- <span id="date">{{dateString}}</span> -->
      <app-button (onClick)="OnSubmit()" text="{{'common.send' | translate}}"></app-button>
      <app-checkbox label="{{'startup.notes.convertToTask' | translate}}"></app-checkbox>
    </div>
  </form>
</div>

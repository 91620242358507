<app-popup #updatePopup [width]="500">
  <div class="popup-title">{{text}}</div>
  <input *ngIf="emailTemplate!=undefined" [(ngModel)]="emailTemplate.title"
  #tileInput type="text" class="popup-input"
  [placeholder]="'Template name'"
  style="margin-bottom: 10px;">
  <input *ngIf="emailTemplate!=undefined" [(ngModel)]="emailTemplate.subject"
  #subjectInput type="text" class="popup-input"
  [placeholder]="'E-Mail subject'"
  style="margin-bottom: 10px;">

  <!--Ide jön a fura textbox...-->
  <textarea rows="10" placeholder="E-Mail content" *ngIf="emailTemplate!=undefined" [(ngModel)]="emailTemplate.text"></textarea>
  <app-agree-disagree-buttons [justifyContent]="'center'" [innerMargin]="34" [agreeColor]="'var(--accentDark)'" [agreeText]="'common.save' | translate"
  (cancel)="updatePopup.Close()" (agree)="OnSave()"></app-agree-disagree-buttons>
</app-popup>

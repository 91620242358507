import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-color-slider',
  templateUrl: './color-slider.component.html',
  styleUrls: ['./color-slider.component.css']
})
export class ColorSliderComponent implements OnInit {


  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;
  private ctx: CanvasRenderingContext2D;

  @Input() mousedown: boolean = false;
  @Output() private mousedownChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() selectedwidth: number;
  @Input() format: 'rgb' | 'hex';
  private defaultColor: string = 'rgb(0,255,255)';

  @Output() color: EventEmitter<string> = new EventEmitter()
  constructor() { }

  ngOnInit(): void {
    if (this.format == 'hex') {
      this.defaultColor = '#00FFFF';
    }
  }

  ngAfterViewInit() {
    this.draw();
  }

  draw() {
    if (!this.ctx) {
      this.ctx = this.canvas.nativeElement.getContext('2d');
    }

    const width: number = this.canvas.nativeElement.width;
    const height: number = this.canvas.nativeElement.height;
    this.ctx.clearRect(0, 0, width, height);
    const gradient: CanvasGradient = this.ctx.createLinearGradient(0, 0, width, 0);
    gradient.addColorStop(0, 'rgba(0, 0, 0, 1)');
    gradient.addColorStop(0.05, 'rgba(0, 0, 0, 1)');
    gradient.addColorStop(0.143, 'rgba(255, 0, 0, 1)');
    gradient.addColorStop(0.286, 'rgba(255, 255, 0, 1)');
    gradient.addColorStop(0.4285, 'rgba(0, 255, 0, 1)');
    gradient.addColorStop(0.5714, 'rgba(0, 255, 255, 1)');
    gradient.addColorStop(0.7143, 'rgba(0, 0, 255, 1)');
    gradient.addColorStop(0.8571, 'rgba(255, 0, 255, 1)');
    gradient.addColorStop(0.95, 'rgba(255, 255, 255, 1)');
    gradient.addColorStop(1, 'rgba(255, 255, 255, 1)');

    this.ctx.beginPath();
    this.ctx.rect(0, 0, width, height);
    this.ctx.fillStyle = gradient;
    this.ctx.fill();
    this.ctx.closePath();

    if (this.selectedwidth) {
      this.ctx.beginPath()
      this.ctx.strokeStyle = 'white';
      this.ctx.lineWidth = 3;
      this.ctx.arc(this.selectedwidth, height / 2, height / 2 - 2, 0, 2 * Math.PI);
      this.ctx.stroke();
      this.ctx.closePath();
    }
  }

  OnMouseDown(event: MouseEvent): void {
    this.mousedown = true;
    this.selectedwidth = event.offsetX;
    this.draw();
    this.emitColor(this.selectedwidth, this.canvas.nativeElement.height / 2);
  }

  OnMouseMove(event: MouseEvent): void {
    if (this.mousedown) {
      this.selectedwidth = event.offsetX;
      this.draw();
      this.emitColor(this.selectedwidth, this.canvas.nativeElement.height / 2);
    }
  }

  emitColor(x: number, y: number): void {
    const rgbaColor: string = this.getColorAtPosition(x, y);
    this.color.emit(rgbaColor);
  }

  private rgbToHex(r: number, g: number, b: number): string {
    return '#' + [r, g, b].map(x => {
      const hex = x.toString(16)
      return hex.length === 1 ? '0' + hex : hex
    }).join('')
  }
  getColorAtPosition(x: number, y: number): string {
    try {
      const imageData: Uint8ClampedArray = this.ctx.getImageData(x, y, 1, 1).data;
      if (this.format == 'rgb') {
        return 'rgb(' + imageData[0] + ',' + imageData[1] + ',' + imageData[2] + ')';
      } else {
        return this.rgbToHex(imageData[0], imageData[1], imageData[2]);
      }

    } catch (error) {
    }
    return this.defaultColor;
  }

  @HostListener('window:mouseup', ['$event'])
  OnMouseUp(event: MouseEvent): void {
    this.mousedown = false;
    this.emitColor(this.selectedwidth, this.canvas.nativeElement.height / 2);
  }
}

import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-drop-down-input',
  templateUrl: './drop-down-input.component.html',
  styleUrls: ['./drop-down-input.component.css']
})
export class DropDownInputComponent implements OnInit {

  @Input() text:string = this.translateService.instant('startup.timeline.type');
  isDown:boolean = false;
  @Input() zIndex:number = 1;
  @Input() widthString:string = '200px';
  @Input() heightString:string = '33px';
  @Input() contentHeight:number = 140;

  constructor(
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
  }

  OnClick():void{
    this.isDown=!this.isDown;
  }
  
  Close(): void {
    this.isDown = false;
  }
}

import {Component, OnInit} from '@angular/core';
import {KnowledgeBaseItem} from '../../models/knowledgebase/KnowledgeBaseItem.model';
import {KnowledgeBaseService} from '../../services/support/knowledge-base.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-knowledgebase-list',
  templateUrl: './knowledgebase-list.component.html',
  styleUrls: ['./knowledgebase-list.component.css']
})
export class KnowledgebaseListComponent implements OnInit {

  constructor(
    private knowledgeBaseService: KnowledgeBaseService
  ) { }

  itemList: Array<KnowledgeBaseItem>;

  ngOnInit(): void {
    this.getItemList();
  }

  getItemList() {
    this.knowledgeBaseService.getItemList(
      res => {
        this.itemList = res.data.list;
      }
    );
  }

  delete(id) {
    Swal.fire({
      title: 'Törlésre készül !',
      text: 'Biztosan törli a cikket ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Igen, Törlöm'
    }).then((result) => {
      if (result.isConfirmed) {
        this.knowledgeBaseService.deleteItem(id, res => {
          this.getItemList();
        });

      }
    });
  }

}

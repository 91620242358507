import { Component, EventEmitter, Input, OnInit, Output, ViewChild, } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { Utils } from 'src/app/common/utils';

@Component({
  selector: 'app-date-input',
  templateUrl: './app-date.component.html',
  styleUrls: ['./app-date.component.scss']
})
export class DateInputComponent implements OnInit {

  @ViewChild('picker') datePicker: MatDatepicker<Date>;
  @Input() invalid: boolean = false;
  @Input() label: string | null = null;
  @Input() date: Date;
  @Output() dateChange = new EventEmitter<Date>();

  dateString: string = "";
  
  constructor() {
  }

  ngOnInit(): void {
    if(this.date)
    this.dateString = Utils.stringifyDateEurope(this.date);
  }

  toggleDatePicker() {
    this.datePicker.open();
  }

  onDateChanged(val) {
    var date = new Date(val);
    this.dateString = Utils.stringifyDateEurope(date);
    this.dateChange.emit(date);
  }
}
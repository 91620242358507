import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: 'app-svg-trashcan',
    templateUrl: './app-svg-trashcan.component.html',
    styleUrls: ['./app-svg-trashcan.component.scss']
  })
  export class SvgTrashCanComponent implements OnInit {

    @Input() color: string | undefined = undefined;
  
    constructor() { }
  
    ngOnInit(): void {
    }
  
  }
  
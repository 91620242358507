<div style="height: 19px;">
  <svg id="tick" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
    <circle id="Ellipse_17" data-name="Ellipse 17" cx="9.5" cy="9.5" r="9.5"/>
    <g id="tick-2" data-name="tick" transform="translate(3.518 4.996)">
      <g id="Group_50" data-name="Group 50">
        <path id="Path_88" data-name="Path 88" d="M11.257,68.177a.544.544,0,0,0-.808,0L3.605,75.525.975,72.7a.544.544,0,0,0-.808,0,.646.646,0,0,0,0,.867L3.2,76.826a.544.544,0,0,0,.808,0l7.247-7.782A.646.646,0,0,0,11.257,68.177Z" transform="translate(0 -67.997)" fill="#fff" stroke="#fff" stroke-width="1"/>
      </g>
    </g>
  </svg>
</div>

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-step-add',
  templateUrl: './svg-step-add.component.html',
  styles: [
  ]
})
export class SvgStepAddComponent implements OnInit {

  constructor() { }

  @Input() width:number = 17.556;
  ngOnInit(): void {
  }

}

<div>
  <div class="title" translate>startup.team.newExtMem</div>
  <input #emailInput ngModel email type="email" placeholder="{{'common.user.emailAddress' | translate}}" [ngClass]="{invalid: errorMsg!==''}">
  <div class="error" *ngIf="errorMsg!==''">{{errorMsg}}</div>
  <div class="info" translate>startup.team.info1 <br>startup.team.info2 </div>
  <div style="display: flex; justify-content: space-around;">
    <button style="background-color: var(--cancelColor);"(click)="OnCancelClicked()" translate>common.cancel</button>
    <button style="background-color: var(--okColor);"(click)="OnInviteClicked()" translate>startup.team.inviteUser</button>
  </div>
</div>


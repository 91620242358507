import { Injectable } from '@angular/core';
import { ResultList } from '../../models/utility/ResultList.model';
import { StartupFilter, StartUp, Step, ManagerDetails } from '../../models/project/Startup.model';
import { BaseService } from '../utility/base.service';
import { StepsMakerService } from './form/steps-maker.service';
import { Language } from 'src/app/models/Language.model';
import { User } from 'src/app/models/project/User.model';

@Injectable({
  providedIn: 'root'
})
export class StartupService {

  projectId:string;
  projectStatus:string;
  filter: StartupFilter = new StartupFilter();

  constructor(
    protected baseService: BaseService,
    private stepsMakerService: StepsMakerService,
  ) { }

  async GetStartupsBySteps(callBack: (value: Step[]) => void) {
    let steps: Step[] = await this.stepsMakerService.GetSteps();

    

    callBack(steps);

    for(let step of steps) {
      await new Promise((resolve, reject) => {
        this.baseService.packageQuery<ResultList<StartUp>, object>('project','project/getProjectListWithDetails', {page: step.currentPage = 0, filter_step: step.id}, (response) => {
          resolve(response.data.list);
        });
      }).then((startups: StartUp[]) => {
        step.startups = startups;
        step.loading = false;
      });
    }
    

    for(let step2 of steps) {

      await new Promise((resolve, reject) => {
        this.getSumOfFundsByStep(step2.id, (res) => {
          step2.sumOfFundSizes = res;
          resolve(1);
        });

      });
    }
    
    for(let step3 of steps) {

      await new Promise((resolve, reject) => {
        this.getProjectManagersByStep(step3.id, (res) => {
          step3.pmList = res;
          resolve(1);
        });
      });

    }
  }

  GetNextPage(step: Step) {
    if(!step.arrievedLastPage) {
      this.baseService.packageQuery<ResultList<StartUp>, object>('project','project/getProjectListWithDetails', {page: ++step.currentPage, filter_step: step.id.toString()}, (response) => {
        step.loading = false;
        if(response.data.list.length === 0) {
          step.arrievedLastPage = true;
        } else {
          step.startups = step.startups.concat(response.data.list);
        }
      });
    } else {
      step.loading = false;
    }
  }

  async FilterStartups(filter: StartupFilter, callBack: (value: Step[]) => void) {


    if(filter.filter_search.length === 0) {

      let steps: Step[] = await this.stepsMakerService.GetSteps();
      callBack(steps);
      for(var step of steps) {
        await new Promise((resolve, reject) => {
          this.baseService.packageQuery<ResultList<StartUp>, object>('project','project/getProjectListWithDetails', {page: step.currentPage = 0, filter_step: step.id, filter_industry: filter.filter_industry, filter_tag: filter.filter_tag, filter_manager: filter.filter_manager, filter_search: filter.filter_search}, (response) => {
            resolve(response.data.list);
          });
        }).then((startups: StartUp[]) => {
          step.startups = startups;
          step.loading = false;
          this.filter = filter;
        });
      }
    } else {
      let stepsReturn: Step[] = [new Step(-1, new Language(), [])];
      callBack(stepsReturn);
    
      await new Promise((resolve, reject) => {
        this.baseService.packageQuery<ResultList<StartUp>, object>('project','project/getProjectListWithDetails', {page: stepsReturn[0].currentPage = 0, filter_industry: filter.filter_industry, filter_tag: filter.filter_tag, filter_manager: filter.filter_manager, filter_search: filter.filter_search}, (response) => {
          resolve(response.data.list);
        });
      }).then((startups: StartUp[]) => {
        for(let startup of startups) {
          stepsReturn[0].startups.push(startup);
        }
        stepsReturn[0].loading = true;
        this.filter = filter;
      });
        
    }
  }

  async FilterStartupsNextPage(filter: StartupFilter, stepsReturn: Step[]) {
    
    if(filter.filter_search.length === 0) {
      for(var step of stepsReturn) {
        await new Promise((resolve, reject) => {
          this.baseService.packageQuery<ResultList<StartUp>, object>('project','project/getProjectListWithDetails', {page: ++step.currentPage, filter_step: step.id.toString(), filter_industry: this.filter.filter_industry, filter_tag: this.filter.filter_tag, filter_manager: this.filter.filter_manager, filter_search: this.filter.filter_search}, (response) => {
            resolve(response.data.list);
          });
        }).then((startups: StartUp[]) => {
          for(let startup of startups) {
            step.startups.push(startup);
          }
          if(startups.length === 0) {
            step.loading = false;
          }
        });
      }
    } else {
      await new Promise((resolve, reject) => {
        this.baseService.packageQuery<ResultList<StartUp>, object>('project','project/getProjectListWithDetails', {page: ++stepsReturn[0].currentPage, filter_industry: this.filter.filter_industry, filter_tag: this.filter.filter_tag, filter_manager: this.filter.filter_manager, filter_search: this.filter.filter_search}, (response) => {
          resolve(response.data.list);
        });
      }).then((startups: StartUp[]) => {
        for(let startup of startups) {
          stepsReturn[0].startups.push(startup);
        }
        if(startups.length === 0) {
          stepsReturn[0].loading = false;
        }
      });
    }
  }

  SearchStartups(search:string, callBack: (value: StartUp[]) => void) {
    this.baseService.packageQuery<ResultList<StartUp>, object>('project','project/getProjectListWithDetails', {page: 1, filter_search: search}, (response) => {
      callBack(response.data.list);
    });
  }

  GetProject(id:string, callBack: (value: StartUp) => void) {
    this.baseService.packageQuery<{item: StartUp}, object>('project', 'project/getProject', {project_id: id}, (response) => {
      callBack(response.data.item);
    });
  }

  GetProjectExists(id:string, status:string, callBack: (value: boolean) => void) {
    this.baseService.packageQuery<{item: boolean}, object>('project', 'project/getProjectExists', {project_id: id, project_status: status}, (response) => {
      callBack(response.data.item);
    });
  }

  GetPortfolioManagement(page:number, callBack: (value: StartUp[]) => void) {
    return this.baseService.packageQuery<ResultList<StartUp>, object>('project', 'project/getProjectListWithDetails', {page: page, filter_status: "1"}, (response) => {
      callBack(response.data.list);
    });
  }

  SearchDealFlow(page:number, filter: StartupFilter, callBack: (value: StartUp[]) => void) {
    return this.baseService.packageQuery<ResultList<StartUp>, object>('project', 'project/getProjectListWithDetails', {page: page, filter_status: "0", filter_industry: filter.filter_industry, filter_manager: filter.filter_manager, filter_tag: filter.filter_tag, filter_search: filter.filter_search}, (response) => {
      callBack(response.data.list);
    });
  }

  getSumOfFundsByStep(step:number, callBack: (value: number) => void) {
    return this.baseService.packageQuery<{item: number}, object>('project', 'project/getSumOfFundsByStep', {project_step: step.toString()}, (response) => {
      callBack(response.data.item);
    });
  }

  getProjectManagersByStep(step:number, callBack: (value: ManagerDetails[]) => void) {
    return this.baseService.packageQuery<ResultList<ManagerDetails>, object>('project', 'project/getProjectManagers', {project_step: step.toString()}, (response) => {
      callBack(response.data.list);
    });
  }

  getProjectManagers(callBack: (value: User[]) => void) {
    return this.baseService.packageQuery<ResultList<User>, object>('project', 'project/getProjectManagers', {}, (response) => {
      callBack(response.data.list);
    });
  }
}

import {ErrorHandler, Injectable} from '@angular/core';
import {NgLoggerService} from '../services/auth/ng-logger.service';

@Injectable({
  providedIn: 'root'
})

export class NgErrorHandler implements ErrorHandler {

  constructor(
    private ngLoggerService: NgLoggerService
  ) {}

  handleError(error : Error) {
    // console.log(error);
    // this.ngLoggerService.logError(error,res=>{});
  }
}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-svg-startup-email',
  templateUrl: './svg-email.component.html'
})
export class SvgStartupEmailComponent implements OnInit {

  @Input() height:number = 22.318;
  constructor() { }

  ngOnInit(): void {
  }

}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {

  private componentMethodCallSource = new Subject<any>()

  constructor() { }

  componentMethodCalled$ = this.componentMethodCallSource.asObservable();

  callComponentMethod() {
    this.componentMethodCallSource.next();
  }

  private invalidPopUpCallSource = new Subject<any>()
  invalidPopUpCalled$ = this.invalidPopUpCallSource.asObservable();
  callinvalidPopUp() {
    this.invalidPopUpCallSource.next();
  }

  private loginCallSource = new Subject<any>()
  loginCalled$ = this.loginCallSource.asObservable();
  callLogin() {
    this.loginCallSource.next();
  }
}

<div class="menu-bar">
  <div class="datas">
      <div class="menu-item">
          <app-svg-location class="svg-item"></app-svg-location>
          <span>{{startup.project_country}}</span>
      </div>
      <div class="menu-item">
          <app-svg-category class="svg-item"></app-svg-category>
          <span>{{startup.details.industry.industry_name}}</span>
      </div>
      <div class="menu-item">
          <app-svg-fundsize class="svg-item"></app-svg-fundsize>
          <span>{{startup.project_fund}}M EURO</span>
      </div>
      <div class="menu-item">
          <app-svg-step class="svg-item"></app-svg-step>
          <span>{{getStep(startup)}}</span>
      </div>
      <div class="menu-item">
          <app-svg-calendar class="svg-item"></app-svg-calendar>
          <span>{{getDate(startup.project_reg_date)}}</span>
      </div>
  </div>
  <div class="rightmenu">
    <div class="menu-item">
      <a [href]="'mailto:' + startup.details.project_contact_details.email"><app-svg-startup-email class="svg-item"></app-svg-startup-email></a>
    </div>
    <div class="menu-item">
      <a [href]="startup.project_page" target="_blank"><app-svg-web class="svg-item"></app-svg-web></a>
    </div>
    <div class="menu-item">
      <a [href]="startup.project_linkedin" target="_blank"><app-svg-startup-linkedin class="svg-item"></app-svg-startup-linkedin></a>
    </div>
    <div class="menu-item">
      <app-svg-onepager class="svg-item"></app-svg-onepager>
    </div>
    <div class="menu-item contact-icons">
      <img style="border-color: var(--dealFlowColor);margin-right: 6px" [src]="startup.details.project_contact_details.image_hash" >
      <img style="border-color: var(--externalColor)"  [src]="startup.details.project_manager_details.image_hash" >
    </div>
  </div>
</div>

<div class="container-fluid p-0 data-content">
    <div class="row mt-3 justify-content-between">
        <div class="col-6 pl-2"></div>
        <div class="col-6">
            <div class="row justify-content-end pr-3">
                <div class="button-container">
                    <button class="btn btn-danger" (click)="deleteItem()"><i class="fa fa-check"></i></button>
                    <p>Törlés</p>
                </div>
                <div class="button-container">
                    <button class="btn btn-success" (click)="saveForm(kbForm)"><i class="fa fa-check"></i></button>
                    <p>Mentés</p>
                </div>
            </div>
        </div>
    </div>
    <form #kbForm="ngForm">
        <div class="card">
            <div class="card-body">
                <input type="hidden" name="in_id" [ngModel]="selectedItem?.in_id"/>
                <div class="form-group">
                    <label>Cím</label>
                    <input type="text" [ngModel]="selectedItem?.in_name" name="in_name" class="form-control">
                </div>
                <div class="form-group">
                    <label>Kategória</label>
                    <select class="form-control" name="in_cat_id" [ngModel]="selectedItem?.in_cat_id">
                        <option value="0">Nincs kategóriában</option>
                        <option *ngFor="let item of categories" [value]="item.inc_id">{{item.inc_name}}</option>
                    </select>
                </div>

                <div class="form-group">
                    <label>Tag</label>
                    <input type="text" [ngModel]="selectedItem?.in_tag" name="in_tag" class="form-control">
                </div>

                <div class="row" *ngIf="selectedItem?.in_id">
                    <div class="form-group col-lg-6">
                        <label>Létrehozta</label>
                        <input type="text" [ngModel]="selectedItem?.added_user?.name" name="in_add_user" class="form-control" disabled>
                    </div>
                    <div class="form-group col-lg-6">
                        <label>Létrehozva</label>
                        <input type="date" class="form-control" name="in_date" [ngModel]="selectedItem?.in_date" disabled>
                    </div>
                </div>
                <div class="row" *ngIf="selectedItem?.in_id">
                    <div class="form-group col-lg-6">
                        <label>Frissítette</label>
                        <input type="text" [ngModel]="selectedItem?.update_user?.name" name="in_update_user" class="form-control" disabled>
                    </div>
                    <div class="form-group col-lg-6">
                        <label>Frissítve</label>
                        <input type="date" class="form-control" name="in_update" [ngModel]="selectedItem?.in_update " disabled>
                    </div>
                </div>
            </div>
        </div>


        <h5>Rövid leírás</h5>
        <div class="card">
            <div class="card-body">
                <div class="form-group">
                    <textarea class="form-control" rows="5" placeholder="Rövid leírás..." [ngModel]="selectedItem?.in_shortdesc" name="in_shortdesc"></textarea>
                </div>
            </div>
        </div>
        <h5>Hosszú leírás</h5>
        <div class="card">
            <div class="card-body">
                <div class="form-group">
                  <!--
                    <div [ngxSummernote]="snConfig" name="in_desc" [ngModel]="selectedItem?.in_desc"></div>
                  -->
                </div>
            </div>
        </div>
    </form>
</div>

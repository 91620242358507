<div class="wrapper">
    <span *ngIf="label" class="label">{{label}}</span>
    <mat-form-field>
        <mat-select [ngModel]="value" [multiple]="allowMultiple" disableOptionCentering [formControl]="selectCtrl" [placeholder]="placeholder" #singleSelect (selectionChange)="onSelectedChanged($event)">
            <mat-option>
                <ngx-mat-select-search 
                    [formControl]="filterCtrl"
                    placeholderLabel="{{'common.search' | translate}}"
                    noEntriesFoundLabel="{{'common.noEntries' | translate}}">
                </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.value">
                {{option.text}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
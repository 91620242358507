import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import Swal from 'sweetalert2';
import {InquiryService} from '../../services/support/inquiry.service';
import {InquiryItem} from '../../models/inquiry/InquiryItem.model';
import {InquiryCategory} from '../../models/inquiry/InquiryCategory.model';

@Component({
  selector: 'app-inquiry-detail',
  templateUrl: './inquiry-detail.component.html',
  styleUrls: ['./inquiry-detail.component.css']
})
export class InquiryDetailComponent implements OnInit {

  constructor(
    private inquiryService: InquiryService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  snConfig: any;
  sn_shortConfig: any;
  selectedItem: InquiryItem;
  categories: Array<InquiryCategory>;

  ngOnInit(): void {
    this.sn_shortConfig = {height: '200px'};
    this.snConfig = {height: '400px'};
    let id = +this.route.snapshot.paramMap.get('id');
    if (id > 0) {
      this.getItem(id);
    }
    this.inquiryService.getCategoryList(res => this.categories = res.data.list);
  }

  getItem(id) {
    this.inquiryService.getItem(id, res => {
      this.selectedItem = res.data.item;
    });
  }

  saveForm(form: NgForm): void {
    if (form.value.in_id) {
      this.inquiryService.updateItem(form.value, res => {
        this.router.navigate(['inquiry/itemlist']);
      });
    } else {
      this.inquiryService.addItem(form.value, res => {
        this.router.navigate(['inquiry/itemlist']);
      });
    }
  }

  deleteItem() {
    Swal.fire({
      title: 'Törlésre készül !',
      text: 'Törli a cikket ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Igen, Törlöm'
    }).then((result) => {
      if (result.isConfirmed) {
        this.inquiryService.deleteItem(this.selectedItem.in_id, res => {
          this.router.navigate(['inquiry/itemlist']);
        });
      }
    });
  }

}

import {Component, OnInit} from '@angular/core';
import {KnowledgeBaseService} from '../../services/support/knowledge-base.service';
import {ActivatedRoute, Router} from '@angular/router';
import {KnowledgeBaseCategory} from '../../models/knowledgebase/KnowledgeBaseCategory.model';
import {NgForm} from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-knowledgebase-category',
  templateUrl: './knowledgebase-category.component.html',
  styleUrls: ['./knowledgebase-category.component.scss']
})
export class KnowledgebaseCategoryComponent implements OnInit {

  constructor(
    private knowledgeBaseService: KnowledgeBaseService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }


  selectedCategory: KnowledgeBaseCategory;

  ngOnInit(): void {
    let id = +this.route.snapshot.paramMap.get('id');
    if (id > 0) {
      this.getCategory(id);
    }
  }

  getCategory(id) {
    this.knowledgeBaseService.getCategory(id, res => {
      this.selectedCategory = res.data.item;
    });
  }

  saveForm(form: NgForm) {
    if (form.value.kbc_id) {
      this.knowledgeBaseService.updateCategory(form.value, res => {
        this.router.navigate(['knowledgebase/category/list']);
      });
    } else {
      this.knowledgeBaseService.addCategory(form.value, res => {
        this.router.navigate(['knowledgebase/category/list']);
      });
    }
  }

  deleteItem() {
    Swal.fire({
      title: 'Törlésre készül !',
      text: 'Biztosan törli a kategóriát ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Igen, Törlöm'
    }).then((result) => {
      if (result.isConfirmed) {
        this.knowledgeBaseService.deleteCategory(this.selectedCategory?.kbc_id, res => {
          this.router.navigate(['knowledgebase/category/list']);
        });
      }
    });
  }
}

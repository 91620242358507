import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-report',
  templateUrl: './svg-report.component.html',
  styles: [
  ]
})
export class SvgReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="card-wrapper">
    <span class="title" translate>profileCard.title</span>
    <div class="image-wrapper">
        <!-- <img class="profile-pic" (click)="addFilePopup.Open();" [src]="currentImg" alt="profile pic"> -->
        <img class="profile-pic" [src]="currentImg" alt="profile pic">
    </div>
    <form #profileForm [formGroup]="formGroup">
        <input-field [invalid]="currentUser.name == ''" label="{{'profileCard.name' | translate}}" [(value)]="currentUser.name"></input-field>
        <input-field [invalid]="emailError" label="{{'profileCard.email' | translate}}" [(value)]="currentUser.email"></input-field>
        <input-field [invalid]="phoneError" label="{{'profileCard.phone' | translate}}" [(value)]="currentUser.phone"></input-field>
        <input-field [invalid]="linkedinError" label="{{'profileCard.linkedin' | translate}}" [(value)]="currentUser.address"></input-field>
        <app-button class="profileBtn" type="{{ButtonType.Primary}}" text="{{'common.save' | translate}}"></app-button>
        <app-button class="profileBtn" type="{{ButtonType.Secondary}}" text="{{'common.resetPw' | translate}}"></app-button>
        <app-button (onClick)="logout()" class="profileBtn" type="{{ButtonType.SecondaryRed}}" text="{{'profileCard.signOut' | translate}}"></app-button>
    </form>
</div>
<app-popup #addFilePopup>
    <form #addFileForm="ngForm" class="addFileForm" (ngSubmit)="AddFileSubmit(addFileForm)">
        <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [style.display]="showCropper ? null : 'none'"
        [roundCropper]="true"
        [maintainAspectRatio]="true"
        [aspectRatio]="1 / 1"
        format="png"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        ></image-cropper>
        <div class="profile-pic-preview">
        <img class="profile-pic" [src]="filePath" alt="profile pic">
        </div>
        <div class="upload-inputs">
        <input #fileInput type="file" name="name" accept="image/*" style="display: none;" (change)="OnFileInputchanged($event)" />
        <button class="addFileButton" (click)="OnFileSelectInput($event, fileInput)" translate><span>common.addFile</span></button>
        <input type="text" class="fnamebox" name="fileOriginalName" [(ngModel)]="fileOriginalName" required/>
        </div>
        <div class="upload-buttons">
        <button style="background-color: var(--cancelColor);" (click)="OnFormCancel($event); addFileForm.resetForm()" translate>common.cancel</button>
        <button style="background-color: var(--okColor);" translate>common.save</button>
        </div>
    </form>
</app-popup>

<app-popup #confirmPopup>
    <form #confirmForm="ngForm" (ngSubmit)="confirmChange(confirmForm)">
        <div class="upload-inputs">
        <input type="password" id="password" name="password" placeholder="Password" [(ngModel)]="cnfForm.password"/>
        </div>
        <div class="upload-buttons">
        <button class="cancel" style="background-color: var(--cancelColor);" (click)="OnFormCancel($event); confirmForm.resetForm()" translate>common.cancel</button>
        <button class="save" style="background-color: var(--okColor);" translate>common.save</button>
        </div>
    </form>
</app-popup>
<!-- <div class="remove" [ngStyle]="{'width.px': isStepEdit?(isMobileResolution()?17:27):0, 'margin-right.px': isMobileResolution()?(isStepEdit?10:0):10}">
  <app-svg-step-remove [width]="'100%'" (click)="OnFormRemove()"></app-svg-step-remove>
</div> -->
<div class="form-elem" [ngStyle]="{'width': isStepEdit?(isMobileResolution()?'calc(100%-35px)':'calc(100%-45px)'):'100%'}" cdkDragLockAxis="y">
  <div class="form-elem-header">
    <span *ngIf="!isStepEdit">{{form.GetName(currentLang)}}</span>
    <!-- <input type="text" (click)="OnHeaderClicked($event)" *ngIf="isStepEdit" [(ngModel)]="form.formTitle[currentLang]"> -->
    <input-field (mousedown)="$event.stopPropagation()" placeholder="{{'steps.dashboard.formTitle' | translate}}" *ngIf="isStepEdit" [(value)]="form.formTitle[currentLang]"></input-field>
    <!-- <div style="flex-grow: 1; justify-self: stretch; height: 1.9rem;"
      [id]="'h-'+listId" cdkDropList
      [cdkDropListConnectedTo]="stepFormIds"
      (cdkDropListDropped)="hDrop($event)"
    ></div> -->
    <div style="display: flex; flex-direction: row; align-items: center;">
      <app-svg-trashcan *ngIf="isStepEdit" style="margin-right: 8px; cursor: pointer;" color="var(--buttonRedColor)" (click)="onFormRemove()"></app-svg-trashcan>
      <app-svg-arrow-blue-down style="cursor: pointer;" #arrow (click)="onHeaderClicked($event)"></app-svg-arrow-blue-down>
    </div>
  </div>
  <div class="inputs example-list" [@openClose]="!arrow.isOpen ? 'open' : 'closed'"
  style="transition: all 0.3s ease-in-out; overflow: hidden;" [id]="'form-list-'+listId" cdkDropList
    [cdkDropListConnectedTo]="stepFormIds" [cdkDropListData]="form.inputFileds"
    [cdkDropListDisabled]="!isStepEdit"
    (cdkDropListDropped)="drop($event)"
    >
    <div *ngIf="form.GetDescription(currentLang)!=undefined && form.GetDescription(currentLang)!='' && !isStepEdit" id="formGroupDescription"><p *ngFor="let p of form.GetDescription(currentLang).split('\n')">{{p}}</p></div>
    <!-- <textarea *ngIf="isStepEdit" class="disableScrollBar" id="desc" [(ngModel)]="form.description[currentLang]" (keydown.enter)="OnEnter($event)"></textarea> -->
    <div *ngFor="let inputField of form.inputFileds; let i = index" style="margin-top: 10px;" cdkDrag class="example-box" cdkDragLockAxis="y">
      <div style="display: flex; align-items: center;">
        <app-svg-trashcan *ngIf="isStepEdit" class="question-delete" (click)="OnXClicked(inputField.id, i)"></app-svg-trashcan>
        <div style="width: 100%;" [ngStyle]="{'cursor': isStepEdit?'pointer':'initial'}">
          <input-field-base [isStepEdit]="isStepEdit" [(label)]="inputField.label" (typeChange)="onTypeChanged($event, i)" [(type)]="inputField.type" *ngIf="inputField.type == InputFieldType.DateScheduling"><app-date-scheduling #dateSchedulingComponent [dateScheduler]="inputField" [currentLang]="currentLang" [editmode]="isStepEdit" [readonly]="readonly" (nameChanged)="OnNameChanged(inputField)" (descriptionChanged)="OnDescriptionChanged(inputField)" (requiredChanged)="OnRequiredChanged(inputField)"></app-date-scheduling></input-field-base>
          <input-field-base [isStepEdit]="isStepEdit" [(label)]="inputField.label" (typeChange)="onTypeChanged($event, i)" [(type)]="inputField.type" *ngIf="inputField.type == InputFieldType.TextEditor"><app-text-editor #textEditorComponent [textEditor]="inputField" [currentLang]="currentLang" [editmode]="isStepEdit" [readonly]="readonly" (nameChanged)="OnNameChanged(inputField)" (descriptionChanged)="OnDescriptionChanged(inputField)" (requiredChanged)="OnRequiredChanged(inputField)"></app-text-editor></input-field-base>
          <input-field-base [isStepEdit]="isStepEdit" [(label)]="inputField.label" (typeChange)="onTypeChanged($event, i)" [(type)]="inputField.type" *ngIf="inputField.type == InputFieldType.Repeater"><app-repeater #repeaterComponent [repeater]="inputField" [currentLang]="currentLang" [editmode]="isStepEdit" [readonly]="readonly" (nameChanged)="OnNameChanged(inputField)" (descriptionChanged)="OnDescriptionChanged(inputField)" (requiredChanged)="OnRequiredChanged(inputField)"></app-repeater></input-field-base>
          <input-field-base [isStepEdit]="isStepEdit" [(label)]="inputField.label" (typeChange)="onTypeChanged($event, i)" [(type)]="inputField.type" *ngIf="inputField.type == InputFieldType.DataMatrix"><app-data-matrix #dataMatrixComponent [dataMatrix]="inputField" [currentLang]="currentLang" [editmode]="isStepEdit" [readonly]="readonly" (nameChanged)="OnNameChanged(inputField)" (descriptionChanged)="OnDescriptionChanged(inputField)" (requiredChanged)="OnRequiredChanged(inputField)"></app-data-matrix></input-field-base>
          <input-field-base [isStepEdit]="isStepEdit" [(label)]="inputField.label" (typeChange)="onTypeChanged($event, i)" [(type)]="inputField.type" *ngIf="inputField.type == InputFieldType.FileUploader"><app-file-uploader #fileUploaderComponent [fileUploader]="inputField" [currentLang]="currentLang" [editmode]="isStepEdit" [readonly]="readonly" (nameChanged)="OnNameChanged(inputField)" (descriptionChanged)="OnDescriptionChanged(inputField)" (requiredChanged)="OnRequiredChanged(inputField)"></app-file-uploader></input-field-base>
          <input-field-base [isStepEdit]="isStepEdit" [(label)]="inputField.label" (typeChange)="onTypeChanged($event, i)" [(type)]="inputField.type" *ngIf="inputField.type == InputFieldType.Selector"><app-selector #SelectorComponent [selectorId]="i+form.formTitle.hu" [selector]="inputField" [currentLang]="currentLang" [editmode]="isStepEdit" [readonly]="readonly" (nameChanged)="OnNameChanged(inputField)" (descriptionChanged)="OnDescriptionChanged(inputField)" (requiredChanged)="OnRequiredChanged(inputField)"></app-selector></input-field-base>
          <input-field-base [isStepEdit]="isStepEdit" [(label)]="inputField.label" (typeChange)="onTypeChanged($event, i)" [(type)]="inputField.type" *ngIf="inputField.type == InputFieldType.RadioButton"><app-radio-button #RadioButtonComponent [radioId]='i' [radioButton]="inputField" [currentLang]="currentLang" [editmode]="isStepEdit" [readonly]="readonly" (nameChanged)="OnNameChanged(inputField)" (descriptionChanged)="OnDescriptionChanged(inputField)" (requiredChanged)="OnRequiredChanged(inputField)"></app-radio-button></input-field-base>
          <input-field-base [isStepEdit]="isStepEdit" [(label)]="inputField.label" (typeChange)="onTypeChanged($event, i)" [(type)]="inputField.type" *ngIf="inputField.type == InputFieldType.DropDownMenu"><app-drop-down-menu #dropDownComponent [dropDown]="inputField" [currentLang]="currentLang" [editmode]="isStepEdit" [readonly]="readonly" (nameChanged)="OnNameChanged(inputField)" (descriptionChanged)="OnDescriptionChanged(inputField)" (requiredChanged)="OnRequiredChanged(inputField)"></app-drop-down-menu></input-field-base>
          <input-field-base [isStepEdit]="isStepEdit" [(label)]="inputField.label" (typeChange)="onTypeChanged($event, i)" [(type)]="inputField.type" *ngIf="inputField.type == InputFieldType.LongTextLine"><app-long-text-line #longTextLineComponent [textLine]="inputField" [currentLang]="currentLang" [editmode]="isStepEdit" [readonly]="readonly" (nameChanged)="OnNameChanged(inputField)" (descriptionChanged)="OnDescriptionChanged(inputField)" (requiredChanged)="OnRequiredChanged(inputField)"></app-long-text-line></input-field-base>
          <input-field-base [isStepEdit]="isStepEdit" [(label)]="inputField.label" (typeChange)="onTypeChanged($event, i)" [(type)]="inputField.type" *ngIf="inputField.type == InputFieldType.ShortTextLine"><app-short-text-line #shortTextLineComponent [textLine]="inputField" [currentLang]="currentLang" [editmode]="isStepEdit" [readonly]="readonly" (nameChanged)="OnNameChanged(inputField)" (descriptionChanged)="OnDescriptionChanged(inputField)" (requiredChanged)="OnRequiredChanged(inputField)"></app-short-text-line></input-field-base>
          <input-field-base [isStepEdit]="isStepEdit" [(label)]="inputField.label" (typeChange)="onTypeChanged($event, i)" [(type)]="inputField.type" *ngIf="inputField.type == InputFieldType.VideoInput"><app-video-input #videoInputComponent></app-video-input></input-field-base>
        </div>
      </div>
    </div>
    <div class="new-question-wrapper">
      <app-button text="{{'steps.dashboard.newQuestion' | translate}}" (onClick)="insertNewQuestion()"></app-button>
    </div>
  </div>
</div>
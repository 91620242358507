import { Component, Input, OnDestroy, OnInit, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Editor, NgxEditorConfig } from 'ngx-editor';
import { TextEditor } from 'src/app/models/dashboard/InputField.model';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.css']
})
export class TextEditorComponent implements OnInit, OnDestroy {

  constructor() { }

  @Input() textEditor:TextEditor;
  @Input() currentLang:string;
  @Input() editmode:boolean = false;
  @Input() readonly:boolean = false;
  @Output() nameChanged:EventEmitter<string> = new EventEmitter<string>();
  @Output() descriptionChanged:EventEmitter<string> = new EventEmitter<string>();
  @Output() requiredChanged:EventEmitter<boolean> = new EventEmitter<boolean>();
  isLabelEdit:boolean = false;
  isDescriptionEdit:boolean = false;
  editor:Editor;
  editorConfig:NgxEditorConfig = {};

  ngOnInit(): void {
    this.editor = new Editor();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.editmode && changes.editmode.currentValue == false) {
      this.isLabelEdit = false;
      this.isDescriptionEdit = false;
    }
  }

  ngOnDestroy():void{
    this.editor.destroy();
  }

  labelCopy: string;
  OnLabelDoubleClick():void{
    if(this.editmode) {
      this.isLabelEdit = true;
      this.labelCopy = this.textEditor.name[this.currentLang];
    }
  }

  OnLabelEnter():void{
    if(this.textEditor.name[this.currentLang] != "") {
      this.nameChanged.emit();
    } else {
      this.textEditor.name[this.currentLang] = this.labelCopy;
    }
    this.isLabelEdit = false;
  }

  descriptionCopy: string;
  OnDescriptionDoubleClick():void{
    if(this.editmode) {
      this.isDescriptionEdit = true;
      this.descriptionCopy = this.textEditor.small[this.currentLang];
    }
  }

  OnDescriptionEnter():void{
    this.descriptionChanged.emit();
    this.isDescriptionEdit = false;
  }

  OnRequiredClicked():void{
    this.requiredChanged.emit();
  }
}

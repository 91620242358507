import { Injectable } from '@angular/core';
import { BaseService } from '../utility/base.service';
import { JWTToken } from 'src/app/models/utility/JWTToken.model';
import { ApiResponse } from 'src/app/models/utility/ApiResponse.model';
import { User } from '../../models/project/User.model';

@Injectable({
  providedIn: 'root'
})
export class InternalPersonsService {
  internalTeamOptions:User[] = [];
  internalTeamIds:number[] = [];
  internalTeamProjectIds:number[] = [];
  portfolioManagers:User[] = [];
  constructor(
    protected baseService: BaseService,
  ) {
    this.RefreshTeamList(() => {});
    let portfolioPerson: User =  {};
    portfolioPerson.name = 'Bradley Bellick';
    // portfolioPerson.jobTitle = 'Portfolio Manager';
    portfolioPerson.image_hash = '/assets/bradley.png';
    portfolioPerson.phone = "1234567";
    portfolioPerson.url = "www.linkedin.com";
    this.portfolioManagers.push(portfolioPerson);
    this.portfolioManagers.push(portfolioPerson);
    this.portfolioManagers.push(portfolioPerson);
    this.portfolioManagers.push(portfolioPerson);
    this.portfolioManagers.push(portfolioPerson);
    this.portfolioManagers.push(portfolioPerson);
    this.portfolioManagers.push(portfolioPerson);
    this.portfolioManagers.push(portfolioPerson);
  }

  GetTeamList(callBack: (value: ApiResponse<JWTToken>) => void) {
    return this.baseService.packageQuery<JWTToken, object>('project', 'team/getTeamList', {}, callBack);
  }

  RefreshTeamList(callBack: () => void) {
    this.GetTeamList((response) => {
      this.internalTeamOptions = [];
      this.internalTeamIds = [];
      this.internalTeamProjectIds = []
      response.data.list.forEach(team => {
        if(team.team_internal == 1) {
          let person: User = {};
          if(team.id) {
            person.id = team.id;
          }
          if(team.user_id) {
            person.id = team.user_id;
          }

          person.name = team.team_name;
          
          
          if(team.picture) {
            person.picture = team.picture;
          } else {
            person.picture = '/assets/test-profile-pic.png'
          }

          
          person.image_hash = person.picture;

          person.email = team.team_email;
          person.phone = team.team_phone;
          person.url = team.team_linkedin;
          person.jobTitle = team.team_position;
          person.isTaged = team.team_contact == 0 ? false : true;
          this.internalTeamOptions.push(person);
          this.internalTeamIds.push(team.team_id);
          this.internalTeamProjectIds.push(team.project_id);
        }
      });
      callBack();
    });
  }

  GetPerson(id:number): User {
    return this.internalTeamOptions[this.internalTeamIds[id]];
  }
}

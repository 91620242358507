import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Utils } from 'src/app/common/utils';
import { Note } from 'src/app/models/Note.model';
import { NotesService } from 'src/app/services/project/notes/notes.service';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
export class NoteComponent implements OnInit {

  @Input()note:Note;
  @Input()tagColor:String='green';

  @Output() delteNoteEvent = new EventEmitter<string>();
  @Output() editNoteEvent = new EventEmitter<Note>();

  constructor(private notesService: NotesService) { }

  dateString:string;

  ngOnInit(): void {
    this.dateString = Utils.stringifyDate(new Date(this.note.date.toString().replace(/-/g, "/")));
  }

  removeNote() {
    this.delteNoteEvent.emit(this.note.id);
  }

  editNote() {
    this.editNoteEvent.emit(this.note);
  }

}

import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Task } from 'src/app/models/Task.model';
import { FilterService } from 'src/app/services/utility/filter.service';
import { TaskService } from 'src/app/services/project/tasks/tasks.service';
import { TeamService } from 'src/app/services/project/team/team.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ButtonType } from 'src/app/models/project/ButtonType.model';
import { MatDialog } from '@angular/material/dialog';
import { TaskDialogComponent } from './task-dialog/task-dialog.component';
import { TopNavTaskComponent } from 'src/app/shared/components/top-nav/top-nav-task/top-nav-task.component';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit, OnDestroy {
  
  filteredTaskListInternal: Task[] = [];
  filteredTaskListInternalDone: Task[] = [];
  filteredTaskListExternal: Task[] = [];
  filteredTaskListExternalDone: Task[] = [];
  
  isAdmin: boolean = false;
  ButtonType = ButtonType;

  projectId:string;
  projectStatus:string;

  private routeSub: Subscription;
  currentId;

  constructor(
    private taskService: TaskService,
    private filterService: FilterService,
    private teamService: TeamService,
    private router: Router,
    private route: ActivatedRoute,
    public authService: AuthService,
    public dialog: MatDialog
  ) {
    this.isAdmin = this.authService.currentUserValue.main_permission == 1;
    this.sub = this.filterService.updated.subscribe((x:string)=>{
      if(this.filteredTaskListInternal!=undefined) this.OnSearchChanged(x);
    });
    this.routeSub = this.route.parent.params.subscribe(params => {
      this.projectId = params['id'];
      this.projectStatus = this.router.url.split('/')[1] == "portfolio" ? "1" : "0";
      this.taskService.RefreshTaskList(this.projectId, this.projectStatus, () => {
        if(this.isAdmin) {
          this.filteredTaskListInternal = this.taskService.taskListInternal;
          this.filteredTaskListInternalDone = this.taskService.taskListInternalDone;
        }
        this.filteredTaskListExternal = this.taskService.taskListExternal;
        this.filteredTaskListExternalDone = this.taskService.taskListExternalDone;
      });
      this.teamService.RefreshTeamList(this.projectId, this.projectStatus, () => {});
    });
  }

  sub:Subscription;

  ngOnInit(): void { 
    this.currentId = this.authService.currentUserValue.id;
  }

  ngOnDestroy(): void {
    if(this.routeSub) {
      this.routeSub.unsubscribe();
    }
    if(this.sub) {
      this.sub.unsubscribe();
    }
  }

  openInternalDialog(task?: Task) {
    const dialogRef = this.dialog.open(TaskDialogComponent, {
      data: task,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result?: Task) => {
      if(result) {
        if(result.id != -1) {
          this.editTaskInternal(result);
        }
        else {
          this.addTaskInternal(result);
        }
      }
    });
  }

  openExternalDialog(task?: Task) {
    const dialogRef = this.dialog.open(TaskDialogComponent, {
      data: task,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result?: Task) => {
      if(result) {
        if(result.id != -1) {
          this.editTaskExternal(result);
        }
        else {
          this.addTaskExternal(result);
        }
      }
    });
  }

  removeInternal(id: number) {
    
  }

  removeExternal(id: number) {

  }

  OnSearchChanged(input: string): void {
    if(this.isAdmin) {
      let result = this.taskService.FilterTasks(input, true);
      this.filteredTaskListInternalDone = result.isDone;
      this.filteredTaskListInternal = result.inProc;
    }
    let result = this.taskService.FilterTasks(input, false);
    this.filteredTaskListExternalDone = result.isDone;
    this.filteredTaskListExternal = result.inProc;
  }

  addTaskInternal(task: Task): void {
    this.taskService.addTask(this.projectId, this.projectStatus, this.currentId, task, "1", () => {
      this.filteredTaskListInternal = this.taskService.taskListInternal;
      this.filteredTaskListInternalDone = this.taskService.taskListInternalDone;
      
      this.teamService.RefreshTeamList(this.projectId, this.projectStatus, () => {});
    })
  }

  addTaskExternal(task: Task): void {
    this.taskService.addTask(this.projectId, this.projectStatus, this.currentId, task, "0", () => {
      this.filteredTaskListExternal = this.taskService.taskListExternal;
      this.filteredTaskListExternalDone = this.taskService.taskListExternalDone;
      
      this.teamService.RefreshTeamList(this.projectId, this.projectStatus, () => {});
    });
  }

  editTaskInternal(task: Task): void {
    this.taskService.UpdateTask(this.currentId, task, "1", () => {
      this.filteredTaskListInternal = this.taskService.taskListInternal;
      this.filteredTaskListInternalDone = this.taskService.taskListInternalDone;

      this.teamService.RefreshTeamList(this.projectId, this.projectStatus, () => {});
    });
  }

  editTaskExternal(task: Task): void {
    this.taskService.UpdateTask(this.currentId, task, "0", () => {
      this.filteredTaskListExternal = this.taskService.taskListExternal;
      this.filteredTaskListExternalDone = this.taskService.taskListExternalDone;
      
      this.teamService.RefreshTeamList(this.projectId, this.projectStatus, () => {});
    });
  }

  drop(event: CdkDragDrop<string[]>): void {
    if(event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.taskService.SortTasks(this.GetIds());
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      this.taskService.TickTask(((event.container.data[event.currentIndex] as unknown) as Task).id, event.previousContainer.element.nativeElement.classList.contains("not-done") ? true : false, () => {
        ((event.container.data[event.currentIndex] as unknown) as Task).isDone = event.previousContainer.element.nativeElement.classList.contains("not-done") ? true : false;
        this.taskService.SortTasks(this.GetIds());
      });
    }
  }

  GetIds(): number[] {
    let tasks = [];
    this.filteredTaskListInternal.forEach(task => {
      tasks.push(task.id);
    });
    this.filteredTaskListInternalDone.forEach(task => {
      tasks.push(task.id);
    });
    this.filteredTaskListExternal.forEach(task => {
      tasks.push(task.id);
    });
    this.filteredTaskListExternalDone.forEach(task => {
      tasks.push(task.id);
    });
    return tasks;
  }

  OnTaskTicked(index: number, tick: boolean, internal:boolean, id:number): void {
    if(internal) {
      if(tick) {
        transferArrayItem(this.filteredTaskListInternal, this.filteredTaskListInternalDone, id, 0);
        this.taskService.SortTasks(this.GetIds());
      } else {
        transferArrayItem(this.filteredTaskListInternalDone, this.filteredTaskListInternal, id, 0);
        this.taskService.SortTasks(this.GetIds());
      }
    } else {
      if(tick) {
        transferArrayItem(this.filteredTaskListExternal, this.filteredTaskListExternalDone, id, 0);
        this.taskService.SortTasks(this.GetIds());
      } else {
        transferArrayItem(this.filteredTaskListExternalDone, this.filteredTaskListExternal, id, 0);
        this.taskService.SortTasks(this.GetIds());
      }
    }
    this.taskService.TickTask(index, tick, () => {});
  }

  OnTaskFlagged(index: number, priority: number): void {
    this.taskService.FlagTask(index, priority, () => {});
  }
}

<div style="display: flex; align-content: center; margin-bottom: 15px;">
  <input #inputField type="text" placeholder="{{'general.editForms.placeholder.addNew' | translate}}" class="popup-input" style="margin-right: 10px; font-size: 12px;">
  <button (click)="OnAdd(inputField)">+</button>
</div>

<div style="height: 250px; overflow-y: auto; font-size: 12px;" class="disableScrollBar disableSelect example-list"
cdkDropList cdkDropListLockAxis="y" #container
(cdkDropListDropped)="drop($event)">
  <div *ngFor="let col of cols; let i = index" class="col-item" cdkDrag>
    <app-arranger cdkDragHandle style="cursor: grab;"></app-arranger>
    <div style="justify-self: stretch;">{{col.name}}</div>
    <app-svg-remove-gr style="justify-self: center; cursor: pointer;" (click)="OnDelete(i)"></app-svg-remove-gr>
  </div>
</div>

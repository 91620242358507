<div style="height: 36.986px;" [ngStyle]="{'width.px':width}">
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="36.986" viewBox="0 0 29.989 36.986">
    <g id="file" transform="translate(-5 -2)">
      <path id="Path_79" data-name="Path 79" d="M23.493,3H9.5A3.5,3.5,0,0,0,6,6.5V34.487a3.5,3.5,0,0,0,3.5,3.5H30.49a3.5,3.5,0,0,0,3.5-3.5V13.5Z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <path id="Path_80" data-name="Path 80" d="M21,3V13.5H31.5" transform="translate(2.493)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <path id="Path_81" data-name="Path 81" d="M25.994,19.5H12" transform="translate(0.997 2.742)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <path id="Path_82" data-name="Path 82" d="M25.994,25.5H12" transform="translate(0.997 3.74)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <path id="Path_83" data-name="Path 83" d="M15.5,13.5H12" transform="translate(0.997 1.745)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    </g>
  </svg>
</div>

<div class="wrapper">
  <div class="header-row">
    <div translate>general.basic.catName</div>
    <div translate>general.basic.color</div>
    <div *ngIf="!isMobileResolution()" translate>general.basic.action</div>
  </div>
  <div class="data-row" *ngFor="let tag of tags; let i = index">
    <div>{{tag.text}}</div>
    <div [ngStyle]="{'background-color': tag.color}" class="tagcolor">{{tag.color}}</div>
    <div class="buttons" *ngIf="!isCategories || i>2">
      <button class="btn remove" (click)="OnRemoveClicked(i);" translate>common.remove</button>
    </div>
  </div>
</div>

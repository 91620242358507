import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-location',
  templateUrl: './svg-location.component.html'
})
export class SvgLocationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

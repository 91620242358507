import { InvexFile } from "../utility/File.model";

export class Tag{
  id?: number;
  text:string;
  color:string;
  textColor?:string = 'white';
  

  constructor(id?: number, text?: string, color?: string) {
    this.id = id;
    this.text = text;
    this.color = color;
  }
}

export class TagResponse{
  tag_id: number;
  tag_name: string;
  tag_color: string;
  tag_textcolor: string;
  tag_status: boolean;
  tag_removed: boolean;
}

export class ActionFilter{
  isImportant:boolean;
  actionTypes:TimelineActionType[];
  tags:Tag[];
}

export enum TimelineActionType{
  TRACTION, MILESTONE, REPORT, EVENT
}
export class TimelineAction{
  id:number;
  projectid:string;
  action:TimelineActionType;
  deadline:Date;
  location:string;
  text:string;
  title:string;
  tag?:{text:string, color:string}
  isDone:boolean = false;
  isImportant:boolean = false;
  subtasks:{isDone:boolean, text:string}[];
  fileRequests:{name:string, format:string[]}[];
  partipicants:string[];
  connectedEvents:TimelineAction[];
  file?:InvexFile
  constructor(){
    this.subtasks = [];
    this.fileRequests = [];
    this.partipicants = [];
    this.connectedEvents = [];
  }
}

import { Injectable } from '@angular/core';
import { InvexFile, FileGroup } from "src/app/models/utility/File.model";
import { JWTToken } from 'src/app/models/utility/JWTToken.model';
import { ApiResponse } from 'src/app/models/utility/ApiResponse.model';
import { BaseService } from '../../utility/base.service';
import { ApiResponseItem } from '../../../models/utility/ApiResponseItem.model';
import { AuthService } from '../../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class FilesService{

  fileGroups: FileGroup[] = [];
  isAdmin: boolean = false;

  constructor(
    protected baseService: BaseService,
    public authService: AuthService,
  ) {
    this.isAdmin = this.authService.currentUserValue.main_permission == 1;
  }

  GetFileGroups(id:string, callBack: () => void) {
    this.baseService.packageQuery<JWTToken, object>('project', 'files/getProjectFileGroupList', {}, (result) => {
      this.fileGroups = [];
      result.data.list.forEach(group => {
        this.fileGroups.push(new FileGroup(Number(group.project_file_group_id), group.project_file_group_name));
      });
      this.fileGroups.push(new FileGroup());
      this.GetProjectFiles(id, callBack);
    });
  }

  //TODO: File description és verzió
  GetProjectFiles(id:string, callBack: () => void) {
    for(let group of this.fileGroups) {
      if(this.isAdmin) {
        this.baseService.packageQuery<JWTToken, object>('project', 'files/getProjectFiles',
        {project_id: id, project_file_group_id: group.id.toString(), project_file_internal: true}, (response) => {
          for(let item of response.data.item) {
            let file: InvexFile = {fileName: item.project_file.op_file_name, id: item.project_file.file_id, projectFileId: item.project_file_id, description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.", loading: false, versions: [
              {version:'v1', uploadDate: new Date(item.project_file.upload_date), fileName:item.project_file.source_file_name, path:''},
            ]};
            group.internal.push(file);
            group.internalFiltered.push(file);
          }
        });
      }
      this.baseService.packageQuery<JWTToken, object>('project', 'files/getProjectFiles',
      {project_id: id, project_file_group_id: group.id.toString(), project_file_internal: false}, (response) => {
        for(let item of response.data.item) {
          let file: InvexFile = {fileName: item.project_file.op_file_name, id: item.project_file.file_id, projectFileId: item.project_file_id, description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.", loading: false, versions: [
            {version:'v1', uploadDate: new Date(item.project_file.upload_date), fileName:item.project_file.source_file_name, path:''},
          ]};
          group.external.push(file);
          group.externalFiltered.push(file);
        }
      });
    }
    callBack();
  }

  AddFileOther(id:string, isInternal: boolean, file:InvexFile, fileType:string, hash:string, callBack: () => void):void{
    this.uploadFile(file, fileType, hash, (response) => {
      this.baseService.packageQuery<JWTToken, object>('project', 'files/addFileHashToProject', {project_file_group_id: "0", project_id: id, project_file_status: "0", project_file_internal: isInternal ? "1" : "0", project_file_hash: response.data.item.file_hash}, () => {
        if(isInternal) {
          this.fileGroups[this.fileGroups.length-1].internal.push(file);
          file.loading = false;
        } else {
          this.fileGroups[this.fileGroups.length-1].external.push(file);
          file.loading = false;
        }
        callBack();
      });
    });
  }

  RemoveFile(file:InvexFile, deleteFile:boolean, callBack: () => void){
    this.baseService.packageQuery<JWTToken, object>('project', 'files/deleteFilehashFromProject', {project_file_id: file.projectFileId}, () => {
      if(deleteFile) {
        this.DeleteFile(file.id, callBack);
      } else {
        callBack();
      }
    });
  }

  DeleteFile(fileId:number, callBack: () => void){
    this.baseService.packageQuery<JWTToken, object>('file', 'files/deleteFile', {file_id: fileId}, callBack);
  }

  EditFile(file:InvexFile, callBack: () => void){
    this.baseService.packageQuery<JWTToken, object>('file', 'files/updateFileData', {file_id: file.id, op_file_name: file.fileName}, callBack);
  }

  FilterFiles(input:string, callBack: (value:FileGroup[]) => void){
    this.fileGroups.forEach(group => {
      if(this.isAdmin) {
        group.internalFiltered = group.internal.filter(x=>x.fileName.toLowerCase().match(input.toLowerCase())!=null);
      }
      group.externalFiltered = group.external.filter(x=>x.fileName.toLowerCase().match(input.toLowerCase())!=null);
    });
    callBack(this.fileGroups);
  }

  getFileType(type:string, callBack: (typeId:number) => void): void {
    this.baseService.packageQuery<JWTToken, object>('file', 'file_type/getFileTypeList', {}, (response) => {
      response.data.list.forEach(typeGroup => {
        typeGroup.allowedTypes.forEach(fileType => {
          if(fileType.allowed_type == type) {
            callBack(fileType.type_id);
          }
        });
      });
    });
  }

  uploadFile(file:InvexFile, fileType:string, hash:string, callBack: (value: ApiResponse<JWTToken>) => void) {
    return this.getFileType(fileType, (type => {
      this.baseService.packageQuery<JWTToken, object>('file', 'files/uploadFile', {source_file_name: file.versions[0].fileName, op_file_name: file.fileName, file_type: type, file_content: hash}, callBack);
    }));
  }

  DownloadFileFromAssets(path:string):void{
    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = path;
    link.download = path;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  getFileContentBase64(fileId: number, callBack: (value: ApiResponse<ApiResponseItem<string>>) => void) {
    return this.baseService.packageQuery<ApiResponseItem<string>, object>('file', 'files/getFileContentBase64', {file_id: fileId}, callBack);
  }
}

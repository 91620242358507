import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { ColorSliderComponent } from './color-picker/color-slider/color-slider.component';
import { ColorPickerDirective } from './directives/color-picker.directive';



@NgModule({
  declarations: [ColorPickerComponent, ColorSliderComponent, ColorPickerDirective],
  imports: [
    CommonModule
  ],
  exports:[
    ColorPickerComponent,
    ColorPickerDirective
  ]
})
export class ColorPickerModule { }

import { Component, OnInit, ViewChild } from '@angular/core';
import { StepsMakerService } from 'src/app/services/project/form/steps-maker.service';
import { StepsDashboardComponent } from './steps-dashboard/steps-dashboard.component';

@Component({
  selector: 'app-steps-maker',
  templateUrl: './steps-maker.component.html',
  styleUrls: ['./steps-maker.component.css']
})
export class StepsMakerComponent implements OnInit {

  constructor(
    // private stepsService: StepsMakerService
  ) { }

  ngOnInit(): void {
  }

  // OnDoneClicked(): void {
  //   this.dashboard.ClearAddmode()
  //   this.isEditmode = !this.isEditmode;
  //   this.stepsService.FixRearrangement();
  // }
}

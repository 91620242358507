<div style="box-sizing: border-box;">
  <div *ngIf="!isEditAllowed || !isEditFileName"
   style="font-weight: bold; font-size: 14px;" (dblclick)="OnFileNameDoubleClick()">
    {{file.fileName}}
  </div>
  <input #fileNameInput *ngIf="isEditAllowed && isEditFileName" type="text" (keydown.enter)="OnEnter($event)" [ngModel]="file.fileName"
  class="popupInput"/>

  <div class="description" *ngIf="!isEditAllowed || !isEditFileDescription" (dblclick)="OnFileDecriptionDoubleClick()">
    {{file.description!==undefined?file.description:'-'}}
  </div>
  <textarea #fileTextInput class="popupTextarea" name="fileDesc" (keydown.enter)="OnEnter($event)" *ngIf="authService.isAdmin && isEditAllowed && isEditFileDescription" placeholder="Leírás..." id="fd" rows="5" [ngModel]="file.description" ></textarea>

  <div class="grid-frame disableScrollBar">
    <div class="grid-row">
        <div style="grid-column: 2;text-align: center;">File Name</div>
        <div style="grid-column: 3;text-align: center;">Uploaded</div>
        <div style="grid-column: 4;text-align: center;">Version</div>
    </div>
    <div style="font-size: 12px;" class="disableSelect" *ngFor="let v of file.versions">
      <hr>
      <div class="grid-row">
        <app-svg-file style="stroke: var(--light);" (click)="OnFileIconClicked()"></app-svg-file>
        <div style="text-align: center;">{{v.fileName}}</div>
        <div style="text-align: center;">{{stringDate(v.uploadDate)}}</div>
        <div style="text-align: center;">{{v.version}}</div>
      </div>
    </div>
  </div>

  <app-file-picker *ngIf="isEditAllowed" (filePathChanged)="OnFileInputchanged($event)"></app-file-picker>
  <app-agree-disagree-buttons *ngIf="isEditAllowed" style="margin-top: 10px;"
  [agreeText]="'common.save' | translate" (cancel)="cancel.emit()" (agree)="OnFileSaveClicked()"></app-agree-disagree-buttons>
  <div *ngIf="!isEditAllowed" style="margin-top: 10px; display: flex; justify-content: center" class="disableSelect">
    <button type="button" (click)="cancel.emit()">Cancel</button>
  </div>
</div>

<div class="header">
  <span class="header-title">{{title}}</span>
  <input class="assing-search-input" type="text" placeholder="{{'common.search' | translate}}" (input)="OnPersonSearchInput($event.target)"/>
</div>

<div class="gridwrap disableScrollBar" [ngStyle]="{ 'height.px': getHeight() }">
  <div class="for-div">
    <div *ngFor="let person of filteredPersonList; let i = index" [ngClass]="{isSelected:i === selectedPersonIndex}"
      class="person-item disableSelect" (click)="OnPersonClicked(i)">
      <img [src]="person.picture" width="65" class="internal-picture" />
      <div style="margin-left: 15px">
        <div style="font-weight: bold; font-size: 20px">
          {{ person.name }}
        </div>
        <div style="font-size: 12px">{{ person.jobTitle }}</div>
      </div>
    </div>
  </div>
</div>
<div class="error">{{errorText}}</div>
<app-agree-disagree-buttons [justifyContent]="'space-around'" [agreeText]="'startup.team.addUser' | translate" [cancelText]="'common.cancel' | translate" (cancel)="cancel.emit()"
(agree)="OnAddUserClicked()"></app-agree-disagree-buttons>

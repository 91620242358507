<div class="header">
  <input *ngIf="isLabelEdit" class="label-input" type="text" [placeholder]="labelCopy" [(ngModel)]="dataMatrix.name[currentLang]" (keydown.enter)="OnLabelEnter()">
  <div class="label" *ngIf="!isLabelEdit && dataMatrix!=undefined" (dblclick)="OnLabelDoubleClick();">{{dataMatrix.GetName(currentLang)}}<span>{{dataMatrix.required?'*':''}}</span></div>
  <div class="important-container" *ngIf="editmode">
    <input type="checkbox" id="important" name="important" [(ngModel)]="dataMatrix.required" (change)="OnRequiredClicked()">
    <label class="importantLabel disableSelect">Important</label>
  </div>
</div>
<div class="description">
  <input *ngIf="isDescriptionEdit" class="label-input" type="text" [placeholder]="descriptionCopy" [(ngModel)]="dataMatrix.small[currentLang]" (keydown.enter)="OnDescriptionEnter()">
  <div class="desc" *ngIf="!isDescriptionEdit && dataMatrix!=undefined" (dblclick)="OnDescriptionDoubleClick();">{{editmode?(dataMatrix.GetDescription(currentLang)==""?('steps.input.doubleClick' | translate):dataMatrix.GetDescription(currentLang)):dataMatrix.GetDescription(currentLang)}}</div>
</div>
<div class="checkbox-column">
  <div></div>
  <div class="column" *ngFor="let labelH of dataMatrix.labelsHorizontal; let j = index">
    <app-svg-step-remove [width]="18" *ngIf="editmode" (click)="OnRadioRemoveClicked(1, j)"></app-svg-step-remove>
    <input class="text-input" type="text" [placeholder]="labelH.GetValue(currentLang)" *ngIf="isOptionEdit.type==1 && isOptionEdit.id==j" (keydown.enter)="OnOptionEnter($event, j)">
    <div class="label-block" [ngClass]="{'checkbox-label': editmode}" *ngIf="isOptionEdit.type!=1 || isOptionEdit.id!=j" (dblclick)="OnOptionDoubleClick(1, j);">{{labelH.GetValue(currentLang)}}</div>
  </div>
  <app-svg-add-action [width]="18" *ngIf="editmode && dataMatrix.labelsHorizontal.length<(isMobileResolution()?4:7)" (click)="OnAddClicked(1)"></app-svg-add-action>
</div>
<div class="checkbox-row" [ngClass]="{'edit': editmode}" *ngFor="let label of dataMatrix.labelsVertical; let i = index">
  <div class="row">
    <app-svg-step-remove [width]="18" *ngIf="editmode" (click)="OnRadioRemoveClicked(0, i)"></app-svg-step-remove>
    <input class="text-input" type="text" [placeholder]="label.GetValue(currentLang)" *ngIf="isOptionEdit.type==0 && isOptionEdit.id==i" (keydown.enter)="OnOptionEnter($event, i)">
    <div class="label-block" [ngClass]="{'checkbox-label': editmode}" *ngIf="isOptionEdit.type!=0 || isOptionEdit.id!=i" (dblclick)="OnOptionDoubleClick(0, i);">{{label.GetValue(currentLang)}}</div>
  </div>
  <input type="checkbox" *ngFor="let labelH of dataMatrix.labelsHorizontal; let j = index"
  (change)="OnChecked(i,j,$event.target)" [disabled]="readonly">
  <div *ngIf="editmode && dataMatrix.labelsHorizontal.length<(isMobileResolution()?4:7)"></div>
</div>
<div class="checkbox-row" [ngClass]="{'edit': editmode}" *ngIf="editmode && dataMatrix.labelsVertical.length<(isMobileResolution()?10:12)" (click)="OnAddClicked(0)">
  <div class="add" translate>
    <app-svg-add-action [width]="18"></app-svg-add-action>
    steps.input.dataMatrix.addRow
  </div>
</div>

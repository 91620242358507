<div style="height: 28px;">
  <svg xmlns="http://www.w3.org/2000/svg" width="72" height="28" viewBox="0 0 72 28">
    <g id="edit" transform="translate(-1606 -134)">
      <rect id="Rectangle_432" data-name="Rectangle 432" width="72" height="28" rx="8" transform="translate(1606 134)" fill="#29487e"/>
      <g id="edit-2" data-name="edit" transform="translate(1618.182 140.121)">
        <path id="Path_925" data-name="Path 925" d="M7.733,4H3.274A1.274,1.274,0,0,0,2,5.274v8.919a1.274,1.274,0,0,0,1.274,1.274h8.919a1.274,1.274,0,0,0,1.274-1.274V9.733" transform="translate(0 -0.77)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
        <path id="Path_926" data-name="Path 926" d="M14.689,2.274A1.351,1.351,0,0,1,16.6,4.186l-6.052,6.052L8,10.875l.637-2.548Z" transform="translate(-2.178)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
      </g>
      <text id="EDIT-3" data-name="EDIT" transform="translate(1641 153)" fill="#fff" font-size="12" font-family="Oxygen-Bold, Oxygen" font-weight="700"><tspan x="0" y="0" translate>svgEdit.edit</tspan></text>
    </g>
  </svg>
</div>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-step',
  templateUrl: './svg-step.component.html'
})
export class SvgStepComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-startup-navigator',
  templateUrl: './startup-navigator.component.html',
  styleUrls: ['./startup-navigator.component.css']
})
export class StartupNavigatorComponent implements OnInit {

  isSettingsClicked:boolean=false;
  @Input() isPortfolio:boolean = false;

  @Output() settingsClicked:EventEmitter<void> = new EventEmitter<void>();
  constructor() { }

  ngOnInit(): void {
  }
  OnSettingsClicked():void{
    this.isSettingsClicked= !this.isSettingsClicked;
    this.settingsClicked.emit();
  }
}
